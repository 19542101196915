import React from "react";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { IProspectFetch } from "../../contracts/data/IProspectFetch";
import { Table } from "react-bootstrap";
import TablePagination from "./TablePagination";

interface ReactTableProps<T extends object> {
  data: T[];
  columns: ColumnDef<T>[];
  title?: React.ReactNode;
  payload: IProspectFetch;
  updateTable: (type: any, newState: any) => void;
  totalSize: number;
}

const TableList = <T extends object>({ data, columns, title, payload, updateTable, totalSize }: ReactTableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="TableList">
      {title ? <h2>{title}</h2> : null}
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      width: header.getSize() !== 150 ? header.getSize() : undefined,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={`d-flex align-items-center text-nowrap ${
                          header.column.columnDef.enableSorting === false ? "disabled" : "enable"
                        }`}
                        onClick={() =>
                          updateTable("sort", {
                            ...payload,
                            sortField: header.id,
                            sortOrder: payload.sortOrder === "desc" ? "asc" : "desc",
                          })
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.columnDef.enableSorting !== false ? (
                          <span
                            className={`fs-5 mb-1 ${
                              header?.id === payload.sortField ? (payload.sortOrder === "desc" ? "desc" : "asc") : ""
                            }`}
                          ></span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <TablePagination
        updateTable={updateTable}
        payload={payload}
        sizePerPageOptions={[10, 15, 20, 40, 80]}
        totalSize={totalSize}
      />
    </div>
  );
};

export default TableList;
