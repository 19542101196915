import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Component } from "react";
import React from "react";
import logo from "./../../assets/app_header_logo_white.png";

type TLoginLayoutProps = {
  children: JSX.Element;
}

export class LoginLayout extends Component<TLoginLayoutProps> {
  render() {
    return (
      <Container className={"LoginLayout"}>
        <Row>
          <Col sm={3} md={4}></Col>
          <Col sm={6} md={4}>
            <div className={"LoginLogo"}>
              <img src={logo} alt={"Logo"} />
            </div>
            {this.props.children}
          </Col>
          <Col sm={3} md={4}></Col>
        </Row>
      </Container>
    );
  }
}
