import { IMeeting } from "./IMeeting";
import { ISale } from "./ISale";

export enum LeadChannel {
  Booking = 1,
  Aktia,
  Veritas,
  ReturningClient,
  Digital,
  CustomerReference,
  Jeppis,
}

export interface ISalesAnalyticsData {
  id: string | null;
  prospectId: string;
  leadChannel: LeadChannel | null;
  firstMeetingBookingDate: string | null;
  meetings: IMeeting[];
  sales: ISale[];
}
