import React, { Component } from "react";
import { IProspect } from "../../../contracts/data/IProspect";
import { IInsurance, InsuranceType } from "../../../contracts/data/IInsurance";
import { withTranslation, WithTranslation } from "react-i18next";
import { Alert, Col, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { IInsuranceDetail, InsuranceDetailType } from "../../../contracts/data/IInsuranceDetail";
import { findInsuranceDetail } from "../../../utils/appUtils";
import { Link } from "react-router-dom";
import * as paths from "../../Routes/routePaths";
import { motion } from "framer-motion";
import { IInsuranceExample } from "../../../contracts/data/IInsuranceExample";
import ProspectInsuranceDetailSummaryRows from "./ProspectInsuranceDetailSummaryRows";
import ProspectInsuranceDetailSummaryContains from "./ProspectInsuranceDetailSummaryContains";

type TProspectInsuranceDetailSummaryProps = {
  prospect: IProspect;
  insurance: IInsurance;
  summaryViewName?: string;
  currentInsuranceId: string;
  currentInsuranceDetailId?: string;
  readonly: boolean;
  navigateToInsuranceView(insuranceId: string, push?: boolean): any;
  navigateToInsuranceDetailView(insuranceId: string, insuranceDetailId: string, push?: boolean): any;
  navigateToDetailedView(push?: boolean): any;
} & WithTranslation;

type TProspectInsuranceDetailSummaryState = {};

class ProspectInsuranceDetailSummary extends Component<
  TProspectInsuranceDetailSummaryProps,
  TProspectInsuranceDetailSummaryState
> {
  renderAnnualAndMonthlySummary = (insurance: IInsurance) => {
    const { t } = this.props;

    let title = "";
    let letter = "";

    switch (insurance.type) {
      case InsuranceType.Current: {
        title = t("Current insurance");
        letter = "A";
        break;
      }
      case InsuranceType.Alternative: {
        title = t("Alternative insurance");
        letter = "B";
        break;
      }
      case InsuranceType.Offer: {
        title = t("Company security");
        letter = "C";
        break;
      }
      case InsuranceType.Omsen: {
        title = t("Ålands Försäkringar Ab");
        letter = "D";
        break;
      }
    }

    return (
      <Row>
        <Col xs={"5"} md={"3"} lg={"2"}>
          <div className={"insurance-letter"}>
            <span>{letter}</span>
          </div>
        </Col>
        <Col xs={"7"} md={"9"} lg={"9"}>
          <div className={"insurance-cost-details ms-4"}>
            <h2 className={"mb-0"}>{title}</h2>
            {insurance.type !== InsuranceType.Offer && (
              <Row>
                <Col xs={"6"} md={"5"}>
                  <h4 className={"mt-4 mb-0"}>{t("Annual cost")}</h4>
                  <h3 className={"annual-cost mb-0"}>
                    <NumericFormat
                      value={insurance.annualCost}
                      displayType={"text"}
                      thousandSeparator={" "}
                      decimalScale={0}
                    />
                    <span className={"value-suffix"}> €</span>
                  </h3>
                </Col>
                <Col xs={"6"} md={"5"} className={`${insurance.type === InsuranceType.Omsen ? "invisible" : ""}`}>
                  <h4 className={"mt-4 mb-0"}>{t("Monthly cost")}</h4>
                  <h3 className={"annual-cost mb-0"}>
                    <NumericFormat
                      value={insurance.monthlyCost}
                      displayType={"text"}
                      thousandSeparator={" "}
                      decimalScale={0}
                    />
                    <span className={"value-suffix"}> €</span>
                  </h3>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    );
  };

  getLinkToInsuranceDetailParent = (parents: IInsuranceDetail[]) => {
    const { prospect, insurance } = this.props;
    const parent = parents.length > 0 ? parents[parents.length - 1] : undefined;

    if (parent === undefined) {
      return paths.prospectInsurance
        .replace(":prospectId", prospect.id)
        .replace(":prospectViewName", "detailed")
        .replace(":insuranceId", insurance.id);
    }

    return paths.prospectInsuranceDetail
      .replace(":prospectId", prospect.id)
      .replace(":prospectViewName", "detailed")
      .replace(":insuranceId", insurance.id)
      .replace(":insuranceDetailId", parent.id);
  };

  renderExamples = (examples: IInsuranceExample[]) => {
    if (examples.length === 0) return null;

    const { t } = this.props;
    const sortedExamples = examples.slice().sort((a, b) => a.sortOrder - b.sortOrder);
    return (
      <div>
        <p className={"mt-4"}>{t("payment examples")}:</p>
        <Row>
          {sortedExamples.map((e, index) => {
            return (
              <Col xs={6} lg={3} key={`${e.title}_${index}`}>
                {/* We use the title from the backend set as the translatable key */}
                <h4 className={"mb-0"}>{t(e.title)}</h4>
                <h3 className={"example-title"}>
                  <span>
                    <NumericFormat value={e.value} displayType={"text"} thousandSeparator={" "} decimalScale={0} />
                  </span>
                  <span className={"value-suffix"}> {t(e.unit)}</span>
                </h3>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  renderCostBreakdown = (insurance: IInsurance) => {
    type TCostBreakdownRow = {
      id: string;
      title: string;
      annualCost: number;
      monthlyCost: number;
    };

    const { t } = this.props;

    const getOptionalInsuranceDetailTitle = (insuranceDetail: IInsuranceDetail, insurance: IInsurance): string => {
      switch (insuranceDetail.type) {
        case InsuranceDetailType.PermanentDisabilityInsurance:
          return t("Disability pension one-off payment");
        case InsuranceDetailType.FamilyPensionOneOff:
          return t("Family pension one-off payment");
        case InsuranceDetailType.OldAgeExtraPension: {
          let title = t("Old age pension extra");
          if (insurance.optionalOldAgeExtraPensionStartAge && insurance.optionalOldAgeExtraPensionEndAge) {
            title += ` ${insurance.optionalOldAgeExtraPensionStartAge}-${insurance.optionalOldAgeExtraPensionEndAge}v`;
          }
          return title;
        }
        case InsuranceDetailType.SicknessPayOptional:
          return t("Sickness benefit daily payment");
        case InsuranceDetailType.SeriousIllnessOneOff:
          return t("Serious illness one-off payment");
        case InsuranceDetailType.LifeInsurance:
          return t("Life insurance");
        default:
          return String(insuranceDetail.type);
      }
    };

    const rows: TCostBreakdownRow[] = [];
    rows.push({
      id: "yel",
      title: t("YEL"),
      annualCost: insurance.annualCostYel,
      monthlyCost: insurance.annualCostYel / 12,
    });

    // Get the nested insurance details which have an annual cost
    const optionalInsuranceDetails = insurance.details
      .flatMap((detail) => detail.children)
      .filter((row) => row.annualCost > 0);

    // ..then add the details which don't have any children, but still have an annual cost
    optionalInsuranceDetails.push(
      ...insurance.details.filter((detail) => detail.children?.length === 0 && detail.annualCost > 0),
    );

    rows.push(
      ...optionalInsuranceDetails.map((detail) => {
        const row: TCostBreakdownRow = {
          id: detail.id,
          title: getOptionalInsuranceDetailTitle(detail, insurance),
          annualCost: detail.annualCost,
          monthlyCost: detail.annualCost / 12,
        };
        return row;
      }),
    );

    // Add the total sums as the last row
    rows.push({
      id: "total",
      title: t("total"),
      annualCost: insurance.annualCost,
      monthlyCost: insurance.annualCost / 12,
    });

    return (
      <>
        <Row className={"details"}>
          <Col xs={{ span: 4, offset: 4 }} className={"details-label text-end"}>
            {t("Annual cost")}
          </Col>
          <Col xs={4} className={"details-label text-end"}>
            {t("Monthly cost")}
          </Col>
        </Row>
        {rows.map((row) => (
          <Row key={row.id} className={"details"}>
            <Col xs={4} className={"details-label"}>
              {row.title}
            </Col>
            <Col xs={4} className={"cost-value text-end"}>
              <span>
                <NumericFormat value={row.annualCost} displayType={"text"} thousandSeparator={" "} decimalScale={0} />
              </span>
              <span className={"value-suffix"}>€</span>
            </Col>
            <Col xs={4} className={"cost-value text-end"}>
              <span>
                <NumericFormat value={row.monthlyCost} displayType={"text"} thousandSeparator={" "} decimalScale={0} />
              </span>
              <span className={"value-suffix"}>€</span>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  renderDataSummary = (insurance: IInsurance) => {
    const { t, currentInsuranceDetailId, summaryViewName } = this.props;

    if (currentInsuranceDetailId !== undefined) {
      const result = findInsuranceDetail(currentInsuranceDetailId, insurance.details);
      if (result !== undefined) {
        return (
          <>
            <p className={"mt-3"}>
              {!this.props.readonly ? (
                <Link to={this.getLinkToInsuranceDetailParent(result.parents)} className={"link link-primary"}>
                  {t("Your benefits")}
                </Link>
              ) : (
                <span className={"readonlyLink"}>{t("Your benefits")}</span>
              )}
              <span> / </span>
              <ProspectInsuranceDetailSummaryContains insuranceDetail={result.data} />
              <span>:</span>
            </p>
            <ProspectInsuranceDetailSummaryRows
              currentInsuranceId={this.props.currentInsuranceId}
              insurance={insurance}
              rows={result.data.children}
              parentRow={result.data}
              navigateToInsuranceDetailView={this.props.navigateToInsuranceDetailView}
              readonly={this.props.readonly}
            />
            {result.data.examples ? this.renderExamples(result.data.examples) : null}
            {result.data.description ? (
              <Row>
                <Col>
                  <Alert variant={"info"}>{result.data.description}</Alert>
                </Col>
              </Row>
            ) : null}
          </>
        );
      } else {
        return <p>Insurance data not found</p>;
      }
    } else if (summaryViewName === "costs") {
      return (
        <>
          <p className={"mt-5 text-uppercase"}>{t("AKTIA and VERITAS yearly and monthly cost breakdown")}:</p>
          {this.renderCostBreakdown(insurance)}
        </>
      );
    } else {
      return (
        <>
          <p className={"mt-5 text-uppercase"}>{t("Your benefits here with earnings before taxes")}:</p>
          <ProspectInsuranceDetailSummaryRows
            currentInsuranceId={this.props.currentInsuranceId}
            insurance={insurance}
            rows={insurance.details}
            navigateToInsuranceDetailView={this.props.navigateToInsuranceDetailView}
            readonly={this.props.readonly}
          />
        </>
      );
    }
  };

  render() {
    const { insurance, currentInsuranceId, currentInsuranceDetailId } = this.props;

    if (insurance === undefined) {
      return <p>Invalid insurance</p>;
    }

    if (!insurance.isVisible && insurance.type !== InsuranceType.Omsen) {
      return this.props.navigateToDetailedView(false);
    }

    const key: string = currentInsuranceId + currentInsuranceDetailId;

    return (
      <div className={"ProspectInsuranceDetailSummary"} key={key}>
        {this.renderAnnualAndMonthlySummary(insurance)}
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: "easeIn", duration: 0.5 }}>
          {this.renderDataSummary(insurance)}
        </motion.div>
      </div>
    );
  }
}

export default withTranslation("translations")(ProspectInsuranceDetailSummary);
