import React, { Component } from "react";

type TAppSidebarButtonProps = {
  buttonType: string;
  isActive: boolean;
  onButtonClick(buttonType: string): void;
  children?: JSX.Element;
};

type TAppSidebarButtonState = {};

class AppSidebarButton extends Component<TAppSidebarButtonProps, TAppSidebarButtonState> {
  render() {
    const isActiveClass = this.props.isActive ? " active" : "";
    const buttonTypeClass = " AppSidebarButton-" + this.props.buttonType;

    return (
      <div
        className={"my-3 AppSidebarButton" + buttonTypeClass + isActiveClass}
        onClick={() => (this.props.isActive ? this.props.onButtonClick(this.props.buttonType) : null)}
      >
        {this.props.children}
      </div>
    );
  }
}

export default AppSidebarButton;
