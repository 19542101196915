import { IAuthenticationService } from "../contracts/services/IAuthenticationService";
import { IStorageService } from "../contracts/services/IStorageService";
import { IProspectService } from "../contracts/services/IProspectService";
import { IApiService } from "../contracts/services/IApiService";

export class ServiceContainer {
  authenticationService: IAuthenticationService;
  storageService: IStorageService;
  prospectService: IProspectService;
  apiService: IApiService;

  constructor(
    authenticationService: IAuthenticationService,
    storageService: IStorageService,
    prospectService: IProspectService,
    apiService: IApiService,
  ) {
    this.authenticationService = authenticationService;
    this.storageService = storageService;
    this.prospectService = prospectService;
    this.apiService = apiService;
  }
}
