import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { WithTranslation, withTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { IProspect, OmsenState } from "../../contracts/data/IProspect";
import FormikIndustrialClassificationPicker from "./FormikIndustrialClassificationPicker";
import FormikCountryPicker from "./FormikCountryPicker";
import Beneficiaries from "./Beneficiaries";
import FormikInput from "./FormikInput";
import { isValueNullOrEmptyString } from "../../utils/appUtils";

type Props = {
  name: string;
  prospect: IProspect;
} & WithTranslation;

const LegalPerson: React.FC<Props> = ({ name, t, prospect }) => {
  const formikProps = useFormikContext<any>();

  const setDefaultValues = async () => {
    const { omsenData } = prospect;
    if (isValueNullOrEmptyString(omsenData?.proposal?.policyHolder?.kycLegalPerson?.industrialClassificationCode)) {
      await formikProps.setFieldValue(`${name}.industrialClassificationCode`, prospect.businessIndustryCode);
    }

    if(isValueNullOrEmptyString(omsenData?.proposal?.policyHolder?.kycLegalPerson?.contactPersonName)){
      await formikProps.setFieldValue(`${name}.contactPersonName`, prospect.companyContactPerson);
    }

    if(isValueNullOrEmptyString(omsenData?.proposal?.policyHolder?.kycLegalPerson?.contactPersonPhone)){
      await formikProps.setFieldValue(`${name}.contactPersonPhone`, prospect.companyPhone);
    }

    if(isValueNullOrEmptyString(omsenData?.proposal?.policyHolder?.kycLegalPerson?.contactPersonEmail)){
      await formikProps.setFieldValue(`${name}.contactPersonEmail`, prospect.companyEmail);
    }
  };

  useEffect(() => {
    setDefaultValues().catch();
  }, []);

  return (
    <Form.Group className={"form-group"}>
      <FormikIndustrialClassificationPicker
        name={`${name}.industrialClassificationCode`}
        title={t("Industrial classification")}
        disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
      />
      <FormikCountryPicker
        name={`${name}.countryOfRegistrationNumber`}
        title={t("Country Of Registration")}
        valueType={"number"}
        disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
      />
      <FormikCountryPicker
        name={`${name}.countriesOfTaxationNumbers`}
        title={t("Countries of taxation")}
        isMulti
        disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
      />
      <h5 className="AppSidebar-subheader">{t("Company contact")}</h5>
      <FormikInput
        name={`${name}.contactPersonName`}
        title={t("Name")}
        disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
      />
      <FormikInput
        name={`${name}.contactPersonPhone`}
        title={t("Phonenumber")}
        disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
      />
      <FormikInput
        name={`${name}.contactPersonEmail`}
        title={t("Email")}
        disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
      />
      <h5 className="AppSidebar-subheader">{t("Real Beneficiaries")}</h5>
      <Beneficiaries name={`${name}.realBeneficiaries`} prospect={prospect}/>
    </Form.Group>
  );
};

export default withTranslation("translations")(LegalPerson);
