import { IProspectHistory } from "./IProspectHistory";

export enum ProspectState {
  New = 1,
  Invited,
  DataCollected,
}

export enum SalesState {
  SalesProspect = 1,
  Pending,
  Customer,
  Rejected,
}

export interface IProspectBase {
  id: string;
  reference: string;

  firstName: string;
  lastName: string;

  email: string;

  sellerId: string;
  sellerFirstName: string;
  sellerLastName: string;

  companyId: string | null;
  companyName: string | null;
  companyCity: string | null;
  fiscalMonth: number | null;

  meetingDate: string | null;
  view: string;

  state: ProspectState;
  salesState: SalesState;
  hasProspectFilledInData: boolean;
  isMeetingActive: boolean;
  prospectHistories: IProspectHistory[] | null;
}
