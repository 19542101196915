import React, { useEffect, useState } from "react";
import { Alert, AlertProps } from "react-bootstrap";

const AlertAutoDismissible = (props: AlertProps) => {
  const [shown, setShown] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShown(false);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []); // Run only once

  const successColors = {
    color: '#155724',
    backgroundColor: '#d4edda',
    borderColor: '"c3e6cb'
  }

  const dangerColors =  {
    color: '#721c24',
    backgroundColor: '#f8d7da',
    borderColor: '"f5c6cb'
  }

  const alertColors = props.variant === 'danger' ? dangerColors : successColors;

  return (
    <Alert {...props}
      style={alertColors}
      show={props.variant !== "danger" ? shown : true}>
      {props.children}
    </Alert>
  );
};

export default AlertAutoDismissible;
