import React, {Component} from "react";
import {ErrorMessage, FieldArray, FormikProps} from "formik";
import {Col, Container, Form, FormCheck, Row} from "react-bootstrap";
import {IProspect} from "../../../contracts/data/IProspect";
import {WithTranslation, withTranslation} from "react-i18next";
import {MeetingOutcome} from "../../../contracts/data/IMeeting";
import {ISale, SalesProduct, SalesProducts} from "../../../contracts/data/ISale";
import {ISalesAnalyticsData, LeadChannel} from "../../../contracts/data/ISalesAnalyticsData";
import FormikDatePicker from "../../shared/FormikDatePicker";
import {TFormValues} from "../questions/BackgroundInformationForm";
import FormikRadioGroup, {TRadioGroupItem} from "../../shared/FormikRadioGroup";

type TProps = {
  prospect: IProspect;
  formikProps: FormikProps<TFormValues>;
} & WithTranslation;

type TState = {
  isSaleMade: boolean;
};

export type TSalesFormItems = {
  id: string | null;
  salesProduct: SalesProduct;
  price: number;
  sold: boolean;
};

export const getValidSaleProductObjects = (existingSales: ISale[] = []) => {
  let result: TSalesFormItems[] = [];

  existingSales.forEach((s) => {
    result.push({...s, sold: true});
  });

  for (let product in SalesProduct) {
    const value = Number(product);

    if (isNaN(value)) {
      continue;
    }
    if (!existingSales.find((sale) => sale.salesProduct === value)) {
      result.push({id: null, sold: false, salesProduct: value, price: 0});
    }
  }

  result = result.sort((sa, sb) => {
    return sa.salesProduct < sb.salesProduct ? -1 : 1;
  });

  return result;
};

class AnalyticsForm extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      isSaleMade: false,
    };
  }

  render() {
    const {t, prospect, formikProps} = this.props;
    const {values, setValues, setFieldValue, handleChange, handleBlur} = formikProps;

    const getValidChannelValues = () => {
      const result = [];

      result.push([LeadChannel.Booking, t("Booking channel")]);
      result.push([LeadChannel.Aktia, t("Aktia channel")]);
      result.push([LeadChannel.Veritas, t("Veritas channel")]);
      result.push([LeadChannel.ReturningClient, t("Returning client channel")]);
      result.push([LeadChannel.Digital, t("Digital channel")]);
      result.push([LeadChannel.CustomerReference, t("Customer reference channel")]);
      result.push([LeadChannel.Jeppis, t("Jeppis channel")]);

      return result;
    };

    const getValidOutcomes = () => {
      const result = [];

      result.push([MeetingOutcome.SaleCompleted, t("Sale outcome")]);
      result.push([MeetingOutcome.SaleNotCompleted, t("No sale outcome")]);
      result.push([MeetingOutcome.NewMeetingBooked, t("New meeting outcome")]);

      return result;
    };

    const isAnalyticsFilled = (sad: ISalesAnalyticsData | null) => {
      if (
        sad &&
        sad.leadChannel !== null &&
        sad.firstMeetingBookingDate !== null &&
        sad.meetings.length > 0 &&
        !sad.meetings.find((m) => m.date === null && m.outcome === null) &&
        (sad.meetings.find((m) => m.outcome === MeetingOutcome.SaleCompleted)
          ? sad.sales.length > 0 && !sad.sales.find((s) => s.salesProduct === null && s.price === null)
          : true)
      ) {
        return true;
      }
      return false;
    };
    const canShowPrompt = false;

    const appendCurrencyOrQuantity = (product: string): string => {
      if (product && product.toLocaleUpperCase() === "YEL") {
        return t("Quantity")
      } else {
        return "€"
      }
    }

    return (
      <>
        <Form.Group className={"FormikLeadChannelPicker form-group"}>
          <Form.Label>{t("Lead channel")}</Form.Label>
          <Form.Control
            name={"leadChannel"}
            as={"select"}
            value={values["leadChannel"] || undefined}
            onChange={(e) => handleChange(e)}
          >
            {values["leadChannel"] === null || values["leadChannel"] === undefined ? (
              <option value={""} key={"-"}/>
            ) : null}
            {getValidChannelValues().map((x) => (
              <option value={x[0]} key={x[0]}>
                {x[1]}
              </option>
            ))}
          </Form.Control>
          <ErrorMessage name={"leadChannel"} component={"div"} className="custom-invalid-feedback"/>
        </Form.Group>
        <Form.Group className='form-group'>
          <Form.Label>{t("Booking date")}</Form.Label>
          <FormikDatePicker name={"firstMeetingBookingDate"}/>
        </Form.Group>
        <Form.Group className='form-group'>
          <Form.Label>{t("Meeting outcome")}</Form.Label>
          <FieldArray name={"meetings"}>
            {() =>
              values.meetings.map((meeting, i) => {
                return (
                  <Form.Group className='form-group' key={`${meeting.id}_${i}`}>
                    <div>{`${i + 1}. ${t("_Meeting")}`}</div>
                    <FormikDatePicker name={`meetings.${i}.date`} nested={true}/>
                    <FormikRadioGroup
                      title={t("Outcome")}
                      name={`meetings.${i}.outcome`}
                      items={getValidOutcomes().map(
                        (o): TRadioGroupItem => {
                          return {
                            label: String(o[1]),
                            value: o[0],
                          };
                        },
                      )}
                      onChange={(value: any) => {
                        let newValues = {...values};

                        newValues.meetings[i].outcome = Number(value);
                        newValues.sales = getValidSaleProductObjects(prospect.salesAnalyticsData?.sales);

                        switch (+value) {
                          case MeetingOutcome.NewMeetingBooked:
                            this.setState({...this.state, isSaleMade: false});
                            newValues.meetings.push({
                              id: null,
                              date: null,
                              outcome: null,
                              sortOrder: values.meetings[i].sortOrder + 1,
                            });
                            newValues.sales = [];
                            setValues(values, true);
                            break;

                          case MeetingOutcome.SaleNotCompleted:
                            this.setState({...this.state, isSaleMade: false});
                            newValues.meetings = values.meetings.slice(0, i + 1);
                            newValues.sales = [];
                            break;

                          case MeetingOutcome.SaleCompleted:
                            this.setState({...this.state, isSaleMade: true});
                            newValues.meetings = values.meetings.slice(0, i + 1);
                            break;
                        }

                        setValues(newValues, false);
                      }}
                    />
                    <ErrorMessage
                      name={`meetings.${i}.outcome`}
                      component={"div"}
                      className="custom-invalid-feedback"
                    />
                  </Form.Group>
                );
              })
            }
          </FieldArray>
        </Form.Group>
        {this.state.isSaleMade || values.sales.find((s) => s.sold) !== undefined ? (
          <Container fluid>
            <Form.Group className='form-group'>
              <Form.Label>{"Sales"}</Form.Label>
              <FieldArray name={"sales"}>
                {() =>
                  values.sales.map((sale, i) => {
                    return (
                      <Row className={"my-3"} key={`sales_${i}`}>
                        <Col>
                          <Form.Check className={"sales-checkbox"} type={"checkbox"} id={`sales_${i}`}>
                            <FormCheck.Input
                              name={`sales.${i}.sold`}
                              type={"checkbox"}
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                handleChange(e);
                                setFieldValue(`sales.${i}`, {
                                  ...values.sales[i],
                                  sold: e.currentTarget.checked,
                                });
                              }}
                              checked={values.sales[i].sold}
                            />
                            <FormCheck.Label>{SalesProducts[sale.salesProduct]}</FormCheck.Label>
                          </Form.Check>
                        </Col>
                        <Col>
                          <div className={"input-group"}>
                            <Form.Control
                              name={`sales.${i}.price`}
                              type={"number"}
                              placeholder={""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.sales[i].price}
                              disabled={!values.sales[i].sold}
                            />
                            <span
                              className="input-group-text">{appendCurrencyOrQuantity(SalesProducts[sale.salesProduct])}
                            </span>
                          </div>
                          <ErrorMessage
                            name={`sales.${i}.price`}
                            component={"div"}
                            className="custom-invalid-feedback"
                          />
                        </Col>
                      </Row>
                    );
                  })
                }
              </FieldArray>
            </Form.Group>
          </Container>
        ) : null}
      </>
    );
  }
}

export default withTranslation("translations")(AnalyticsForm);
