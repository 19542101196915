import {IInsuranceDetail} from "../../../contracts/data/IInsuranceDetail";
import {useTranslation} from "react-i18next";
import useOmsenProducts from "../../../hooks/useOmsenProducts";
import getInsuranceDetailSummaryTitle from "./utils/getInsuranceDetailSummaryTitle";
import React from "react";

function ProspectInsuranceDetailSummaryContains(props: { insuranceDetail: IInsuranceDetail }) {
  const { t } = useTranslation("translations");
  const { data: omsenProducts } = useOmsenProducts();
  return (<span>
    {getInsuranceDetailSummaryTitle({ insuranceDetail: props.insuranceDetail, page: true, t, omsenProducts})} {t("contains")}
  </span>);
}
export default ProspectInsuranceDetailSummaryContains;
