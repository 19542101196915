import useOmsenApiClient from "./clients/useOmsenApiClient";
import { useQuery } from "@tanstack/react-query";

export default function useOmsenMaintenanceWindow() {
  const client = useOmsenApiClient();

  const getOmsenMaintenanceWindow = async () => {
    const response = await client.apiOmsenMaintenancewindowGet();
    return response.data;
  };

  return useQuery(["omsenMaintenanceWindow"], getOmsenMaintenanceWindow, {
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: 5 * 60 * 1000 // every 5 minutes
  });
}
