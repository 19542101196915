import React, { Component } from "react";
import { IProspect } from "../../../contracts/data/IProspect";
import { TUpdateProspectAction } from "../../../redux/actions/prospect";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Form, Spinner } from "react-bootstrap";
import FormikInput from "../../shared/FormikInput";
import AlertAutoDismissible from "../../shared/AlertAutoDismissible";
import { InsuranceType } from "../../../contracts/data/IInsurance";
import PromptMessage, { SetPromptMessageFunc } from "../../shared/PromptMessage";
import {hasFormikError} from '../../../utils/appUtils';

type TFormValues = {
  estimationOfWorth: number | null;
  personalLoans: number | null;
};

type TProps = {
  isSubmitting: boolean;
  prospect: IProspect;
  updateProspect: TUpdateProspectAction;
  setPromptMessage: SetPromptMessageFunc;
} & WithTranslation;

type TState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
};

class DeemedNeedForm extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      submitResultMessage: "",
      submitResultSuccess: true,
    };
  }

  render() {
    const { t, prospect } = this.props;

    const initialValues: TFormValues = {
      estimationOfWorth: prospect.estimationOfWorth,
      personalLoans: prospect.personalLoans,
    };

    const validationSchema = Yup.object().shape({
      estimationOfWorth: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number().required(t("Required")).lessThan(15062.51, t("Value too high"));
        }
        return Yup.mixed().required(t("Required"));
      }),
      personalLoans: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.number().required(t("Required")).min(0, t("Must be positive"));
        }
        return Yup.mixed().required(t("Required"));
      }),
    });

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          this.setState((state) => ({
            ...state,
            submitResultSuccess: true,
            submitResultMessage: "",
          }));

          prospect.insurances.forEach((insurance) => {
            if (insurance.type === InsuranceType.Offer || insurance.type === InsuranceType.Alternative) {
              insurance.isVisible = true;
            }
          });

          const result = await this.props.updateProspect(prospect.id, { ...values });

          if (result !== null) {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: true,
              submitResultMessage: t("Successfully updated"),
            }));
          } else {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: false,
              submitResultMessage: t("Unable to update"),
            }));
          }
        }}
      >
        {(formikProps) => (
          <Form autoComplete={"off"} noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            <Form.Group className="form-group">
              <FormikInput
                name={"estimationOfWorth"}
                unit={"€/" + t("month_unit")}
                type={"number"}
                title={t("Income requirement / month")}
              />
              <FormikInput name={"personalLoans"} title={t("Personal loans")} type={"number"} unit={"€"} />
            </Form.Group>
            <Form.Group className="form-group">
              <Button variant="primary" type="submit" className={"w-100"} disabled={this.props.isSubmitting}>
                {this.props.isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                  </>
                ) : null}
                {t("Show deemed need")}
              </Button>
              {this.state.submitResultMessage ? (
                <AlertAutoDismissible
                  variant={this.state.submitResultSuccess ? "success" : "danger"}
                  className={"mt-2"}
                >
                  {this.state.submitResultMessage}
                </AlertAutoDismissible>
              ) : null}
            </Form.Group>
            <PromptMessage
              setPromptMessage={this.props.setPromptMessage}
              message={{
                place: t("Questions affecting the deemed need"),
                changes: hasFormikError(formikProps.errors) ? null : formikProps.values,
              }}
              enabled={formikProps.dirty}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(DeemedNeedForm);
