import { IInsuranceExample } from "./IInsuranceExample";

export enum InsuranceDetailType {
  Unknown = 1,
  FamilyPension,
  FamilyPensionOneOff,
  SicknessPay,
  SicknessPayCommon,
  DisabilityPension,
  DisabilityPensionCommon,
  OldAgePension,
  OldAgeWorkPension,
  OldAgeWarrantyPension,
  OldAgeCommonPension,
  FamilyWidowPension,
  FamilyChildPension,
  SicknessPayOptional,
  DisabilityPensionOneOff,
  OldAgeExtraPension,
  SeriousIllnessOneOff,
  LifeInsurance,
  PermanentDisabilityInsurance,
  DisabilityOldAgeCommonPension,
  OmsenPremium,
}

export interface IInsuranceDetail {
  id: string;
  type: InsuranceDetailType;
  monthlyAmount: number;
  totalAmount?: number;
  simpleAmount?: number;
  oneOffAmount: number;
  isOptional: boolean;
  isSummable: boolean;
  annualCost: number;
  savingYears?: number;
  savingsAtBeginningOfPension?: number;
  description: string;
  children: IInsuranceDetail[];
  sortOrder: number;
  examples: IInsuranceExample[] | null;
  omsenProductCode: number | null;
}
