import { action } from "typesafe-actions";
import {
  PROSPECT_FETCH_PROSPECT,
  PROSPECT_FETCH_PROSPECT_FAILURE,
  PROSPECT_FETCH_PROSPECT_SUCCESS,
  PROSPECT_FETCH_PROSPECTS,
  PROSPECT_FETCH_PROSPECTS_FAILURE,
  PROSPECT_FETCH_PROSPECTS_SUCCESS,
  PROSPECT_UPDATE_PROSPECT,
  PROSPECT_UPDATE_PROSPECT_FAILURE,
  PROSPECT_UPDATE_PROSPECT_SUCCESS,
  PROSPECT_PUSH_PROSPECT_VIEW,
  PROSPECT_PUSH_PROSPECT_VIEW_SUCCESS,
  PROSPECT_PUSH_PROSPECT_VIEW_FAILURE,
  PROSPECT_CREATE_PROSPECT_SUCCESS,
  PROSPECT_CREATE_PROSPECT_FAILURE,
  PROSPECT_CREATE_PROSPECT,
  PROSPECT_UPDATE_BASIC_INFORMATION,
  PROSPECT_UPDATE_BASIC_INFORMATION_SUCCESS,
  PROSPECT_UPDATE_BASIC_INFORMATION_FAILURE,
  PROSPECT_FETCH_PROSPECT_EMAIL,
  PROSPECT_FETCH_PROSPECT_EMAIL_SUCCESS,
  PROSPECT_FETCH_PROSPECT_EMAIL_FAILURE,
  PROSPECT_SEND_PROSPECT_EMAIL,
  PROSPECT_SEND_PROSPECT_EMAIL_SUCCESS,
  PROSPECT_SEND_PROSPECT_EMAIL_FAILURE,
  PROSPECT_PUSH_ANALYTICS_DATA,
  PROSPECT_PUSH_ANALYTICS_DATA_SUCCESS,
  PROSPECT_PUSH_ANALYTICS_DATA_FAILURE,
  PROSPECT_FETCH_ANALYTICS_DATA,
  PROSPECT_FETCH_ANALYTICS_DATA_SUCCESS,
  PROSPECT_FETCH_ANALYTICS_DATA_FAILURE,
  PROSPECT_CLEAR_ANALYTICS_DATA,
  PROSPECT_DELETE_PROSPECT_SUCCESS,
  PROSPECT_DELETE_PROSPECT_FAILURE,
  PROSPECT_DELETE_PROSPECT,
  PROSPECT_CREATE_PROSPECT_COMMENT_SUCCESS,
  PROSPECT_CREATE_PROSPECT_COMMENT_FAILURE,
  PROSPECT_CREATE_PROSPECT_COMMENT,
  PROSPECT_FETCH_ALL_SELLERS,
  PROSPECT_FETCH_ALL_SELLERS_FAILURE,
  PROSPECT_FETCH_ALL_SELLERS_SUCCESS,
  PROSPECT_DELETE_PROSPECT_COMMENT,
  PROSPECT_DELETE_PROSPECT_COMMENT_SUCCESS,
  PROSPECT_DELETE_PROSPECT_COMMENT_FAILURE,
  PROSPECT_UPDATE_PROSPECT_COMMENT,
  PROSPECT_UPDATE_PROSPECT_COMMENT_SUCCESS,
  PROSPECT_UPDATE_PROSPECT_COMMENT_FAILURE,
} from "./actionTypes";
import { IProspect } from "../../contracts/data/IProspect";
import { IProspectCreate } from "../../contracts/data/IProspectCreate";
import { IProspectBasicInformation } from "../../contracts/data/IProspectBasicInformation";
import { TAppThunkAction, TAppThunkActionResult } from "../types/stateTypes";
import { IProspectBase } from "../../contracts/data/IProspectBase";
import { ISalesAnalyticsData } from "../../contracts/data/ISalesAnalyticsData";
import { IProspectHistory } from "../../contracts/data/IProspectHistory";
import { ISeller } from "../../contracts/data/ISeller";
import { IProspectFetch } from "../../contracts/data/IProspectFetch";
import { IPaginatedProspects } from "../../contracts/data/IPaginatedProspects";

export type TFetchProspectsThunkAction = (payload: IProspectFetch) => TAppThunkAction<IPaginatedProspects | null>;
export type TFetchProspectsAction = (payload: IProspectFetch) => TAppThunkActionResult<IPaginatedProspects | null>;
export const fetchProspects: TFetchProspectsThunkAction = (payload: IProspectFetch) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_FETCH_PROSPECTS));

    const prospects = await services.prospectService.fetchProspects(payload);
    if (prospects.data) {
      dispatch(fetchProspectsSuccess(prospects.data));
      return prospects.data;
    }

    dispatch(fetchProspectsFailure());
    return null;
  };
};

export const fetchProspectsSuccess = (prospects: IPaginatedProspects) =>
  action(PROSPECT_FETCH_PROSPECTS_SUCCESS, { prospects });
export const fetchProspectsFailure = () => action(PROSPECT_FETCH_PROSPECTS_FAILURE);

export type TFetchProspectByIdThunkAction = (prospectId: string) => TAppThunkAction<IProspect | null>;
export type TFetchProspectByIdAction = (prospectId: string) => TAppThunkActionResult<IProspect | null>;
export const fetchProspectById: TFetchProspectByIdThunkAction = (prospectId: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_FETCH_PROSPECT));

    const result = await services.prospectService.fetchProspectById(prospectId);
    if (result.data) {
      dispatch(fetchProspectSuccess(result.data));
      return result.data;
    }

    dispatch(fetchProspectFailure());
    return null;
  };
};

export type TFetchProspectByReferenceThunkAction = (prospectReference: string) => TAppThunkAction<IProspectBase | null>;
export type TFetchProspectByReferenceAction = (
  prospectReference: string,
) => TAppThunkActionResult<IProspectBase | null>;
export const fetchProspectByReference: TFetchProspectByReferenceThunkAction = (prospectReference: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_FETCH_PROSPECT));

    const result = await services.prospectService.fetchProspectByReference(prospectReference);
    if (result.data) {
      const prospect: IProspect = {
        created: null,
        phoneNumber: null,
        countryOfBirth: null,
        citizenship: null,
        streetAddress: null,
        zipCode: null,
        socialSecurityNumber: null,
        city: null,
        language: null,
        companyCity: null,
        fiscalMonth: null,
        age: null,
        annualIncomeYel: null,
        assets: null,
        birthDate: null,
        childAmount: null,
        childDetails: [],
        companyLoans: null,
        companyContactPerson: null,
        companyEmail: null,
        companyId: null,
        companyLanguage: null,
        companyZipCode: null,
        companyName: null,
        broker: false,
        companyAddress: null,
        companyPhone: null,
        companyOtherOwners: null,
        companyOwnershipPercentage: null,
        companyType: null,
        businessIndustryCode: null,
        businessEmployeesAmount: null,
        businessRevenue: null,
        businessProfit: null,
        email: result.data.email,
        estimationOfWorth: null,
        firstName: result.data.firstName,
        hasProspectFilledInData: result.data.hasProspectFilledInData,
        id: result.data.id,
        insurances: [],
        maritalStatus: null,
        lastName: result.data.lastName,
        marriedYear: null,
        minimumPartialPensionAge: null,
        meetingDate: result.data.meetingDate,
        minimumPensionAge: null,
        monthlyPension: null,
        notes: "",
        numberOfYelYears: null,
        yelDiscount: null,
        optionalLifeInsuranceDiscount: null,
        personalLoans: null,
        reference: result.data.reference,
        sellerId: result.data.sellerId,
        sellerFirstName: result.data.sellerFirstName,
        sellerLastName: result.data.sellerLastName,
        spouseMonthlyPension: null,
        spouseMonthlySalary: null,
        spouseBirthDate: null,
        state: result.data.state,
        salesState: result.data.salesState,
        view: result.data.view,
        yearsUntilPension: null,
        isMeetingActive: result.data.isMeetingActive,
        salesAnalyticsData: null,
        prospectHistories: null,
        omsenData: null,
        omsenState: null
      };
      dispatch(fetchProspectSuccess(prospect));
      return prospect;
    }

    dispatch(fetchProspectFailure());
    return null;
  };
};

export const fetchProspectSuccess = (prospect: IProspectBase) => action(PROSPECT_FETCH_PROSPECT_SUCCESS, { prospect });
export const fetchProspectFailure = () => action(PROSPECT_FETCH_PROSPECT_FAILURE);

export type TUpdateProspectThunkAction = (
  prospectId: string,
  prospect: Partial<IProspect>,
) => TAppThunkAction<IProspect | null>;
export type TUpdateProspectAction = (
  prospectId: string,
  prospect: Partial<IProspect>,
) => TAppThunkActionResult<IProspect | null>;
export const updateProspect: TUpdateProspectThunkAction = (prospectId: string, prospect: Partial<IProspect>) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_UPDATE_PROSPECT));
    const result = await services.prospectService.updateProspect(prospectId, prospect);
    if (result.data) {
      dispatch(updateProspectSuccess(result.data));
      return result.data;
    }

    dispatch(updateProspectFailure());
    return null;
  };
};

export const updateProspectSuccess = (prospect: IProspect) => action(PROSPECT_UPDATE_PROSPECT_SUCCESS, { prospect });
export const updateProspectFailure = () => action(PROSPECT_UPDATE_PROSPECT_FAILURE);

export type TPushProspectViewThunkAction = (prospectId: string, view: string) => TAppThunkAction<boolean>;
export type TPushProspectViewAction = (prospectId: string, view: string) => TAppThunkActionResult<boolean>;
export const pushProspectView: TPushProspectViewThunkAction = (prospectId: string, view: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_PUSH_PROSPECT_VIEW));

    const result = await services.prospectService.pushProspectView(prospectId, view);
    if (result.data) {
      dispatch(pushProspectViewSuccess());
      return result.data;
    }

    dispatch(pushProspectViewFailure());
    return false;
  };
};

export const pushProspectViewSuccess = () => action(PROSPECT_PUSH_PROSPECT_VIEW_SUCCESS);
export const pushProspectViewFailure = () => action(PROSPECT_PUSH_PROSPECT_VIEW_FAILURE);

export type TCreateProspectThunkAction = (prospect: IProspectCreate) => TAppThunkAction<IProspectBase | null>;
export type TCreateProspectAction = (prospect: IProspectCreate) => TAppThunkActionResult<IProspectBase | null>;
export const createProspect: TCreateProspectThunkAction = (prospect: IProspectCreate) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_CREATE_PROSPECT));

    const result = await services.prospectService.createProspect(prospect);
    if (result.data) {
      dispatch(createProspectSuccess(result.data));
      return result.data;
    }

    dispatch(createProspectFailure());
    return null;
  };
};

export const createProspectSuccess = (prospect: IProspectBase) =>
  action(PROSPECT_CREATE_PROSPECT_SUCCESS, { prospect });
export const createProspectFailure = () => action(PROSPECT_CREATE_PROSPECT_FAILURE);

export type TUpdateProspectBasicInformationThunkAction = (
  prospect: IProspect,
  basicInformation: IProspectBasicInformation,
) => TAppThunkAction<IProspect | null>;
export type TUpdateProspectBasicInformationAction = (
  prospect: IProspect,
  basicInformation: IProspectBasicInformation,
) => TAppThunkActionResult<IProspect | null>;
export const updateProspectBasicInformation: TUpdateProspectBasicInformationThunkAction = (
  prospect: IProspect,
  basicInformation: IProspectBasicInformation,
) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_UPDATE_BASIC_INFORMATION));

    const result = await services.prospectService.updateProspectBasicInformation(prospect, basicInformation);
    if (result.data) {
      dispatch(updateProspectBasicInformationSuccess(result.data));
      return result.data;
    }

    dispatch(updateProspectBasicInformationFailure());
    return null;
  };
};

export const updateProspectBasicInformationSuccess = (prospect: IProspect) =>
  action(PROSPECT_UPDATE_BASIC_INFORMATION_SUCCESS, { prospect });
export const updateProspectBasicInformationFailure = () => action(PROSPECT_UPDATE_BASIC_INFORMATION_FAILURE);

export type TFetchProspectEmailThunkAction = (prospectId: string) => TAppThunkAction<string | null>;
export type TFetchProspectEmailAction = (prospectId: string) => TAppThunkActionResult<string | null>;
export const fetchProspectEmail: TFetchProspectEmailThunkAction = (prospectId: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_FETCH_PROSPECT_EMAIL));

    const result = await services.prospectService.fetchProspectEmail(prospectId);
    if (result.data) {
      dispatch(fetchProspectEmailSuccess());
      return result.data;
    }

    dispatch(fetchProspectEmailFailure());
    return null;
  };
};

export const fetchProspectEmailSuccess = () => action(PROSPECT_FETCH_PROSPECT_EMAIL_SUCCESS);
export const fetchProspectEmailFailure = () => action(PROSPECT_FETCH_PROSPECT_EMAIL_FAILURE);

export type TSendProspectEmailThunkAction = (prospectId: string) => TAppThunkAction<boolean>;
export type TSendProspectEmailAction = (prospectId: string) => TAppThunkActionResult<boolean>;
export const sendProspectEmail: TSendProspectEmailThunkAction = (prospectId: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_SEND_PROSPECT_EMAIL));

    const result = await services.prospectService.sendProspectEmail(prospectId);
    if (result.data) {
      dispatch(sendProspectEmailSuccess());
      return result.data;
    }

    dispatch(sendProspectEmailFailure());
    return false;
  };
};

export const sendProspectEmailSuccess = () => action(PROSPECT_SEND_PROSPECT_EMAIL_SUCCESS);
export const sendProspectEmailFailure = () => action(PROSPECT_SEND_PROSPECT_EMAIL_FAILURE);

export type TPushAnalyticsDataThunkAction = (
  analyticsData: ISalesAnalyticsData,
) => TAppThunkAction<ISalesAnalyticsData | null>;
export type TPushAnalyticsDataAction = (
  analyticsData: ISalesAnalyticsData,
) => TAppThunkActionResult<ISalesAnalyticsData | null>;
export const pushAnalyticsData: TPushAnalyticsDataThunkAction = (analyticsData: ISalesAnalyticsData) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_PUSH_ANALYTICS_DATA));

    const result = await services.prospectService.pushAnalyticsData(analyticsData);
    if (result.data) {
      dispatch(pushAnalyticsDataSuccess(result.data));
      return result.data;
    }

    dispatch(pushAnalyticsDataFailure);
    return null;
  };
};

export const pushAnalyticsDataSuccess = (analyticsData: ISalesAnalyticsData) =>
  action(PROSPECT_PUSH_ANALYTICS_DATA_SUCCESS, analyticsData);
export const pushAnalyticsDataFailure = () => action(PROSPECT_PUSH_ANALYTICS_DATA_FAILURE);

export type TFetchAnalyticsDataThunkAction = (prospectId: string) => TAppThunkAction<ISalesAnalyticsData | null>;
export type TFetchAnalyticsDataAction = (prospectId: string) => TAppThunkActionResult<ISalesAnalyticsData | null>;
export const fetchAnalyticsData: TFetchAnalyticsDataThunkAction = (prospectId: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_FETCH_ANALYTICS_DATA));

    const result = await services.prospectService.fetchAnalyticsData(prospectId);
    if (result.data) {
      dispatch(fetchAnalyticsDataSuccess(result.data));
      return result.data;
    }

    dispatch(fetchAnalyticsDataFailure);
    return null;
  };
};

export const fetchAnalyticsDataSuccess = (analyticsData: ISalesAnalyticsData) =>
  action(PROSPECT_FETCH_ANALYTICS_DATA_SUCCESS, analyticsData);
export const fetchAnalyticsDataFailure = () => action(PROSPECT_FETCH_ANALYTICS_DATA_FAILURE);

export type TClearAnalyticsDataThunkAction = () => TAppThunkAction;
export type TClearAnalyticsDataAction = () => TAppThunkActionResult<void>;
export const clearAnalyticsData: TClearAnalyticsDataThunkAction = () => {
  return async (dispatch) => {
    dispatch(action(PROSPECT_CLEAR_ANALYTICS_DATA));
  };
};

export type TProspectDeleteThunkAction = (prospectId: string) => TAppThunkAction<IProspectBase | null>;
export type TProspectDeleteAction = (prospectId: string) => TAppThunkActionResult<IProspectBase | null>;

export const deleteProspect: TProspectDeleteThunkAction = (prospectId: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_DELETE_PROSPECT));
    const result = await services.prospectService.deleteProspect(prospectId);
    if (result.data) {
      dispatch(action(PROSPECT_DELETE_PROSPECT_SUCCESS, result));
      return result.data;
    } else {
      dispatch(action(PROSPECT_DELETE_PROSPECT_FAILURE));
      return null;
    }
  };
};

export type TCreateProspectCommentThunkAction = (prospectId: string, comment: string) => TAppThunkAction<IProspectHistory | null>;
export type TCreateProspectCommentAction = (prospectId: string, comment: string) => TAppThunkAction<IProspectHistory | null>;
export const createProspectComment: TCreateProspectCommentThunkAction = (prospectId: string, comment: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_CREATE_PROSPECT_COMMENT));

    const result = await services.prospectService.createProspectComment(prospectId, comment);
    if (result.data) {
      dispatch(action(PROSPECT_CREATE_PROSPECT_COMMENT_SUCCESS, result));
      return result.data;
    }

    dispatch(action(PROSPECT_CREATE_PROSPECT_COMMENT_FAILURE));
    return null;
  };
};

export const createProspectCommentSuccess = (prospectId: string, comment: string) =>
  action(PROSPECT_CREATE_PROSPECT_COMMENT_SUCCESS, { comment });
export const createProspectCommentFailure = () => action(PROSPECT_CREATE_PROSPECT_COMMENT_FAILURE);

export type TFetchAllSellersThunkAction =  () => TAppThunkAction<ISeller[] | null>;
export type TFetchAllSellersAction = () => TAppThunkActionResult<ISeller[] | null>;
export const fetchAllSellers: TFetchAllSellersThunkAction = () => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_FETCH_ALL_SELLERS));

    const sellers = await services.prospectService.fetchAllSellers();
    if (sellers.data) {
      dispatch(fetchAllSellersSuccess(sellers.data));
      return sellers.data;
    }
    dispatch(fetchAllSellersFailure());
    return null;
  }
}
export const fetchAllSellersSuccess = (sellers: ISeller[]) =>
  action(PROSPECT_FETCH_ALL_SELLERS_SUCCESS, { sellers });
export const fetchAllSellersFailure = () => action(PROSPECT_FETCH_ALL_SELLERS_FAILURE);

export type TUpdateProspectCommentThunkAction = (id: string, comment: string) => TAppThunkAction<IProspectHistory | null>;
export type TUpdateProspectCommentAction = (id: string, comment: string) => TAppThunkAction<IProspectHistory | null>;
export const updateProspectComment: TUpdateProspectCommentThunkAction = (id: string, comment: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_UPDATE_PROSPECT_COMMENT));

    const result = await services.prospectService.updateProspectComment(id, comment)
    if (result.data) {
      dispatch(action(PROSPECT_UPDATE_PROSPECT_COMMENT_SUCCESS, result));
      return result.data;
    }

    dispatch(action(PROSPECT_UPDATE_PROSPECT_COMMENT_FAILURE));
    return null;
  }
}

export type TDeleteProspectCommentThunkAction = (commentId: string) => TAppThunkAction<IProspectHistory | null>;
export type TDeleteProspectCommentAction = (commentId: string) => TAppThunkActionResult<IProspectHistory | null>;

export const deleteProspectComment: TDeleteProspectCommentThunkAction = (commentId: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(PROSPECT_DELETE_PROSPECT_COMMENT));

    const result = await services.prospectService.deleteProspectComment(commentId);
    if (result.data) {
      dispatch(action(PROSPECT_DELETE_PROSPECT_COMMENT_SUCCESS, result));
      return result.data;
    } else {
      dispatch(action(PROSPECT_DELETE_PROSPECT_COMMENT_FAILURE));
      return null;
    }
  };
};
