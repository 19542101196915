import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { IProspect, MaritalStatus } from "../../../contracts/data/IProspect";
import { Col, Row } from "react-bootstrap";
import { dateFormat_DDMMYYYY, dateTimeFormat_YYYYMMDD_HHmmss } from "../../../constants/dateFormats";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import FadingContainer from "../../shared/FadingContainer";
import { InsuranceType } from "../../../contracts/data/IInsurance";

type TProspectBasicDetailsProps = {
  prospect: IProspect;
  readonly: boolean;
  navigateToDetailedView(push: boolean): any;
} & WithTranslation;

type TProspectBasicDetailsState = {};

class ProspectBasicView extends Component<TProspectBasicDetailsProps, TProspectBasicDetailsState> {
  render() {
    const { t, prospect } = this.props;

    const hasVisibleInsurances = prospect.insurances.find((i) => i.isVisible === true && i.type !== InsuranceType.Comparison) !== undefined;

    if (hasVisibleInsurances) {
      return this.props.readonly === false ? this.props.navigateToDetailedView(false) : <div></div>;
    }

    const birthDate = moment(prospect.birthDate, dateTimeFormat_YYYYMMDD_HHmmss, true);

    type TInformationItem = {
      itemProperty: any;
      itemElement(): any;
    };

    const basicInformationItems: TInformationItem[] = [];
    const householdInformationItems: TInformationItem[] = [];

    basicInformationItems.push({
      itemProperty: prospect.companyName,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"companyName"}>
          <FadingContainer
            trigger={"companyName_" + String(prospect.companyName) + "_" + String(prospect.companyOwnershipPercentage)}
          >
            <div className={"h3 mb-0 item-title"}>{t("Company name")}</div>
            <div className={"h3 mb-0 item-value"}>{prospect.companyName}</div>
          </FadingContainer>
        </Col>
      ),
    });

    basicInformationItems.push({
      itemProperty: prospect.companyId,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"companyId"}>
          <FadingContainer
            trigger={"companyId" + String(prospect.companyId)}
          >
            <div className={"h3 mb-0 item-title"}>{t("Company id")}</div>
            <div className={"h3 mb-0 item-value"}>{prospect.companyId}</div>
          </FadingContainer>
        </Col>
      ),
    });

    basicInformationItems.push({
      itemProperty: prospect.birthDate,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"birthDate"}>
          <FadingContainer trigger={"birthDate_" + String(prospect.birthDate)}>
            <div className={"h3 mb-0 item-title"}>{t("Birthdate")}</div>
            <div className={"h3 mb-0 item-value"}>
              {birthDate.isValid() ? birthDate.format(dateFormat_DDMMYYYY) : "-"}
            </div>
          </FadingContainer>
        </Col>
      ),
    });

    basicInformationItems.push({
      itemProperty: prospect.maritalStatus,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"isMarried"}>
          <FadingContainer
            trigger={
              "isMarried_" + String(prospect.maritalStatus === MaritalStatus.Married) + String(prospect.marriedYear)
            }
          >
            <div className={"h3 mb-0 item-title"}>{t("Married")}</div>
            <div className={"h3 mb-0 item-value"}>
              {prospect.maritalStatus === MaritalStatus.Married &&
              prospect.marriedYear !== null &&
              prospect.marriedYear > 0
                ? prospect.marriedYear
                : prospect.maritalStatus === MaritalStatus.Married
                ? t("Yes")
                : t("No")}
            </div>
          </FadingContainer>
        </Col>
      ),
    });

    basicInformationItems.push({
      itemProperty: prospect.childAmount,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"childAmount"}>
          <FadingContainer trigger={"childAmount_" + String(prospect.childAmount)}>
            <div className={"h3 mb-0 item-title"}>{t("Number of children")}</div>
            <div className={"h3 mb-0 item-value"}>{prospect.childAmount}</div>
          </FadingContainer>
        </Col>
      ),
    });

    const underAgeChildren = prospect.childDetails.filter((x) => x.age !== null && x.age < 18);
    basicInformationItems.push({
      itemProperty: prospect.childAmount,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"numberOfUnderAgeChildren"}>
          <FadingContainer trigger={"numberOfUnderAgeChildren_" + String(underAgeChildren.length)}>
            <div className={"h3 mb-0 item-title"}>{t("Underage children")}</div>
            <div className={"h3 mb-0 item-value"}>{underAgeChildren.length}</div>
          </FadingContainer>
        </Col>
      ),
    });

    householdInformationItems.push({
      itemProperty: prospect.annualIncomeYel,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"annualIncomeYel"}>
          <FadingContainer trigger={"annualIncomeYel_" + String(prospect.annualIncomeYel)}>
            <div className={"h3 mb-0 item-title"}>{t("YEL income")}</div>
            <div className={"h3 mb-0 item-value"}>
              <NumericFormat value={Number(prospect.annualIncomeYel)} displayType={"text"} thousandSeparator={" "} />
              <span className={"value-suffix"}> €/{t("year_unit")}</span>
            </div>
          </FadingContainer>
        </Col>
      ),
    });

    householdInformationItems.push({
      itemProperty: prospect.monthlyPension,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"monthlyPension"}>
          <FadingContainer trigger={"monthlyPension_" + String(prospect.monthlyPension)}>
            <div className={"h3 mb-0 item-title"}>{t("Accumulated pension to date")}</div>
            <div className={"h3 mb-0 item-value"}>
              <NumericFormat value={Number(prospect.monthlyPension)} displayType={"text"} thousandSeparator={" "} />
              <span className={"value-suffix"}> €/{t("month_unit")}</span>
            </div>
          </FadingContainer>
        </Col>
      ),
    });

    return (
      <div className={"ProspectBasicView"}>
        {basicInformationItems.find((x) => x.itemProperty !== null) !== undefined ? (
          <>
            <Row>
              <Col>
                <h2 className={"pt-2 text-uppercase"}>{t("Basic information")}</h2>
              </Col>
            </Row>
            <Row>{basicInformationItems.map((x) => (x.itemProperty !== null ? x.itemElement() : null))}</Row>
          </>
        ) : null}

        {householdInformationItems.find((x) => x.itemProperty !== null) !== undefined ? (
          <>
            <Row>
              <Col>
                <h2 className={"pt-4 pb-2 text-uppercase"}>{t("Household information")}</h2>
              </Col>
            </Row>
            <Row>{householdInformationItems.map((x) => (x.itemProperty !== null ? x.itemElement() : null))}</Row>
          </>
        ) : null}
      </div>
    );
  }
}

export default withTranslation("translations")(ProspectBasicView);
