import React, { Component } from "react";
import { IProspect } from "../../../contracts/data/IProspect";
import { withTranslation, WithTranslation } from "react-i18next";
import { TAppRootState } from "../../../redux/types/stateTypes";
import { pushProspectView, TPushProspectViewAction } from "../../../redux/actions/prospect";
import { connect } from "react-redux";
import ProspectBasicView from "./ProspectBasicView";
import ProspectDetailedView from "./ProspectDetailedView";
import * as paths from "../../Routes/routePaths";
import { Navigate, NavigateFunction } from "react-router-dom";

type TProspectViewProps = {
  prospectId?: string;
  prospectReference?: string;
  prospectViewName?: string;
  summaryViewName?: string;
  insuranceId?: string;
  insuranceDetailId?: string;
  prospect: IProspect | null;
  pushProspectView: TPushProspectViewAction;
  navigate: NavigateFunction;
  pathname: string;
  queryParams: URLSearchParams
} & WithTranslation;

class ProspectView extends Component<TProspectViewProps> {

  onHistoryChange = async (pathname: string) => {
    const prospectId = this.props.prospectId;

    if (
      prospectId &&
      pathname.includes(paths.prospectsList + "/" + prospectId + "/view/")
    ) {
      const finalView = this.props.summaryViewName? `?summary=${this.props.summaryViewName}` : '';
      const view = pathname.replace(paths.prospectsList + "/" + prospectId + "/view/", "") + finalView;

      await this.props.pushProspectView(prospectId, view);
    }
  };

  getCurrentProspect = (): IProspect | undefined => {
    const { prospectReference, prospectId, prospect } = this.props;
    if (prospect === null) {
      return undefined;
    }

    if (prospectReference !== undefined) {
      return prospect.reference === prospectReference ? prospect : undefined;
    }
    return prospect.id === prospectId ? prospect : undefined;
  };

  componentDidUpdate(prevProps: Readonly<TProspectViewProps>): void {
   if (prevProps.pathname !== this.props.pathname) this.onHistoryChange(this.props.pathname)
 }

  navigateToDetailedView = (push?: boolean) => {
    if (this.props.prospectId) {
      const path = paths.prospect
        .replace(":prospectId", this.props.prospectId)
        .replace(":prospectViewName", "detailed");

      return push !== false ? this.props.navigate(path) : <Navigate replace to={path} />;
    }
  };

  navigateToBasicView = (push?: boolean) => {
    if (this.props.prospectId) {
      const path = paths.prospect.replace(":prospectId", this.props.prospectId).replace(":prospectViewName", "basic");

      return push !== false ? this.props.navigate(path) : <Navigate replace to={path} />;
    }
  };

  navigateToInsuranceView = (insuranceId: string, push?: boolean) => {
    if (this.props.prospectId) {
      const path = paths.prospectInsurance
        .replace(":prospectId", this.props.prospectId)
        .replace(":prospectViewName", "detailed")
        .replace(":insuranceId", insuranceId);

      return push !== false ? this.props.navigate(path) : <Navigate replace to={path} />;
    }
  };

  navigateToInsuranceCostSummaryView = (insuranceId: string, push?: boolean) => {
    if (this.props.prospectId) {
      const path =
        paths.prospectInsurance
          .replace(":prospectId", this.props.prospectId)
          .replace(":prospectViewName", "detailed")
          .replace(":insuranceId", insuranceId) + "?summary=costs";

      return push !== false ? this.props.navigate(path) : <Navigate replace to={path} />;
    }
  };

  navigateToInsuranceDetailView = (insuranceId: string, insuranceDetailId: string, push?: boolean) => {
    if (this.props.prospectId) {
      const path = paths.prospectInsuranceDetail
        .replace(":prospectId", this.props.prospectId)
        .replace(":prospectViewName", "detailed")
        .replace(":insuranceId", insuranceId)
        .replace(":insuranceDetailId", insuranceDetailId);

      return push !== false ? this.props.navigate(path) : <Navigate replace to={path} />;
    }
  };

  render() {
    const prospect = this.getCurrentProspect();

    if (prospect === undefined) {
      return (
        <div className={"py-4"}>
          <p>Prospect not found</p>
        </div>
      );
    }

    let viewElement = (
      <div className={"py-4"}>
        <p>Unknown view</p>
      </div>
    );

    switch (this.props.prospectViewName) {
      case "basic": {
        viewElement = (
          <ProspectBasicView
            prospect={prospect}
            readonly={this.props.prospectId === undefined}
            navigateToDetailedView={this.navigateToDetailedView}
          />
        );
        break;
      }
      case "detailed": {
        viewElement = (
          <ProspectDetailedView
            prospect={prospect}
            readonly={this.props.prospectId === undefined}
            currentInsuranceId={this.props.insuranceId}
            currentInsuranceDetailId={this.props.insuranceDetailId}
            summaryViewName={this.props.summaryViewName}
            navigateToInsuranceView={this.navigateToInsuranceView}
            navigateToInsuranceCostSummaryView={this.navigateToInsuranceCostSummaryView}
            navigateToInsuranceDetailView={this.navigateToInsuranceDetailView}
            navigateToDetailedView={this.navigateToDetailedView}
            navigateToBasicView={this.navigateToBasicView}
          />
        );
        break;
      }
    }

    return <div className={"ProspectView"}>{viewElement}</div>;
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
  prospect: state.prospect.prospect,
});

type TMapDispatchToProps = {
  pushProspectView: TPushProspectViewAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  pushProspectView: (prospectId: string, view: string) => dispatch(pushProspectView(prospectId, view)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(ProspectView));
