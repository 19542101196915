import { useMutation, useQueryClient } from "@tanstack/react-query";
import useUserApiClient from "./clients/useUserApiClient";
import { ApplicationUserActionsUpdateUserUpdateUserCommand } from "../api";

export default function useUpdateUser() {
  const client = useUserApiClient();
  const queryClient = useQueryClient();

  const updateUser = async (userId: string, command: ApplicationUserActionsUpdateUserUpdateUserCommand) => {
    return await client.apiUsersUserIdPut(userId, command);
  };

  return useMutation(
    ({ userId, command }: { userId: string; command: ApplicationUserActionsUpdateUserUpdateUserCommand }) =>
      updateUser(userId, command),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["users"]);
      },
    },
  );
}
