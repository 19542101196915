import React from "react";
import {withTranslation} from "react-i18next";
import {useField, useFormikContext} from "formik";
import {Form} from 'react-bootstrap'
import {InsuranceExpectedNetRate} from "../../contracts/data/IInsurance";

const FormikExpectedNetRatePicker = ({...props}: any) => {
  const formikProps = useFormikContext<any>()
  const [field] = useField(props);

  const values = new Map<number, string>();
  values.set(0, "0%");
  values.set(235, "2,35%");
  values.set(300, "3%");
  values.set(350, "3,5%");
  values.set(500, "5%");
  values.set(650, "6,5%");
  values.set(800, "8%");
  values.set(1000, "10%");

  const getValidNetRates = () => {

    const result = [];
    for (let interval in InsuranceExpectedNetRate) {
      const value = Number(interval);
      if (!isNaN(value)) {
        result.push([value, `${value}%`]);
      }
    }
    return result
  }

  const handleChange = (e: any) => {
    const value = e.currentTarget.value;

    formikProps.setFieldTouched(field.name, true, false);
    formikProps.setFieldError(field.name, "");
    formikProps.setFieldValue(field.name, Number(value));
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);

  return (
    <Form.Group className='form-group'>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Form.Control
        name={field.name}
        as={"select"}
        value={field.value}
        onChange={handleChange}
        isInvalid={isInvalid}
      >
        {field.value === undefined || field.value === null ? (
          <option value={""} key={"-"}></option>
        ) : null}
        {getValidNetRates().map((x) => (
          <option value={x[0]} key={x[0]}>
            {values.get(x[0] as number)}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type={"invalid"}>{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
    </Form.Group>
  )
}

export default withTranslation("translations")(FormikExpectedNetRatePicker);
