import { applyMiddleware, combineReducers, compose, createStore, Store } from "redux";
import thunk from "redux-thunk";
import * as appUtils from "../utils/appUtils";
import signalRHub from "./reducers/signalRHub";
import { TAppRootState } from "./types/stateTypes";
import authentication from "./reducers/authentication";
import { ServiceContainer } from "../services/ServiceContainer";
import prospect from "./reducers/prospect";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const rootReducers = combineReducers<TAppRootState>({
  signalRHub,
  authentication,
  prospect,
});

const getComposeEnhancers = () => {
  const reduxExtensionCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  if (appUtils.isDevEnv() && reduxExtensionCompose) {
    return reduxExtensionCompose;
  }
  return compose;
};

export const configureStore = (services: ServiceContainer): Store<TAppRootState> => {
  const composeEnhancers = getComposeEnhancers();
  const store = createStore(rootReducers, composeEnhancers(applyMiddleware(thunk.withExtraArgument(services))));
  return store;
};
