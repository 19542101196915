import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { withTranslation } from "react-i18next";
import { IChildDetail } from "../../contracts/data/IChildDetail";

const getValidChildrenValues = () => {
  const result: string[][] = [];

  for (let i = 0; i <= 10; i++) {
    const str = String(i);
    result.push([str, str]);
  }

  return result;
};

const FormikChildrenPicker = ({ ...props }: any) => {
  const { t, detailsName } = props;
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const resizeDetails = async (value?: number) => {
    if (detailsName === undefined) {
      return;
    }

    const currentLength = value ?? Number(formikProps.getFieldProps(field.name).value);
    const existingDetails = [...formikProps.getFieldProps(detailsName).value];

    if (existingDetails.length < currentLength) {
      const newEmptyValues: IChildDetail[] = existingDetails;
      let sortOrder = existingDetails.length;

      while (existingDetails.length < currentLength) {
        newEmptyValues.push({
          id: "",
          sortOrder,
          age: null,
        });
        sortOrder++;
      }

      await formikProps.setFieldValue(detailsName, newEmptyValues);
    } else if (existingDetails.length > currentLength) {
      const newEmptyValues: IChildDetail[] = existingDetails;

      while (existingDetails.length > currentLength) {
        existingDetails.pop();
      }

      await formikProps.setFieldValue(detailsName, newEmptyValues);
    }
  };

  const handleChange = async (e: any) => {
    const value = e.currentTarget.value;
    await resizeDetails(value);

    await formikProps.setFieldTouched(field.name, true, false);
    await formikProps.setFieldValue(field.name, value ? Number(value) : value);
  };

  const handleDetailChange = async (e: any, fieldName: string) => {
    const value = e.currentTarget.value;

    await formikProps.setFieldTouched(fieldName, true, false);
    await formikProps.setFieldValue(fieldName, value ? Number(value) : value);
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);
  const childDetailsElements: any[] = [];
  const fieldValue = Number(field.value);

  if (detailsName !== undefined && !isNaN(fieldValue)) {
    for (let i = 0; i < fieldValue; i++) {
      const detailFieldName = detailsName + "[" + i + "].age";
      let detailFieldErrors = formikProps.errors[detailsName];
      if (detailFieldErrors !== undefined) {
        // @ts-ignore
        detailFieldErrors = detailFieldErrors[i] !== undefined ? detailFieldErrors[i].age : undefined;
      }
      let detailFieldTouched = formikProps.touched[detailsName];
      if (detailFieldTouched !== undefined) {
        // @ts-ignore
        detailFieldTouched = detailFieldTouched[i] !== undefined ? detailFieldTouched[i].age : undefined;
      }
      const isDetailFieldInvalid = Boolean(!!detailFieldErrors && detailFieldTouched);
      const detailFieldValue = formikProps.getFieldProps(detailFieldName).value;

      childDetailsElements.push(
        <Form.Group className={"FormikChildrenPicker-Child form-group"} key={"FormikChildrenPicker-Child" + i}>
          <Row>
            <Col xs={4}>
              <Form.Label>{t("Age")}</Form.Label>
            </Col>
            <Col>
              <Form.Control
                name={detailFieldName}
                type={"number"}
                onChange={(e: any) => handleDetailChange(e, detailFieldName)}
                isInvalid={isDetailFieldInvalid}
                value={detailFieldValue || undefined}
              />
              <Form.Control.Feedback type="invalid">{String(detailFieldErrors)}</Form.Control.Feedback>
            </Col>
          </Row>
        </Form.Group>,
      );
    }
  }
  return (
    <>
      <Form.Group className={"FormikChildrenPicker form-group"}>
        {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
        <Form.Control
          name={field.name}
          as={"select"}
          onChange={(e: any) => handleChange(e)}
          isInvalid={isInvalid}
          value={field.value !== undefined && field.value !== null ? String(field.value) : undefined}
        >
          {field.value === "" || field.value === null ? <option value={""} key={"-"} /> : null}
          {getValidChildrenValues().map((x) => (
            <option value={x[0]} key={x[0]}>
              {x[1]}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])}</Form.Control.Feedback>
      </Form.Group>
      {childDetailsElements.map((x) => x)}
    </>
  );
};

export default withTranslation("translations")(FormikChildrenPicker);
