import axios from "axios";
import { TApiResponse, IApiService } from "../contracts/services/IApiService";
import { IStorageService } from "../contracts/services/IStorageService";
import { getApiBaseUrl } from "../utils/appUtils";

export class ApiService implements IApiService {
  private readonly storageService: IStorageService;

  constructor(storageService: IStorageService) {
    this.storageService = storageService;
  }

  async get<TResponse>(endpoint: string, params?: any): Promise<TApiResponse<TResponse>> {
    try {
      const user = this.storageService.getCurrentUser();
      const headers: any = {};
      if (user !== null) {
        headers["Authorization"] = `Bearer ${user.tokens.accessToken}`;
      }

      const result = await axios.get(getApiBaseUrl() + endpoint, {
        params: params === undefined ? {} : params,
        headers,
        validateStatus: (status) => true,
      });

      if (result.status === 400) {
        const response: TApiResponse<TResponse> = {
          errors: Array.isArray(result.data) ? result.data : [result.data],
        };
        return response;
      } else if (result.status === 200) {
        const response: TApiResponse<TResponse> = {
          data: result.data,
        };
        return response;
      }
    } catch (error) {}

    const response: TApiResponse<TResponse> = {
      errors: [
        {
          property: null,
          code: "UnknownError",
          message: "Unknown error",
        },
      ],
    };
    return response;
  }

  async post<TResponse>(endpoint: string, payload?: any, params?: any): Promise<TApiResponse<TResponse>> {
    try {
      const user = this.storageService.getCurrentUser();
      const headers: any = {};
      if (user !== null) {
        headers["Authorization"] = `Bearer ${user.tokens.accessToken}`;
      }

      const result = await axios.post(getApiBaseUrl() + endpoint, payload, {
        params: params === undefined ? {} : params,
        headers,
        validateStatus: (status) => true,
      });

      if (result.status === 400) {
        const response: TApiResponse<TResponse> = {
          errors: Array.isArray(result.data) ? result.data : [result.data],
        };
        return response;
      } else if (result.status === 200) {
        const response: TApiResponse<TResponse> = {
          data: result.data,
        };
        return response;
      }
    } catch (error) {}

    const response: TApiResponse<TResponse> = {
      errors: [
        {
          property: null,
          code: "UnknownError",
          message: "Unknown error",
        },
      ],
    };
    return response;
  }

  async put<TResponse>(endpoint: string, payload?: any, params?: any): Promise<TApiResponse<TResponse>> {
    try {
      const user = this.storageService.getCurrentUser();
      const headers: any = {};
      if (user !== null) {
        headers["Authorization"] = `Bearer ${user.tokens.accessToken}`;
      }

      const result = await axios.put(getApiBaseUrl() + endpoint, payload, {
        params: params === undefined ? {} : params,
        headers,
        validateStatus: (status) => true,
      });

      if (result.status === 400) {
        const response: TApiResponse<TResponse> = {
          errors: Array.isArray(result.data) ? result.data : [result.data],
        };
        return response;
      } else if (result.status === 200) {
        const response: TApiResponse<TResponse> = {
          data: result.data,
        };
        return response;
      }
    } catch (error) {}

    const response: TApiResponse<TResponse> = {
      errors: [
        {
          property: null,
          code: "UnknownError",
          message: "Unknown error",
        },
      ],
    };
    return response;
  }

  async delete<TResponse>(endpoint: string): Promise<TApiResponse<TResponse>> {
    try {
      const user = this.storageService.getCurrentUser();
      const headers: any = {};

      if (user !== null) {
        headers["Authorization"] = `Bearer ${user.tokens.accessToken}`;
      }

      const result = await axios.delete(getApiBaseUrl() + endpoint, {
        headers,
      });

      const response: TApiResponse<TResponse> = {
        data: result.data,
      };
      return response;
    } catch (error) {
      const errorString = error instanceof Error ? error.message : String(error);
      return {
        errors: [
          {
            property: null,
            code: "UnknownError",
            message: errorString,
          },
        ],
      };
    }
  }
}
