import React, { Component } from "react";
import { fetchProspectByReference, TFetchProspectByReferenceAction } from "../../redux/actions/prospect";
import { TAppRootState } from "../../redux/types/stateTypes";
import { connect } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import * as paths from "../Routes/routePaths";
import { signalRHubListenProspect, TSignalRHubListenProspectAction } from "../../redux/actions/signalRHub";
import { IProspect } from "../../contracts/data/IProspect";

type TMeetingRedirectProps = {
  prospectReference?: string;
  prospect: IProspect | null;
  isFetchingProspect: boolean;
  fetchProspectByReference: TFetchProspectByReferenceAction;
  signalRHubListenProspect: TSignalRHubListenProspectAction;
};

type TMeetingRedirectState = {};

class MeetingRedirect extends Component<TMeetingRedirectProps, TMeetingRedirectState> {
  async componentDidMount() {
    await this.props.fetchProspectByReference(this.props.prospectReference ?? '');
  }

  render() {
    const prospect = this.props.prospect;

    if (this.props.isFetchingProspect || prospect === undefined || prospect === null) {
      return null;
    }

    if (prospect.isMeetingActive) {
      const path = paths.meetingPresentation.replace(":prospectReference", this.props.prospectReference ?? '');
      return <Navigate replace to={path} />;
    }

    const path = paths.meetingBasicInformation.replace(":prospectReference", this.props.prospectReference ?? '');
    return <Navigate replace to={path} />;
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  prospect: state.prospect.prospect,
  isFetchingProspect: state.prospect.isFetchingProspect,
});

type TMapDispatchToProps = {
  fetchProspectByReference: TFetchProspectByReferenceAction;
  signalRHubListenProspect: TSignalRHubListenProspectAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  fetchProspectByReference: (prospectReference: string) => dispatch(fetchProspectByReference(prospectReference)),
  signalRHubListenProspect: (prospectReference: string) => dispatch(signalRHubListenProspect(prospectReference)),
});

export default connect(mapStateToProps, mapDispatchToProps)((props: TMeetingRedirectProps) => {
  const { prospectReference } = useParams();
  return (
    <MeetingRedirect {...props} prospectReference={prospectReference} />
  );
});
