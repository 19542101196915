import React, { useEffect } from "react";
import { useField, useFormikContext } from "formik";
import useOmsenCountries from "../../hooks/useOmsenCountries";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { ApplicationOmsenDtosCountryDto } from "../../api";
import {selectCustomStyle} from '../../styles/selectCustomStyle';

const FormikCountryPicker = ({ ...props }: any) => {
  const { data: countries, isLoading } = useOmsenCountries();
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);
  const defaultValue: ApplicationOmsenDtosCountryDto = { code: "FI", number: 246, name: "Suomi" };

  const exitingValues = countries?.filter((c) => {
    if (Array.isArray(field.value)) {
      return field.value.includes(c.number);
    } else {
      if (props.valueType === "number") {
        return field.value?.toString() === c.number?.toString();
      } else {
        return field.value?.toString() === c.code;
      }
    }
  });

  const handleChange = async (e: any) => {
    await formikProps.setFieldTouched(field.name, true);
    if (props.isMulti) {
      await formikProps.setFieldValue(field.name, Array.isArray(e) ? e.map((x) => x.number) : []);
    } else {
      await formikProps.setFieldValue(field.name, props.valueType === "number" ? e.number : e.code);
    }
  };

  const isInvalid = Boolean(
    !!formikProps.getFieldMeta(field.name).error && formikProps.getFieldMeta(field.name).touched,
  );

  const setDefaultValue = async () => {
    if(props.isMulti) {
       await formikProps.setFieldValue(field.name, [defaultValue.number]);
    } else {
       await formikProps.setFieldValue(field.name, props.valueType === "number" ? defaultValue.number : defaultValue.code);
    }
  };

  useEffect(() => {
    if (field.value === "" || field.value === 0 || field.value === null || (Array.isArray(field.value) && field.value.length < 1)) {
      setDefaultValue().catch();
    }
  }, [isLoading]);

  return (
    <Form.Group className={"FormikCountryPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Select
        options={countries}
        getOptionValue={(option) => option.number?.toString() ?? ""}
        getOptionLabel={(option) => option.name ?? ""}
        isMulti={props.isMulti}
        onChange={handleChange}
        value={exitingValues}
        styles={selectCustomStyle(isInvalid)}
        className={isInvalid ? "is-invalid" : "is-valid"}
        isDisabled={props.disabled}
        isLoading={isLoading}
      />
      <Form.Control.Feedback type="invalid">
        {String(formikProps.getFieldMeta(field.name).error) ?? undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikCountryPicker);
