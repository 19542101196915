import React from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { withTranslation } from "react-i18next";

const FormikCheckbox = ({ ...props }: any) => {
  const { t } = props;
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const handleChange = (value: boolean) => {
    formikProps.setFieldTouched(field.name, true, false);
    formikProps.setFieldError(field.name, "");
    formikProps.setFieldValue(field.name, value);

    if (props.onChange !== undefined) {
      props.onChange(value);
    }
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);

  return (
    <Form.Group className={"FormikCheckbox form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Form.Control name={field.name} as={ButtonGroup} isInvalid={isInvalid}>
        <Button variant={field.value === true ? "info" : "light"} onClick={(e) => handleChange(true)}>
          {t("Yes")}
        </Button>
        <Button variant={field.value === false ? "info" : "light"} onClick={(e) => handleChange(false)}>
          {t("No")}
        </Button>
      </Form.Control>
      <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikCheckbox);
