import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IProspect, MaritalStatus } from "../../contracts/data/IProspect";
import { Formik } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import AlertAutoDismissible from "../shared/AlertAutoDismissible";
import FormikDatePicker from "../shared/FormikDatePicker";
import FormikChildrenPicker from "../shared/FormikChildrenPicker";
import * as Yup from "yup";
import FormikInput from "../shared/FormikInput";
import { IProspectBasicInformation } from "../../contracts/data/IProspectBasicInformation";
import FormikRadioGroup, { TRadioGroupItem } from "../shared/FormikRadioGroup";

type TProspectEditBasicInformationFormValues = {
  birthDate: string;
  maritalStatus: MaritalStatus | null;
  annualIncomeYel: number | "";
  childAmount: number | "";
  monthlyPension: number | "";
};

type TProspectEditBasicInformationFormProps = {
  isSubmitting: boolean;
  prospect: IProspect;
  updateProspectBasicInformation(
    prospect: IProspect,
    basicInformation: IProspectBasicInformation,
  ): Promise<IProspect | null>;
} & WithTranslation;

type TProspectEditBasicInformationFormState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
};

class ProspectEditBasicInformationForm extends Component<
  TProspectEditBasicInformationFormProps,
  TProspectEditBasicInformationFormState
> {
  constructor(props: TProspectEditBasicInformationFormProps) {
    super(props);

    this.state = {
      submitResultMessage: "",
      submitResultSuccess: true,
    };
  }

  render() {
    const { t, prospect } = this.props;

    const marriageStatusItems: TRadioGroupItem[] = [
      {
        label: t("marriage"),
        value: MaritalStatus.Married,
      },
      {
        label: t("registered partnership"),
        value: MaritalStatus.RegisteredPartnership,
      },
      {
        label: t("single"),
        value: MaritalStatus.Single,
      },
    ];

    const initialValues: TProspectEditBasicInformationFormValues = {
      birthDate: "",
      maritalStatus: null,
      annualIncomeYel: "",
      childAmount: "",
      monthlyPension: "",
    };

    const validationSchema = Yup.object().shape(
      {
        birthDate: Yup.date().required(t("Required")),
        maritalStatus: Yup.mixed().required(t("Required")),
        annualIncomeYel: Yup.number().required(t("Required")).min(0, t("Must be positive")),
        childAmount: Yup.number().required(t("Required")),
        monthlyPension: Yup.number().required(t("Required")).min(0, t("Must be positive")),
      },
      [["monthlySalary", "monthlyPension"]],
    );

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          this.setState((state) => ({
            ...state,
            submitResultSuccess: true,
            submitResultMessage: "",
          }));

          const basicInformationValues: IProspectBasicInformation = {
            annualIncomeYel: values.annualIncomeYel ? Number(values.annualIncomeYel) : 0,
            birthDate: values.birthDate,
            maritalStatus: values.maritalStatus as MaritalStatus,
            monthlyPension: values.monthlyPension ? Number(values.monthlyPension) : null,
            childAmount: values.childAmount ? Number(values.childAmount) : 0,
          };

          const result = await this.props.updateProspectBasicInformation(prospect, basicInformationValues);

          if (result === null) {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: false,
              submitResultMessage: t("Unable to save"),
            }));
          }
        }}
      >
        {(formikProps) => (
          <Form autoComplete={"off"} noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            <h3 className={"text-uppercase section-header my-3"}>{t("Basic information")}</h3>
            <FormikDatePicker name={"birthDate"} title={t("Birthdate")} singleRow={true} />
            <Row>
              <Col xs={12} sm={8}>
                <FormikRadioGroup name={"maritalStatus"} items={marriageStatusItems} title={t("Marital status")} />
              </Col>
              <Col xs={12} sm={4}>
                <FormikChildrenPicker name={"childAmount"} title={t("Number of children")} />
              </Col>
            </Row>

            <h3 className={"text-uppercase section-header my-3"}>{t("Household information")}</h3>
            <Row>
              <Col xs={12} xl={6}>
                <FormikInput
                  name={"annualIncomeYel"}
                  title={t("YEL income")}
                  type={"number"}
                  unit={"€/" + t("year_unit")}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} xl={6}>
              <FormikInput title={<>
                {t("Accumulated pension to date")}
                  <div>
                    <a target="_blank" rel="noopener noreferrer" href={"https://www.tyoelake.fi/tyoelakeote/"}>
                    {t("check-pension-link")}<span className="text-danger red-link-word">{t("check-pension-link-last-word")}</span>
                    </a>
                  </div>
                </>}
                name={"monthlyPension"} type={"number"} unit={"€/" + t("month_unit")} />
              </Col>
            </Row>

            <Row>
              <Col xs={7} md={12} lg={7}>
                <p className={"confirm-section"}>{t("When you have confirmed the information above, press Send.")}</p>
              </Col>
              <Col xs={5} md={12} lg={5}>
                <Form.Group className="form-group">
                  <Button variant="primary" type="submit" className={"w-100"} disabled={this.props.isSubmitting}>
                    {this.props.isSubmitting ? (
                      <>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                      </>
                    ) : null}
                    {t("Send")}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            {this.state.submitResultMessage ? (
              <AlertAutoDismissible variant={this.state.submitResultSuccess ? "success" : "danger"} className={"mt-2"}>
                {this.state.submitResultMessage}
              </AlertAutoDismissible>
            ) : null}
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(ProspectEditBasicInformationForm);
