import { TAuthenticationState } from "../types/stateTypes";
import { Reducer } from "redux";
import {
  AUTHENTICATION_LOGIN_USER,
  AUTHENTICATION_LOGIN_USER_FAILURE,
  AUTHENTICATION_LOGIN_USER_FROM_STORAGE,
  AUTHENTICATION_REFRESH_USER,
  AUTHENTICATION_LOGIN_USER_SUCCESS,
  AUTHENTICATION_LOGOUT_USER,
  AUTHENTICATION_LOGOUT_USER_FAILURE,
  AUTHENTICATION_LOGOUT_USER_SUCCESS,
  AUTHENTICATION_REGISTER_USER,
  AUTHENTICATION_REGISTER_USER_FAILURE,
  AUTHENTICATION_REGISTER_USER_SUCCESS,
} from "../actions/actionTypes";

export const initialState: TAuthenticationState = {
  isLoggedIn: false,
  isLoggingIn: false,
  isLoggingOut: false,
  isRegistering: false,
  isFetchingSellers: false,
  user: null,
};

const authentication: Reducer<TAuthenticationState> = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATION_LOGIN_USER_SUCCESS:
      return { ...state, ...action.payload, isLoggedIn: true, isLoggingIn: false };
    case AUTHENTICATION_LOGIN_USER_FAILURE:
      return { ...state, isLoggingIn: false };
    case AUTHENTICATION_LOGOUT_USER_SUCCESS:
      return { ...state, user: null, isLoggedIn: false, isLoggingOut: false };
    case AUTHENTICATION_LOGOUT_USER_FAILURE:
      return { ...state, isLoggingOut: false };
    case AUTHENTICATION_LOGIN_USER:
      return { ...state, isLoggingIn: true };
    case AUTHENTICATION_LOGOUT_USER:
      return { ...state, isLoggingOut: true };
    case AUTHENTICATION_REGISTER_USER:
      return { ...state, isRegistering: true };
    case AUTHENTICATION_REGISTER_USER_SUCCESS:
      return { ...state, isRegistering: false };
    case AUTHENTICATION_REGISTER_USER_FAILURE:
      return { ...state, isRegistering: false };
    case AUTHENTICATION_LOGIN_USER_FROM_STORAGE:
    case AUTHENTICATION_REFRESH_USER:
    default:
      return state;
  }
};

export default authentication;
