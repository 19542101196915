import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import React, { Component, RefObject } from "react";
import syt_logo from "../../assets/app_header_logo_black.png";

type TStickyImageLayoutProps = {
  children: JSX.Element;
};

type TStickyImageLayoutState = {
  stickyWidth: number;
};

export class StickyImageLayout extends Component<TStickyImageLayoutProps, TStickyImageLayoutState> {
  colRef: RefObject<HTMLDivElement>;

  constructor(props: TStickyImageLayoutProps) {
    super(props);

    this.colRef = React.createRef();
    this.handleResize = this.handleResize.bind(this);

    this.state = {
      stickyWidth: 0,
    };
  }

  handleResize() {
    const current = this.colRef?.current;
    if (current !== null) {
      this.setState((state) => ({
        ...state,
        stickyWidth: current.clientWidth,
      }));
    }
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <Container className={"StickyImageLayout"}>
        <Row>
          <Col xs={12} md={6}>
            <div className={"py-3 mb-5 mt-4 logo-container d-flex justify-content-start align-items-center"}>
              <img src={syt_logo} alt={"Logo"} />
            </div>
            <div className={"StickyImageLayout-inner"}>{this.props.children}</div>
          </Col>
          <Col xs={12} md={6} ref={this.colRef}>
            <div className={"sticky-image-container"} style={{ width: this.state.stickyWidth + "px" }} />
          </Col>
        </Row>
      </Container>
    );
  }
}
