import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ApplicationUserActionsGetUsersGetPaginatedUsersResponse } from "../../../api";
import { Button, Spinner } from "react-bootstrap";
import { withTranslation, WithTranslation } from "react-i18next";
import TableList from "../../TableList/TableList";
import { IProspectFetch } from "../../../contracts/data/IProspectFetch";
import HeaderButton from "../../shared/HeaderButton";
import useUpdateUser from "../../../hooks/useUpdateUser";
import AlertAutoDismissible from "../../shared/AlertAutoDismissible";

type Props = {
  users: ApplicationUserActionsGetUsersGetPaginatedUsersResponse[];
  loadingUsers: boolean;
  usersTotalAmount: number;
  queryState: IProspectFetch;
  setQueryState: React.Dispatch<React.SetStateAction<IProspectFetch>>;
  handleOpenUserModal: (user: ApplicationUserActionsGetUsersGetPaginatedUsersResponse) => void;
} & WithTranslation;

const UsersTable: React.FC<Props> = ({
  users,
  loadingUsers,
  usersTotalAmount,
  queryState,
  setQueryState,
  handleOpenUserModal,
  t,
}) => {
  const { mutate: updateUser, isError: updateUserError, isSuccess: updateUserSuccess } = useUpdateUser();

  const updateTable = async (type: any, newState: any) => {
    switch (type) {
      case "pagination":
        setQueryState((state) => ({
          ...state,
          page: newState.page,
          sizePerPage: newState.sizePerPage,
        }));
        break;
      case "sort":
        if (newState.sortOrder !== queryState.sortOrder || newState.sortField !== queryState.sortField) {
          setQueryState((state) => ({
            ...state,
            page: 1,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
          }));
        }
        break;
    }
  };

  const activeInActiveToggle = (userId: string, user: ApplicationUserActionsGetUsersGetPaginatedUsersResponse) => {
    const { isInActive } = user;
    return (
      <div className="btn-group ActiveInActiveToggle" data-toggle="buttons">
        <HeaderButton
          onClick={() => updateUser({ userId: userId, command: { ...user, isInActive: false } })}
          text="Active"
          id="active"
          className={`btn px-3 ${!isInActive && 'bg-success'}`}
          variant="light"
        />
        <HeaderButton
          onClick={() => updateUser({ userId: userId, command: { ...user, isInActive: true } })}
          text="Inactive"
          id="inActive"
          className={`btn px-3 ${isInActive && 'bg-danger'}`}
          variant="light"
        />
      </div>
    );
  };

  const columns: ColumnDef<ApplicationUserActionsGetUsersGetPaginatedUsersResponse>[] = [
    {
      header: t("Full Name"),
      accessorKey: "fullName",
      cell: (props: any) => props.getValue(),
    },
    {
      header: t("Email"),
      accessorKey: "email",
      cell: (props: any) => props.getValue(),
    },
    {
      header: t("Roles"),
      accessorKey: "roles",
      cell: (props: any) =>
        props
          .getValue()
          .map((role: string) => t(role))
          .join(", "),
    },
    {
      header: t("Active") + "/" + t("Inactive"),
      accessorKey: "isInActive",
      cell: ({ row }) => row.original?.id && activeInActiveToggle(row.original.id, row.original),
      size: 250,
    },
    {
      id: "edit",
      cell: ({ row }) => <Button onClick={() => handleOpenUserModal(row.original)}>{t("Edit")}</Button>,
      enableSorting: false,
      size: 20,
    },
  ];

  if (loadingUsers) return <Spinner as="span" animation="border" size="sm" aria-hidden="true" />;
  return (
    <>
      <TableList
        data={users}
        columns={columns}
        payload={queryState}
        updateTable={updateTable}
        totalSize={usersTotalAmount}
      />
      {updateUserError && <AlertAutoDismissible className="UserAlerts" variant="danger">{t("Unable to update")}</AlertAutoDismissible>}
      {updateUserSuccess && <AlertAutoDismissible className="UserAlerts" variant="success">{t("User updated successful")}</AlertAutoDismissible>}
    </>
  );
};

export default withTranslation("translations")(UsersTable);
