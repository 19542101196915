import React, { useState } from "react";
import { Page, Text, View, Document, StyleSheet, Image, pdf } from "@react-pdf/renderer";
import moment from "moment";
import { IProspect } from "../../contracts/data/IProspect";
import { WithTranslation, withTranslation } from "react-i18next";
import html2canvas from "html2canvas";
import { Button, Spinner } from "react-bootstrap";
import { saveAs } from "file-saver";
import { dateFormat_DDMMYYYY, dateTimeFormat_YYYYMMDD_HHmmss } from "../../constants/dateFormats";

const styles = StyleSheet.create({
  page: {
    margin: "1cm",
    position: "relative",
  },
  header: {
    width: "19cm",
  },
  footer: {
    width: "19cm",
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "3cm",
  },
  body: {
    width: "19cm",
  },
  disclaimerTextBorder: {
    borderTop: 1,
    borderTopColor: "gray",
    borderTopStyle: "solid",
  },
  disclaimerText: {
    paddingTop: 5,
    width: "19cm",
    fontSize: 8,
    color: "gray",
  },
  headerImage: {
    width: "100%",
    height: "auto",
  },
  prospectsImage: {
    width: "100%",
    height: "auto",
  },
});

type TProspectPdfDocumentProps = {
  prospect: IProspect;
  headerImage: string | null;
  prospectsImage: string | null;
} & WithTranslation;

type TProspectPdfDocumentLink = {
  prospect: IProspect;
} & WithTranslation;

const ProspectPdfDocument = (props: TProspectPdfDocumentProps) => {
  return (
    <Document>
      <Page style={styles.page} size={"A4"} orientation={"portrait"}>
        <View style={styles.header}>
          {props.headerImage ? (
            <Image src={props.headerImage} style={styles.headerImage} />
          ) : (
            <Text>Missing header</Text>
          )}
        </View>
        <View style={styles.body}>
          {props.prospectsImage ? (
            <Image src={props.prospectsImage} style={styles.prospectsImage} />
          ) : (
            <Text>Missing body</Text>
          )}
        </View>
        <View style={styles.footer}>
          <Text style={[styles.disclaimerText, styles.disclaimerTextBorder]}>{props.t("PDF disclaimer text")}</Text>
          <Text style={styles.disclaimerText}>{props.t("Insurance disclaimer text")}</Text>
        </View>
      </Page>
    </Document>
  );
};

const ProspectPdfDocumentWithTranslations = withTranslation("translations")(ProspectPdfDocument);

export const ProspectPdfDocumentLink = (props: TProspectPdfDocumentLink) => {
  const [loading, setLoading] = useState(false);
  const prospectInsurances = document.getElementById("ProspectInsurances");
  const prospectInsurancesForPDF = document.getElementById("ProspectInsurancesForPDF")

  const changeComponentStyle = () => {
    if(prospectInsurancesForPDF) {
      prospectInsurancesForPDF.classList.remove("d-none")

      if(prospectInsurances) {
        prospectInsurances.style.display = "none"
      }
    }
  };

  const backToStyle = () => {
    if(prospectInsurances) {
      prospectInsurances.style.display = "flex";
    }
    if(prospectInsurancesForPDF) {
      prospectInsurancesForPDF.style.display = "none"
    }
  }

  const generatePdf = async () => {
    const { prospect } = props;

    setLoading(true);
    changeComponentStyle();

    const header = document.getElementById("AppHeader");
    const prospects = document.getElementById("Prospects");
    let headerImage = null;
    let prospectsImage = null;
    if (header) {
      headerImage = await html2canvas(header, {
        scrollY: -window.scrollY,
      }).then((canvas) => canvas.toDataURL("image/png"));
    }
    if (prospects) {
      prospectsImage = await html2canvas(prospects, {
        scrollY: -window.scrollY,
      }).then((canvas) => canvas.toDataURL("image/png"));
    }

    const meetingDate = moment(prospect.meetingDate, dateTimeFormat_YYYYMMDD_HHmmss);
    const fileName =
      prospect.firstName + " " + prospect.lastName + " " + meetingDate.format(dateFormat_DDMMYYYY) + ".pdf";
    const blob = await pdf(
      <ProspectPdfDocumentWithTranslations
        prospect={props.prospect}
        headerImage={headerImage}
        prospectsImage={prospectsImage}
      />,
    ).toBlob();
    saveAs(blob, fileName);

    backToStyle()
    setLoading(false);
  };

  return (
    <Button variant="primary" type="button" className={"w-100"} disabled={loading} onClick={() => generatePdf()}>
      {loading ? (
        <>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
        </>
      ) : null}
      {props.t("Download PDF")}
    </Button>
  );
};

export default withTranslation("translations")(ProspectPdfDocumentLink);
