import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import fi from "date-fns/locale/fi";
import {
  dateTimePickerFormat_time,
  dateTimePickerFormat_date,
  dateTimeFormat_YYYYMMDD_HHmmss,
  dateFormat_HHmm,
  dateTimeFormat_YYYYMMDD_HHmm,
  dateFormat_DDMMYYYY,
  dateTimeFormat_YYYYMMDD_HH,
} from "../../constants/dateFormats";
import moment, { MomentInput } from "moment";
import { withTranslation } from "react-i18next";

const FormikDateTimePicker = ({ ...props }: any) => {
  const { setFieldValue } = useFormikContext<any>();
  const [field] = useField(props);
  const { t } = props;

  const selectedDate = moment(field.value || "", dateTimeFormat_YYYYMMDD_HHmmss, true);
  if (selectedDate.isValid()) {
    field.value = selectedDate.format(dateFormat_DDMMYYYY + " " + dateFormat_HHmm);
  } else {
    field.value = "";
  }
  const isInvalid = !selectedDate.isValid();
  const isInvalidClassname = isInvalid ? "is-invalid" : "";

  return (
    <DatePicker
      {...field}
      {...props}
      selected={selectedDate.isValid() ? selectedDate.toDate() : null}
      onChange={async (val: MomentInput) => {
        const momentVal = moment(val);
        if (momentVal.isValid()) {
          const formattedVal = momentVal.format(dateTimeFormat_YYYYMMDD_HHmm) + ":00";
          await setFieldValue(field.name, formattedVal);
        } else {
          await setFieldValue(field.name, "");
        }
      }}
      className={"form-control " + isInvalidClassname}
      showTimeSelect
      locale={fi}
      timeCaption={"klo"}
      dateFormat={dateTimePickerFormat_date}
      timeFormat={dateTimePickerFormat_time}
      wrapperClassName={isInvalidClassname}
      autoComplete={"off"}
      minDate={props.minDate}
      maxDate={props.maxDate}
      todayButton={
        <button
          type="button"
          className="today-button"
          onClick={async () => {
            const momentVal = moment(new Date());
            momentVal.add(1, "hour");
            const formattedVal = momentVal.format(dateTimeFormat_YYYYMMDD_HH) + ":00:00";
            await setFieldValue(field.name, formattedVal);
          }}
        >
          {t("Today")}
        </button>
      }
    />
  );
};

export default withTranslation("translations")(FormikDateTimePicker);
