import React, { Component } from "react";
import { BusinessProfit, BusinessRevenue, IProspect } from "../../../contracts/data/IProspect";
import { TPushAnalyticsDataAction, TUpdateProspectAction } from "../../../redux/actions/prospect";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import FormikInput from "../../shared/FormikInput";
import AlertAutoDismissible from "../../shared/AlertAutoDismissible";
import FormikCompanyTypePicker from "../../shared/FormikCompanyTypePicker";
import { IsCodeValid, TOLCodes } from "../../../constants/industryCodes";
import AnalyticsForm, { getValidSaleProductObjects, TSalesFormItems } from "../analytics/AnalyticsForm";
import { ISale } from "../../../contracts/data/ISale";
import { ISalesAnalyticsData, LeadChannel } from "../../../contracts/data/ISalesAnalyticsData";
import { IMeeting } from "../../../contracts/data/IMeeting";
import { hasFormikError, isValueNullOrEmptyString } from "../../../utils/appUtils";
import PromptMessage, { SetPromptMessageFunc } from "../../shared/PromptMessage";

const emptyFieldToNull = (fieldToNull: any) => (fieldToNull === "" || fieldToNull === undefined ? null : fieldToNull);

export type TFormValues = {
  companyName: string | null;
  broker: boolean;
  companyCity: string | null;
  companyAddress: string | null;
  companyZipCode: string | null;
  companyId: string | null;
  companyContactPerson: string | null;
  companyEmail: string | null;
  companyLanguage: string | null;
  companyPhone: string | null;
  companyType: number | null;
  companyOwnershipPercentage: number | null;
  companyOtherOwners: string | null;
  businessIndustryCode: string | null;
  businessEmployeesAmount: number | null;
  fiscalMonth: number | null;
  businessRevenue: BusinessRevenue | null;
  businessProfit: BusinessProfit | null;
  salesAnalyticsId: string | null;
  prospectId: string | null;
  leadChannel: LeadChannel | null;
  firstMeetingBookingDate: string | null;
  meetings: IMeeting[];
  sales: TSalesFormItems[];
};

type TProps = {
  isSubmitting: boolean;
  prospect: IProspect;
  updateProspect: TUpdateProspectAction;
  pushAnalyticsData: TPushAnalyticsDataAction;
  setPromptMessage: SetPromptMessageFunc;
} & WithTranslation;

type TState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
  inputTolCode: string;
  addSalesData: boolean;
};

class BackgroundInformationForm extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      submitResultMessage: "",
      submitResultSuccess: true,
      inputTolCode: "",
      addSalesData: false,
    };
  }

  render() {
    const { t, prospect } = this.props;
    const initialValues: TFormValues = {
      companyContactPerson: prospect.companyContactPerson,
      companyEmail: prospect.companyEmail ?? prospect.email,
      companyId: prospect.companyId,
      companyLanguage: isValueNullOrEmptyString(prospect.companyLanguage)
        ? prospect.language
        : prospect.companyLanguage,
      companyZipCode: prospect.companyZipCode,
      companyName: prospect.companyName,
      broker: prospect.broker,
      companyCity: prospect.companyCity,
      companyAddress: prospect.companyAddress,
      companyPhone: prospect.companyPhone,
      companyType: prospect.companyType,
      companyOwnershipPercentage: prospect.companyOwnershipPercentage,
      companyOtherOwners: emptyFieldToNull(prospect.companyOtherOwners),
      businessIndustryCode: prospect.businessIndustryCode,
      businessEmployeesAmount: prospect.businessEmployeesAmount,
      fiscalMonth: prospect.fiscalMonth,
      businessRevenue: prospect.businessRevenue,
      businessProfit: prospect.businessProfit,
      salesAnalyticsId: prospect.salesAnalyticsData?.id || null,
      prospectId: prospect.id,
      leadChannel: prospect.salesAnalyticsData?.leadChannel || null,
      firstMeetingBookingDate: prospect.salesAnalyticsData?.firstMeetingBookingDate || null,
      meetings: prospect.salesAnalyticsData?.meetings || [],
      sales: getValidSaleProductObjects(prospect.salesAnalyticsData?.sales),
    };

    if (initialValues.meetings.length === 0) {
      const emptyMeeting: IMeeting = { id: null, date: null, outcome: null, sortOrder: 0 };
      initialValues.meetings.push(emptyMeeting);
    }

    const validationSchema = Yup.object().shape({
      companyName: Yup.mixed(),
      broker: Yup.boolean(),
      companyAddress: Yup.mixed(),
      companyPhone: Yup.mixed(),
      companyType: Yup.mixed().nullable(),
      businessIndustryCode: Yup.lazy((value) => {
        if (value) {
          return Yup.mixed().test("isValidTolCode", t("Invalid tol code"), () => IsCodeValid(value));
        }
        return Yup.mixed();
      }),
      businessEmployeesAmount: Yup.lazy((value) => {
        if (value) {
          return Yup.number().min(1, t("Must be positive")).notRequired();
        }
        return Yup.mixed().nullable();
      }),
      fiscalMonth: Yup.lazy((value) => {
        if (value) {
          return Yup.number().min(1, t("More than 0")).max(12, t("Less than 13"));
        }
        return Yup.mixed();
      }),
      businessRevenue: Yup.mixed().nullable(),
      businessProfit: Yup.mixed().nullable(),
      leadChannel: Yup.mixed().nullable(),
      firstMeetingBookingDate: Yup.mixed().nullable(),
      meetings: Yup.array().of(
        Yup.object().shape({
          date: Yup.mixed().nullable(),
          outcome: Yup.mixed().nullable(),
        }),
      ),
      sales: Yup.array().of(
        Yup.object().shape({
          sold: Yup.boolean(),
          price: Yup.mixed().when("sold", ([sold], schema) => {
            if (sold) return Yup.number().min(1, t("Required")).required(t("Required"));
            return schema;
          }),
        }),
      ),
    });

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          this.setState((state) => ({
            ...state,
            submitResultSuccess: true,
            submitResultMessage: "",
          }));
          values.businessRevenue && (values.businessRevenue = parseInt(values.businessRevenue.toString()));
          values.businessProfit && (values.businessProfit = parseInt(values.businessProfit.toString()));
          values.businessIndustryCode &&
            (values.businessIndustryCode = values.businessIndustryCode.toLocaleUpperCase());
          values.companyOwnershipPercentage = emptyFieldToNull(values.companyOwnershipPercentage);
          values.companyOtherOwners = emptyFieldToNull(values.companyOtherOwners);
          values.businessEmployeesAmount = emptyFieldToNull(values.businessEmployeesAmount);
          values.fiscalMonth = emptyFieldToNull(values.fiscalMonth);

          // Update prospect data
          const result = await this.props.updateProspect(prospect.id, { ...values });

          // Update sales data
          const leadChannel: LeadChannel = Number(values.leadChannel);

          const meetings: IMeeting[] = values.meetings.map((m) => {
            return { ...m, outcome: Number(m.outcome) };
          });

          const sales: ISale[] = values.sales
            .filter((s) => s.sold === true)
            .map((s) => {
              return { id: s.id, salesProduct: s.salesProduct, price: s.price };
            });

          const payload: ISalesAnalyticsData = {
            id: values.salesAnalyticsId,
            prospectId: prospect.id,
            leadChannel: leadChannel,
            firstMeetingBookingDate: values.firstMeetingBookingDate,
            meetings: meetings,
            sales: sales,
          };

          const analyticsResult = await this.props.pushAnalyticsData(payload);

          // Atm we only care about that both api calls succeeded.
          if (result !== null && analyticsResult !== null) {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: true,
              submitResultMessage: t("Successfully updated"),
            }));
          } else {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: false,
              submitResultMessage: t("Unable to update"),
            }));
          }
        }}
      >
        {(formikProps) => (
          <Form autoComplete={"off"} noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            <h5 className="AppSidebar-subheader">{t("Company information")}</h5>
            <FormikInput name={"companyName"} title={t("Company name")} className="mb-1" />
            <Form.Check
              type="checkbox"
              checked={formikProps.values.broker}
              label={t("Broker")}
              onChange={(e) => formikProps.setFieldValue("broker", e.currentTarget.checked)}
            />
            <FormikInput name={"companyId"} title={t("Company id")} />
            <FormikInput name={"companyPhone"} title={t("Company phone")} />
            <FormikInput name={"companyEmail"} title={t("Company email")} />
            <FormikInput name={"companyContactPerson"} title={t("Company contact person")} />
            <FormikInput name={"companyAddress"} title={t("Company address")} />
            <Row>
              <FormikInput as={Col} xs={6} name={"companyZipCode"} title={t("Company zipcode")} />
              <FormikInput as={Col} xs={6} name={"companyCity"} title={t("Company city")} />
            </Row>
            <FormikCompanyTypePicker name={"companyType"} title={t("Company type")} />
            <Form.Group className="form-group">
              <Form.Label>{t("TOL Business Industry Code")}</Form.Label>
              <Form.Control
                className="text-uppercase"
                name={"businessIndustryCode"}
                type={"text"}
                onChange={(e) => {
                  formikProps.handleChange(e);
                  this.setState((state) => ({ ...state, inputTolCode: e.target.value }));
                }}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.businessIndustryCode || ""}
                isInvalid={!!formikProps.errors.businessIndustryCode && formikProps.touched.businessIndustryCode}
              />
              {this.state.inputTolCode && (
                <div className="tol-code-value w-100 ">{TOLCodes(this.state.inputTolCode) || ""}</div>
              )}
              <Form.Control.Feedback type="invalid">{formikProps.errors.businessIndustryCode}</Form.Control.Feedback>
            </Form.Group>
            <FormikInput type={"number"} name={"businessEmployeesAmount"} title={t("Number of employees")} />
            <FormikInput type={"number"} name={"fiscalMonth"} title={t("Fiscal month")} />
            {this.state.addSalesData && (
              <>
                <h5 className="AppSidebar-subheader">{t("Sales data")}</h5>
                <AnalyticsForm prospect={prospect} formikProps={formikProps} />
              </>
            )}
            <Form.Group className="form-group">
              <Button variant="primary" type="submit" className={"w-100"} disabled={this.props.isSubmitting}>
                {this.props.isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                  </>
                ) : null}
                {t("Save")}
              </Button>
              {!this.state.addSalesData && (
                <Button
                  className="w-100 mt-3"
                  onClick={() =>
                    this.setState((state) => ({
                      ...state,
                      addSalesData: true,
                    }))
                  }
                >
                  {t("Create") + " " + t("Sales data")}
                </Button>
              )}
              {this.state.submitResultMessage ? (
                <AlertAutoDismissible
                  variant={this.state.submitResultSuccess ? "success" : "danger"}
                  className={"mt-2"}
                >
                  {this.state.submitResultMessage}
                </AlertAutoDismissible>
              ) : null}
            </Form.Group>
            <PromptMessage
              setPromptMessage={this.props.setPromptMessage}
              message={{
                place: t("Background information"),
                changes: hasFormikError(formikProps.errors) ? null : formikProps.values,
              }}
              enabled={formikProps.dirty}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(BackgroundInformationForm);
