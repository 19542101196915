import React from "react";
import { motion } from "framer-motion";

const FadingContainer = (props: any) => {
  return (
    <div key={props.trigger}>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: "easeIn", duration: 0.5 }}>
        {props.children}
      </motion.div>
    </div>
  );
};

export default FadingContainer;
