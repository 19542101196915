import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
  text: string;
};

const HeaderButton: React.FC<Props & ButtonProps> = ({ text, ...props }) => {
  const { t } = useTranslation("translations");
  return (
    <Button variant="primary" className="btn-header me-1 px-4 py-2 align-self-center" {...props}>
      {t(text)}
    </Button>
  );
};

export default HeaderButton;
