/* tslint:disable */
/* eslint-disable */
/**
 * Jeppis invest insurance API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApplicationAnalyticsActionsCommonAnalyticsDto
 */
export interface ApplicationAnalyticsActionsCommonAnalyticsDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationAnalyticsActionsCommonAnalyticsDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAnalyticsActionsCommonAnalyticsDto
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAnalyticsActionsCommonAnalyticsDto
     */
    'leadChannel'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAnalyticsActionsCommonAnalyticsDto
     */
    'firstMeetingBookingDate'?: string;
    /**
     * 
     * @type {Array<ApplicationAnalyticsActionsCommonMeetingDto>}
     * @memberof ApplicationAnalyticsActionsCommonAnalyticsDto
     */
    'meetings'?: Array<ApplicationAnalyticsActionsCommonMeetingDto> | null;
    /**
     * 
     * @type {Array<ApplicationAnalyticsActionsCommonSaleDto>}
     * @memberof ApplicationAnalyticsActionsCommonAnalyticsDto
     */
    'sales'?: Array<ApplicationAnalyticsActionsCommonSaleDto> | null;
}
/**
 * 
 * @export
 * @interface ApplicationAnalyticsActionsCommonMeetingDto
 */
export interface ApplicationAnalyticsActionsCommonMeetingDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationAnalyticsActionsCommonMeetingDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAnalyticsActionsCommonMeetingDto
     */
    'date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAnalyticsActionsCommonMeetingDto
     */
    'outcome'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAnalyticsActionsCommonMeetingDto
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface ApplicationAnalyticsActionsCommonSaleDto
 */
export interface ApplicationAnalyticsActionsCommonSaleDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationAnalyticsActionsCommonSaleDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAnalyticsActionsCommonSaleDto
     */
    'salesProduct'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAnalyticsActionsCommonSaleDto
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand
 */
export interface ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand {
    /**
     * 
     * @type {number}
     * @memberof ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand
     */
    'leadChannel'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand
     */
    'firstMeetingBookingDate'?: string | null;
    /**
     * 
     * @type {Array<ApplicationAnalyticsActionsCommonMeetingDto>}
     * @memberof ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand
     */
    'meetings'?: Array<ApplicationAnalyticsActionsCommonMeetingDto> | null;
    /**
     * 
     * @type {Array<ApplicationAnalyticsActionsCommonSaleDto>}
     * @memberof ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand
     */
    'sales'?: Array<ApplicationAnalyticsActionsCommonSaleDto> | null;
}
/**
 * 
 * @export
 * @interface ApplicationAuthenticationActionsAuthenticateCommand
 */
export interface ApplicationAuthenticationActionsAuthenticateCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationAuthenticationActionsAuthenticateCommand
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAuthenticationActionsAuthenticateCommand
     */
    'password'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationAuthenticationActionsAuthenticateResponse
 */
export interface ApplicationAuthenticationActionsAuthenticateResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationAuthenticationActionsAuthenticateResponse
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAuthenticationActionsAuthenticateResponse
     */
    'refreshToken'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationAuthenticationActionsRefreshTokenCommand
 */
export interface ApplicationAuthenticationActionsRefreshTokenCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationAuthenticationActionsRefreshTokenCommand
     */
    'accessToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAuthenticationActionsRefreshTokenCommand
     */
    'refreshToken'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationOmsenDtosCountryDto
 */
export interface ApplicationOmsenDtosCountryDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosCountryDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationOmsenDtosCountryDto
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosCountryDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationOmsenDtosCustomerCategoryDto
 */
export interface ApplicationOmsenDtosCustomerCategoryDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosCustomerCategoryDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosCustomerCategoryDto
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationOmsenDtosIndustrialClassificationDto
 */
export interface ApplicationOmsenDtosIndustrialClassificationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosIndustrialClassificationDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosIndustrialClassificationDto
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationOmsenDtosInstallmentDto
 */
export interface ApplicationOmsenDtosInstallmentDto {
    /**
     * 
     * @type {number}
     * @memberof ApplicationOmsenDtosInstallmentDto
     */
    'number'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosInstallmentDto
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosInstallmentDto
     */
    'requirementInfo'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationOmsenDtosLanguageDto
 */
export interface ApplicationOmsenDtosLanguageDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosLanguageDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosLanguageDto
     */
    'text'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationOmsenDtosMaintenanceWindowDto
 */
export interface ApplicationOmsenDtosMaintenanceWindowDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosMaintenanceWindowDto
     */
    'startTimeUTC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosMaintenanceWindowDto
     */
    'endTimeUTC'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosMaintenanceWindowDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosMaintenanceWindowDto
     */
    'message'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationOmsenDtosMaintenanceWindowDto
     */
    'isActive'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationOmsenDtosMaintenanceWindowDto
     */
    'isInFuture'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationOmsenDtosMaintenanceWindowDto
     */
    'isInPast'?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationOmsenDtosOccupationDto
 */
export interface ApplicationOmsenDtosOccupationDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosOccupationDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosOccupationDto
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosOccupationDto
     */
    'riskClass'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationOmsenDtosOccupationDto
     */
    'tariffYear'?: number;
}
/**
 * 
 * @export
 * @interface ApplicationOmsenDtosTaxDomicileDto
 */
export interface ApplicationOmsenDtosTaxDomicileDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosTaxDomicileDto
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationOmsenDtosTaxDomicileDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsCreateProspectCreateProspectCommand
 */
export interface ApplicationProspectActionsCreateProspectCreateProspectCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'meetingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyCity'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'fiscalMonth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyContactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyZipCode'?: string | null;
    /**
     * 
     * @type {DomainValueObjectsCompanyType}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyType'?: DomainValueObjectsCompanyType;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyOwnershipPercentage'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'companyOtherOwners'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'businessIndustryCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'businessEmployeesAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'businessRevenue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'businessProfit'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectCommand
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsCreateProspectCreateProspectResponse
 */
export interface ApplicationProspectActionsCreateProspectCreateProspectResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'meetingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'sellerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'sellerLastName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'state'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'hasProspectFilledInData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'view'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'isMeetingActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'companyCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsCreateProspectCreateProspectResponse
     */
    'fiscalMonth'?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand
 */
export interface ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand
     */
    'modifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand
     */
    'newValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand
     */
    'sellerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand
     */
    'salesState'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse
 */
export interface ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse
     */
    'newValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse
     */
    'modifiedDate'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsDeleteProspectDeleteProspectResponse
 */
export interface ApplicationProspectActionsDeleteProspectDeleteProspectResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'meetingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'sellerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'sellerLastName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'state'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'hasProspectFilledInData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'view'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsDeleteProspectDeleteProspectResponse
     */
    'isMeetingActive'?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
 */
export interface ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'sellerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'sellerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'sellerLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'meetingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'created'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'state'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'hasProspectFilledInData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'view'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'isMeetingActive'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'fiscalMonth'?: number | null;
    /**
     * 
     * @type {Array<DomainEntitiesProspectHistory>}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'prospectHistories'?: Array<DomainEntitiesProspectHistory> | null;
    /**
     * 
     * @type {DomainValueObjectsSalesState}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'salesState'?: DomainValueObjectsSalesState;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse
     */
    'businessIndustryCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsGetPaginatedProspectsPaginatedResultGetPaginatedProspectsResponse
 */
export interface ApplicationProspectActionsGetPaginatedProspectsPaginatedResultGetPaginatedProspectsResponse {
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsPaginatedResultGetPaginatedProspectsResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsPaginatedResultGetPaginatedProspectsResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsPaginatedResultGetPaginatedProspectsResponse
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {Array<ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse>}
     * @memberof ApplicationProspectActionsGetPaginatedProspectsPaginatedResultGetPaginatedProspectsResponse
     */
    'items'?: Array<ApplicationProspectActionsGetPaginatedProspectsGetPaginatedProspectsResponse> | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsGetProspectByIdChildDetailDto
 */
export interface ApplicationProspectActionsGetProspectByIdChildDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdChildDetailDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdChildDetailDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdChildDetailDto
     */
    'age'?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
 */
export interface ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'streetAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'countryOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'citizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'language'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'meetingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'sellerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'sellerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'sellerLastName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'state'?: number;
    /**
     * 
     * @type {DomainValueObjectsSalesState}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'salesState'?: DomainValueObjectsSalesState;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'maritalStatus'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'marriedYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'spouseMonthlySalary'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'spouseMonthlyPension'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'spouseBirthDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'childAmount'?: number | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsGetProspectByIdChildDetailDto>}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'childDetails'?: Array<ApplicationProspectActionsGetProspectByIdChildDetailDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'annualIncomeYel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'monthlyPension'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'numberOfYelYears'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'yelDiscount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'optionalLifeInsuranceDiscount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'hasProspectFilledInData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'assets'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'broker'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyContactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyPhoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyLanguage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyPhone'?: string | null;
    /**
     * 
     * @type {DomainValueObjectsCompanyType}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyType'?: DomainValueObjectsCompanyType;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyOwnershipPercentage'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyOtherOwners'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'businessIndustryCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'businessEmployeesAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'fiscalMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'businessRevenue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'businessProfit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'estimationOfWorth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsGetProspectByIdInsuranceDto>}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'insurances'?: Array<ApplicationProspectActionsGetProspectByIdInsuranceDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'view'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'companyLoans'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'personalLoans'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'yearsUntilPension'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'age'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'minimumPensionAge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'minimumPartialPensionAge'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'isMeetingActive'?: boolean;
    /**
     * 
     * @type {ApplicationAnalyticsActionsCommonAnalyticsDto}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'salesAnalyticsData'?: ApplicationAnalyticsActionsCommonAnalyticsDto;
    /**
     * 
     * @type {DomainEntitiesProspectOmsenData}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'omsenData'?: DomainEntitiesProspectOmsenData;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse
     */
    'omsenState'?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
 */
export interface ApplicationProspectActionsGetProspectByIdInsuranceDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'type'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'monthlyAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'oneOffAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'simpleAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'annualCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'savingYears'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'savingsAtBeginningOfPension'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'isOptional'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'isSummable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsGetProspectByIdInsuranceDetailDto>}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'children'?: Array<ApplicationProspectActionsGetProspectByIdInsuranceDetailDto> | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsGetProspectByIdInsuranceExampleDto>}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'examples'?: Array<ApplicationProspectActionsGetProspectByIdInsuranceExampleDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDetailDto
     */
    'omsenProductCode'?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsGetProspectByIdInsuranceDto
 */
export interface ApplicationProspectActionsGetProspectByIdInsuranceDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'type'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'isVisible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'isTotalsVisible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'annualIncomeYel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'annualCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'monthlyCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'annualCostYel'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'monthlyCostYel'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'optionalSicknessPayDeductibleDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'optionalSicknessDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'optionalSicknessPayAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'optionalDisabilityPensionAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'optionalLifeInsuranceAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'optionalSeriousIllnessInsuranceAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'optionalExpectedNetRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'optionalOldAgeExtraPensionAnnualCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'paymentInterval'?: number;
    /**
     * 
     * @type {Array<ApplicationProspectActionsGetProspectByIdInsuranceDetailDto>}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceDto
     */
    'details'?: Array<ApplicationProspectActionsGetProspectByIdInsuranceDetailDto> | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsGetProspectByIdInsuranceExampleDto
 */
export interface ApplicationProspectActionsGetProspectByIdInsuranceExampleDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceExampleDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceExampleDto
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceExampleDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByIdInsuranceExampleDto
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
 */
export interface ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'reference'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'state'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'hasProspectFilledInData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'meetingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'sellerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'sellerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'sellerLastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'view'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse
     */
    'isMeetingActive'?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsGetProspectEmailGetProspectEmailResponse
 */
export interface ApplicationProspectActionsGetProspectEmailGetProspectEmailResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsGetProspectEmailGetProspectEmailResponse
     */
    'emailContent'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsPushProspectViewPushProspectViewCommand
 */
export interface ApplicationProspectActionsPushProspectViewPushProspectViewCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsPushProspectViewPushProspectViewCommand
     */
    'view'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsSendProspectEmailSendProspectEmailCommand
 */
export interface ApplicationProspectActionsSendProspectEmailSendProspectEmailCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsSendProspectEmailSendProspectEmailCommand
     */
    'prospectId'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsUpdateProspectChildDetailDto
 */
export interface ApplicationProspectActionsUpdateProspectChildDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectChildDetailDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectChildDetailDto
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectChildDetailDto
     */
    'age'?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsUpdateProspectInsuranceDetailDto
 */
export interface ApplicationProspectActionsUpdateProspectInsuranceDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'type'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'monthlyAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'simpleAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'oneOffAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'annualCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'savingYears'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'savingsAtBeginningOfPension'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'isOptional'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'isSummable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsUpdateProspectInsuranceDetailDto>}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'children'?: Array<ApplicationProspectActionsUpdateProspectInsuranceDetailDto> | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsUpdateProspectInsuranceExampleDto>}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'examples'?: Array<ApplicationProspectActionsUpdateProspectInsuranceExampleDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDetailDto
     */
    'omsenProductCode'?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsUpdateProspectInsuranceDto
 */
export interface ApplicationProspectActionsUpdateProspectInsuranceDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'type'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'isVisible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'isTotalsVisible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'annualIncomeYel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'annualCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'monthlyCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'annualCostYel'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'monthlyCostYel'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'optionalSicknessPayDeductibleDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'optionalSicknessPayAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'optionalSicknessDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'optionalDisabilityPensionAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'optionalLifeInsuranceAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'optionalSeriousIllnessInsuranceAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'optionalExpectedNetRate'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'optionalOldAgeExtraPensionAnnualCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'paymentInterval'?: number;
    /**
     * 
     * @type {Array<ApplicationProspectActionsUpdateProspectInsuranceDetailDto>}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceDto
     */
    'details'?: Array<ApplicationProspectActionsUpdateProspectInsuranceDetailDto> | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsUpdateProspectInsuranceExampleDto
 */
export interface ApplicationProspectActionsUpdateProspectInsuranceExampleDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceExampleDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceExampleDto
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceExampleDto
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectInsuranceExampleDto
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsUpdateProspectProspectHistoryDto
 */
export interface ApplicationProspectActionsUpdateProspectProspectHistoryDto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectProspectHistoryDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectProspectHistoryDto
     */
    'modifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectProspectHistoryDto
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectProspectHistoryDto
     */
    'newValue'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand
 */
export interface ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand
     */
    'maritalStatus'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand
     */
    'childAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand
     */
    'annualIncomeYel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand
     */
    'monthlyPension'?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataResponse
 */
export interface ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataResponse {
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataResponse
     */
    'state'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataResponse
     */
    'hasProspectFilledInData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataResponse
     */
    'view'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataResponse
     */
    'isMeetingActive'?: boolean;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsUpdateProspectUpdateProspectCommand
 */
export interface ApplicationProspectActionsUpdateProspectUpdateProspectCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'streetAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'countryOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'citizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'language'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'meetingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'maritalStatus'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'marriedYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'spouseMonthlySalary'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'spouseMonthlyPension'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'spouseBirthDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'childAmount'?: number | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsUpdateProspectChildDetailDto>}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'childDetails'?: Array<ApplicationProspectActionsUpdateProspectChildDetailDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'annualIncomeYel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'monthlyPension'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'numberOfYelYears'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'yelDiscount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'optionalLifeInsuranceDiscount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'assets'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'broker'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyContactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyPhoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyLanguage'?: string | null;
    /**
     * 
     * @type {DomainValueObjectsCompanyType}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyType'?: DomainValueObjectsCompanyType;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyOwnershipPercentage'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyOtherOwners'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'businessIndustryCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'businessEmployeesAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'fiscalMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'businessRevenue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'businessProfit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'estimationOfWorth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'companyLoans'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'personalLoans'?: number | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsUpdateProspectInsuranceDto>}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'insurances'?: Array<ApplicationProspectActionsUpdateProspectInsuranceDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'salesState'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'sellerId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesProspectOmsenData}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'omsenData'?: DomainEntitiesProspectOmsenData;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectCommand
     */
    'omsenState'?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectActionsUpdateProspectUpdateProspectResponse
 */
export interface ApplicationProspectActionsUpdateProspectUpdateProspectResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'streetAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'countryOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'citizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'language'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'meetingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'sellerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'sellerFirstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'sellerLastName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'state'?: number;
    /**
     * 
     * @type {DomainValueObjectsSalesState}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'salesState'?: DomainValueObjectsSalesState;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'maritalStatus'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'marriedYear'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'spouseMonthlySalary'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'spouseMonthlyPension'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'spouseBirthDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'childAmount'?: number | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsUpdateProspectChildDetailDto>}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'childDetails'?: Array<ApplicationProspectActionsUpdateProspectChildDetailDto> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'annualIncomeYel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'monthlyPension'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'numberOfYelYears'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'yelDiscount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'optionalLifeInsuranceDiscount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'hasProspectFilledInData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'assets'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'broker'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyContactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyPhoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyLanguage'?: string | null;
    /**
     * 
     * @type {DomainValueObjectsCompanyType}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyType'?: DomainValueObjectsCompanyType;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyOwnershipPercentage'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyOtherOwners'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'businessIndustryCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'businessEmployeesAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'fiscalMonth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'businessRevenue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'businessProfit'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'estimationOfWorth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<ApplicationProspectActionsUpdateProspectInsuranceDto>}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'insurances'?: Array<ApplicationProspectActionsUpdateProspectInsuranceDto> | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'view'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'companyLoans'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'personalLoans'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'yearsUntilPension'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'age'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'minimumPensionAge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'minimumPartialPensionAge'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'isMeetingActive'?: boolean;
    /**
     * 
     * @type {ApplicationAnalyticsActionsCommonAnalyticsDto}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'salesAnalyticsData'?: ApplicationAnalyticsActionsCommonAnalyticsDto;
    /**
     * 
     * @type {Array<ApplicationProspectActionsUpdateProspectProspectHistoryDto>}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'prospectHistories'?: Array<ApplicationProspectActionsUpdateProspectProspectHistoryDto> | null;
    /**
     * 
     * @type {DomainEntitiesProspectOmsenData}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'omsenData'?: DomainEntitiesProspectOmsenData;
    /**
     * 
     * @type {number}
     * @memberof ApplicationProspectActionsUpdateProspectUpdateProspectResponse
     */
    'omsenState'?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse
 */
export interface ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse
     */
    'newValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse
     */
    'modifiedDate'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse
 */
export interface ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse
     */
    'modifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse
     */
    'newValue'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand
 */
export interface ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand
     */
    'sellerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse
 */
export interface ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse
     */
    'newValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse
     */
    'modifiedDate'?: string;
}
/**
 * 
 * @export
 * @interface ApplicationUserActionsGetAllSellersGetAllSellersResponse
 */
export interface ApplicationUserActionsGetAllSellersGetAllSellersResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsGetAllSellersGetAllSellersResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsGetAllSellersGetAllSellersResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsGetAllSellersGetAllSellersResponse
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserActionsGetUsersGetPaginatedUsersResponse
 */
export interface ApplicationUserActionsGetUsersGetPaginatedUsersResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsGetUsersGetPaginatedUsersResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsGetUsersGetPaginatedUsersResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsGetUsersGetPaginatedUsersResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsGetUsersGetPaginatedUsersResponse
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsGetUsersGetPaginatedUsersResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserActionsGetUsersGetPaginatedUsersResponse
     */
    'isInActive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationUserActionsGetUsersGetPaginatedUsersResponse
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserActionsGetUsersPaginatedResultGetPaginatedUserResponse
 */
export interface ApplicationUserActionsGetUsersPaginatedResultGetPaginatedUserResponse {
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserActionsGetUsersPaginatedResultGetPaginatedUserResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserActionsGetUsersPaginatedResultGetPaginatedUserResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationUserActionsGetUsersPaginatedResultGetPaginatedUserResponse
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {Array<ApplicationUserActionsGetUsersGetPaginatedUsersResponse>}
     * @memberof ApplicationUserActionsGetUsersPaginatedResultGetPaginatedUserResponse
     */
    'items'?: Array<ApplicationUserActionsGetUsersGetPaginatedUsersResponse> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserActionsRegisterUserRegisterCommand
 */
export interface ApplicationUserActionsRegisterUserRegisterCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsRegisterUserRegisterCommand
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsRegisterUserRegisterCommand
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsRegisterUserRegisterCommand
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsRegisterUserRegisterCommand
     */
    'password'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationUserActionsRegisterUserRegisterCommand
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserActionsRegisterUserRegisterResponse
 */
export interface ApplicationUserActionsRegisterUserRegisterResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsRegisterUserRegisterResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsRegisterUserRegisterResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsRegisterUserRegisterResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsRegisterUserRegisterResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationUserActionsRegisterUserRegisterResponse
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserActionsUpdateUserUpdateUserCommand
 */
export interface ApplicationUserActionsUpdateUserUpdateUserCommand {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserCommand
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserCommand
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserCommand
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserCommand
     */
    'isInActive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserCommand
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ApplicationUserActionsUpdateUserUpdateUserResponse
 */
export interface ApplicationUserActionsUpdateUserUpdateUserResponse {
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserResponse
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserResponse
     */
    'isInActive'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationUserActionsUpdateUserUpdateUserResponse
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface ClientsOmsenProduct
 */
export interface ClientsOmsenProduct {
    /**
     * 
     * @type {number}
     * @memberof ClientsOmsenProduct
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientsOmsenProduct
     */
    'text'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientsOmsenProduct
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientsOmsenProduct
     */
    'insuranceTypeCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClientsOmsenProduct
     */
    'dependsOnProductCode'?: number;
}
/**
 * 
 * @export
 * @interface DomainEntitiesChildDetail
 */
export interface DomainEntitiesChildDetail {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesChildDetail
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesChildDetail
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesChildDetail
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesChildDetail
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesChildDetail
     */
    'age'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesChildDetail
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesProspect}
     * @memberof DomainEntitiesChildDetail
     */
    'prospect'?: DomainEntitiesProspect;
}
/**
 * 
 * @export
 * @interface DomainEntitiesInsurance
 */
export interface DomainEntitiesInsurance {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsurance
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsurance
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsurance
     */
    'id'?: string | null;
    /**
     * 
     * @type {DomainValueObjectsInsuranceType}
     * @memberof DomainEntitiesInsurance
     */
    'type'?: DomainValueObjectsInsuranceType;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesInsurance
     */
    'isVisible'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesInsurance
     */
    'isTotalsVisible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'annualIncomeYel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'monthlyIncomeYel'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'annualCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'costPerRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'monthlyCost'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'annualCostYel'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'monthlyCostYel'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'optionalSicknessPayDeductibleDays'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'optionalSicknessPayAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'optionalSicknessDuration'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'optionalDisabilityPensionAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'optionalLifeInsuranceAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'optionalSeriousIllnessInsuranceAmount'?: number;
    /**
     * 
     * @type {DomainValueObjectsInsuranceExpectedNetRate}
     * @memberof DomainEntitiesInsurance
     */
    'optionalExpectedNetRate'?: DomainValueObjectsInsuranceExpectedNetRate;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'optionalOldAgeExtraPensionAnnualCost'?: number;
    /**
     * 
     * @type {DomainValueObjectsInsurancePaymentInterval}
     * @memberof DomainEntitiesInsurance
     */
    'paymentInterval'?: DomainValueObjectsInsurancePaymentInterval;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesInsurance
     */
    'shouldRecalculateNeed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsurance
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesProspect}
     * @memberof DomainEntitiesInsurance
     */
    'prospect'?: DomainEntitiesProspect;
    /**
     * 
     * @type {Array<DomainEntitiesInsuranceDetail>}
     * @memberof DomainEntitiesInsurance
     */
    'details'?: Array<DomainEntitiesInsuranceDetail> | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'omsenPremiumCalculationId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsurance
     */
    'omsenInsuranceProposalId'?: number | null;
}
/**
 * 
 * @export
 * @interface DomainEntitiesInsuranceDetail
 */
export interface DomainEntitiesInsuranceDetail {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'id'?: string | null;
    /**
     * 
     * @type {DomainValueObjectsInsuranceDetailType}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'type'?: DomainValueObjectsInsuranceDetailType;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'totalAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'monthlyAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'simpleAmount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'oneOffAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'savingYears'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'savingsAtBeginningOfPension'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'omsenProductCode'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'isOptional'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'isSummable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'annualCost'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesInsuranceDetail}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'parent'?: DomainEntitiesInsuranceDetail;
    /**
     * 
     * @type {Array<DomainEntitiesInsuranceDetail>}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'children'?: Array<DomainEntitiesInsuranceDetail> | null;
    /**
     * 
     * @type {Array<DomainEntitiesInsuranceExample>}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'examples'?: Array<DomainEntitiesInsuranceExample> | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'insuranceId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesInsurance}
     * @memberof DomainEntitiesInsuranceDetail
     */
    'insurance'?: DomainEntitiesInsurance;
}
/**
 * 
 * @export
 * @interface DomainEntitiesInsuranceExample
 */
export interface DomainEntitiesInsuranceExample {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceExample
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceExample
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceExample
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceExample
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceExample
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceExample
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesInsuranceExample
     */
    'sortOrder'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesInsuranceExample
     */
    'insuranceDetailId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesInsuranceDetail}
     * @memberof DomainEntitiesInsuranceExample
     */
    'insuranceDetail'?: DomainEntitiesInsuranceDetail;
}
/**
 * 
 * @export
 * @interface DomainEntitiesMeeting
 */
export interface DomainEntitiesMeeting {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesMeeting
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesMeeting
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesMeeting
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesMeeting
     */
    'salesAnalyticsDataId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesSalesAnalyticsData}
     * @memberof DomainEntitiesMeeting
     */
    'salesAnalyticsData'?: DomainEntitiesSalesAnalyticsData;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesMeeting
     */
    'date'?: string | null;
    /**
     * 
     * @type {DomainValueObjectsMeetingOutcome}
     * @memberof DomainEntitiesMeeting
     */
    'outcome'?: DomainValueObjectsMeetingOutcome;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesMeeting
     */
    'sortOrder'?: number;
}
/**
 * 
 * @export
 * @interface DomainEntitiesOmsenDataEmployeePayrollDetail
 */
export interface DomainEntitiesOmsenDataEmployeePayrollDetail {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataEmployeePayrollDetail
     */
    'occupationCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesOmsenDataEmployeePayrollDetail
     */
    'totalPayrollAmount'?: number;
}
/**
 * 
 * @export
 * @interface DomainEntitiesOmsenDataEmployerAnnualEarningsDetail
 */
export interface DomainEntitiesOmsenDataEmployerAnnualEarningsDetail {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataEmployerAnnualEarningsDetail
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataEmployerAnnualEarningsDetail
     */
    'ssn'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesOmsenDataEmployerAnnualEarningsDetail
     */
    'annualEarningsAmount'?: number;
    /**
     * 
     * @type {Array<DomainEntitiesOmsenDataOccupationAndEarningPercentage>}
     * @memberof DomainEntitiesOmsenDataEmployerAnnualEarningsDetail
     */
    'occupationAndEarningPercentages'?: Array<DomainEntitiesOmsenDataOccupationAndEarningPercentage> | null;
}
/**
 * 
 * @export
 * @interface DomainEntitiesOmsenDataOccupationAndEarningPercentage
 */
export interface DomainEntitiesOmsenDataOccupationAndEarningPercentage {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataOccupationAndEarningPercentage
     */
    'occupationCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesOmsenDataOccupationAndEarningPercentage
     */
    'percentageOfAnnualEarnings'?: number;
}
/**
 * 
 * @export
 * @interface DomainEntitiesOmsenDataProposal
 */
export interface DomainEntitiesOmsenDataProposal {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposal
     */
    'invoiceReceiverName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposal
     */
    'claimReceiverName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposal
     */
    'internalNote'?: string | null;
    /**
     * 
     * @type {DomainEntitiesOmsenDataProposalPolicyHolder}
     * @memberof DomainEntitiesOmsenDataProposal
     */
    'policyHolder'?: DomainEntitiesOmsenDataProposalPolicyHolder;
}
/**
 * 
 * @export
 * @interface DomainEntitiesOmsenDataProposalKycLegalPerson
 */
export interface DomainEntitiesOmsenDataProposalKycLegalPerson {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycLegalPerson
     */
    'industrialClassificationCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesOmsenDataProposalKycLegalPerson
     */
    'countryOfRegistrationNumber'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainEntitiesOmsenDataProposalKycLegalPerson
     */
    'countriesOfTaxationNumbers'?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycLegalPerson
     */
    'contactPersonName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycLegalPerson
     */
    'contactPersonPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycLegalPerson
     */
    'contactPersonEmail'?: string | null;
    /**
     * 
     * @type {Array<DomainEntitiesOmsenDataProposalKycRealBeneficiary>}
     * @memberof DomainEntitiesOmsenDataProposalKycLegalPerson
     */
    'realBeneficiaries'?: Array<DomainEntitiesOmsenDataProposalKycRealBeneficiary> | null;
}
/**
 * 
 * @export
 * @interface DomainEntitiesOmsenDataProposalKycNaturalPerson
 */
export interface DomainEntitiesOmsenDataProposalKycNaturalPerson {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycNaturalPerson
     */
    'taxDomicileCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesOmsenDataProposalKycNaturalPerson
     */
    'countryOfBirthNumber'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainEntitiesOmsenDataProposalKycNaturalPerson
     */
    'citizenshipNumbers'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesOmsenDataProposalKycNaturalPerson
     */
    'politicalExposedPerson'?: boolean;
}
/**
 * 
 * @export
 * @interface DomainEntitiesOmsenDataProposalKycRealBeneficiary
 */
export interface DomainEntitiesOmsenDataProposalKycRealBeneficiary {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'ssn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'postalAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'languageCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'taxDomicileCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'countryOfBirthNumber'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'citizenshipNumbers'?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'politicalExposedPerson'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'positionInCompanyInfo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalKycRealBeneficiary
     */
    'shareHoldingInfo'?: string | null;
}
/**
 * 
 * @export
 * @interface DomainEntitiesOmsenDataProposalPolicyHolder
 */
export interface DomainEntitiesOmsenDataProposalPolicyHolder {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'customerCategoryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'ssn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'postalAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'postalCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'countryCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'languageCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'mobile'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'email'?: string | null;
    /**
     * 
     * @type {DomainEntitiesOmsenDataProposalKycNaturalPerson}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'kycNaturalPerson'?: DomainEntitiesOmsenDataProposalKycNaturalPerson;
    /**
     * 
     * @type {DomainEntitiesOmsenDataProposalKycLegalPerson}
     * @memberof DomainEntitiesOmsenDataProposalPolicyHolder
     */
    'kycLegalPerson'?: DomainEntitiesOmsenDataProposalKycLegalPerson;
}
/**
 * 
 * @export
 * @interface DomainEntitiesProspect
 */
export interface DomainEntitiesProspect {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'streetAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'zipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'countryOfBirth'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'citizenship'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'language'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'socialSecurityNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'reference'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'meetingDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'sellerId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesUser}
     * @memberof DomainEntitiesProspect
     */
    'seller'?: DomainEntitiesUser;
    /**
     * 
     * @type {Array<DomainEntitiesInsurance>}
     * @memberof DomainEntitiesProspect
     */
    'insurances'?: Array<DomainEntitiesInsurance> | null;
    /**
     * 
     * @type {DomainValueObjectsProspectState}
     * @memberof DomainEntitiesProspect
     */
    'state'?: DomainValueObjectsProspectState;
    /**
     * 
     * @type {DomainValueObjectsSalesState}
     * @memberof DomainEntitiesProspect
     */
    'salesState'?: DomainValueObjectsSalesState;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'birthDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'marriedDate'?: string | null;
    /**
     * 
     * @type {DomainValueObjectsMaritalStatus}
     * @memberof DomainEntitiesProspect
     */
    'maritalStatus'?: DomainValueObjectsMaritalStatus;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesProspect
     */
    'isMarried'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'spouseMonthlySalary'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'spouseMonthlyPension'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'spouseAge'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'spouseBirthDate'?: string | null;
    /**
     * 
     * @type {Array<DomainEntitiesProspectHistory>}
     * @memberof DomainEntitiesProspect
     */
    'prospectHistories'?: Array<DomainEntitiesProspectHistory> | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'estimatedSpouseAge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'childAmount'?: number | null;
    /**
     * 
     * @type {Array<DomainEntitiesChildDetail>}
     * @memberof DomainEntitiesProspect
     */
    'childDetails'?: Array<DomainEntitiesChildDetail> | null;
    /**
     * 
     * @type {DomainEntitiesChildDetail}
     * @memberof DomainEntitiesProspect
     */
    'youngestChild'?: DomainEntitiesChildDetail;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'annualIncomeYel'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'monthlyPension'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'numberOfYelYears'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'yelDiscount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'optionalLifeInsuranceDiscount'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesProspect
     */
    'hasProspectFilledInData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'annualEarnings'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'assets'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'view'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesProspect
     */
    'broker'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'fiscalMonth'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyZipCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyCity'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyPhone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyContactPerson'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyLanguage'?: string | null;
    /**
     * 
     * @type {DomainValueObjectsCompanyType}
     * @memberof DomainEntitiesProspect
     */
    'companyType'?: DomainValueObjectsCompanyType;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'companyOwnershipPercentage'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'companyOtherOwners'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'estimationOfWorth'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'companyLoans'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'personalLoans'?: number | null;
    /**
     * 
     * @type {DomainEntitiesProspectOmsenData}
     * @memberof DomainEntitiesProspect
     */
    'omsenData'?: DomainEntitiesProspectOmsenData;
    /**
     * 
     * @type {DomainValueObjectsProspectOmsenState}
     * @memberof DomainEntitiesProspect
     */
    'omsenState'?: DomainValueObjectsProspectOmsenState;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesProspect
     */
    'dataCollectionEnabled'?: boolean;
    /**
     * 
     * @type {DomainEntitiesSalesAnalyticsData}
     * @memberof DomainEntitiesProspect
     */
    'salesAnalyticsData'?: DomainEntitiesSalesAnalyticsData;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'businessIndustryCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'businessEmployeesAmount'?: number | null;
    /**
     * 
     * @type {DomainValueObjectsBusinessRevenue}
     * @memberof DomainEntitiesProspect
     */
    'businessRevenue'?: DomainValueObjectsBusinessRevenue;
    /**
     * 
     * @type {DomainValueObjectsBusinessProfit}
     * @memberof DomainEntitiesProspect
     */
    'businessProfit'?: DomainValueObjectsBusinessProfit;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'age'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'ageYears'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'yearsUntilPension'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'minimumPensionAge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'minimumPartialPensionAge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'underageChildAmount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspect
     */
    'currentView'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesProspect
     */
    'marriedYear'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesProspect
     */
    'isMeetingActive'?: boolean;
}
/**
 * 
 * @export
 * @interface DomainEntitiesProspectHistory
 */
export interface DomainEntitiesProspectHistory {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspectHistory
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspectHistory
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesProspect}
     * @memberof DomainEntitiesProspectHistory
     */
    'prospect'?: DomainEntitiesProspect;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspectHistory
     */
    'modifiedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspectHistory
     */
    'oldValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspectHistory
     */
    'newValue'?: string | null;
}
/**
 * 
 * @export
 * @interface DomainEntitiesProspectOmsenData
 */
export interface DomainEntitiesProspectOmsenData {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspectOmsenData
     */
    'industrialClassificationCode'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof DomainEntitiesProspectOmsenData
     */
    'productCodes'?: Array<number> | null;
    /**
     * 
     * @type {Array<DomainEntitiesOmsenDataEmployeePayrollDetail>}
     * @memberof DomainEntitiesProspectOmsenData
     */
    'employeePayrollDetails'?: Array<DomainEntitiesOmsenDataEmployeePayrollDetail> | null;
    /**
     * 
     * @type {Array<DomainEntitiesOmsenDataEmployerAnnualEarningsDetail>}
     * @memberof DomainEntitiesProspectOmsenData
     */
    'employerAnnualEarningsDetails'?: Array<DomainEntitiesOmsenDataEmployerAnnualEarningsDetail> | null;
    /**
     * 
     * @type {DomainEntitiesOmsenDataProposal}
     * @memberof DomainEntitiesProspectOmsenData
     */
    'proposal'?: DomainEntitiesOmsenDataProposal;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspectOmsenData
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesProspectOmsenData
     */
    'errors'?: string | null;
}
/**
 * 
 * @export
 * @interface DomainEntitiesSale
 */
export interface DomainEntitiesSale {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesSale
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesSale
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesSale
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesSale
     */
    'salesAnalyticsDataId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesSalesAnalyticsData}
     * @memberof DomainEntitiesSale
     */
    'salesAnalyticsData'?: DomainEntitiesSalesAnalyticsData;
    /**
     * 
     * @type {DomainValueObjectsSalesProduct}
     * @memberof DomainEntitiesSale
     */
    'salesProduct'?: DomainValueObjectsSalesProduct;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesSale
     */
    'price'?: number | null;
}
/**
 * 
 * @export
 * @interface DomainEntitiesSalesAnalyticsData
 */
export interface DomainEntitiesSalesAnalyticsData {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesSalesAnalyticsData
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesSalesAnalyticsData
     */
    'lastModified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesSalesAnalyticsData
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesSalesAnalyticsData
     */
    'prospectId'?: string | null;
    /**
     * 
     * @type {DomainEntitiesProspect}
     * @memberof DomainEntitiesSalesAnalyticsData
     */
    'prospect'?: DomainEntitiesProspect;
    /**
     * 
     * @type {DomainValueObjectsLeadChannel}
     * @memberof DomainEntitiesSalesAnalyticsData
     */
    'leadChannel'?: DomainValueObjectsLeadChannel;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesSalesAnalyticsData
     */
    'firstMeetingBookingDate'?: string;
    /**
     * 
     * @type {Array<DomainEntitiesMeeting>}
     * @memberof DomainEntitiesSalesAnalyticsData
     */
    'meetings'?: Array<DomainEntitiesMeeting> | null;
    /**
     * 
     * @type {Array<DomainEntitiesSale>}
     * @memberof DomainEntitiesSalesAnalyticsData
     */
    'sales'?: Array<DomainEntitiesSale> | null;
}
/**
 * 
 * @export
 * @interface DomainEntitiesUser
 */
export interface DomainEntitiesUser {
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'normalizedUserName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'normalizedEmail'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesUser
     */
    'emailConfirmed'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'passwordHash'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'securityStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'concurrencyStamp'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesUser
     */
    'phoneNumberConfirmed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesUser
     */
    'twoFactorEnabled'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'lockoutEnd'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DomainEntitiesUser
     */
    'lockoutEnabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DomainEntitiesUser
     */
    'accessFailedCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {Array<DomainEntitiesProspect>}
     * @memberof DomainEntitiesUser
     */
    'prospects'?: Array<DomainEntitiesProspect> | null;
    /**
     * 
     * @type {string}
     * @memberof DomainEntitiesUser
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsBusinessProfit
 */
export interface DomainValueObjectsBusinessProfit {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsBusinessProfit
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsBusinessProfit
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsBusinessRevenue
 */
export interface DomainValueObjectsBusinessRevenue {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsBusinessRevenue
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsBusinessRevenue
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DomainValueObjectsCompanyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type DomainValueObjectsCompanyType = typeof DomainValueObjectsCompanyType[keyof typeof DomainValueObjectsCompanyType];


/**
 * 
 * @export
 * @interface DomainValueObjectsInsuranceDetailType
 */
export interface DomainValueObjectsInsuranceDetailType {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsInsuranceDetailType
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsInsuranceDetailType
     */
    'id'?: number;
    /**
     * 
     * @type {DomainValueObjectsInsuranceDetailType}
     * @memberof DomainValueObjectsInsuranceDetailType
     */
    'parentType'?: DomainValueObjectsInsuranceDetailType;
    /**
     * 
     * @type {boolean}
     * @memberof DomainValueObjectsInsuranceDetailType
     */
    'isOptional'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DomainValueObjectsInsuranceDetailType
     */
    'isSummable'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsInsuranceDetailType
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsInsuranceExpectedNetRate
 */
export interface DomainValueObjectsInsuranceExpectedNetRate {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsInsuranceExpectedNetRate
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsInsuranceExpectedNetRate
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsInsuranceExpectedNetRate
     */
    'expectedNetRate'?: number;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsInsurancePaymentInterval
 */
export interface DomainValueObjectsInsurancePaymentInterval {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsInsurancePaymentInterval
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsInsurancePaymentInterval
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsInsurancePaymentInterval
     */
    'ratesPerYear'?: number;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsInsuranceType
 */
export interface DomainValueObjectsInsuranceType {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsInsuranceType
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsInsuranceType
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsLeadChannel
 */
export interface DomainValueObjectsLeadChannel {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsLeadChannel
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsLeadChannel
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsMaritalStatus
 */
export interface DomainValueObjectsMaritalStatus {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsMaritalStatus
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsMaritalStatus
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsMeetingOutcome
 */
export interface DomainValueObjectsMeetingOutcome {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsMeetingOutcome
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsMeetingOutcome
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsProspectOmsenState
 */
export interface DomainValueObjectsProspectOmsenState {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsProspectOmsenState
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsProspectOmsenState
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsProspectState
 */
export interface DomainValueObjectsProspectState {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsProspectState
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsProspectState
     */
    'id'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DomainValueObjectsProspectState
     */
    'dataCollectionEnabled'?: boolean;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsSalesProduct
 */
export interface DomainValueObjectsSalesProduct {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsSalesProduct
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsSalesProduct
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface DomainValueObjectsSalesState
 */
export interface DomainValueObjectsSalesState {
    /**
     * 
     * @type {string}
     * @memberof DomainValueObjectsSalesState
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DomainValueObjectsSalesState
     */
    'id'?: number;
}

/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsProspectIdGetsalesdataGet: async (prospectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiAnalyticsProspectIdGetsalesdataGet', 'prospectId', prospectId)
            const localVarPath = `/api/analytics/{prospectId}/getsalesdata`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand} [applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsProspectIdPushsalesdataPost: async (prospectId: string, applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand?: ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiAnalyticsProspectIdPushsalesdataPost', 'prospectId', prospectId)
            const localVarPath = `/api/analytics/{prospectId}/pushsalesdata`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsReportsClientsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/reports/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsReportsMeetingsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/reports/meetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsProspectIdGetsalesdataGet(prospectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAnalyticsActionsCommonAnalyticsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsProspectIdGetsalesdataGet(prospectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand} [applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsProspectIdPushsalesdataPost(prospectId: string, applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand?: ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAnalyticsActionsCommonAnalyticsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsProspectIdPushsalesdataPost(prospectId, applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsReportsClientsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsReportsClientsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAnalyticsReportsMeetingsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAnalyticsReportsMeetingsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsProspectIdGetsalesdataGet(prospectId: string, options?: any): AxiosPromise<ApplicationAnalyticsActionsCommonAnalyticsDto> {
            return localVarFp.apiAnalyticsProspectIdGetsalesdataGet(prospectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand} [applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsProspectIdPushsalesdataPost(prospectId: string, applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand?: ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand, options?: any): AxiosPromise<ApplicationAnalyticsActionsCommonAnalyticsDto> {
            return localVarFp.apiAnalyticsProspectIdPushsalesdataPost(prospectId, applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsReportsClientsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiAnalyticsReportsClientsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAnalyticsReportsMeetingsGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiAnalyticsReportsMeetingsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - interface
 * @export
 * @interface AnalyticsApi
 */
export interface AnalyticsApiInterface {
    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    apiAnalyticsProspectIdGetsalesdataGet(prospectId: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationAnalyticsActionsCommonAnalyticsDto>;

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand} [applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    apiAnalyticsProspectIdPushsalesdataPost(prospectId: string, applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand?: ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationAnalyticsActionsCommonAnalyticsDto>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    apiAnalyticsReportsClientsGet(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApiInterface
     */
    apiAnalyticsReportsMeetingsGet(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI implements AnalyticsApiInterface {
    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticsProspectIdGetsalesdataGet(prospectId: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticsProspectIdGetsalesdataGet(prospectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand} [applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticsProspectIdPushsalesdataPost(prospectId: string, applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand?: ApplicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticsProspectIdPushsalesdataPost(prospectId, applicationAnalyticsActionsPushAnalyticsDataPushAnalyticsDataCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticsReportsClientsGet(options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticsReportsClientsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public apiAnalyticsReportsMeetingsGet(options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).apiAnalyticsReportsMeetingsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApplicationAuthenticationActionsAuthenticateCommand} [applicationAuthenticationActionsAuthenticateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationPost: async (applicationAuthenticationActionsAuthenticateCommand?: ApplicationAuthenticationActionsAuthenticateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authentication`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationAuthenticationActionsAuthenticateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationAuthenticationActionsRefreshTokenCommand} [applicationAuthenticationActionsRefreshTokenCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationRefreshPost: async (applicationAuthenticationActionsRefreshTokenCommand?: ApplicationAuthenticationActionsRefreshTokenCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authentication/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationAuthenticationActionsRefreshTokenCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApplicationAuthenticationActionsAuthenticateCommand} [applicationAuthenticationActionsAuthenticateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationPost(applicationAuthenticationActionsAuthenticateCommand?: ApplicationAuthenticationActionsAuthenticateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAuthenticationActionsAuthenticateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationPost(applicationAuthenticationActionsAuthenticateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationAuthenticationActionsRefreshTokenCommand} [applicationAuthenticationActionsRefreshTokenCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthenticationRefreshPost(applicationAuthenticationActionsRefreshTokenCommand?: ApplicationAuthenticationActionsRefreshTokenCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationAuthenticationActionsAuthenticateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthenticationRefreshPost(applicationAuthenticationActionsRefreshTokenCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {ApplicationAuthenticationActionsAuthenticateCommand} [applicationAuthenticationActionsAuthenticateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationPost(applicationAuthenticationActionsAuthenticateCommand?: ApplicationAuthenticationActionsAuthenticateCommand, options?: any): AxiosPromise<ApplicationAuthenticationActionsAuthenticateResponse> {
            return localVarFp.apiAuthenticationPost(applicationAuthenticationActionsAuthenticateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationAuthenticationActionsRefreshTokenCommand} [applicationAuthenticationActionsRefreshTokenCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthenticationRefreshPost(applicationAuthenticationActionsRefreshTokenCommand?: ApplicationAuthenticationActionsRefreshTokenCommand, options?: any): AxiosPromise<ApplicationAuthenticationActionsAuthenticateResponse> {
            return localVarFp.apiAuthenticationRefreshPost(applicationAuthenticationActionsRefreshTokenCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - interface
 * @export
 * @interface AuthenticationApi
 */
export interface AuthenticationApiInterface {
    /**
     * 
     * @param {ApplicationAuthenticationActionsAuthenticateCommand} [applicationAuthenticationActionsAuthenticateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiInterface
     */
    apiAuthenticationPost(applicationAuthenticationActionsAuthenticateCommand?: ApplicationAuthenticationActionsAuthenticateCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationAuthenticationActionsAuthenticateResponse>;

    /**
     * 
     * @param {ApplicationAuthenticationActionsRefreshTokenCommand} [applicationAuthenticationActionsRefreshTokenCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApiInterface
     */
    apiAuthenticationRefreshPost(applicationAuthenticationActionsRefreshTokenCommand?: ApplicationAuthenticationActionsRefreshTokenCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationAuthenticationActionsAuthenticateResponse>;

}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI implements AuthenticationApiInterface {
    /**
     * 
     * @param {ApplicationAuthenticationActionsAuthenticateCommand} [applicationAuthenticationActionsAuthenticateCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationPost(applicationAuthenticationActionsAuthenticateCommand?: ApplicationAuthenticationActionsAuthenticateCommand, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationPost(applicationAuthenticationActionsAuthenticateCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationAuthenticationActionsRefreshTokenCommand} [applicationAuthenticationActionsRefreshTokenCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public apiAuthenticationRefreshPost(applicationAuthenticationActionsRefreshTokenCommand?: ApplicationAuthenticationActionsRefreshTokenCommand, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).apiAuthenticationRefreshPost(applicationAuthenticationActionsRefreshTokenCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OmsenApi - axios parameter creator
 * @export
 */
export const OmsenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenCountriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/omsen/countries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenCustomercategoriesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/omsen/customercategories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenIndustrialclassificationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/omsen/industrialclassifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenInstallmentsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/omsen/installments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenLanguagesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/omsen/languages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenMaintenancewindowGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/omsen/maintenancewindow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [tariffYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenOccupationsGet: async (tariffYear?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/omsen/occupations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (tariffYear !== undefined) {
                localVarQueryParameter['tariffYear'] = tariffYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenProductsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/omsen/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenTaxdomicilesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/omsen/taxdomiciles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OmsenApi - functional programming interface
 * @export
 */
export const OmsenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OmsenApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOmsenCountriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationOmsenDtosCountryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOmsenCountriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOmsenCustomercategoriesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationOmsenDtosCustomerCategoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOmsenCustomercategoriesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOmsenIndustrialclassificationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationOmsenDtosIndustrialClassificationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOmsenIndustrialclassificationsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOmsenInstallmentsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationOmsenDtosInstallmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOmsenInstallmentsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOmsenLanguagesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationOmsenDtosLanguageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOmsenLanguagesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOmsenMaintenancewindowGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationOmsenDtosMaintenanceWindowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOmsenMaintenancewindowGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [tariffYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOmsenOccupationsGet(tariffYear?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationOmsenDtosOccupationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOmsenOccupationsGet(tariffYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOmsenProductsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientsOmsenProduct>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOmsenProductsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOmsenTaxdomicilesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplicationOmsenDtosTaxDomicileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOmsenTaxdomicilesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OmsenApi - factory interface
 * @export
 */
export const OmsenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OmsenApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenCountriesGet(options?: any): AxiosPromise<Array<ApplicationOmsenDtosCountryDto>> {
            return localVarFp.apiOmsenCountriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenCustomercategoriesGet(options?: any): AxiosPromise<Array<ApplicationOmsenDtosCustomerCategoryDto>> {
            return localVarFp.apiOmsenCustomercategoriesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenIndustrialclassificationsGet(options?: any): AxiosPromise<Array<ApplicationOmsenDtosIndustrialClassificationDto>> {
            return localVarFp.apiOmsenIndustrialclassificationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenInstallmentsGet(options?: any): AxiosPromise<Array<ApplicationOmsenDtosInstallmentDto>> {
            return localVarFp.apiOmsenInstallmentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenLanguagesGet(options?: any): AxiosPromise<Array<ApplicationOmsenDtosLanguageDto>> {
            return localVarFp.apiOmsenLanguagesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenMaintenancewindowGet(options?: any): AxiosPromise<ApplicationOmsenDtosMaintenanceWindowDto> {
            return localVarFp.apiOmsenMaintenancewindowGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [tariffYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenOccupationsGet(tariffYear?: number, options?: any): AxiosPromise<Array<ApplicationOmsenDtosOccupationDto>> {
            return localVarFp.apiOmsenOccupationsGet(tariffYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenProductsGet(options?: any): AxiosPromise<Array<ClientsOmsenProduct>> {
            return localVarFp.apiOmsenProductsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOmsenTaxdomicilesGet(options?: any): AxiosPromise<Array<ApplicationOmsenDtosTaxDomicileDto>> {
            return localVarFp.apiOmsenTaxdomicilesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OmsenApi - interface
 * @export
 * @interface OmsenApi
 */
export interface OmsenApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApiInterface
     */
    apiOmsenCountriesGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationOmsenDtosCountryDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApiInterface
     */
    apiOmsenCustomercategoriesGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationOmsenDtosCustomerCategoryDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApiInterface
     */
    apiOmsenIndustrialclassificationsGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationOmsenDtosIndustrialClassificationDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApiInterface
     */
    apiOmsenInstallmentsGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationOmsenDtosInstallmentDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApiInterface
     */
    apiOmsenLanguagesGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationOmsenDtosLanguageDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApiInterface
     */
    apiOmsenMaintenancewindowGet(options?: AxiosRequestConfig): AxiosPromise<ApplicationOmsenDtosMaintenanceWindowDto>;

    /**
     * 
     * @param {number} [tariffYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApiInterface
     */
    apiOmsenOccupationsGet(tariffYear?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationOmsenDtosOccupationDto>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApiInterface
     */
    apiOmsenProductsGet(options?: AxiosRequestConfig): AxiosPromise<Array<ClientsOmsenProduct>>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApiInterface
     */
    apiOmsenTaxdomicilesGet(options?: AxiosRequestConfig): AxiosPromise<Array<ApplicationOmsenDtosTaxDomicileDto>>;

}

/**
 * OmsenApi - object-oriented interface
 * @export
 * @class OmsenApi
 * @extends {BaseAPI}
 */
export class OmsenApi extends BaseAPI implements OmsenApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApi
     */
    public apiOmsenCountriesGet(options?: AxiosRequestConfig) {
        return OmsenApiFp(this.configuration).apiOmsenCountriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApi
     */
    public apiOmsenCustomercategoriesGet(options?: AxiosRequestConfig) {
        return OmsenApiFp(this.configuration).apiOmsenCustomercategoriesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApi
     */
    public apiOmsenIndustrialclassificationsGet(options?: AxiosRequestConfig) {
        return OmsenApiFp(this.configuration).apiOmsenIndustrialclassificationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApi
     */
    public apiOmsenInstallmentsGet(options?: AxiosRequestConfig) {
        return OmsenApiFp(this.configuration).apiOmsenInstallmentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApi
     */
    public apiOmsenLanguagesGet(options?: AxiosRequestConfig) {
        return OmsenApiFp(this.configuration).apiOmsenLanguagesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApi
     */
    public apiOmsenMaintenancewindowGet(options?: AxiosRequestConfig) {
        return OmsenApiFp(this.configuration).apiOmsenMaintenancewindowGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [tariffYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApi
     */
    public apiOmsenOccupationsGet(tariffYear?: number, options?: AxiosRequestConfig) {
        return OmsenApiFp(this.configuration).apiOmsenOccupationsGet(tariffYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApi
     */
    public apiOmsenProductsGet(options?: AxiosRequestConfig) {
        return OmsenApiFp(this.configuration).apiOmsenProductsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmsenApi
     */
    public apiOmsenTaxdomicilesGet(options?: AxiosRequestConfig) {
        return OmsenApiFp(this.configuration).apiOmsenTaxdomicilesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProspectHistoriesApi - axios parameter creator
 * @export
 */
export const ProspectHistoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspecthistoriesCommentIdDelete: async (commentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('apiProspecthistoriesCommentIdDelete', 'commentId', commentId)
            const localVarPath = `/api/prospecthistories/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} commentId 
         * @param {ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand} [applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspecthistoriesCommentIdPut: async (commentId: string, applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand?: ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('apiProspecthistoriesCommentIdPut', 'commentId', commentId)
            const localVarPath = `/api/prospecthistories/{commentId}`
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspecthistoriesProspectIdGet: async (prospectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiProspecthistoriesProspectIdGet', 'prospectId', prospectId)
            const localVarPath = `/api/prospecthistories/{prospectId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand} [applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspecthistoriesProspectIdPost: async (prospectId: string, applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand?: ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiProspecthistoriesProspectIdPost', 'prospectId', prospectId)
            const localVarPath = `/api/prospecthistories/{prospectId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProspectHistoriesApi - functional programming interface
 * @export
 */
export const ProspectHistoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProspectHistoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspecthistoriesCommentIdDelete(commentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspecthistoriesCommentIdDelete(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} commentId 
         * @param {ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand} [applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspecthistoriesCommentIdPut(commentId: string, applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand?: ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspecthistoriesCommentIdPut(commentId, applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspecthistoriesProspectIdGet(prospectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspecthistoriesProspectIdGet(prospectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand} [applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspecthistoriesProspectIdPost(prospectId: string, applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand?: ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspecthistoriesProspectIdPost(prospectId, applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProspectHistoriesApi - factory interface
 * @export
 */
export const ProspectHistoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProspectHistoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspecthistoriesCommentIdDelete(commentId: string, options?: any): AxiosPromise<ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse> {
            return localVarFp.apiProspecthistoriesCommentIdDelete(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} commentId 
         * @param {ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand} [applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspecthistoriesCommentIdPut(commentId: string, applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand?: ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand, options?: any): AxiosPromise<ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse> {
            return localVarFp.apiProspecthistoriesCommentIdPut(commentId, applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspecthistoriesProspectIdGet(prospectId: string, options?: any): AxiosPromise<ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse> {
            return localVarFp.apiProspecthistoriesProspectIdGet(prospectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand} [applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspecthistoriesProspectIdPost(prospectId: string, applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand?: ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand, options?: any): AxiosPromise<ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse> {
            return localVarFp.apiProspecthistoriesProspectIdPost(prospectId, applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProspectHistoriesApi - interface
 * @export
 * @interface ProspectHistoriesApi
 */
export interface ProspectHistoriesApiInterface {
    /**
     * 
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectHistoriesApiInterface
     */
    apiProspecthistoriesCommentIdDelete(commentId: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectHistoryActionsDeleteProspectCommentDeleteProspectCommentResponse>;

    /**
     * 
     * @param {string} commentId 
     * @param {ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand} [applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectHistoriesApiInterface
     */
    apiProspecthistoriesCommentIdPut(commentId: string, applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand?: ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentResponse>;

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectHistoriesApiInterface
     */
    apiProspecthistoriesProspectIdGet(prospectId: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectHistoryActionsGetProspectHistoryByIdResponse>;

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand} [applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectHistoriesApiInterface
     */
    apiProspecthistoriesProspectIdPost(prospectId: string, applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand?: ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryResponse>;

}

/**
 * ProspectHistoriesApi - object-oriented interface
 * @export
 * @class ProspectHistoriesApi
 * @extends {BaseAPI}
 */
export class ProspectHistoriesApi extends BaseAPI implements ProspectHistoriesApiInterface {
    /**
     * 
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectHistoriesApi
     */
    public apiProspecthistoriesCommentIdDelete(commentId: string, options?: AxiosRequestConfig) {
        return ProspectHistoriesApiFp(this.configuration).apiProspecthistoriesCommentIdDelete(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} commentId 
     * @param {ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand} [applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectHistoriesApi
     */
    public apiProspecthistoriesCommentIdPut(commentId: string, applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand?: ApplicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand, options?: AxiosRequestConfig) {
        return ProspectHistoriesApiFp(this.configuration).apiProspecthistoriesCommentIdPut(commentId, applicationProspectHistoryActionsUpdateProspectCommentUpdateProspectCommentCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectHistoriesApi
     */
    public apiProspecthistoriesProspectIdGet(prospectId: string, options?: AxiosRequestConfig) {
        return ProspectHistoriesApiFp(this.configuration).apiProspecthistoriesProspectIdGet(prospectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand} [applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectHistoriesApi
     */
    public apiProspecthistoriesProspectIdPost(prospectId: string, applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand?: ApplicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand, options?: AxiosRequestConfig) {
        return ProspectHistoriesApiFp(this.configuration).apiProspecthistoriesProspectIdPost(prospectId, applicationProspectActionsCreateProspectHistoryCreateProspectHistoryCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProspectsApi - axios parameter creator
 * @export
 */
export const ProspectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [sellerId] 
         * @param {boolean} [broker] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [sortOrder] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsGet: async (sellerId?: string, broker?: boolean, page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prospects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sellerId !== undefined) {
                localVarQueryParameter['SellerId'] = sellerId;
            }

            if (broker !== undefined) {
                localVarQueryParameter['Broker'] = broker;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['SortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationProspectActionsCreateProspectCreateProspectCommand} [applicationProspectActionsCreateProspectCreateProspectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsPost: async (applicationProspectActionsCreateProspectCreateProspectCommand?: ApplicationProspectActionsCreateProspectCreateProspectCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/prospects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationProspectActionsCreateProspectCreateProspectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdDelete: async (prospectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiProspectsProspectIdDelete', 'prospectId', prospectId)
            const localVarPath = `/api/prospects/{prospectId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdEmailGet: async (prospectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiProspectsProspectIdEmailGet', 'prospectId', prospectId)
            const localVarPath = `/api/prospects/{prospectId}/email`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdEmailPost: async (prospectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiProspectsProspectIdEmailPost', 'prospectId', prospectId)
            const localVarPath = `/api/prospects/{prospectId}/email`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdGet: async (prospectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiProspectsProspectIdGet', 'prospectId', prospectId)
            const localVarPath = `/api/prospects/{prospectId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsUpdateProspectUpdateProspectCommand} [applicationProspectActionsUpdateProspectUpdateProspectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdPut: async (prospectId: string, applicationProspectActionsUpdateProspectUpdateProspectCommand?: ApplicationProspectActionsUpdateProspectUpdateProspectCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiProspectsProspectIdPut', 'prospectId', prospectId)
            const localVarPath = `/api/prospects/{prospectId}`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationProspectActionsUpdateProspectUpdateProspectCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand} [applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdQuestioneerdataPut: async (prospectId: string, applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand?: ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiProspectsProspectIdQuestioneerdataPut', 'prospectId', prospectId)
            const localVarPath = `/api/prospects/{prospectId}/questioneerdata`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsPushProspectViewPushProspectViewCommand} [applicationProspectActionsPushProspectViewPushProspectViewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdViewPut: async (prospectId: string, applicationProspectActionsPushProspectViewPushProspectViewCommand?: ApplicationProspectActionsPushProspectViewPushProspectViewCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prospectId' is not null or undefined
            assertParamExists('apiProspectsProspectIdViewPut', 'prospectId', prospectId)
            const localVarPath = `/api/prospects/{prospectId}/view`
                .replace(`{${"prospectId"}}`, encodeURIComponent(String(prospectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationProspectActionsPushProspectViewPushProspectViewCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isProspectExistingWithCompanyId: async (companyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/existing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['CompanyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProspectsApi - functional programming interface
 * @export
 */
export const ProspectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProspectsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [sellerId] 
         * @param {boolean} [broker] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [sortOrder] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspectsGet(sellerId?: string, broker?: boolean, page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsGetPaginatedProspectsPaginatedResultGetPaginatedProspectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspectsGet(sellerId, broker, page, pageSize, filter, sortOrder, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationProspectActionsCreateProspectCreateProspectCommand} [applicationProspectActionsCreateProspectCreateProspectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspectsPost(applicationProspectActionsCreateProspectCreateProspectCommand?: ApplicationProspectActionsCreateProspectCreateProspectCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsCreateProspectCreateProspectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspectsPost(applicationProspectActionsCreateProspectCreateProspectCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspectsProspectIdDelete(prospectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsDeleteProspectDeleteProspectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspectsProspectIdDelete(prospectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspectsProspectIdEmailGet(prospectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsGetProspectEmailGetProspectEmailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspectsProspectIdEmailGet(prospectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspectsProspectIdEmailPost(prospectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsSendProspectEmailSendProspectEmailCommand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspectsProspectIdEmailPost(prospectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspectsProspectIdGet(prospectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspectsProspectIdGet(prospectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsUpdateProspectUpdateProspectCommand} [applicationProspectActionsUpdateProspectUpdateProspectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspectsProspectIdPut(prospectId: string, applicationProspectActionsUpdateProspectUpdateProspectCommand?: ApplicationProspectActionsUpdateProspectUpdateProspectCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsUpdateProspectUpdateProspectResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspectsProspectIdPut(prospectId, applicationProspectActionsUpdateProspectUpdateProspectCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand} [applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspectsProspectIdQuestioneerdataPut(prospectId: string, applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand?: ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspectsProspectIdQuestioneerdataPut(prospectId, applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsPushProspectViewPushProspectViewCommand} [applicationProspectActionsPushProspectViewPushProspectViewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiProspectsProspectIdViewPut(prospectId: string, applicationProspectActionsPushProspectViewPushProspectViewCommand?: ApplicationProspectActionsPushProspectViewPushProspectViewCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiProspectsProspectIdViewPut(prospectId, applicationProspectActionsPushProspectViewPushProspectViewCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isProspectExistingWithCompanyId(companyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isProspectExistingWithCompanyId(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProspectsApi - factory interface
 * @export
 */
export const ProspectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProspectsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [sellerId] 
         * @param {boolean} [broker] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [sortOrder] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsGet(sellerId?: string, broker?: boolean, page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options?: any): AxiosPromise<ApplicationProspectActionsGetPaginatedProspectsPaginatedResultGetPaginatedProspectsResponse> {
            return localVarFp.apiProspectsGet(sellerId, broker, page, pageSize, filter, sortOrder, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationProspectActionsCreateProspectCreateProspectCommand} [applicationProspectActionsCreateProspectCreateProspectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsPost(applicationProspectActionsCreateProspectCreateProspectCommand?: ApplicationProspectActionsCreateProspectCreateProspectCommand, options?: any): AxiosPromise<ApplicationProspectActionsCreateProspectCreateProspectResponse> {
            return localVarFp.apiProspectsPost(applicationProspectActionsCreateProspectCreateProspectCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdDelete(prospectId: string, options?: any): AxiosPromise<ApplicationProspectActionsDeleteProspectDeleteProspectResponse> {
            return localVarFp.apiProspectsProspectIdDelete(prospectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdEmailGet(prospectId: string, options?: any): AxiosPromise<ApplicationProspectActionsGetProspectEmailGetProspectEmailResponse> {
            return localVarFp.apiProspectsProspectIdEmailGet(prospectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdEmailPost(prospectId: string, options?: any): AxiosPromise<ApplicationProspectActionsSendProspectEmailSendProspectEmailCommand> {
            return localVarFp.apiProspectsProspectIdEmailPost(prospectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdGet(prospectId: string, options?: any): AxiosPromise<ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse> {
            return localVarFp.apiProspectsProspectIdGet(prospectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsUpdateProspectUpdateProspectCommand} [applicationProspectActionsUpdateProspectUpdateProspectCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdPut(prospectId: string, applicationProspectActionsUpdateProspectUpdateProspectCommand?: ApplicationProspectActionsUpdateProspectUpdateProspectCommand, options?: any): AxiosPromise<ApplicationProspectActionsUpdateProspectUpdateProspectResponse> {
            return localVarFp.apiProspectsProspectIdPut(prospectId, applicationProspectActionsUpdateProspectUpdateProspectCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand} [applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdQuestioneerdataPut(prospectId: string, applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand?: ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand, options?: any): AxiosPromise<ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataResponse> {
            return localVarFp.apiProspectsProspectIdQuestioneerdataPut(prospectId, applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prospectId 
         * @param {ApplicationProspectActionsPushProspectViewPushProspectViewCommand} [applicationProspectActionsPushProspectViewPushProspectViewCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiProspectsProspectIdViewPut(prospectId: string, applicationProspectActionsPushProspectViewPushProspectViewCommand?: ApplicationProspectActionsPushProspectViewPushProspectViewCommand, options?: any): AxiosPromise<ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse> {
            return localVarFp.apiProspectsProspectIdViewPut(prospectId, applicationProspectActionsPushProspectViewPushProspectViewCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isProspectExistingWithCompanyId(companyId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isProspectExistingWithCompanyId(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProspectsApi - interface
 * @export
 * @interface ProspectsApi
 */
export interface ProspectsApiInterface {
    /**
     * 
     * @param {string} [sellerId] 
     * @param {boolean} [broker] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [filter] 
     * @param {string} [sortOrder] 
     * @param {string} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    apiProspectsGet(sellerId?: string, broker?: boolean, page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsGetPaginatedProspectsPaginatedResultGetPaginatedProspectsResponse>;

    /**
     * 
     * @param {ApplicationProspectActionsCreateProspectCreateProspectCommand} [applicationProspectActionsCreateProspectCreateProspectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    apiProspectsPost(applicationProspectActionsCreateProspectCreateProspectCommand?: ApplicationProspectActionsCreateProspectCreateProspectCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsCreateProspectCreateProspectResponse>;

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    apiProspectsProspectIdDelete(prospectId: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsDeleteProspectDeleteProspectResponse>;

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    apiProspectsProspectIdEmailGet(prospectId: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsGetProspectEmailGetProspectEmailResponse>;

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    apiProspectsProspectIdEmailPost(prospectId: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsSendProspectEmailSendProspectEmailCommand>;

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    apiProspectsProspectIdGet(prospectId: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse>;

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationProspectActionsUpdateProspectUpdateProspectCommand} [applicationProspectActionsUpdateProspectUpdateProspectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    apiProspectsProspectIdPut(prospectId: string, applicationProspectActionsUpdateProspectUpdateProspectCommand?: ApplicationProspectActionsUpdateProspectUpdateProspectCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsUpdateProspectUpdateProspectResponse>;

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand} [applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    apiProspectsProspectIdQuestioneerdataPut(prospectId: string, applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand?: ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataResponse>;

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationProspectActionsPushProspectViewPushProspectViewCommand} [applicationProspectActionsPushProspectViewPushProspectViewCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    apiProspectsProspectIdViewPut(prospectId: string, applicationProspectActionsPushProspectViewPushProspectViewCommand?: ApplicationProspectActionsPushProspectViewPushProspectViewCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsGetProspectByIdGetProspectByIdResponse>;

    /**
     * 
     * @param {string} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApiInterface
     */
    isProspectExistingWithCompanyId(companyId?: string, options?: AxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * ProspectsApi - object-oriented interface
 * @export
 * @class ProspectsApi
 * @extends {BaseAPI}
 */
export class ProspectsApi extends BaseAPI implements ProspectsApiInterface {
    /**
     * 
     * @param {string} [sellerId] 
     * @param {boolean} [broker] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [filter] 
     * @param {string} [sortOrder] 
     * @param {string} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public apiProspectsGet(sellerId?: string, broker?: boolean, page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).apiProspectsGet(sellerId, broker, page, pageSize, filter, sortOrder, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationProspectActionsCreateProspectCreateProspectCommand} [applicationProspectActionsCreateProspectCreateProspectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public apiProspectsPost(applicationProspectActionsCreateProspectCreateProspectCommand?: ApplicationProspectActionsCreateProspectCreateProspectCommand, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).apiProspectsPost(applicationProspectActionsCreateProspectCreateProspectCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public apiProspectsProspectIdDelete(prospectId: string, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).apiProspectsProspectIdDelete(prospectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public apiProspectsProspectIdEmailGet(prospectId: string, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).apiProspectsProspectIdEmailGet(prospectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public apiProspectsProspectIdEmailPost(prospectId: string, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).apiProspectsProspectIdEmailPost(prospectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public apiProspectsProspectIdGet(prospectId: string, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).apiProspectsProspectIdGet(prospectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationProspectActionsUpdateProspectUpdateProspectCommand} [applicationProspectActionsUpdateProspectUpdateProspectCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public apiProspectsProspectIdPut(prospectId: string, applicationProspectActionsUpdateProspectUpdateProspectCommand?: ApplicationProspectActionsUpdateProspectUpdateProspectCommand, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).apiProspectsProspectIdPut(prospectId, applicationProspectActionsUpdateProspectUpdateProspectCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand} [applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public apiProspectsProspectIdQuestioneerdataPut(prospectId: string, applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand?: ApplicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).apiProspectsProspectIdQuestioneerdataPut(prospectId, applicationProspectActionsUpdateProspectQuestioneerDataUpdateProspectQuestioneerDataCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prospectId 
     * @param {ApplicationProspectActionsPushProspectViewPushProspectViewCommand} [applicationProspectActionsPushProspectViewPushProspectViewCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public apiProspectsProspectIdViewPut(prospectId: string, applicationProspectActionsPushProspectViewPushProspectViewCommand?: ApplicationProspectActionsPushProspectViewPushProspectViewCommand, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).apiProspectsProspectIdViewPut(prospectId, applicationProspectActionsPushProspectViewPushProspectViewCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProspectsApi
     */
    public isProspectExistingWithCompanyId(companyId?: string, options?: AxiosRequestConfig) {
        return ProspectsApiFp(this.configuration).isProspectExistingWithCompanyId(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReferencesApi - axios parameter creator
 * @export
 */
export const ReferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReferencesReferenceGet: async (reference: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('apiReferencesReferenceGet', 'reference', reference)
            const localVarPath = `/api/references/{reference}`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferencesApi - functional programming interface
 * @export
 */
export const ReferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReferencesReferenceGet(reference: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReferencesReferenceGet(reference, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReferencesApi - factory interface
 * @export
 */
export const ReferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReferencesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReferencesReferenceGet(reference: string, options?: any): AxiosPromise<ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse> {
            return localVarFp.apiReferencesReferenceGet(reference, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReferencesApi - interface
 * @export
 * @interface ReferencesApi
 */
export interface ReferencesApiInterface {
    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApiInterface
     */
    apiReferencesReferenceGet(reference: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationProspectActionsGetProspectByReferenceGetProspectByReferenceResponse>;

}

/**
 * ReferencesApi - object-oriented interface
 * @export
 * @class ReferencesApi
 * @extends {BaseAPI}
 */
export class ReferencesApi extends BaseAPI implements ReferencesApiInterface {
    /**
     * 
     * @param {string} reference 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencesApi
     */
    public apiReferencesReferenceGet(reference: string, options?: AxiosRequestConfig) {
        return ReferencesApiFp(this.configuration).apiReferencesReferenceGet(reference, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SellersApi - axios parameter creator
 * @export
 */
export const SellersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSellersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sellers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellersApi - functional programming interface
 * @export
 */
export const SellersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSellersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserActionsGetAllSellersGetAllSellersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSellersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellersApi - factory interface
 * @export
 */
export const SellersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSellersGet(options?: any): AxiosPromise<ApplicationUserActionsGetAllSellersGetAllSellersResponse> {
            return localVarFp.apiSellersGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellersApi - interface
 * @export
 * @interface SellersApi
 */
export interface SellersApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersApiInterface
     */
    apiSellersGet(options?: AxiosRequestConfig): AxiosPromise<ApplicationUserActionsGetAllSellersGetAllSellersResponse>;

}

/**
 * SellersApi - object-oriented interface
 * @export
 * @class SellersApi
 * @extends {BaseAPI}
 */
export class SellersApi extends BaseAPI implements SellersApiInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellersApi
     */
    public apiSellersGet(options?: AxiosRequestConfig) {
        return SellersApiFp(this.configuration).apiSellersGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [sortOrder] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet: async (page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (filter !== undefined) {
                localVarQueryParameter['Filter'] = filter;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['SortOrder'] = sortOrder;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationUserActionsRegisterUserRegisterCommand} [applicationUserActionsRegisterUserRegisterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost: async (applicationUserActionsRegisterUserRegisterCommand?: ApplicationUserActionsRegisterUserRegisterCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActionsRegisterUserRegisterCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {ApplicationUserActionsUpdateUserUpdateUserCommand} [applicationUserActionsUpdateUserUpdateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUserIdPut: async (userId: string, applicationUserActionsUpdateUserUpdateUserCommand?: ApplicationUserActionsUpdateUserUpdateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUsersUserIdPut', 'userId', userId)
            const localVarPath = `/api/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication TokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationUserActionsUpdateUserUpdateUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [sortOrder] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersGet(page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserActionsGetUsersPaginatedResultGetPaginatedUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersGet(page, pageSize, filter, sortOrder, sortBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ApplicationUserActionsRegisterUserRegisterCommand} [applicationUserActionsRegisterUserRegisterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersPost(applicationUserActionsRegisterUserRegisterCommand?: ApplicationUserActionsRegisterUserRegisterCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserActionsRegisterUserRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersPost(applicationUserActionsRegisterUserRegisterCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {ApplicationUserActionsUpdateUserUpdateUserCommand} [applicationUserActionsUpdateUserUpdateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUsersUserIdPut(userId: string, applicationUserActionsUpdateUserUpdateUserCommand?: ApplicationUserActionsUpdateUserUpdateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationUserActionsUpdateUserUpdateUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUsersUserIdPut(userId, applicationUserActionsUpdateUserUpdateUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [filter] 
         * @param {string} [sortOrder] 
         * @param {string} [sortBy] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet(page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options?: any): AxiosPromise<ApplicationUserActionsGetUsersPaginatedResultGetPaginatedUserResponse> {
            return localVarFp.apiUsersGet(page, pageSize, filter, sortOrder, sortBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationUserActionsRegisterUserRegisterCommand} [applicationUserActionsRegisterUserRegisterCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost(applicationUserActionsRegisterUserRegisterCommand?: ApplicationUserActionsRegisterUserRegisterCommand, options?: any): AxiosPromise<ApplicationUserActionsRegisterUserRegisterResponse> {
            return localVarFp.apiUsersPost(applicationUserActionsRegisterUserRegisterCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {ApplicationUserActionsUpdateUserUpdateUserCommand} [applicationUserActionsUpdateUserUpdateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUserIdPut(userId: string, applicationUserActionsUpdateUserUpdateUserCommand?: ApplicationUserActionsUpdateUserUpdateUserCommand, options?: any): AxiosPromise<ApplicationUserActionsUpdateUserUpdateUserResponse> {
            return localVarFp.apiUsersUserIdPut(userId, applicationUserActionsUpdateUserUpdateUserCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [filter] 
     * @param {string} [sortOrder] 
     * @param {string} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersGet(page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options?: AxiosRequestConfig): AxiosPromise<ApplicationUserActionsGetUsersPaginatedResultGetPaginatedUserResponse>;

    /**
     * 
     * @param {ApplicationUserActionsRegisterUserRegisterCommand} [applicationUserActionsRegisterUserRegisterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersPost(applicationUserActionsRegisterUserRegisterCommand?: ApplicationUserActionsRegisterUserRegisterCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationUserActionsRegisterUserRegisterResponse>;

    /**
     * 
     * @param {string} userId 
     * @param {ApplicationUserActionsUpdateUserUpdateUserCommand} [applicationUserActionsUpdateUserUpdateUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    apiUsersUserIdPut(userId: string, applicationUserActionsUpdateUserUpdateUserCommand?: ApplicationUserActionsUpdateUserUpdateUserCommand, options?: AxiosRequestConfig): AxiosPromise<ApplicationUserActionsUpdateUserUpdateUserResponse>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [filter] 
     * @param {string} [sortOrder] 
     * @param {string} [sortBy] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGet(page?: number, pageSize?: number, filter?: string, sortOrder?: string, sortBy?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersGet(page, pageSize, filter, sortOrder, sortBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationUserActionsRegisterUserRegisterCommand} [applicationUserActionsRegisterUserRegisterCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersPost(applicationUserActionsRegisterUserRegisterCommand?: ApplicationUserActionsRegisterUserRegisterCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersPost(applicationUserActionsRegisterUserRegisterCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {ApplicationUserActionsUpdateUserUpdateUserCommand} [applicationUserActionsUpdateUserUpdateUserCommand] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersUserIdPut(userId: string, applicationUserActionsUpdateUserUpdateUserCommand?: ApplicationUserActionsUpdateUserUpdateUserCommand, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).apiUsersUserIdPut(userId, applicationUserActionsUpdateUserUpdateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


