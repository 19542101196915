import { action } from "typesafe-actions";
import {
  AUTHENTICATION_LOGIN_USER,
  AUTHENTICATION_LOGOUT_USER,
  AUTHENTICATION_LOGOUT_USER_SUCCESS,
  AUTHENTICATION_LOGOUT_USER_FAILURE,
  AUTHENTICATION_LOGIN_USER_SUCCESS,
  AUTHENTICATION_LOGIN_USER_FAILURE,
  AUTHENTICATION_LOGIN_USER_FROM_STORAGE,
  AUTHENTICATION_REGISTER_USER,
  AUTHENTICATION_REGISTER_USER_SUCCESS,
  AUTHENTICATION_REGISTER_USER_FAILURE,
  AUTHENTICATION_REFRESH_USER,
} from "./actionTypes";
import { IUser } from "../../contracts/data/IUser";
import { IUserRegistrationData } from "../../contracts/data/IUserRegistrationData";
import { TAppThunkAction, TAppThunkActionResult } from "../types/stateTypes";
import { IErrorResponse } from "../../contracts/IErrorResponse";

export type TLoginUserFromStorageThunkAction = () => TAppThunkAction<IUser | null>;
export type TLoginUserFromStorageAction = () => TAppThunkActionResult<IUser | null>;
export const loginUserFromStorage: TLoginUserFromStorageThunkAction = () => {
  return async (dispatch, getState, services) => {
    dispatch(action(AUTHENTICATION_LOGIN_USER_FROM_STORAGE));

    const user = await services.storageService.getCurrentUser();
    if (user !== null) {
      const validatedUser = await services.authenticationService.validate(user);
      if (validatedUser.data) {
        services.storageService.setCurrentUser(validatedUser.data);
        dispatch(loginUserSuccess(validatedUser.data));
        return validatedUser.data;
      } else {
        services.storageService.clearCurrentUser();
        dispatch(logoutUserSuccess());
      }
    }

    return null;
  };
};

export type TRefreshUserThunkAction = () => TAppThunkAction<IUser | null>;
export type TRefreshUserAction = () => TAppThunkActionResult<IUser | null>;
export const refreshUser: TRefreshUserThunkAction = () => {
  return async (dispatch, getState, services) => {
    dispatch(action(AUTHENTICATION_REFRESH_USER));

    const user = await services.storageService.getCurrentUser();
    if (user !== null) {
      const refreshedUser = await services.authenticationService.refresh(user);
      if (refreshedUser.data) {
        services.storageService.setCurrentUser(refreshedUser.data);
        dispatch(loginUserSuccess(refreshedUser.data));
        return refreshedUser.data;
      } else {
        services.storageService.clearCurrentUser();
        dispatch(logoutUserSuccess());
      }
    }

    return null;
  };
};

export type TLoginUserThunkAction = (email: string, password: string) => TAppThunkAction<IUser | null>;
export type TLoginUserAction = (email: string, password: string) => TAppThunkActionResult<IUser | null>;
export const loginUser: TLoginUserThunkAction = (email, password) => {
  return async (dispatch, getState, services) => {
    dispatch(action(AUTHENTICATION_LOGIN_USER));

    const user = await services.authenticationService.login(email, password);
    if (user.data) {
      services.storageService.setCurrentUser(user.data);
      dispatch(loginUserSuccess(user.data));
      return user.data;
    }

    dispatch(loginUserFailure());
    return null;
  };
};

export const loginUserSuccess = (user: IUser) => action(AUTHENTICATION_LOGIN_USER_SUCCESS, { user });
export const loginUserFailure = () => action(AUTHENTICATION_LOGIN_USER_FAILURE);

export type TLogoutUserThunkAction = () => TAppThunkAction<boolean>;
export type TLogoutUserAction = () => TAppThunkActionResult<boolean>;
export const logoutUser: TLogoutUserThunkAction = () => {
  return async (dispatch, getState, services) => {
    dispatch(action(AUTHENTICATION_LOGOUT_USER));

    const user = services.storageService.getCurrentUser();

    if (user === null) {
      dispatch(logoutUserFailure());
      return false;
    }

    await services.authenticationService.logout(user);

    services.storageService.clearCurrentUser();
    dispatch(logoutUserSuccess());

    return true;
  };
};

export const logoutUserSuccess = () => action(AUTHENTICATION_LOGOUT_USER_SUCCESS);
export const logoutUserFailure = () => action(AUTHENTICATION_LOGOUT_USER_FAILURE);

export type TRegisterUserResult = {
  errors?: IErrorResponse[];
};
export type TRegisterUserThunkAction = (
  registrationData: IUserRegistrationData,
) => TAppThunkAction<TRegisterUserResult>;
export type TRegisterUserAction = (
  registrationData: IUserRegistrationData,
) => TAppThunkActionResult<TRegisterUserResult>;
export const registerUser: TRegisterUserThunkAction = (registrationData) => {
  return async (dispatch, getState, services) => {
    dispatch(action(AUTHENTICATION_REGISTER_USER));

    const result = await services.authenticationService.register(registrationData);

    if (result.data) {
      dispatch(registerUserSuccess());
      return {};
    }

    dispatch(registerUserFailure());
    return {
      errors: result.errors,
    };
  };
};

export const registerUserSuccess = () => action(AUTHENTICATION_REGISTER_USER_SUCCESS);
export const registerUserFailure = () => action(AUTHENTICATION_REGISTER_USER_FAILURE);
