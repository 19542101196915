import React, { useState } from "react";
import { Button, Dropdown, DropdownButton, Spinner } from "react-bootstrap";
import {  withTranslation } from "react-i18next";
import { getValidSalesStates} from "../../utils/appUtils";

const SalesStatePicker = ({ ...props }: any) => {
  const { t } = props;

  const [updating, setUpdating] = useState(false);
  const [resultMessage, setResultMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const handleChange = async (stateId: number) => {
      setUpdating(true);
      const result = await props.updateProspect(props.prospect.id, {salesState: stateId});
      if (result !== null){
        setResultMessage(t("Successfully updated"));
        setMessageType("success");
      } else{
        setResultMessage(t("Unable to update"));
        setMessageType("danger");
      }
      setTimeout(() => setUpdating(false), 500);
      setTimeout(async () => {
        setResultMessage("");
        await props.prospectUpdated();
        }, 1500);

    };

  const renderOptions = () => {
    const currentSalesState = props.prospect.salesState? getValidSalesStates()[props.prospect.salesState.id -1] : "";
    const buttonVariant = props.prospect.salesState? 'primary' : 'outline-primary';
    if(updating) return <Button><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /></Button>;
    else if(resultMessage) return <Button variant={messageType}>{resultMessage}</Button>;
    else{
      return (
        <DropdownButton variant={buttonVariant} id="dropdown-basic-button" title={t(currentSalesState)}>
           {updating? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null}

          {getValidSalesStates().map((ss, i) => {
            return (<Dropdown.Item onClick={() => handleChange(i+1)} id={String(i+1)} key={i} href="">{t(ss)}</Dropdown.Item>);
          })}
        </DropdownButton>
      );
    }
  }
  return <div className={"salesStatePickerWrapper"}>{renderOptions()}</div>;
};

export default withTranslation("translations")(SalesStatePicker);
