import useUserApiClient from "./clients/useUserApiClient";
import { useQuery } from "@tanstack/react-query";

export type TGetPaginatedUsersQuery = {
  page: number;
  pageSize: number;
  filter: string;
  sortOrder: "asc" | "desc";
  sortBy: string;
};

export default function useGetUsers(query: TGetPaginatedUsersQuery) {
  const client = useUserApiClient();

  const getUsers = async () => {
    const response = await client.apiUsersGet(query.page, query.pageSize, query.filter, query.sortOrder, query.sortBy);
    return response.data;
  };

  return useQuery({
    queryKey: ["users", query],
    queryFn: getUsers,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
