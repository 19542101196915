import React, { useState } from "react";
import infoIcon from "../../assets/info-circle-solid.svg";
import { WithTranslation, withTranslation } from "react-i18next";

type TInfoHoverBoxProps = {
  infoText: string;
  totalAmount: number;
} & WithTranslation;

const InfoHoverBox = (props: TInfoHoverBoxProps): JSX.Element => {
  const [active, setActive] = useState(false);
  const { t } = props;
  const onBtnClick = () => {
    setActive(!active);
  };
  const numberWithThousandSeparator = (number: number) =>
    Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return (
    <div className={"row"}>
      <div {...props} onClick={onBtnClick} role="button" tabIndex={-3}>
        {active ? props.infoText : <img width={25} height={25} src={infoIcon} alt={"info-logo"} />}
      </div>
      <div className={"ms-3"}>
        {t("Amount until end of life", { amount: numberWithThousandSeparator(props.totalAmount) })}
      </div>
    </div>
  );
};

export default withTranslation("translations")(InfoHoverBox);
