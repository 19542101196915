import { useEffect } from "react";
import {IPromptMessage} from '../../contracts/data/IPromptMessage';

export type SetPromptMessageFunc = (message: IPromptMessage, enabled: boolean) => void;

interface Props {
  setPromptMessage: SetPromptMessageFunc;
  message: IPromptMessage;
  enabled: boolean;
}

const PromptMessage = (props: Props) => {
  const { message, enabled, setPromptMessage } = props;
  useEffect(() => {
    setPromptMessage(message, enabled);
  }, [message.changes, enabled, setPromptMessage]);

  return null;
};

export default PromptMessage;
