import React, { Component } from "react";
import Routes from "./components/Routes/Routes";
import { connect } from "react-redux";
import { ServiceContainer } from "./services/ServiceContainer";
import { IUser } from "./contracts/data/IUser";
import { Spinner } from "react-bootstrap";
import {
  loginUserFromStorage,
  logoutUserSuccess,
  refreshUser,
  TLoginUserFromStorageAction,
  TRefreshUserAction,
} from "./redux/actions/authentication";
import { TAppRootState } from "./redux/types/stateTypes";

type TAppProps = {
  services: ServiceContainer;
  loginUserFromStorage: TLoginUserFromStorageAction;
  refreshUser: TRefreshUserAction;
  logoutUserSuccess: () => any;
  user: IUser | null;
  isLoggedIn: boolean;
};

type TAppState = {
  isReady: boolean;
};

export class App extends Component<TAppProps, TAppState> {
  constructor(props: TAppProps) {
    super(props);

    this.handleStorageUpdate = this.handleStorageUpdate.bind(this);

    this.state = {
      isReady: false,
    };
  }

  refreshUser = async () => {
    if (this.props.isLoggedIn === true && this.props.user !== null) {
      await this.props.refreshUser();
    }
  };

  handleStorageUpdate(e: StorageEvent) {
    const storageKey = e.key;
    if (storageKey === this.props.services.storageService.getCurrentUserStorageKey()) {
      if (e.oldValue !== null && e.newValue === null) {
        // Log out user in all open tabs
        this.props.logoutUserSuccess();
      }
    }
  }

  async componentDidMount() {
    window.addEventListener("storage", this.handleStorageUpdate);

    await this.props.loginUserFromStorage();

    this.setState({ isReady: true });

    // Refresh user / access token every 5 minutes
    setInterval(this.refreshUser, 5 * 60 * 1000);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.handleStorageUpdate);
  }

  render() {
    const hasSidebar = this.props.isLoggedIn === true;

    return (
      <div className={"App" + (hasSidebar ? " has-sidebar" : "")}>
        {this.state.isReady ? (
          <Routes />
        ) : (
          <Spinner animation="border" role="status" className={"app-loading"} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  user: state.authentication.user,
  isLoggedIn: state.authentication.isLoggedIn,
});

type TMapDispatchToProps = {
  loginUserFromStorage: TLoginUserFromStorageAction;
  refreshUser: TRefreshUserAction;
  logoutUserSuccess: () => any;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  loginUserFromStorage: () => dispatch(loginUserFromStorage()),
  refreshUser: () => dispatch(refreshUser()),
  logoutUserSuccess: () => dispatch(logoutUserSuccess()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
