import React, { useCallback, useState } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import * as paths from "../../Routes/routePaths";
import useGetUsers from "../../../hooks/useGetUsers";
import HeaderButton from "../../shared/HeaderButton";
import { FormControl, InputGroup } from "react-bootstrap";
import CreateEditUserModal from "./UserModal";
import { ApplicationUserActionsGetUsersGetPaginatedUsersResponse } from "../../../api";
import AlertAutoDismissible from "../../shared/AlertAutoDismissible";
import { ReactComponent as ClearIcon } from "../../../assets/times_solid.svg";
import { debounce } from "lodash";
import { IProspectFetch } from "../../../contracts/data/IProspectFetch";
import UsersTable from "./UsersTable";

type Props = {
  isLoggedIn: boolean;
} & WithTranslation;

const initialPaginatedUserQuery: IProspectFetch = {
  sellerId: "",
  page: 1,
  sizePerPage: 10,
  filter: "*",
  sortOrder: "asc",
  sortField: "isInActive",
  totalSize: 0,
};

const Admin: React.FC<Props> = ({ isLoggedIn, t }) => {
  const [queryState, setQueryState] = useState<IProspectFetch>(initialPaginatedUserQuery);
  const [search, setSearch] = useState<string>("*");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [user, setUser] = useState<ApplicationUserActionsGetUsersGetPaginatedUsersResponse>({});

  const debouncedSetQueryState = useCallback(
    debounce((input) => {
      const searchWord = input.length === 0 ? "*" : input;
      setQueryState((state) => ({
        ...state,
        filter: searchWord,
        page: 1,
      }));
    }, 300),
    [],
  );

  const {
    data: paginatedUsers,
    isLoading: loadingPaginatedUsers,
    isError: errorPaginatedUsers,
  } = useGetUsers({
    filter: queryState.filter,
    page: queryState.page,
    pageSize: queryState.sizePerPage,
    sortBy: queryState.sortField,
    sortOrder: queryState.sortOrder,
  });

  if (!isLoggedIn) {
    return <Navigate replace to={paths.login} />;
  }

  const handleSearch = (value: string) => {
    setSearch(value);
    debouncedSetQueryState(value);
  };

  const handleOpenUserModal = (user: ApplicationUserActionsGetUsersGetPaginatedUsersResponse) => {
    setUser(user);
    setOpenModal(true);
  };

  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <h1>{t("Users")}</h1>
        <div className="form-group">
          <label className="form-label">{t("Search")}</label>
          <InputGroup className="mb-3">
            <FormControl
              className="filter-input"
              placeholder={t("Name") + "," + t("Email")}
              value={search !== "*" ? search : ""}
              onChange={(event) => handleSearch(event.target.value)}
            />
            <InputGroup.Text className="clear-find-prospects btn-info border" onClick={() => handleSearch("*")}>
              <ClearIcon />
            </InputGroup.Text>
          </InputGroup>
        </div>
        <HeaderButton text="Create user" onClick={() => handleOpenUserModal({})} />
      </div>
      {errorPaginatedUsers ? (
        <AlertAutoDismissible variant={"danger"}>{t("Error fetching data")}</AlertAutoDismissible>
      ) : (
        <UsersTable
          users={paginatedUsers?.items ?? []}
          loadingUsers={loadingPaginatedUsers}
          usersTotalAmount={paginatedUsers?.totalAmount ?? 0}
          queryState={queryState}
          setQueryState={setQueryState}
          handleOpenUserModal={handleOpenUserModal}
        />
      )}
      <CreateEditUserModal isOpen={openModal} setOpenModal={setOpenModal} user={user} />
    </>
  );
};

export default withTranslation("translations")(Admin);
