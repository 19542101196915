export enum MeetingOutcome {
  SaleCompleted = 1,
  SaleNotCompleted,
  NewMeetingBooked,
}

export interface IMeeting {
  id: string | null;
  date: string | null;
  outcome: MeetingOutcome | null;
  sortOrder: number;
}
