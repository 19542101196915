import React, {useEffect} from "react";
import { useField, useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import useOmsenLanguages from "../../hooks/useOmsenLanguages";
import Select from "react-select";
import {selectCustomStyle} from '../../styles/selectCustomStyle';

const FormikLanguagePicker = ({ ...props }: any) => {
  const { data: languages, isLoading } = useOmsenLanguages();
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);
  const defaultValue = "fi"

  const isInvalid = Boolean(
    !!formikProps.getFieldMeta(field.name).error && formikProps.getFieldMeta(field.name).touched,
  );

  const setDefaultValue = async () => {
    await formikProps.setFieldValue(field.name, defaultValue)
  }

  useEffect(() => {
    if(field.value === "" || field.value === null) {
      setDefaultValue().catch()
    }
  }, [isLoading])

  return (
    <Form.Group className={"FormikLanguagePicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Select
        options={languages}
        getOptionValue={(option) => option.code?.toString() ?? ""}
        getOptionLabel={(option) => option.text ?? ""}
        isLoading={isLoading}
        isMulti={false}
        onChange={(newValue) => formikProps.setFieldValue(field.name, newValue?.code)}
        value={languages ? languages.find((option) => option.code === field.value) : { code: "", text: "" }}
        styles={selectCustomStyle(isInvalid)}
        className={isInvalid ? "is-invalid" : "is-valid"}
        isDisabled={props.disabled}
      />
      <Form.Control.Feedback type="invalid">
        {String(formikProps.getFieldMeta(field.name).error) ?? undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
export default withTranslation("translations")(FormikLanguagePicker);
