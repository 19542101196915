import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import * as paths from "../../Routes/routePaths";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import useIsProspectExistingWithCompanyId from "../../../hooks/useIsProspectExistingWithCompanyId";
import axios from "axios";

type Props = {
  isLoggedIn: boolean;
} & WithTranslation;

const Booker: React.FC<Props> = ({ isLoggedIn, t }) => {
  const [response, setResponse] = useState<boolean | undefined>(undefined);
  const { mutateAsync: isProspectExisting } = useIsProspectExistingWithCompanyId();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const search = event.currentTarget.search.value;
    try {
      const res = await isProspectExisting(search);
      setResponse(res.data);
    } catch (error) {
      axios.isAxiosError(error) && setResponse(error.response?.data);
    }
  };

  if (!isLoggedIn) {
    return <Navigate replace to={paths.login} />;
  }

  return (
    <div className="Booker">
      <h1>{t("Search with business Id")}</h1>
      <form onSubmit={handleSubmit}>
        <input type="search" name="search" />
        <Button type="submit">{t("Search")}</Button>
      </form>
      {response !== undefined && (
        <h4 className={`mt-4 text-white py-2 px-4 ${response ? "bg-success" : "bg-warning"}`}>
          {t(response ? "Company exist" : "Company not exist")}!
        </h4>
      )}
    </div>
  );
};

export default withTranslation("translations")(Booker);
