import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { IProspect } from "../../contracts/data/IProspect";
import { Formik } from "formik";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import AlertAutoDismissible from "../shared/AlertAutoDismissible";
import { validateEmail } from "../../utils/validatorUtils";
import FormikDateTimePicker from "../shared/FormikDateTimePicker";
import * as Yup from "yup";
import { TUpdateProspectAction } from "../../redux/actions/prospect";
import CustomerClipBoardButton from "../shared/CustomerClipBoardButton";
import FormikInput from "../shared/FormikInput";
import { ISeller } from "../../contracts/data/ISeller";
import FormikSellerPicker from "../shared/FormikSellerPicker";
import PromptMessage, { SetPromptMessageFunc } from "../shared/PromptMessage";
import { hasFormikError } from "../../utils/appUtils";

type TProspectEditCustomerFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  meetingDate: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  phoneNumber: string | null;
  socialSecurityNumber: string | null;
  countryOfBirth: string | null;
  citizenship: string | null;
  language: string | null;
  sellerId: string;
};

type TProspectEditCustomerFormProps = {
  isSubmitting: boolean;
  prospect: IProspect;
  sellers: ISeller[];
  updateProspect: TUpdateProspectAction;
  setPromptMessage: SetPromptMessageFunc;
} & WithTranslation;

type TProspectEditCustomerFormState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
  selectSellerDisabled: boolean;
};

class ProspectEditCustomerForm extends Component<TProspectEditCustomerFormProps, TProspectEditCustomerFormState> {
  constructor(props: TProspectEditCustomerFormProps) {
    super(props);

    this.state = {
      submitResultMessage: "",
      submitResultSuccess: true,
      selectSellerDisabled: true,
    };
  }

  render() {
    const { t, prospect } = this.props;

    const initialValues: TProspectEditCustomerFormValues = {
      firstName: prospect.firstName,
      lastName: prospect.lastName,
      socialSecurityNumber: prospect.socialSecurityNumber,
      email: prospect.email,
      streetAddress: prospect.streetAddress ?? prospect.companyAddress,
      zipCode: prospect.zipCode ?? prospect.companyZipCode,
      city: prospect.city ?? prospect.companyCity,
      phoneNumber: prospect.phoneNumber ?? prospect.companyPhone,
      countryOfBirth: prospect.countryOfBirth,
      citizenship: prospect.citizenship,
      language: prospect.language ?? prospect.companyLanguage,
      meetingDate: prospect.meetingDate,
      sellerId: prospect.sellerId,
    };

    const validationSchema = Yup.object().shape({
      firstName: Yup.string().required(t("Required")),
      lastName: Yup.string().required(t("Required")),
      email: Yup.string().test("test-email", t("Invalid email address"), validateEmail),
      meetingDate: Yup.lazy((value) => {
        if (value !== null) {
          return Yup.date().required(t("Required"));
        }
        return Yup.mixed().required(t("Required"));
      }),
    });

    return (
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          this.setState((state) => ({
            ...state,
            submitResultSuccess: true,
            submitResultMessage: "",
          }));

          const result = await this.props.updateProspect(prospect.id, { ...values });

          if (result !== null) {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: true,
              submitResultMessage: t("Successfully updated"),
            }));
          } else {
            this.setState((state) => ({
              ...state,
              submitResultSuccess: false,
              submitResultMessage: t("Unable to update"),
            }));
          }
        }}
      >
        {(formikProps) => (
          <Form autoComplete="off" noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            <h4 className={"text-uppercase"}>{t("Seller information")}</h4>
            <Form.Group className="form-group">
              <Form.Check
                label={t("Assign new seller")}
                onChange={(e: any) => this.setState({...this.state, selectSellerDisabled: !e.target.checked})}></Form.Check>
              <FormikSellerPicker disabled={this.state.selectSellerDisabled} name="sellerId" sellers={this.props.sellers} />
            </Form.Group>
            <h4 className={"text-uppercase"}>{t("Basic information")}</h4>
            <Form.Group className="form-group">
              <Form.Label>{t("Meeting")}</Form.Label>
              <FormikDateTimePicker name={"meetingDate"} />
              <Form.Control.Feedback type="invalid">{formikProps.errors.meetingDate}</Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Form.Group className="form-group" as={Col}>
                <Form.Label>{t("Firstname")}</Form.Label>
                <Form.Control
                  name={"firstName"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.firstName}
                  isInvalid={!!formikProps.errors.firstName && formikProps.touched.firstName}
                  autoComplete="firstName"
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.firstName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="form-group" as={Col}>
                <Form.Label>{t("Lastname")}</Form.Label>
                <Form.Control
                  name={"lastName"}
                  type={"text"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.lastName}
                  isInvalid={!!formikProps.errors.lastName && formikProps.touched.lastName}
                  autoComplete="lastName"
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.lastName}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <FormikInput name={"socialSecurityNumber"} title={t("Social security number")} />
            <Form.Group className="form-group">
              <Form.Label>{t("Email")}</Form.Label>
              <Form.Control
                name={"email"}
                type={"email"}
                placeholder={""}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.email}
                isInvalid={!!formikProps.errors.email && formikProps.touched.email}
                autoComplete="email"
              />
              <Form.Control.Feedback type="invalid">{formikProps.errors.email}</Form.Control.Feedback>
            </Form.Group>
            <FormikInput name={"phoneNumber"} title={t("Phonenumber")} />
            <FormikInput name={"streetAddress"} title={t("StreetAddress")} />
            <Row>
              <FormikInput as={Col} xs={4} name={"zipCode"} title={t("Zipcode")} />
              <FormikInput as={Col} xs={8} name={"city"} title={t("City")} />
            </Row>
            <FormikInput name={"countryOfBirth"} title={t("Country of birth")} />
            <FormikInput name={"citizenship"} title={t("Citizenship")} />
            <FormikInput name={"language"} title={t("Language")} />

            <Form.Group className="form-group mt-3">
              <Button variant="primary" type="submit" className={"w-100"} disabled={this.props.isSubmitting}>
                {this.props.isSubmitting ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                  </>
                ) : null}
                {t("Update")}
              </Button>
              {this.state.submitResultMessage ? (
                <AlertAutoDismissible
                  variant={this.state.submitResultSuccess ? "success" : "danger"}
                  className={"mt-2"}
                >
                  {this.state.submitResultMessage}
                </AlertAutoDismissible>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <CustomerClipBoardButton customer={prospect} />
            </Form.Group>
            <PromptMessage
              setPromptMessage={this.props.setPromptMessage}
              message={{
                place: t("Edit customer"),
                changes: hasFormikError(formikProps.errors) ? null : formikProps.values,
              }}
              enabled={formikProps.dirty}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(ProspectEditCustomerForm);
