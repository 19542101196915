import useOmsenApiClient from "./clients/useOmsenApiClient";
import { useQuery } from "@tanstack/react-query";

export default function useOmsenCustomerCategories() {
  const client = useOmsenApiClient();

  const getOmsenCustomerCategories = async () => {
    const response = await client.apiOmsenCustomercategoriesGet();
    return response.data;
  };

  return useQuery(["omsenCustomerCategories"], getOmsenCustomerCategories, {
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
