import React, { Component } from "react";

type TAppSidebarContentProps = {
  contentType: string;
  isActive: boolean;
  children: JSX.Element;
  id?: string
};

type TAppSidebarContentState = {};

class AppSidebarContent extends Component<TAppSidebarContentProps, TAppSidebarContentState> {
  render() {
    const isActiveClass = this.props.isActive ? " active" : "";
    const contentTypeClass = " AppSidebarContent-" + this.props.contentType + isActiveClass;

    return (
      <div className={"m-1 p-2 AppSidebarContent" + contentTypeClass} id={this.props.id}>
        <div className={"AppSidebarContent-inner"}>{this.props.children}</div>
      </div>
    );
  }
}

export default AppSidebarContent;
