import React, {useEffect} from "react";
import { useField, useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import useOmsenProducts from "../../hooks/useOmsenProducts";

const FromikProductPicker = ({ ...props }: any) => {
  const { data: products, isLoading, isError } = useOmsenProducts();
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const handleChange = async (e: any) => {
    const { value, checked } = e.currentTarget;
    const numberValue = Number(value);

    formikProps.setFieldError(field.name, "");

    if (checked) {
      if (numberValue === 211) {
        await formikProps.setFieldValue(field.name, [...formikProps.values.omsenData.productCodes, numberValue, 219]);
      } else {
        await formikProps.setFieldValue(field.name, [...formikProps.values.omsenData.productCodes, numberValue]);
      }
    } else {
      await formikProps.setFieldValue(
        field.name,
        formikProps.values.omsenData.productCodes.filter((p: number) => p !== numberValue),
      );

      if (numberValue === 212) {
        await formikProps.setFieldValue("omsenData.employerAnnualEarningsDetails", []);
      }

      if (numberValue === 211) {
        await formikProps.setFieldValue("omsenData.employeePayrollDetails", []);
        await formikProps.setFieldValue(
          field.name,
          formikProps.values.omsenData.productCodes.filter((num: number) => num === 212),
        );
      }
    }
  };

  useEffect(() => {
    if(formikProps.values.omsenData.productCodes?.includes(211) && !formikProps.values.omsenData.productCodes?.includes(219)){
      formikProps.setFieldValue(field.name, [...formikProps.values.omsenData.productCodes, 219]);
    }
  }, []);

  return (
    <Form.Group className={"FromikProductPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      {field.value === 0 || field.value === "" || field.value === null || props.emptyOption ? (
        <Form.Check value={""} key={"-"}></Form.Check>
      ) : null}
      {isLoading && (
        <Form.Check value={""} key={"loading"}>
          Loading...
        </Form.Check>
      )}
      {!isLoading && isError && (
        <Form.Check value={""} key={"error"}>
          Error loading languages
        </Form.Check>
      )}
      {!!products &&
        products.map((product) => {
          if (product.code !== null)
            if (
              product.dependsOnProductCode === 0 ||
              formikProps.values.omsenData.productCodes?.includes(product.dependsOnProductCode)
            ) {
              return (
                <Form.Check
                  name={field.name}
                  value={product.code}
                  key={product.code}
                  label={product.text}
                  onChange={(e) => handleChange(e)}
                  checked={formikProps.values.omsenData.productCodes?.includes(product.code)}
                  disabled={props.disabled || (product.code === 219 && formikProps.values.omsenData.productCodes?.includes(219))}
                />
              );
            }
        })}
      <Form.Control.Feedback type="invalid">
        {String(formikProps.errors[field.name]) ?? undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FromikProductPicker);
