import React, { Component } from "react";
import { TAppRootState } from "../../../redux/types/stateTypes";
import { connect } from "react-redux";
import { withTranslation, WithTranslation } from "react-i18next";
import {
  fetchProspectByReference,
  TFetchProspectByReferenceAction,
  TUpdateProspectBasicInformationAction,
  updateProspectBasicInformation,
} from "../../../redux/actions/prospect";
import { IProspect } from "../../../contracts/data/IProspect";
import { Spinner } from "react-bootstrap";
import ProspectEditBasicInformationForm from "../../forms/ProspectEditBasicInformationForm";
import aktia_black_logo from "./../../../assets/aktia_black.png";
import veritas_black_logo from "./../../../assets/veritas_black.png";
import { IProspectBasicInformation } from "../../../contracts/data/IProspectBasicInformation";
import moment from "moment";
import { dateFormat_DDMMYYYY, dateFormat_HHmm, dateTimeFormat_YYYYMMDD_HHmmss } from "../../../constants/dateFormats";
import { getFullName } from "../../../utils/appUtils";
import * as paths from "../../Routes/routePaths";
import { Navigate, useParams } from "react-router-dom";

type TProspectEditBasicInformationProps = {
  prospectReference?: string;
  prospect: IProspect | null;
  isFetchingProspect: boolean;
  isUpdatingBasicInformation: boolean;
  fetchProspectByReference: TFetchProspectByReferenceAction;
  updateProspectBasicInformation: TUpdateProspectBasicInformationAction;
} & WithTranslation;

type TProspectEditBasicInformationState = {};

class ProspectEditBasicInformation extends Component<
  TProspectEditBasicInformationProps,
  TProspectEditBasicInformationState
> {
  async componentDidMount() {
    if (this.props.prospectReference !== undefined) {
      await this.props.fetchProspectByReference(this.props.prospectReference);
    }
  }

  getCurrentProspect = (): IProspect | undefined => {
    const { prospect, prospectReference } = this.props;

    if (prospect === null) {
      return undefined;
    }

    return prospect.reference === prospectReference ? prospect : undefined;
  };

  render() {
    const { t } = this.props;

    if (this.props.isFetchingProspect) {
      return (
        <div className="ProspectEdit">
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;
        </div>
      );
    }

    const prospect = this.getCurrentProspect();
    if (prospect === undefined) {
      return <div className="ProspectEdit">No meeting found</div>;
    }

    if (prospect.isMeetingActive) {
      const path = paths.meetingPresentation.replace(":prospectReference", prospect.reference);
      return <Navigate replace to={path} />;
    }

    const isUpdatingBasicInformation = this.props.isUpdatingBasicInformation;
    const updateProspectBasicInformation = this.props.updateProspectBasicInformation;

    const editFormContent = (
      <>
        <h1>Moi {prospect.firstName}!</h1>
        <p>
          Alla olevalla lomakkeella kysymme muutamia perustietoja jotka kaikki vaikuttavat jollain tavalla
          sosiaaliturvaasi. Täytäthän lomakkeen huolellisesti jotta saamme tapaamisessamme esitettyä tarkan arvion sinun
          sosiaaliturvasi nykytilanteesta.
        </p>
        <p>Huomaathan että tarvitset verkkopankkitunnukset jotta pääset tarkistamaan eläkekertymäsi.</p>
        <ProspectEditBasicInformationForm
          isSubmitting={isUpdatingBasicInformation}
          prospect={prospect}
          updateProspectBasicInformation={updateProspectBasicInformation}
        />
        <div className={"my-4 MeetingEdit-partnership"}>
          <h6 className={"text-uppercase strikethrough text-center"}>
            <span>{t("Partnership")}</span>
          </h6>
          <div className={"pt-2 partnership-logo-container d-flex justify-content-center align-items-center"}>
            <img src={aktia_black_logo} alt={"Aktia logo"} className={"black"} />
            <img src={veritas_black_logo} alt={"Veritas logo"} className={"black"} />
          </div>
        </div>
      </>
    );

    const meetingDate = moment(prospect.meetingDate, dateTimeFormat_YYYYMMDD_HHmmss, true);

    const completedContent = (
      <>
        <h1>{t("Thanks")}!</h1>
        <p>
          Nyt pallo on meillä ja asiantuntijamme voi valmistautua huolellisesti tulevaan tapaamiseen. Jos tapaaminen on
          sovittu hoidettavaksi verkon välityksellä, varaathan itsellesi häiriöttömän tilan jossa voit tietokoneesi
          näytöltä seurata ja osallistua esitykseen.
        </p>
        <p>
          Tavataan{" "}
          <strong>
            {t(`day ${meetingDate.isoWeekday()}`) +
              " " +
              meetingDate.format(dateFormat_DDMMYYYY) +
              t("at_clock") +
              meetingDate.format(dateFormat_HHmm)}
          </strong>
          .
        </p>
        <p>
          Jos et jo ole ilmoittanut meille eläkekertymääsi, otathan mukaan verkkopankkitunnuksesi joilla voit tarkistaa
          oman eläkekertymäsi. Voit myös tarkistaa eläkekertymäsi etukäteen{" "}
          <a href={"https://www.tyoelake.fi/tyoelakeote/"}>tästä linkistä</a>.
        </p>
        <p>
          {t("Best regards")},<br />
          {getFullName(prospect.sellerFirstName, prospect.sellerLastName)} <br />
        </p>
      </>
    );

    return (
      <div className="ProspectEdit">
        {prospect.hasProspectFilledInData !== true ? editFormContent : completedContent}
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  prospect: state.prospect.prospect,
  isFetchingProspect: state.prospect.isFetchingProspect,
  isUpdatingBasicInformation: state.prospect.isUpdatingBasicInformation,
});

type TMapDispatchToProps = {
  fetchProspectByReference: TFetchProspectByReferenceAction;
  updateProspectBasicInformation: TUpdateProspectBasicInformationAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  fetchProspectByReference: (reference: string) => dispatch(fetchProspectByReference(reference)),
  updateProspectBasicInformation: (prospect: IProspect, basicInformation: IProspectBasicInformation) =>
    dispatch(updateProspectBasicInformation(prospect, basicInformation)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation("translations")((props: TProspectEditBasicInformationProps) => {
  const { prospectReference } = useParams();
  return (
    <ProspectEditBasicInformation {...props} prospectReference={prospectReference} />
  )
}));
