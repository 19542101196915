import useOmsenApiClient from "./clients/useOmsenApiClient";
import { useQuery } from "@tanstack/react-query";

export default function useOmsenLanguages() {
  const client = useOmsenApiClient();

  const getOmsenLanguages = async () => {
    const response = await client.apiOmsenLanguagesGet();
    return response.data;
  };

  return useQuery(["omsenLanguages"], getOmsenLanguages, {
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
