export const TOLCodes = (code: string) => tolCodes[code.toLocaleUpperCase()];

export const IsCodeValid = (code: string) => code.toLocaleUpperCase() in tolCodes;

const tolCodes: { [code: string]: string } = {
  SSSSS: "Toimialat yhteensä",
  A: "Maatalous, metsätalous ja kalatalous",
  "01": "Kasvinviljely ja kotieläintalous, riistatalous ja niihin liittyvät palvelut",
  "011": "Yksivuotisten kasvien viljely",
  "0111": "Viljakasvien (pl. riisin), palkokasvien ja öljysiemenkasvien viljely",
  "01110": "Viljakasvien (pl. riisin), palkokasvien ja öljysiemenkasvien viljely",
  "0112": "Riisin viljely",
  "01120": "Riisin viljely",
  "0113": "Vihannesten ja melonien, juuresten ja mukulakasvien viljely",
  "01131": "Vihannesten viljely avomaalla (pl. peruna ja sokerijuurikas)",
  "01132": "Vihannesten viljely kasvihuoneessa",
  "01133": "Perunan viljely",
  "01134": "Sokerijuurikkaan viljely",
  "0114": "Sokeriruo&#39;on viljely",
  "01140": "Sokeriruo&#39;on viljely",
  "0115": "Tupakan viljely",
  "01150": "Tupakan viljely",
  "0116": "Kuitukasvien viljely",
  "01160": "Kuitukasvien viljely",
  "0119": "Muu yksivuotisten ja koristekasvien viljely",
  "01191": "Koristekasvien viljely",
  "01199": "Muiden yksivuotisten kasvien viljely",
  "012": "Monivuotisten kasvien viljely",
  "0121": "Rypäleiden viljely",
  "01210": "Rypäleiden viljely",
  "0122": "Trooppisten ja subtrooppisten hedelmien viljely",
  "01220": "Trooppisten ja subtrooppisten hedelmien viljely",
  "0123": "Sitrushedelmien viljely",
  "01230": "Sitrushedelmien viljely",
  "0124": "Omenoiden, kirsikoiden, luumujen ym. kota- ja kivihedelmien viljely",
  "01240": "Omenoiden, kirsikoiden, luumujen ym. kota- ja kivihedelmien viljely",
  "0125": "Marjojen, pähkinöiden ja muiden puissa ja pensaissa kasvavien hedelmien viljely",
  "01250": "Marjojen, pähkinöiden ja muiden puissa ja pensaissa kasvavien hedelmien viljely",
  "0126": "Öljyä sisältävien hedelmien viljely",
  "01260": "Öljyä sisältävien hedelmien viljely",
  "0127": "Juomakasvien viljely",
  "01270": "Juomakasvien viljely",
  "0128": "Mauste-, aromi-, rohdos- ja lääkekasvien viljely",
  "01280": "Mauste-, aromi-, rohdos- ja lääkekasvien viljely",
  "0129": "Muu monivuotisten kasvien viljely",
  "01290": "Muu monivuotisten kasvien viljely",
  "013": "Taimien kasvatus ja muu kasvien lisääminen",
  "0130": "Taimien kasvatus ja muu kasvien lisääminen",
  "01300": "Taimien kasvatus ja muu kasvien lisääminen",
  "014": "Kotieläintalous",
  "0141": "Lypsykarjan kasvatus",
  "01410": "Lypsykarjan kasvatus",
  "0142": "Muun nautakarjan ja puhvelien kasvatus",
  "01420": "Muun nautakarjan ja puhvelien kasvatus",
  "0143": "Hevosten ja muiden hevoseläinten kasvatus",
  "01430": "Hevosten ja muiden hevoseläinten kasvatus",
  "0144": "Kamelien ja kamelieläinten kasvatus",
  "01440": "Kamelien ja kamelieläinten kasvatus",
  "0145": "Lampaiden ja vuohien kasvatus",
  "01450": "Lampaiden ja vuohien kasvatus",
  "0146": "Sikojen kasvatus",
  "01461": "Porsastuotanto",
  "01462": "Lihasikojen kasvatus",
  "0147": "Siipikarjan kasvatus",
  "01471": "Kananmunien tuotanto",
  "01472": "Broilerien tuotanto",
  "01479": "Muu siipikarjatalous",
  "0149": "Muiden eläinten kasvatus",
  "01491": "Turkistarhaus",
  "01492": "Poronhoito",
  "01499": "Muu eläinten hoito",
  "015": "Yhdistetty kasvinviljely ja kotieläintalous (sekatilat)",
  "0150": "Yhdistetty kasvinviljely ja kotieläintalous (sekatilat)",
  "01500": "Yhdistetty kasvinviljely ja kotieläintalous (sekatilat)",
  "016": "Maataloutta palveleva toiminta",
  "0161": "Kasvinviljelyä palveleva toiminta",
  "01611": "Kasvinviljelyn tukipalvelut",
  "01612": "Maatalousmaan pitäminen viljelykelpoisena",
  "0162": "Kotieläintaloutta palveleva toiminta",
  "01620": "Kotieläintaloutta palveleva toiminta",
  "0163": "Sadon jatkokäsittely",
  "01630": "Sadon jatkokäsittely",
  "0164": "Siementen käsittely kasvinviljelyä varten",
  "01640": "Siementen käsittely kasvinviljelyä varten",
  "017": "Metsästys ja sitä palveleva toiminta",
  "0170": "Metsästys ja sitä palveleva toiminta",
  "01700": "Metsästys ja sitä palveleva toiminta",
  "02": "Metsätalous ja puunkorjuu",
  "021": "Metsänhoito",
  "0210": "Metsänhoito",
  "02100": "Metsänhoito",
  "022": "Puunkorjuu",
  "0220": "Puunkorjuu",
  "02200": "Puunkorjuu",
  "023": "Luonnon tuotteiden keruu (pl. polttopuu)",
  "0230": "Luonnon tuotteiden keruu (pl. polttopuu)",
  "02300": "Luonnon tuotteiden keruu (pl. polttopuu)",
  "024": "Metsätaloutta palveleva toiminta",
  "0240": "Metsätaloutta palveleva toiminta",
  "02400": "Metsätaloutta palveleva toiminta",
  "03": "Kalastus ja vesiviljely",
  "031": "Kalastus",
  "0311": "Merikalastus",
  "03110": "Merikalastus",
  "0312": "Sisävesikalastus",
  "03120": "Sisävesikalastus",
  "032": "Vesiviljely",
  "0321": "Kalanviljely meressä",
  "03210": "Kalanviljely meressä",
  "0322": "Kalanviljely sisävesissä",
  "03220": "Kalanviljely sisävesissä",
  B: "Kaivostoiminta ja louhinta",
  "05": "Kivihiilen ja ruskohiilen kaivu",
  "051": "Kivihiilen kaivu",
  "0510": "Kivihiilen kaivu",
  "05100": "Kivihiilen kaivu",
  "052": "Ruskohiilen kaivu",
  "0520": "Ruskohiilen kaivu",
  "05200": "Ruskohiilen kaivu",
  "06": "Raakaöljyn ja maakaasun tuotanto",
  "061": "Raakaöljyn tuotanto",
  "0610": "Raakaöljyn tuotanto",
  "06100": "Raakaöljyn tuotanto",
  "062": "Maakaasun tuotanto",
  "0620": "Maakaasun tuotanto",
  "06200": "Maakaasun tuotanto",
  "07": "Metallimalmien louhinta",
  "071": "Rautamalmien louhinta",
  "0710": "Rautamalmien louhinta",
  "07100": "Rautamalmien louhinta",
  "072": "Värimetallimalmien louhinta",
  "0721": "Uraani- ja toriummalmien louhinta",
  "07210": "Uraani- ja toriummalmien louhinta",
  "0729": "Muiden värimetallimalmien louhinta",
  "07290": "Muiden värimetallimalmien louhinta",
  "08": "Muu kaivostoiminta ja louhinta",
  "081": "Kiven louhinta, hiekan ja saven otto",
  "0811": "Koriste- ja rakennuskiven, kalkkikiven, kipsin, liidun ja liuskekiven louhinta",
  "08111": "Koriste- ja rakennuskiven louhinta",
  "08112": "Kalkkikiven, kipsin, liidun ja dolomiitin louhinta",
  "08113": "Liuskekiven louhinta",
  "0812": "Soran, hiekan, saven ja kaoliinin otto",
  "08120": "Soran, hiekan, saven ja kaoliinin otto",
  "089": "Muu mineraalien kaivu",
  "0891": "Kemiallisten ja lannoitemineraalien louhinta",
  "08910": "Kemiallisten ja lannoitemineraalien louhinta",
  "0892": "Turpeen nosto",
  "08920": "Turpeen nosto",
  "0893": "Suolan tuotanto",
  "08930": "Suolan tuotanto",
  "0899": "Muualla luokittelematon kaivostoiminta ja louhinta",
  "08990": "Muualla luokittelematon kaivostoiminta ja louhinta",
  "09": "Kaivostoimintaa palveleva toiminta",
  "091": "Raakaöljyn ja maakaasun tuotantoa palveleva toiminta",
  "0910": "Raakaöljyn ja maakaasun tuotantoa palveleva toiminta",
  "09100": "Raakaöljyn ja maakaasun tuotantoa palveleva toiminta",
  "099": "Muuta kaivostoimintaa ja louhintaa palveleva toiminta",
  "0990": "Muuta kaivostoimintaa ja louhintaa palveleva toiminta",
  "09900": "Muuta kaivostoimintaa ja louhintaa palveleva toiminta",
  C: "Teollisuus",
  "10": "Elintarvikkeiden valmistus",
  "101": "Teurastus, lihan säilyvyyskäsittely ja lihatuotteiden valmistus",
  "1011": "Teurastus ja lihan säilyvyyskäsittely (pl. siipikarja)",
  "10110": "Teurastus ja lihan säilyvyyskäsittely (pl. siipikarja)",
  "1012": "Siipikarjan teurastus ja lihan säilyvyyskäsittely",
  "10120": "Siipikarjan teurastus ja lihan säilyvyyskäsittely",
  "1013": "Liha- ja siipikarjatuotteiden valmistus",
  "10130": "Liha- ja siipikarjatuotteiden valmistus",
  "102": "Kalan, äyriäisten ja nilviäisten jalostus ja säilöntä",
  "1020": "Kalan, äyriäisten ja nilviäisten jalostus ja säilöntä",
  "10200": "Kalan, äyriäisten ja nilviäisten jalostus ja säilöntä",
  "103": "Hedelmien ja kasvisten jalostus ja säilöntä",
  "1031": "Perunoiden jalostus ja säilöntä",
  "10310": "Perunoiden jalostus ja säilöntä",
  "1032": "Hedelmä-, marja- ja kasvismehujen valmistus",
  "10320": "Hedelmä-, marja- ja kasvismehujen valmistus",
  "1039": "Muu hedelmien, marjojen ja kasvisten jalostus ja säilöntä",
  "10390": "Muu hedelmien, marjojen ja kasvisten jalostus ja säilöntä",
  "104": "Kasvi- ja eläinöljyjen ja -rasvojen valmistus",
  "1041": "Kasvi- ja eläinperäisten öljyjen ja -rasvojen valmistus (pl. ravintorasvat)",
  "10410": "Kasvi- ja eläinperäisten öljyjen ja -rasvojen valmistus (pl. ravintorasvat)",
  "1042": "Margariinin ja sen kaltaisten ravintorasvojen valmistus",
  "10420": "Margariinin ja sen kaltaisten ravintorasvojen valmistus",
  "105": "Maitotaloustuotteiden valmistus",
  "1051": "Maitotaloustuotteiden ja juuston valmistus",
  "10510": "Maitotaloustuotteiden ja juuston valmistus",
  "1052": "Jäätelön valmistus",
  "10520": "Jäätelön valmistus",
  "106": "Mylly- ja tärkkelystuotteiden valmistus",
  "1061": "Myllytuotteiden valmistus",
  "10610": "Myllytuotteiden valmistus",
  "1062": "Tärkkelyksen ja tärkkelystuotteiden valmistus",
  "10620": "Tärkkelyksen ja tärkkelystuotteiden valmistus",
  "107": "Leipomotuotteiden, makaronien yms. valmistus",
  "1071": "Leivän valmistus; tuoreiden leivonnaisten ja kakkujen valmistus",
  "10710": "Leivän valmistus; tuoreiden leivonnaisten ja kakkujen valmistus",
  "1072": "Näkkileivän ja keksien valmistus; säilyvien leivonnaisten ja kakkujen valmistus",
  "10720": "Näkkileivän ja keksien valmistus; säilyvien leivonnaisten ja kakkujen valmistus",
  "1073": "Makaronin, nuudelien, kuskusin ja vastaavien jauhotuotteiden valmistus",
  "10730": "Makaronin, nuudelien, kuskusin ja vastaavien jauhotuotteiden valmistus",
  "108": "Muiden elintarvikkeiden valmistus",
  "1081": "Sokerin valmistus",
  "10810": "Sokerin valmistus",
  "1082": "Kaakaon, suklaan ja makeisten valmistus",
  "10820": "Kaakaon, suklaan ja makeisten valmistus",
  "1083": "Teen ja kahvin valmistus",
  "10830": "Teen ja kahvin valmistus",
  "1084": "Mausteiden ja maustekastikkeiden valmistus",
  "10840": "Mausteiden ja maustekastikkeiden valmistus",
  "1085": "Einesten ja valmisruokien valmistus",
  "10850": "Einesten ja valmisruokien valmistus",
  "1086": "Homogenoitujen ravintovalmisteiden ja dieettiruokien valmistus",
  "10860": "Homogenoitujen ravintovalmisteiden ja dieettiruokien valmistus",
  "1089": "Muualla luokittelematon elintarvikkeiden valmistus",
  "10890": "Muualla luokittelematon elintarvikkeiden valmistus",
  "109": "Eläinten ruokien valmistus",
  "1091": "Kotieläinten rehujen valmistus",
  "10910": "Kotieläinten rehujen valmistus",
  "1092": "Lemmikkieläinten ruokien valmistus",
  "10920": "Lemmikkieläinten ruokien valmistus",
  "11": "Juomien valmistus",
  "110": "Juomien valmistus",
  "1101": "Alkoholijuomien tislaus ja sekoittaminen; etyylialkoholin valmistus käymisteitse",
  "11010": "Alkoholijuomien tislaus ja sekoittaminen; etyylialkoholin valmistus käymisteitse",
  "1102": "Viinin valmistus rypäleistä",
  "11020": "Viinin valmistus rypäleistä",
  "1103": "Siiderin, hedelmä- ja marjaviinien valmistus",
  "11030": "Siiderin, hedelmä- ja marjaviinien valmistus",
  "1104": "Muiden tislaamattomien juomien valmistus käymisteitse",
  "11040": "Muiden tislaamattomien juomien valmistus käymisteitse",
  "1105": "Oluen valmistus",
  "11050": "Oluen valmistus",
  "1106": "Maltaiden valmistus",
  "11060": "Maltaiden valmistus",
  "1107": "Virvoitusjuomien valmistus; kivennäisvesien ja muiden pullotettujen vesien tuotanto",
  "11070": "Virvoitusjuomien valmistus; kivennäisvesien ja muiden pullotettujen vesien tuotanto",
  "12": "Tupakkatuotteiden valmistus",
  "120": "Tupakkatuotteiden valmistus",
  "1200": "Tupakkatuotteiden valmistus",
  "12000": "Tupakkatuotteiden valmistus",
  "13": "Tekstiilien valmistus",
  "131": "Tekstiilikuitujen valmistelu ja kehruu",
  "1310": "Tekstiilikuitujen valmistelu ja kehruu",
  "13100": "Tekstiilikuitujen valmistelu ja kehruu",
  "132": "Kankaiden kudonta",
  "1320": "Kankaiden kudonta",
  "13200": "Kankaiden kudonta",
  "133": "Tekstiilien viimeistely",
  "1330": "Tekstiilien viimeistely",
  "13300": "Tekstiilien viimeistely",
  "139": "Muiden tekstiilituotteiden valmistus",
  "1391": "Neulosten valmistus",
  "13910": "Neulosten valmistus",
  "1392": "Sovitettujen tekstiilituotteiden valmistus (pl. vaatteet)",
  "13921": "Sisustustekstiilien valmistus",
  "13922": "Tavaranpeitteiden, purjeiden ja muiden sovitettujen tekstiilituotteiden valmistus",
  "1393": "Mattojen valmistus",
  "13930": "Mattojen valmistus",
  "1394": "Purjelankojen, nuoran, sidelangan ja verkkojen valmistus",
  "13940": "Purjelankojen, nuoran, sidelangan ja verkkojen valmistus",
  "1395": "Kuitukankaiden ja kuitukangastuotteiden valmistus (pl. vaatteet)",
  "13950": "Kuitukankaiden ja kuitukangastuotteiden valmistus (pl. vaatteet)",
  "1396": "Teknisten ja teollisuustekstiilien valmistus",
  "13960": "Teknisten ja teollisuustekstiilien valmistus",
  "1399": "Muualla luokittelematon tekstiilituotteiden valmistus",
  "13990": "Muualla luokittelematon tekstiilituotteiden valmistus",
  "14": "Vaatteiden valmistus",
  "141": "Vaatteiden valmistus (pl. turkisvaatteet)",
  "1411": "Nahkavaatteiden valmistus",
  "14110": "Nahkavaatteiden valmistus",
  "1412": "Työvaatteiden valmistus",
  "14120": "Työvaatteiden valmistus",
  "1413": "Muu takkien, pukujen, housujen, hameiden yms. valmistus",
  "14130": "Muu takkien, pukujen, housujen, hameiden yms. valmistus",
  "1414": "Alusvaatteiden valmistus",
  "14140": "Alusvaatteiden valmistus",
  "1419": "Muiden vaatteiden ja asusteiden valmistus",
  "14190": "Muiden vaatteiden ja asusteiden valmistus",
  "142": "Turkisvaatteiden ja -tuotteiden valmistus",
  "1420": "Turkisvaatteiden ja -tuotteiden valmistus",
  "14200": "Turkisvaatteiden ja -tuotteiden valmistus",
  "143": "Neulevaatteiden ja sukkien valmistus",
  "1431": "Sukkien ja sukkahousujen valmistus",
  "14310": "Sukkien ja sukkahousujen valmistus",
  "1439": "Muiden neulevaatteiden valmistus",
  "14390": "Muiden neulevaatteiden valmistus",
  "15": "Nahan ja nahkatuotteiden valmistus",
  "151":
    "Nahan parkitseminen ja muokkaus; matka- ja käsilaukkujen, satuloiden ja valjaiden valmistus; turkisten muokkaus ja värjäys",
  "1511": "Turkisten ja nahan muokkaus ja värjäys",
  "15110": "Turkisten ja nahan muokkaus ja värjäys",
  "1512": "Matka-, käsi- ym. laukkujen, satuloiden ja valjaiden valmistus",
  "15120": "Matka-, käsi- ym. laukkujen, satuloiden ja valjaiden valmistus",
  "152": "Jalkineiden valmistus",
  "1520": "Jalkineiden valmistus",
  "15200": "Jalkineiden valmistus",
  "16": "Sahatavaran sekä puu- ja korkkituotteiden valmistus (pl. huonekalut); olki- ja punontatuotteiden valmistus",
  "161": "Puun sahaus, höyläys ja kyllästys",
  "1610": "Puun sahaus, höyläys ja kyllästys",
  "16100": "Puun sahaus, höyläys ja kyllästys",
  "162": "Puu-, korkki-, olki- ja punontatuotteiden valmistus",
  "1621": "Vaneriviilun ja puupaneelien valmistus",
  "16211": "Vanerin ja vaneriviilun valmistus",
  "16212": "Lastulevyn valmistus",
  "16213": "Kuitulevyn valmistus",
  "1622": "Asennettavien parkettilevyjen valmistus",
  "16220": "Asennettavien parkettilevyjen valmistus",
  "1623": "Muiden rakennuspuusepäntuotteiden valmistus",
  "16231": "Puutalojen valmistus",
  "16239": "Muu rakennuspuusepäntuotteiden valmistus",
  "1624": "Puupakkausten valmistus",
  "16240": "Puupakkausten valmistus",
  "1629": "Muiden puutuotteiden valmistus; korkki-, olki- ja punontatuotteiden valmistus",
  "16290": "Muiden puutuotteiden valmistus; korkki-, olki- ja punontatuotteiden valmistus",
  "17": "Paperin, paperi- ja kartonkituotteiden valmistus",
  "171": "Massan, paperin, kartongin ja pahvin valmistus",
  "1711": "Massan valmistus",
  "17110": "Massan valmistus",
  "1712": "Paperin, kartongin ja pahvin valmistus",
  "17120": "Paperin, kartongin ja pahvin valmistus",
  "172": "Paperi-, kartonki- ja pahvituotteiden valmistus",
  "1721": "Aaltopaperin ja -pahvin sekä paperi-, kartonki- ja pahvipakkausten valmistus",
  "17211": "Paperisäkkien ja -pussien valmistus",
  "17212": "Aaltopahvin sekä paperi- ja kartonkipakkausten valmistus",
  "1722": "Paperisten talous- ja hygieniatarvikkeiden valmistus",
  "17220": "Paperisten talous- ja hygieniatarvikkeiden valmistus",
  "1723": "Paperikauppatavaroiden valmistus",
  "17230": "Paperikauppatavaroiden valmistus",
  "1724": "Tapettien valmistus",
  "17240": "Tapettien valmistus",
  "1729": "Muiden paperi-, kartonki- ja pahvituotteiden valmistus",
  "17290": "Muiden paperi-, kartonki- ja pahvituotteiden valmistus",
  "18": "Painaminen ja tallenteiden jäljentäminen",
  "181": "Painaminen ja siihen liittyvät palvelut",
  "1811": "Sanomalehtien painaminen",
  "18110": "Sanomalehtien painaminen",
  "1812": "Muu painaminen",
  "18120": "Muu painaminen",
  "1813": "Painamista ja julkaisemista edeltävät palvelut",
  "18130": "Painamista ja julkaisemista edeltävät palvelut",
  "1814": "Sidonta ja siihen liittyvät palvelut",
  "18140": "Sidonta ja siihen liittyvät palvelut",
  "182": "Ääni-, kuva- ja atk-tallenteiden tuotanto",
  "1820": "Ääni-, kuva- ja atk-tallenteiden tuotanto",
  "18200": "Ääni-, kuva- ja atk-tallenteiden tuotanto",
  "19": "Koksin ja jalostettujen öljytuotteiden valmistus",
  "191": "Koksituotteiden valmistus",
  "1910": "Koksituotteiden valmistus",
  "19100": "Koksituotteiden valmistus",
  "192": "Jalostettujen öljytuotteiden valmistus",
  "1920": "Jalostettujen öljytuotteiden valmistus",
  "19200": "Jalostettujen öljytuotteiden valmistus",
  "20": "Kemikaalien ja kemiallisten tuotteiden valmistus",
  "201":
    "Peruskemikaalien, lannoitteiden ja typpiyhdisteiden, muoviaineiden ja synteettisen kumiraaka-aineen valmistus",
  "2011": "Teollisuuskaasujen valmistus",
  "20110": "Teollisuuskaasujen valmistus",
  "2012": "Värien ja pigmenttien valmistus",
  "20120": "Värien ja pigmenttien valmistus",
  "2013": "Muiden epäorgaanisten peruskemikaalien valmistus",
  "20130": "Muiden epäorgaanisten peruskemikaalien valmistus",
  "2014": "Muiden orgaanisten peruskemikaalien valmistus",
  "20140": "Muiden orgaanisten peruskemikaalien valmistus",
  "2015": "Lannoitteiden ja typpiyhdisteiden valmistus",
  "20150": "Lannoitteiden ja typpiyhdisteiden valmistus",
  "2016": "Muoviaineiden valmistus",
  "20160": "Muoviaineiden valmistus",
  "2017": "Synteettisen kumiraaka-aineen valmistus",
  "20170": "Synteettisen kumiraaka-aineen valmistus",
  "202": "Torjunta-aineiden ja muiden maatalouskemikaalien valmistus",
  "2020": "Torjunta-aineiden ja muiden maatalouskemikaalien valmistus",
  "20200": "Torjunta-aineiden ja muiden maatalouskemikaalien valmistus",
  "203": "Maalien, lakan, painovärien yms. valmistus",
  "2030": "Maalien, lakan, painovärien yms. valmistus",
  "20300": "Maalien, lakan, painovärien yms. valmistus",
  "204": "Saippuan, pesu-, puhdistus- ja kiillotusaineiden; hajuvesien ja hygieniatuotteiden valmistus",
  "2041": "Saippuan, pesu-, puhdistus- ja kiillotusaineiden valmistus",
  "20410": "Saippuan, pesu-, puhdistus- ja kiillotusaineiden valmistus",
  "2042": "Hajuvesien ja hygieniatuotteiden valmistus",
  "20420": "Hajuvesien ja hygieniatuotteiden valmistus",
  "205": "Muiden kemiallisten tuotteiden valmistus",
  "2051": "Räjähdysaineiden valmistus",
  "20510": "Räjähdysaineiden valmistus",
  "2052": "Liimojen valmistus",
  "20520": "Liimojen valmistus",
  "2053": "Eteeristen öljyjen valmistus",
  "20530": "Eteeristen öljyjen valmistus",
  "2059": "Muualla luokittelematon kemiallisten tuotteiden valmistus",
  "20590": "Muualla luokittelematon kemiallisten tuotteiden valmistus",
  "206": "Tekokuitujen valmistus",
  "2060": "Tekokuitujen valmistus",
  "20600": "Tekokuitujen valmistus",
  "21": "Lääkeaineiden ja lääkkeiden valmistus",
  "211": "Lääkeaineiden valmistus",
  "2110": "Lääkeaineiden valmistus",
  "21100": "Lääkeaineiden valmistus",
  "212": "Lääkkeiden ja muiden lääkevalmisteiden valmistus",
  "2120": "Lääkkeiden ja muiden lääkevalmisteiden valmistus",
  "21200": "Lääkkeiden ja muiden lääkevalmisteiden valmistus",
  "22": "Kumi- ja muovituotteiden valmistus",
  "221": "Kumituotteiden valmistus",
  "2211": "Renkaiden valmistus ja uudelleenpinnoitus",
  "22110": "Renkaiden valmistus ja uudelleenpinnoitus",
  "2219": "Muiden kumituotteiden valmistus",
  "22190": "Muiden kumituotteiden valmistus",
  "222": "Muovituotteiden valmistus",
  "2221": "Muovilevyjen, -kalvojen, -putkien ja -profiilien valmistus",
  "22210": "Muovilevyjen, -kalvojen, -putkien ja -profiilien valmistus",
  "2222": "Muovipakkausten valmistus",
  "22220": "Muovipakkausten valmistus",
  "2223": "Rakennusmuovien valmistus",
  "22230": "Rakennusmuovien valmistus",
  "2229": "Muiden muovituotteiden valmistus",
  "22290": "Muiden muovituotteiden valmistus",
  "23": "Muiden ei-metallisten mineraalituotteiden valmistus",
  "231": "Lasin ja lasituotteiden valmistus",
  "2311": "Tasolasin valmistus",
  "23110": "Tasolasin valmistus",
  "2312": "Tasolasin muotoilu ja muokkaus",
  "23120": "Tasolasin muotoilu ja muokkaus",
  "2313": "Onton lasitavaran valmistus",
  "23130": "Onton lasitavaran valmistus",
  "2314": "Lasikuitujen valmistus",
  "23140": "Lasikuitujen valmistus",
  "2319": "Muu lasin valmistus ja muokkaus ml. tekniset lasituotteet",
  "23190": "Muu lasin valmistus ja muokkaus ml. tekniset lasituotteet",
  "232": "Tulenkestävien keraamisten tuotteiden valmistus",
  "2320": "Tulenkestävien keraamisten tuotteiden valmistus",
  "23200": "Tulenkestävien keraamisten tuotteiden valmistus",
  "233": "Keraamisten rakennusaineiden valmistus",
  "2331": "Keraamisten tiilien ja laattojen valmistus",
  "23310": "Keraamisten tiilien ja laattojen valmistus",
  "2332": "Poltettujen tiilien ja muun rakennuskeramiikan valmistus",
  "23320": "Poltettujen tiilien ja muun rakennuskeramiikan valmistus",
  "234": "Muiden posliini- ja keramiikkatuotteiden valmistus",
  "2341": "Keraamisten talous- ja koriste-esineiden valmistus",
  "23410": "Keraamisten talous- ja koriste-esineiden valmistus",
  "2342": "Keraamisten saniteettikalusteiden valmistus",
  "23420": "Keraamisten saniteettikalusteiden valmistus",
  "2343": "Keraamisten eristystuotteiden valmistus",
  "23430": "Keraamisten eristystuotteiden valmistus",
  "2344": "Muiden teknisten keraamisten tuotteiden valmistus",
  "23440": "Muiden teknisten keraamisten tuotteiden valmistus",
  "2349": "Muiden keramiikkatuotteiden valmistus",
  "23490": "Muiden keramiikkatuotteiden valmistus",
  "235": "Sementin, kalkin ja kipsin valmistus",
  "2351": "Sementin valmistus",
  "23510": "Sementin valmistus",
  "2352": "Kalkin ja kipsin valmistus",
  "23520": "Kalkin ja kipsin valmistus",
  "236": "Betoni-, kipsi- ja sementtituotteiden valmistus",
  "2361": "Betonituotteiden valmistus rakennustarkoituksiin",
  "23610": "Betonituotteiden valmistus rakennustarkoituksiin",
  "2362": "Kipsituotteiden valmistus rakennustarkoituksiin",
  "23620": "Kipsituotteiden valmistus rakennustarkoituksiin",
  "2363": "Valmisbetonin valmistus",
  "23630": "Valmisbetonin valmistus",
  "2364": "Muurauslaastin valmistus",
  "23640": "Muurauslaastin valmistus",
  "2365": "Kuitusementin valmistus",
  "23650": "Kuitusementin valmistus",
  "2369": "Muiden betoni-, kipsi- ja sementtituotteiden valmistus",
  "23690": "Muiden betoni-, kipsi- ja sementtituotteiden valmistus",
  "237": "Kiven leikkaaminen, muotoilu ja viimeistely",
  "2370": "Kiven leikkaaminen, muotoilu ja viimeistely",
  "23700": "Kiven leikkaaminen, muotoilu ja viimeistely",
  "239": "Hiontatuotteiden ja muualla luokittelemattomien ei-metallisten mineraalituotteiden valmistus",
  "2391": "Hiontatuotteiden valmistus",
  "23910": "Hiontatuotteiden valmistus",
  "2399": "Muualla luokittelemattomien ei-metallisten mineraalituotteiden valmistus",
  "23990": "Muualla luokittelemattomien ei-metallisten mineraalituotteiden valmistus",
  "24": "Metallien jalostus",
  "241": "Raudan, teräksen ja rautaseosten valmistus",
  "2410": "Raudan, teräksen ja rautaseosten valmistus",
  "24100": "Raudan, teräksen ja rautaseosten valmistus",
  "242": "Putkien, profiiliputkien ja niihin liittyvien tarvikkeiden valmistus teräksestä",
  "2420": "Putkien, profiiliputkien ja niihin liittyvien tarvikkeiden valmistus teräksestä",
  "24200": "Putkien, profiiliputkien ja niihin liittyvien tarvikkeiden valmistus teräksestä",
  "243": "Muu teräksen jalostus",
  "2431": "Raudan ja teräksen kylmävetäminen",
  "24310": "Raudan ja teräksen kylmävetäminen",
  "2432": "Rainan kylmävalssaus",
  "24320": "Rainan kylmävalssaus",
  "2433": "Kylmämuovaus tai kylmätaitto",
  "24330": "Kylmämuovaus tai kylmätaitto",
  "2434": "Teräslangan veto",
  "24340": "Teräslangan veto",
  "244": "Jalometallien ja muiden värimetallien valmistus",
  "2441": "Jalometallien valmistus",
  "24410": "Jalometallien valmistus",
  "2442": "Alumiinin valmistus",
  "24420": "Alumiinin valmistus",
  "2443": "Lyijyn, sinkin ja tinan valmistus",
  "24430": "Lyijyn, sinkin ja tinan valmistus",
  "2444": "Kuparin valmistus",
  "24440": "Kuparin valmistus",
  "2445": "Muiden värimetallien valmistus",
  "24450": "Muiden värimetallien valmistus",
  "2446": "Ydinpolttoaineen valmistus",
  "24460": "Ydinpolttoaineen valmistus",
  "245": "Metallien valu",
  "2451": "Raudan valu",
  "24510": "Raudan valu",
  "2452": "Teräksen valu",
  "24520": "Teräksen valu",
  "2453": "Kevytmetallien valu",
  "24530": "Kevytmetallien valu",
  "2454": "Muiden värimetallien valu",
  "24540": "Muiden värimetallien valu",
  "25": "Metallituotteiden valmistus (pl. koneet ja laitteet)",
  "251": "Metallirakenteiden valmistus",
  "2511": "Metallirakenteiden ja niiden osien valmistus",
  "25110": "Metallirakenteiden ja niiden osien valmistus",
  "2512": "Metalliovien ja -ikkunoiden valmistus",
  "25120": "Metalliovien ja -ikkunoiden valmistus",
  "252": "Metallisäiliöiden ja -altaiden yms. valmistus",
  "2521": "Keskuslämmityspatterien ja kuumavesivaraajien valmistus",
  "25210": "Keskuslämmityspatterien ja kuumavesivaraajien valmistus",
  "2529": "Muiden metallisäiliöiden ja -altaiden yms. valmistus",
  "25290": "Muiden metallisäiliöiden ja -altaiden yms. valmistus",
  "253": "Höyrykattiloiden valmistus (pl. keskuslämmityslaitteet)",
  "2530": "Höyrykattiloiden valmistus (pl. keskuslämmityslaitteet)",
  "25300": "Höyrykattiloiden valmistus (pl. keskuslämmityslaitteet)",
  "254": "Aseiden ja ammusten valmistus",
  "2540": "Aseiden ja ammusten valmistus",
  "25400": "Aseiden ja ammusten valmistus",
  "255": "Metallin takominen, puristaminen, meistäminen ja valssaus; jauhemetallurgia",
  "2550": "Metallin takominen, puristaminen, meistäminen ja valssaus; jauhemetallurgia",
  "25500": "Metallin takominen, puristaminen, meistäminen ja valssaus; jauhemetallurgia",
  "256": "Metallien käsittely, päällystäminen ja työstö",
  "2561": "Metallien käsittely ja päällystäminen",
  "25610": "Metallien käsittely ja päällystäminen",
  "2562": "Metallien työstö",
  "25620": "Metallien työstö",
  "257": "Ruokailu- ja leikkuuvälineiden yms. sekä työkalujen ja rautatavaran valmistus",
  "2571": "Ruokailu- ja leikkuuvälineiden yms. valmistus",
  "25710": "Ruokailu- ja leikkuuvälineiden yms. valmistus",
  "2572": "Lukkojen ja saranoiden valmistus",
  "25720": "Lukkojen ja saranoiden valmistus",
  "2573": "Työkalujen valmistus",
  "25730": "Työkalujen valmistus",
  "259": "Muu metallituotteiden valmistus",
  "2591": "Metallipakkausten ja -astioiden valmistus",
  "25910": "Metallipakkausten ja -astioiden valmistus",
  "2592": "Kevytmetallipakkausten valmistus",
  "25920": "Kevytmetallipakkausten valmistus",
  "2593": "Metallilankatuotteiden, ketjujen ja jousien valmistus",
  "25930": "Metallilankatuotteiden, ketjujen ja jousien valmistus",
  "2594": "Kiinnittimien ja ruuvituotteiden valmistus",
  "25940": "Kiinnittimien ja ruuvituotteiden valmistus",
  "2599": "Muiden metallituotteiden valmistus",
  "25990": "Muiden metallituotteiden valmistus",
  "26": "Tietokoneiden sekä elektronisten ja optisten tuotteiden valmistus",
  "261": "Elektronisten komponenttien ja piirilevyjen valmistus",
  "2611": "Elektronisten komponenttien valmistus",
  "26110": "Elektronisten komponenttien valmistus",
  "2612": "Kalustettujen piirilevyjen valmistus",
  "26120": "Kalustettujen piirilevyjen valmistus",
  "262": "Tietokoneiden ja niiden oheislaitteiden valmistus",
  "2620": "Tietokoneiden ja niiden oheislaitteiden valmistus",
  "26200": "Tietokoneiden ja niiden oheislaitteiden valmistus",
  "263": "Viestintälaitteiden valmistus",
  "2630": "Viestintälaitteiden valmistus",
  "26300": "Viestintälaitteiden valmistus",
  "264": "Viihde-elektroniikan valmistus",
  "2640": "Viihde-elektroniikan valmistus",
  "26400": "Viihde-elektroniikan valmistus",
  "265": "Mittaus-, testaus- ja navigointivälineiden ja -laitteiden valmistus; kellot",
  "2651": "Mittaus-, testaus- ja navigointivälineiden ja -laitteiden valmistus",
  "26510": "Mittaus-, testaus- ja navigointivälineiden ja -laitteiden valmistus",
  "2652": "Kellojen valmistus",
  "26520": "Kellojen valmistus",
  "266": "Säteilylaitteiden sekä elektronisten lääkintä- ja terapialaitteiden valmistus",
  "2660": "Säteilylaitteiden sekä elektronisten lääkintä- ja terapialaitteiden valmistus",
  "26600": "Säteilylaitteiden sekä elektronisten lääkintä- ja terapialaitteiden valmistus",
  "267": "Optisten instrumenttien ja valokuvausvälineiden valmistus",
  "2670": "Optisten instrumenttien ja valokuvausvälineiden valmistus",
  "26700": "Optisten instrumenttien ja valokuvausvälineiden valmistus",
  "268": "Tallennevälineiden valmistus",
  "2680": "Tallennevälineiden valmistus",
  "26800": "Tallennevälineiden valmistus",
  "27": "Sähkölaitteiden valmistus",
  "271": "Sähkömoottorien, generaattorien, muuntajien sekä sähkönjakelu- ja valvontalaitteiden valmistus",
  "2711": "Sähkömoottorien, generaattorien ja muuntajien valmistus",
  "27110": "Sähkömoottorien, generaattorien ja muuntajien valmistus",
  "2712": "Sähkönjakelu- ja valvontalaitteiden valmistus",
  "27120": "Sähkönjakelu- ja valvontalaitteiden valmistus",
  "272": "Paristojen ja akkujen valmistus",
  "2720": "Paristojen ja akkujen valmistus",
  "27200": "Paristojen ja akkujen valmistus",
  "273": "Sähköjohtojen ja kytkentälaitteiden valmistus",
  "2731": "Optisten kuitukaapelien valmistus",
  "27310": "Optisten kuitukaapelien valmistus",
  "2732": "Muiden elektronisten ja sähköjohtojen sekä -kaapelien valmistus",
  "27320": "Muiden elektronisten ja sähköjohtojen sekä -kaapelien valmistus",
  "2733": "Kytkentälaitteiden valmistus",
  "27330": "Kytkentälaitteiden valmistus",
  "274": "Sähkölamppujen ja valaisimien valmistus",
  "2740": "Sähkölamppujen ja valaisimien valmistus",
  "27400": "Sähkölamppujen ja valaisimien valmistus",
  "275": "Kodinkoneiden valmistus",
  "2751": "Sähköisten kodinkoneiden valmistus",
  "27510": "Sähköisten kodinkoneiden valmistus",
  "2752": "Sähköistämättömien kodinkoneiden valmistus",
  "27520": "Sähköistämättömien kodinkoneiden valmistus",
  "279": "Muiden sähkölaitteiden valmistus",
  "2790": "Muiden sähkölaitteiden valmistus",
  "27900": "Muiden sähkölaitteiden valmistus",
  "28": "Muiden koneiden ja laitteiden valmistus",
  "281": "Yleiskäyttöön tarkoitettujen voimakoneiden valmistus",
  "2811": "Moottorien ja turbiinien valmistus (pl. lentokoneiden ja ajoneuvojen moottorit)",
  "28110": "Moottorien ja turbiinien valmistus (pl. lentokoneiden ja ajoneuvojen moottorit)",
  "2812": "Hydraulisten voimalaitteiden valmistus",
  "28120": "Hydraulisten voimalaitteiden valmistus",
  "2813": "Pumppujen ja kompressoreiden valmistus",
  "28130": "Pumppujen ja kompressoreiden valmistus",
  "2814": "Muiden hanojen ja venttiilien valmistus",
  "28140": "Muiden hanojen ja venttiilien valmistus",
  "2815": "Laakereiden, hammaspyörien, vaihteisto- ja ohjauselementtien valmistus",
  "28150": "Laakereiden, hammaspyörien, vaihteisto- ja ohjauselementtien valmistus",
  "282": "Muiden yleiskäyttöön tarkoitettujen koneiden valmistus",
  "2821": "Teollisuusuunien, lämmitysjärjestelmien ja tulipesäpolttimien valmistus",
  "28210": "Teollisuusuunien, lämmitysjärjestelmien ja tulipesäpolttimien valmistus",
  "2822": "Nosto- ja siirtolaitteiden valmistus",
  "28220": "Nosto- ja siirtolaitteiden valmistus",
  "2823": "Konttorikoneiden ja -laitteiden valmistus (pl. tietokoneet ja niiden oheislaitteet)",
  "28230": "Konttorikoneiden ja -laitteiden valmistus (pl. tietokoneet ja niiden oheislaitteet)",
  "2824": "Voimakäyttöisten käsityökalujen valmistus",
  "28240": "Voimakäyttöisten käsityökalujen valmistus",
  "2825": "Muuhun kuin kotitalouskäyttöön tarkoitettujen jäähdytys- ja tuuletuslaitteiden valmistus",
  "28250": "Muuhun kuin kotitalouskäyttöön tarkoitettujen jäähdytys- ja tuuletuslaitteiden valmistus",
  "2829": "Muualla luokittelematon yleiskäyttöön tarkoitettujen koneiden valmistus",
  "28290": "Muualla luokittelematon yleiskäyttöön tarkoitettujen koneiden valmistus",
  "283": "Maa- ja metsätalouskoneiden valmistus",
  "2830": "Maa- ja metsätalouskoneiden valmistus",
  "28300": "Maa- ja metsätalouskoneiden valmistus",
  "284": "Metallin työstökoneiden ja konetyökalujen valmistus",
  "2841": "Metallin työstökoneiden valmistus",
  "28410": "Metallin työstökoneiden valmistus",
  "2849": "Muiden konetyökalujen valmistus",
  "28490": "Muiden konetyökalujen valmistus",
  "289": "Muiden erikoiskoneiden valmistus",
  "2891": "Metallinjalostuskoneiden valmistus",
  "28910": "Metallinjalostuskoneiden valmistus",
  "2892": "Kaivos-, louhinta- ja rakennuskoneiden valmistus",
  "28920": "Kaivos-, louhinta- ja rakennuskoneiden valmistus",
  "2893": "Elintarvike-, juoma- ja tupakkateollisuuden koneiden valmistus",
  "28930": "Elintarvike-, juoma- ja tupakkateollisuuden koneiden valmistus",
  "2894": "Tekstiili-, vaate- ja nahkateollisuuden koneiden valmistus",
  "28940": "Tekstiili-, vaate- ja nahkateollisuuden koneiden valmistus",
  "2895": "Paperi-, kartonki- ja pahviteollisuuden koneiden valmistus",
  "28950": "Paperi-, kartonki- ja pahviteollisuuden koneiden valmistus",
  "2896": "Muovi- ja kumiteollisuuden koneiden valmistus",
  "28960": "Muovi- ja kumiteollisuuden koneiden valmistus",
  "2899": "Muualla luokittelematon erikoiskoneiden valmistus",
  "28990": "Muualla luokittelematon erikoiskoneiden valmistus",
  "29": "Moottoriajoneuvojen, perävaunujen ja puoliperävaunujen valmistus",
  "291": "Moottoriajoneuvojen valmistus",
  "2910": "Moottoriajoneuvojen valmistus",
  "29100": "Moottoriajoneuvojen valmistus",
  "292": "Moottoriajoneuvojen korien valmistus; perävaunujen ja puoliperävaunujen valmistus",
  "2920": "Moottoriajoneuvojen korien valmistus; perävaunujen ja puoliperävaunujen valmistus",
  "29200": "Moottoriajoneuvojen korien valmistus; perävaunujen ja puoliperävaunujen valmistus",
  "293": "Osien ja tarvikkeiden valmistus moottoriajoneuvoihin",
  "2931": "Sähkö- ja elektroniikkalaitteiden valmistus moottoriajoneuvoihin",
  "29310": "Sähkö- ja elektroniikkalaitteiden valmistus moottoriajoneuvoihin",
  "2932": "Muiden osien ja tarvikkeiden valmistus moottoriajoneuvoihin",
  "29320": "Muiden osien ja tarvikkeiden valmistus moottoriajoneuvoihin",
  "30": "Muiden kulkuneuvojen valmistus",
  "301": "Laivojen ja veneiden rakentaminen",
  "3011": "Laivojen ja kelluvien rakenteiden rakentaminen",
  "30110": "Laivojen ja kelluvien rakenteiden rakentaminen",
  "3012": "Huvi- ja urheiluveneiden rakentaminen",
  "30120": "Huvi- ja urheiluveneiden rakentaminen",
  "302": "Raideliikenteen kulkuneuvojen valmistus",
  "3020": "Raideliikenteen kulkuneuvojen valmistus",
  "30200": "Raideliikenteen kulkuneuvojen valmistus",
  "303": "Ilma- ja avaruusalusten ja niihin liittyvien koneiden valmistus",
  "3030": "Ilma- ja avaruusalusten ja niihin liittyvien koneiden valmistus",
  "30300": "Ilma- ja avaruusalusten ja niihin liittyvien koneiden valmistus",
  "304": "Taisteluajoneuvojen valmistus",
  "3040": "Taisteluajoneuvojen valmistus",
  "30400": "Taisteluajoneuvojen valmistus",
  "309": "Muualla luokittelematon kulkuneuvojen valmistus",
  "3091": "Moottoripyörien valmistus",
  "30910": "Moottoripyörien valmistus",
  "3092": "Polkupyörien ja invalidiajoneuvojen valmistus",
  "30920": "Polkupyörien ja invalidiajoneuvojen valmistus",
  "3099": "Muiden muualla luokittelemattomien kulkuneuvojen valmistus",
  "30990": "Muiden muualla luokittelemattomien kulkuneuvojen valmistus",
  "31": "Huonekalujen valmistus",
  "310": "Huonekalujen valmistus",
  "3101": "Konttori- ja myymäläkalusteiden valmistus",
  "31010": "Konttori- ja myymäläkalusteiden valmistus",
  "3102": "Keittiökalusteiden valmistus",
  "31020": "Keittiökalusteiden valmistus",
  "3103": "Patjojen valmistus",
  "31030": "Patjojen valmistus",
  "3109": "Muiden huonekalujen valmistus",
  "31090": "Muiden huonekalujen valmistus",
  "32": "Muu valmistus",
  "321": "Korujen, kultasepäntuotteiden ja muiden vastaavien tuotteiden valmistus",
  "3211": "Kolikoiden ja mitalien valmistus",
  "32110": "Kolikoiden ja mitalien valmistus",
  "3212": "Jalokivikorujen ja muiden kultasepäntuotteiden valmistus",
  "32120": "Jalokivikorujen ja muiden kultasepäntuotteiden valmistus",
  "3213": "Jäljitelmäkorujen ja muiden vastaavien tuotteiden valmistus",
  "32130": "Jäljitelmäkorujen ja muiden vastaavien tuotteiden valmistus",
  "322": "Soitinten valmistus",
  "3220": "Soitinten valmistus",
  "32200": "Soitinten valmistus",
  "323": "Urheiluvälineiden valmistus",
  "3230": "Urheiluvälineiden valmistus",
  "32300": "Urheiluvälineiden valmistus",
  "324": "Pelien ja leikkikalujen valmistus",
  "3240": "Pelien ja leikkikalujen valmistus",
  "32400": "Pelien ja leikkikalujen valmistus",
  "325": "Lääkintä- ja hammaslääkintäinstrumenttien ja -tarvikkeiden valmistus",
  "3250": "Lääkintä- ja hammaslääkintäinstrumenttien ja -tarvikkeiden valmistus",
  "32501": "Lääkintä- ja hammaslääkintäinstrumenttien ja -tarvikkeiden valmistus (pl. hammasproteesit)",
  "32502": "Hammasproteesien, keinohampaiden ym. valmistus",
  "329": "Muualla luokittelematon valmistus",
  "3291": "Luutien ja harjojen valmistus",
  "32910": "Luutien ja harjojen valmistus",
  "3299": "Muu muualla luokittelematon valmistus",
  "32991": "Turvavarusteiden valmistus",
  "32999": "Muu muualla luokittelemattomien tuotteiden valmistus",
  "33": "Koneiden ja laitteiden korjaus, huolto ja asennus",
  "331": "Metallituotteiden, teollisuuden koneiden ja laitteiden korjaus ja huolto",
  "3311": "Metallituotteiden korjaus ja huolto",
  "33110": "Metallituotteiden korjaus ja huolto",
  "3312": "Teollisuuden koneiden ja laitteiden korjaus ja huolto",
  "33121": "Yleiskäyttöön tarkoitettujen koneiden korjaus ja huolto",
  "33122": "Maa- ja metsätalouskoneiden korjaus ja huolto",
  "33123": "Metallintyöstökoneiden ja muiden konetyökalujen korjaus ja huolto",
  "33129": "Muiden erikoiskoneiden korjaus ja huolto",
  "3313": "Elektronisten ja optisten laitteiden korjaus ja huolto",
  "33130": "Elektronisten ja optisten laitteiden korjaus ja huolto",
  "3314": "Sähkölaitteiden korjaus ja huolto",
  "33140": "Sähkölaitteiden korjaus ja huolto",
  "3315": "Laivojen ja veneiden korjaus ja huolto",
  "33150": "Laivojen ja veneiden korjaus ja huolto",
  "3316": "Ilma- ja avaruusalusten korjaus ja huolto",
  "33160": "Ilma- ja avaruusalusten korjaus ja huolto",
  "3317": "Muiden kulkuneuvojen korjaus ja huolto",
  "33170": "Muiden kulkuneuvojen korjaus ja huolto",
  "3319": "Muiden laitteiden korjaus ja huolto",
  "33190": "Muiden laitteiden korjaus ja huolto",
  "332": "Teollisuuden koneiden ja laitteiden ym. asennus",
  "3320": "Teollisuuden koneiden ja laitteiden ym. asennus",
  "33200": "Teollisuuden koneiden ja laitteiden ym. asennus",
  D: "Sähkö-, kaasu- ja lämpöhuolto, jäähdytysliiketoiminta",
  "35": "Sähkö-, kaasu- ja lämpöhuolto, jäähdytysliiketoiminta",
  "351": "Sähkövoiman tuotanto, siirto ja jakelu",
  "3511": "Sähkön tuotanto",
  "35111": "Sähkön tuotanto vesi- ja tuulivoimalla",
  "35112": "Sähkön erillistuotanto lämpövoimalla",
  "35113": "Sähkön ja kaukolämmön yhteistuotanto",
  "35114": "Sähkön tuotanto ydinvoimalla",
  "35115": "Teollisuutta palveleva sähkön ja lämmön tuotanto",
  "3512": "Sähkön siirto",
  "35120": "Sähkön siirto",
  "3513": "Sähkön jakelu",
  "35130": "Sähkön jakelu",
  "3514": "Sähkön kauppa",
  "35140": "Sähkön kauppa",
  "352": "Kaasun tuotanto; kaasumaisten polttoaineiden jakelu putkiverkossa",
  "3521": "Kaasun tuotanto",
  "35210": "Kaasun tuotanto",
  "3522": "Kaasumaisten polttoaineiden jakelu putkiverkossa",
  "35220": "Kaasumaisten polttoaineiden jakelu putkiverkossa",
  "3523": "Kaasun kauppa putkiverkossa",
  "35230": "Kaasun kauppa putkiverkossa",
  "353": "Lämmön ja kylmän tuotanto ja jakelu",
  "3530": "Lämmön ja kylmän tuotanto ja jakelu",
  "35301": "Kaukolämmön ja -kylmän erillistuotanto ja jakelu",
  "35302": "Teollisuutta palveleva lämmön ja kylmän erillistuotanto",
  E: "Vesihuolto, viemäri- ja jätevesihuolto, jätehuolto ja muu ympäristön puhtaanapito",
  "36": "Veden otto, puhdistus ja jakelu",
  "360": "Veden otto, puhdistus ja jakelu",
  "3600": "Veden otto, puhdistus ja jakelu",
  "36000": "Veden otto, puhdistus ja jakelu",
  "37": "Viemäri- ja jätevesihuolto",
  "370": "Viemäri- ja jätevesihuolto",
  "3700": "Viemäri- ja jätevesihuolto",
  "37000": "Viemäri- ja jätevesihuolto",
  "38": "Jätteen keruu, käsittely ja loppusijoitus; materiaalien kierrätys",
  "381": "Jätteen keruu",
  "3811": "Tavanomaisen jätteen keruu",
  "38110": "Tavanomaisen jätteen keruu",
  "3812": "Ongelmajätteen keruu",
  "38120": "Ongelmajätteen keruu",
  "382": "Jätteen käsittely ja loppusijoitus",
  "3821": "Tavanomaisen jätteen käsittely ja loppusijoitus",
  "38210": "Tavanomaisen jätteen käsittely ja loppusijoitus",
  "3822": "Ongelmajätteen käsittely, loppusijoitus ja hävittäminen",
  "38220": "Ongelmajätteen käsittely, loppusijoitus ja hävittäminen",
  "383": "Materiaalien kierrätys",
  "3831": "Romujen purkaminen",
  "38310": "Romujen purkaminen",
  "3832": "Lajiteltujen materiaalien kierrätys",
  "38320": "Lajiteltujen materiaalien kierrätys",
  "39": "Maaperän ja vesistöjen kunnostus ja muut ympäristönhuoltopalvelut",
  "390": "Maaperän ja vesistöjen kunnostus ja muut ympäristönhuoltopalvelut",
  "3900": "Maaperän ja vesistöjen kunnostus ja muut ympäristönhuoltopalvelut",
  "39000": "Maaperän ja vesistöjen kunnostus ja muut ympäristönhuoltopalvelut",
  F: "Rakentaminen",
  "41": "Talonrakentaminen",
  "411": "Rakennuttaminen ja rakennushankkeiden kehittäminen",
  "4110": "Rakennuttaminen ja rakennushankkeiden kehittäminen",
  "41100": "Rakennuttaminen ja rakennushankkeiden kehittäminen",
  "412": "Asuin- ja muiden rakennusten rakentaminen",
  "4120": "Asuin- ja muiden rakennusten rakentaminen",
  "41200": "Asuin- ja muiden rakennusten rakentaminen",
  "42": "Maa- ja vesirakentaminen",
  "421": "Teiden ja rautateiden rakentaminen",
  "4211": "Teiden ja moottoriteiden rakentaminen",
  "42110": "Teiden ja moottoriteiden rakentaminen",
  "4212": "Rautateiden ja metrolinjojen rakentaminen",
  "42120": "Rautateiden ja metrolinjojen rakentaminen",
  "4213": "Siltojen ja tunneleiden rakentaminen",
  "42130": "Siltojen ja tunneleiden rakentaminen",
  "422": "Yleisten jakeluverkkojen rakentaminen",
  "4221": "Yleisten jakeluverkkojen rakentaminen nestemäisiä ja kaasumaisia aineita varten",
  "42210": "Yleisten jakeluverkkojen rakentaminen nestemäisiä ja kaasumaisia aineita varten",
  "4222": "Sähkö- ja tietoliikenneverkkojen rakentaminen",
  "42220": "Sähkö- ja tietoliikenneverkkojen rakentaminen",
  "429": "Muu maa- ja vesirakentaminen",
  "4291": "Vesirakentaminen",
  "42910": "Vesirakentaminen",
  "4299": "Muualla luokittelematon maa- ja vesirakentaminen",
  "42991": "Maa- ja vesirakennushankkeiden kehittäminen ja rakennuttaminen",
  "42999": "Muu muualla luokittelematon maa- ja vesirakentaminen",
  "43": "Erikoistunut rakennustoiminta",
  "431": "Rakennusten ja rakennelmien purku ja rakennuspaikan valmistelutyöt",
  "4311": "Rakennusten ja rakennelmien purku",
  "43110": "Rakennusten ja rakennelmien purku",
  "4312": "Rakennuspaikan valmistelutyöt",
  "43120": "Rakennuspaikan valmistelutyöt",
  "4313": "Koeporaus",
  "43130": "Koeporaus",
  "432": "Sähkö-, vesijohto- ja muu rakennusasennus",
  "4321": "Sähköasennus",
  "43210": "Sähköasennus",
  "4322": "Lämpö-, vesijohto- ja ilmastointiasennus",
  "43220": "Lämpö-, vesijohto- ja ilmastointiasennus",
  "4329": "Muu rakennusasennus",
  "43291": "Lämpö-, ääni- ja tärinäeristeiden asennus",
  "43292": "Hissien ja liukuportaiden asennus",
  "43299": "Muualla luokittelematon rakennusasennus",
  "433": "Rakennusten ja rakennelmien viimeistely",
  "4331": "Rappaus",
  "43310": "Rappaus",
  "4332": "Rakennuspuusepän asennustyöt",
  "43320": "Rakennuspuusepän asennustyöt",
  "4333": "Lattianpäällystys ja seinien verhoilu",
  "43330": "Lattianpäällystys ja seinien verhoilu",
  "4334": "Maalaus ja lasitus",
  "43341": "Maalaus",
  "43342": "Lasitus",
  "4339": "Muu rakennusten viimeistely",
  "43390": "Muu rakennusten viimeistely",
  "439": "Muu erikoistunut rakennustoiminta",
  "4391": "Kattorakenteiden asennus ja kattaminen",
  "43910": "Kattorakenteiden asennus ja kattaminen",
  "4399": "Muualla luokittelematon erikoistunut rakennustoiminta",
  "43991": "Rakennuskonevuokraus käyttäjineen",
  "43999": "Muu muualla luokittelematon erikoistunut rakennustoiminta",
  G: "Tukku- ja vähittäiskauppa; moottoriajoneuvojen ja moottoripyörien korjaus",
  "45": "Moottoriajoneuvojen ja moottoripyörien tukku- ja vähittäiskauppa sekä korjaus",
  "451": "Moottoriajoneuvojen kauppa",
  "4511": "Henkilöautojen ja kevyiden moottoriajoneuvojen kauppa",
  "45111": "Henkilöautojen ja kevyiden moottoriajoneuvojen tukkukauppa",
  "45112": "Henkilöautojen ja kevyiden moottoriajoneuvojen vähittäiskauppa",
  "4519": "Muiden moottoriajoneuvojen myynti",
  "45191": "Kuorma-autojen ja muiden raskaiden moottoriajoneuvojen tukkukauppa",
  "45192": "Matkailuvaunujen ja muualla luokittelemattomien moottoriajoneuvojen vähittäiskauppa",
  "452": "Moottoriajoneuvojen huolto ja korjaus (pl. moottoripyörät)",
  "4520": "Moottoriajoneuvojen huolto ja korjaus (pl. moottoripyörät)",
  "45201": "Moottoriajoneuvojen huolto ja korjaus (pl. renkaat)",
  "45202": "Renkaiden korjaus",
  "453": "Moottoriajoneuvojen osien ja varusteiden kauppa",
  "4531": "Moottoriajoneuvojen osien ja varusteiden tukkukauppa",
  "45311": "Moottoriajoneuvojen osien ja varusteiden tukkukauppa (pl. renkaat)",
  "45312": "Renkaiden tukkukauppa",
  "4532": "Moottoriajoneuvojen osien ja varusteiden vähittäiskauppa",
  "45321": "Moottoriajoneuvojen osien ja varusteiden vähittäiskauppa (pl. renkaat)",
  "45322": "Renkaiden vähittäiskauppa",
  "454": "Moottoripyörien sekä niiden osien ja varusteiden myynti, huolto ja korjaus",
  "4540": "Moottoripyörien sekä niiden osien ja varusteiden myynti, huolto ja korjaus",
  "45401": "Moottoripyörien sekä niiden osien ja varusteiden tukkukauppa",
  "45402": "Moottoripyörien sekä niiden osien ja varusteiden vähittäiskauppa",
  "45403": "Moottoripyörien huolto ja korjaus",
  "46": "Tukkukauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa)",
  "461": "Agentuuritoiminta",
  "4611": "Maatalousraaka-aineiden, elävien eläinten, tekstiiliraaka-aineiden sekä puolivalmisteiden agentuuritoiminta",
  "46110":
    "Maatalousraaka-aineiden, elävien eläinten, tekstiiliraaka-aineiden sekä puolivalmisteiden agentuuritoiminta",
  "4612": "Polttoaineiden, malmien, metallien ja teollisuuskemikaalien agentuuritoiminta",
  "46120": "Polttoaineiden, malmien, metallien ja teollisuuskemikaalien agentuuritoiminta",
  "4613": "Puutavaran ja rakennusmateriaalien agentuuritoiminta",
  "46130": "Puutavaran ja rakennusmateriaalien agentuuritoiminta",
  "4614": "Koneiden ja laitteiden agentuuritoiminta",
  "46140": "Koneiden ja laitteiden agentuuritoiminta",
  "4615": "Huonekalujen, taloustavaroiden ja rautakauppatavaroiden agentuuritoiminta",
  "46150": "Huonekalujen, taloustavaroiden ja rautakauppatavaroiden agentuuritoiminta",
  "4616": "Tekstiilien, vaatteiden, jalkineiden ja nahkavalmisteiden agentuuritoiminta",
  "46160": "Tekstiilien, vaatteiden, jalkineiden ja nahkavalmisteiden agentuuritoiminta",
  "4617": "Elintarvikkeiden, juomien ja tupakan agentuuritoiminta",
  "46170": "Elintarvikkeiden, juomien ja tupakan agentuuritoiminta",
  "4618": "Muu erikoistunut agentuuritoiminta",
  "46181": "Paperialan agentuuritoiminta",
  "46189": "Muualla luokittelematon erikoistunut agentuuritoiminta",
  "4619": "Yleisagentuuritoiminta",
  "46190": "Yleisagentuuritoiminta",
  "462": "Maatalousperäisten raaka-aineiden ja elävien eläinten tukkukauppa",
  "4621": "Viljan, raakatupakan, siementen ja eläinrehujen tukkukauppa",
  "46210": "Viljan, raakatupakan, siementen ja eläinrehujen tukkukauppa",
  "4622": "Kukkien ja taimien tukkukauppa",
  "46220": "Kukkien ja taimien tukkukauppa",
  "4623": "Elävien eläinten tukkukauppa",
  "46230": "Elävien eläinten tukkukauppa",
  "4624": "Turkisten ja nahkojen tukkukauppa",
  "46240": "Turkisten ja nahkojen tukkukauppa",
  "463": "Elintarvikkeiden, juomien ja tupakan tukkukauppa",
  "4631": "Juures-, vihannes- marja- ja hedelmätukkukauppa",
  "46310": "Juures-, vihannes- marja- ja hedelmätukkukauppa",
  "4632": "Lihan ja lihatuotteiden tukkukauppa",
  "46320": "Lihan ja lihatuotteiden tukkukauppa",
  "4633": "Maitotaloustuotteiden, munien sekä ravintoöljyjen ja -rasvojen tukkukauppa",
  "46331": "Maitotaloustuotteiden, ravintoöljyjen ja -rasvojen tukkukauppa",
  "46332": "Munatukkukauppa",
  "4634": "Alkoholi- ja muiden juomien tukkukauppa",
  "46340": "Alkoholi- ja muiden juomien tukkukauppa",
  "4635": "Tupakkatuotteiden tukkukauppa",
  "46350": "Tupakkatuotteiden tukkukauppa",
  "4636": "Sokerin, suklaan, makeisten ja leipomotuotteiden tukkukauppa",
  "46360": "Sokerin, suklaan, makeisten ja leipomotuotteiden tukkukauppa",
  "4637": "Kahvin, teen, kaakaon ja mausteiden tukkukauppa",
  "46370": "Kahvin, teen, kaakaon ja mausteiden tukkukauppa",
  "4638": "Kalan, luontaistuotteiden ja muiden elintarvikkeiden tukkukauppa",
  "46381": "Kalatukkukauppa",
  "46382": "Luontaistuotteiden tukkukauppa",
  "46383": "Lemmikkieläinten ruokien tukkukauppa",
  "46389": "Muualla luokittelematon elintarvikkeiden tukkukauppa",
  "4639": "Elintarvikkeiden, juomien ja tupakan yleistukkukauppa",
  "46390": "Elintarvikkeiden, juomien ja tupakan yleistukkukauppa",
  "464": "Taloustavaroiden tukkukauppa",
  "4641": "Tekstiilien tukkukauppa",
  "46411": "Kangas- ja lankatukkukauppa",
  "46412": "Tekstiilivalmisteiden tukkukauppa",
  "4642": "Vaatteiden ja jalkineiden tukkukauppa",
  "46421": "Vaatteiden tukkukauppa",
  "46422": "Jalkineiden tukkukauppa",
  "4643": "Kodinkoneiden tukkukauppa",
  "46431": "Kodinkoneiden ja kodin sähkölaitteiden tukkukauppa",
  "46432": "Viihde-elektroniikan tukkukauppa",
  "46433": "Valokuvausvälineiden ja -tarvikkeiden tukkukauppa",
  "46434": "Optisen alan tukkukauppa",
  "4644": "Posliini-, lasi- ja muiden taloustavaroiden sekä puhdistusaineiden tukkukauppa",
  "46441": "Taloustavaroiden ja -tarvikkeiden tukkukauppa",
  "46442": "Puhdistusaineiden tukkukauppa",
  "4645": "Hajuvesien ja kosmetiikan tukkukauppa",
  "46450": "Hajuvesien ja kosmetiikan tukkukauppa",
  "4646": "Farmaseuttisten tuotteiden tukkukauppa",
  "46461": "Lääketukkukauppa",
  "46462": "Laboratorio- ja sairaanhoitovälineiden tukkukauppa",
  "4647": "Huonekalujen, mattojen ja valaisimien tukkukauppa",
  "46470": "Huonekalujen, mattojen ja valaisimien tukkukauppa",
  "4648": "Kellojen ja korujen tukkukauppa",
  "46480": "Kellojen ja korujen tukkukauppa",
  "4649": "Muiden taloustavaroiden tukkukauppa",
  "46491": "Paperi- ja toimistotarvikkeiden tukkukauppa",
  "46492": "Kirjatukkukauppa",
  "46493": "Urheilualan tukkukauppa",
  "46494": "Musiikkitarvikkeiden tukkukauppa",
  "46495": "Veneiden ja veneilytarvikkeiden tukkukauppa",
  "46496": "Lelujen ja pelien tukkukauppa",
  "46499": "Muu kotitaloustavaroiden tukkukauppa",
  "465": "Tieto- ja viestintäteknisten laitteiden tukkukauppa",
  "4651": "Tietokoneiden, oheislaitteiden ja ohjelmistojen tukkukauppa",
  "46510": "Tietokoneiden, oheislaitteiden ja ohjelmistojen tukkukauppa",
  "4652": "Elektroniikka- ja viestintälaitteiden ja osien tukkukauppa",
  "46521": "Viestintälaitteiden tukkukauppa",
  "46522": "Elektronisten komponenttien tukkukauppa",
  "466": "Muiden koneiden, laitteiden ja tarvikkeiden tukkukauppa",
  "4661": "Maa- ja metsätalouskoneiden ja -tarvikkeiden tukkukauppa ml. traktorit",
  "46610": "Maa- ja metsätalouskoneiden ja -tarvikkeiden tukkukauppa ml. traktorit",
  "4662": "Työstökoneiden tukkukauppa",
  "46620": "Työstökoneiden tukkukauppa",
  "4663": "Kaivos- ja rakennuskoneiden tukkukauppa",
  "46630": "Kaivos- ja rakennuskoneiden tukkukauppa",
  "4664": "Tekstiiliteollisuuden koneiden sekä ompelu- ja kutomakoneiden tukkukauppa",
  "46640": "Tekstiiliteollisuuden koneiden sekä ompelu- ja kutomakoneiden tukkukauppa",
  "4665": "Toimitilakalusteiden tukkukauppa",
  "46650": "Toimitilakalusteiden tukkukauppa",
  "4666": "Muiden konttorikoneiden ja -laitteiden tukkukauppa",
  "46660": "Muiden konttorikoneiden ja -laitteiden tukkukauppa",
  "4669": "Muiden koneiden, laitteiden ja tarvikkeiden tukkukauppa",
  "46691": "Sähkötarviketukkukauppa",
  "46692": "Teollisuudessa käytettävien muiden koneiden tukkukauppa",
  "46699": "Muualla luokittelemattomien koneiden ja laitteiden tukkukauppa",
  "467": "Muu erikoistunut tukkukauppa",
  "4671": "Kiinteiden, nestemäisten ja kaasumaisten polttoaineiden yms. tukkukauppa",
  "46711": "Nestemäisten ja kaasumaisten polttoaineiden tukkukauppa",
  "46719": "Muiden polttoaineiden tukkukauppa",
  "4672": "Raakametallien ja metallimalmien tukkukauppa",
  "46720": "Raakametallien ja metallimalmien tukkukauppa",
  "4673": "Puun, rakennusmateriaalien ja saniteettilaitteiden tukkukauppa",
  "46731": "Raakapuutukkukauppa",
  "46732": "Puutavaratuotetukkukauppa",
  "46733": "Metalli- ja mineraalituotteiden tukkukauppa",
  "46734": "Kylpyhuonekalusteiden ja -tarvikkeiden tukkukauppa",
  "46735": "Lattianpäällysteiden ja tapettien tukkukauppa",
  "46739": "Rakennustarvikkeiden yleistukkukauppa",
  "4674": "Rautakauppatavaroiden, LVI-laitteiden ja -tarvikkeiden tukkukauppa",
  "46741": "Työkalu- ja tarviketukkukauppa",
  "46742": "Lämpö-, vesi- ja ilmastointilaitteiden ja -tarvikkeiden tukkukauppa",
  "4675": "Peruskemikaalien, lannoitteiden yms. tukkukauppa",
  "46750": "Peruskemikaalien, lannoitteiden yms. tukkukauppa",
  "4676": "Muiden välituotteiden tukkukauppa",
  "46760": "Muiden välituotteiden tukkukauppa",
  "4677": "Jätteen ja romun tukkukauppa",
  "46770": "Jätteen ja romun tukkukauppa",
  "469": "Muu tukkukauppa",
  "4690": "Muu tukkukauppa",
  "46901": "Yleistukkukauppa",
  "46909": "Muualla luokittelematon tukkukauppa",
  "47": "Vähittäiskauppa (pl. moottoriajoneuvojen ja moottoripyörien kauppa)",
  "471": "Vähittäiskauppa erikoistumattomissa myymälöissä",
  "4711": "Elintarvikkeiden, juomien ja tupakan erikoistumaton vähittäiskauppa",
  "47111": "Isot supermarketit (yli 1000 m²)",
  "47112": "Pienet supermarketit (yli 400 m², enintään 1000 m²)",
  "47113": "Valintamyymälät (yli 100 m², enintään 400 m²)",
  "47114": "Elintarvike-, makeis- ym. kioskit (enintään 100 m²)",
  "4719": "Muu vähittäiskauppa erikoistumattomissa myymälöissä",
  "47191": "Itsepalvelutavaratalot (yli 2500 m²)",
  "47192": "Tavaratalot (yli 2500 m²)",
  "47199": "Pienoistavaratalot ja muut erikoistumattomat myymälät (enintään 2500 m²)",
  "472": "Elintarvikkeiden, juomien ja tupakan vähittäiskauppa erikoismyymälöissä",
  "4721": "Hedelmien, marjojen ja vihannesten vähittäiskauppa",
  "47210": "Hedelmien, marjojen ja vihannesten vähittäiskauppa",
  "4722": "Lihan ja lihatuotteiden vähittäiskauppa",
  "47220": "Lihan ja lihatuotteiden vähittäiskauppa",
  "4723": "Kalan, äyriäisten ja nilviäisten vähittäiskauppa",
  "47230": "Kalan, äyriäisten ja nilviäisten vähittäiskauppa",
  "4724": "Leipomotuotteiden ja makeisten vähittäiskauppa",
  "47241": "Leipomotuotteiden vähittäiskauppa",
  "47242": "Makeisten vähittäiskauppa",
  "4725": "Alkoholi- ja muiden juomien vähittäiskauppa",
  "47250": "Alkoholi- ja muiden juomien vähittäiskauppa",
  "4726": "Tupakkatuotteiden vähittäiskauppa",
  "47260": "Tupakkatuotteiden vähittäiskauppa",
  "4729": "Muu vähittäiskauppa erikoismyymälöissä",
  "47291": "Jäätelökioskit",
  "47292": "Luontaistuotteiden vähittäiskauppa",
  "47299": "Muu päivittäistavaroiden erikoisvähittäiskauppa",
  "473": "Ajoneuvojen polttoaineen vähittäiskauppa",
  "4730": "Ajoneuvojen polttoaineen vähittäiskauppa",
  "47301": "Huoltamotoiminta",
  "47302": "Polttoaineiden vähittäiskauppa automaateista",
  "474": "Tieto- ja viestintäteknisten laitteiden vähittäiskauppa erikoismyymälöissä",
  "4741": "Tietokoneiden, niiden oheislaitteiden ja ohjelmistojen vähittäiskauppa",
  "47410": "Tietokoneiden, niiden oheislaitteiden ja ohjelmistojen vähittäiskauppa",
  "4742": "Televiestintälaitteiden vähittäiskauppa",
  "47420": "Televiestintälaitteiden vähittäiskauppa",
  "4743": "Viihde-elektroniikan vähittäiskauppa",
  "47430": "Viihde-elektroniikan vähittäiskauppa",
  "475": "Muiden kotitaloustavaroiden vähittäiskauppa erikoismyymälöissä",
  "4751": "Tekstiilien vähittäiskauppa",
  "47511": "Kankaiden vähittäiskauppa",
  "47512": "Lankojen ja käsityötarvikkeiden vähittäiskauppa",
  "4752": "Rautakauppatavaran, maalien ja lasin vähittäiskauppa",
  "47521": "Rauta- ja rakennustarvikkeiden yleisvähittäiskauppa",
  "47522": "Maalien vähittäiskauppa",
  "47523": "Keittiö- ja saniteettitilojen kalusteiden vähittäiskauppa",
  "47529": "Muu rauta- ja rakennusalan vähittäiskauppa",
  "4753": "Mattojen, tapettien ja lattianpäällysteiden vähittäiskauppa",
  "47531": "Mattojen ja verhojen vähittäiskauppa",
  "47532": "Tapettien ja lattianpäällysteiden vähittäiskauppa",
  "4754": "Sähköisten kodinkoneiden vähittäiskauppa",
  "47540": "Sähköisten kodinkoneiden vähittäiskauppa",
  "4759":
    "Huonekalujen, valaisimien ja muualla luokittelemattomien taloustarvikkeiden vähittäiskauppa erikoismyymälöissä",
  "47591": "Huonekalujen vähittäiskauppa",
  "47592": "Sähkötarvikkeiden ja valaisimien vähittäiskauppa",
  "47593": "Kumi- ja muovitavaroiden vähittäiskauppa",
  "47594": "Taloustavaroiden vähittäiskauppa",
  "47595": "Soittimien ja musiikkitarvikkeiden vähittäiskauppa",
  "47596": "Lukkoseppä- ja avainliikkeet",
  "47599": "Muualla luokittelemattomien kotitaloustarvikkeiden vähittäiskauppa",
  "476": "Kulttuuri- ja vapaa-ajan tuotteiden vähittäiskauppa erikoismyymälöissä",
  "4761": "Kirjojen vähittäiskauppa",
  "47610": "Kirjojen vähittäiskauppa",
  "4762": "Sanomalehtien ja paperitavaran vähittäiskauppa",
  "47621": "Paperi- ja toimistotarvikkeiden vähittäiskauppa",
  "47622": "Aikakausjulkaisujen ja lehtien vähittäiskauppa",
  "4763": "Musiikki- ja videotallenteiden vähittäiskauppa",
  "47630": "Musiikki- ja videotallenteiden vähittäiskauppa",
  "4764": "Urheiluvälineiden vähittäiskauppa",
  "47641": "Urheiluvälineiden ja polkupyörien vähittäiskauppa",
  "47642": "Veneiden ja veneilytarvikkeiden vähittäiskauppa",
  "4765": "Pelien ja leikkikalujen vähittäiskauppa",
  "47650": "Pelien ja leikkikalujen vähittäiskauppa",
  "477": "Muiden tavaroiden vähittäiskauppa erikoismyymälöissä",
  "4771": "Vaatteiden vähittäiskauppa",
  "47711": "Naisten vaatteiden vähittäiskauppa",
  "47712": "Miesten vaatteiden vähittäiskauppa",
  "47713": "Lastenvaatteiden vähittäiskauppa",
  "47714": "Turkisten ja nahkavaatteiden vähittäiskauppa",
  "47715": "Lakkien ja hattujen vähittäiskauppa",
  "47719": "Vaatteiden yleisvähittäiskauppa",
  "4772": "Jalkineiden ja nahkatavaroiden vähittäiskauppa",
  "47721": "Jalkineiden vähittäiskauppa",
  "47722": "Laukkujen vähittäiskauppa",
  "4773": "Apteekit",
  "47730": "Apteekit",
  "4774": "Terveydenhoitotarvikkeiden vähittäiskauppa",
  "47740": "Terveydenhoitotarvikkeiden vähittäiskauppa",
  "4775": "Kosmetiikka- ja hygieniatuotteiden vähittäiskauppa",
  "47750": "Kosmetiikka- ja hygieniatuotteiden vähittäiskauppa",
  "4776": "Kukkien, kasvien, siementen, lannoitteiden, lemmikkieläinten ja niiden ruokien vähittäiskauppa",
  "47761": "Kukkien vähittäiskauppa",
  "47762": "Kukkakioskit",
  "47763": "Puutarha-alan vähittäiskauppa",
  "47764": "Lemmikkieläinten, niiden ruokien ja tarvikkeiden vähittäiskauppa",
  "4777": "Kultasepänteosten ja kellojen vähittäiskauppa",
  "47770": "Kultasepänteosten ja kellojen vähittäiskauppa",
  "4778": "Muu uusien tavaroiden vähittäiskauppa",
  "47781": "Taideliikkeet",
  "47782": "Valokuvausalan vähittäiskauppa",
  "47783": "Optisen alan vähittäiskauppa",
  "47784": "Lastenvaunujen ja -tarvikkeiden vähittäiskauppa",
  "47785": "Lahjatavaroiden ja askartelutarvikkeiden vähittäiskauppa",
  "47789": "Muiden uusien tavaroiden vähittäiskauppa",
  "4779": "Käytettyjen tavaroiden vähittäiskauppa myymälöissä",
  "47791": "Antiikkiliikkeet",
  "47792": "Antikvariaattikauppa",
  "47793": "Huutokauppakamarit",
  "47799": "Muiden käytettyjen tavaroiden vähittäiskauppa",
  "478": "Tori- ja markkinakauppa",
  "4781": "Elintarvikkeiden, juomien ja tupakkatuotteiden vähittäiskauppa kojuista ja toreilla",
  "47810": "Elintarvikkeiden, juomien ja tupakkatuotteiden vähittäiskauppa kojuista ja toreilla",
  "4782": "Tekstiilien, vaatteiden ja jalkineiden vähittäiskauppa kojuista ja toreilla",
  "47820": "Tekstiilien, vaatteiden ja jalkineiden vähittäiskauppa kojuista ja toreilla",
  "4789": "Muiden tavaroiden vähittäiskauppa kojuista ja toreilla",
  "47890": "Muiden tavaroiden vähittäiskauppa kojuista ja toreilla",
  "479": "Vähittäiskauppa muualla kuin myymälöissä (pl. tori- ja markkinakauppa)",
  "4791": "Vähittäiskauppa postimyyntiliikkeiden tai Internetin välityksellä",
  "47911": "Kirjojen, musiikki- ja videotallenteiden postimyynti ja verkkokauppa",
  "47912": "Vaatteiden postimyynti ja verkkokauppa",
  "47913": "Laajan valikoiman postimyynti ja verkkokauppa",
  "47919": "Muu postimyynti ja verkkokauppa",
  "4799": "Muu vähittäiskauppa muualla kuin myymälöissä",
  "47990": "Muu vähittäiskauppa muualla kuin myymälöissä",
  H: "Kuljetus ja varastointi",
  "49": "Maaliikenne ja putkijohtokuljetus",
  "491": "Rautateiden henkilöliikenne, kaukoliikenne",
  "4910": "Rautateiden henkilöliikenne, kaukoliikenne",
  "49100": "Rautateiden henkilöliikenne, kaukoliikenne",
  "492": "Rautateiden tavaraliikenne",
  "4920": "Rautateiden tavaraliikenne",
  "49200": "Rautateiden tavaraliikenne",
  "493": "Muu maaliikenteen henkilöliikenne",
  "4931": "Paikallisliikenne",
  "49310": "Paikallisliikenne",
  "4932": "Taksiliikenne",
  "49320": "Taksiliikenne",
  "4939": "Muualla luokittelematon maaliikenteen henkilöliikenne",
  "49391": "Säännöllinen linja-autojen kaukoliikenne",
  "49392": "Linja-autojen tilausliikenne",
  "49399": "Muualla luokittelematon muu maaliikenteen henkilöliikenne",
  "494": "Tieliikenteen tavarankuljetus ja muuttopalvelut",
  "4941": "Tieliikenteen tavarankuljetus",
  "49410": "Tieliikenteen tavarankuljetus",
  "4942": "Muuttopalvelut",
  "49420": "Muuttopalvelut",
  "495": "Putkijohtokuljetus",
  "4950": "Putkijohtokuljetus",
  "49500": "Putkijohtokuljetus",
  "50": "Vesiliikenne",
  "501": "Meri- ja rannikkovesiliikenteen henkilökuljetus",
  "5010": "Meri- ja rannikkovesiliikenteen henkilökuljetus",
  "50101": "Meriliikenteen henkilökuljetus",
  "50102": "Rannikkovesiliikenteen henkilökuljetus",
  "502": "Meri- ja rannikkovesiliikenteen tavarankuljetus",
  "5020": "Meri- ja rannikkovesiliikenteen tavarankuljetus",
  "50201": "Meriliikenteen tavarankuljetus",
  "50202": "Rannikkovesiliikenteen tavarankuljetus",
  "503": "Sisävesiliikenteen henkilökuljetus",
  "5030": "Sisävesiliikenteen henkilökuljetus",
  "50300": "Sisävesiliikenteen henkilökuljetus",
  "504": "Sisävesiliikenteen tavarankuljetus",
  "5040": "Sisävesiliikenteen tavarankuljetus",
  "50400": "Sisävesiliikenteen tavarankuljetus",
  "51": "Ilmaliikenne",
  "511": "Matkustajalentoliikenne",
  "5110": "Matkustajalentoliikenne",
  "51101": "Säännöllinen lentoliikenne",
  "51102": "Tilauslentoliikenne",
  "512": "Lentoliikenteen tavarankuljetus ja avaruusliikenne",
  "5121": "Lentoliikenteen tavarankuljetus",
  "51210": "Lentoliikenteen tavarankuljetus",
  "5122": "Avaruusliikenne",
  "51220": "Avaruusliikenne",
  "52": "Varastointi ja liikennettä palveleva toiminta",
  "521": "Varastointi",
  "5210": "Varastointi",
  "52100": "Varastointi",
  "522": "Liikennettä palveleva toiminta",
  "5221": "Maaliikennettä palveleva toiminta",
  "52211": "Linja-autoasemat",
  "52212": "Tieliikenteen terminaalitoiminta",
  "52213": "Maksullinen pysäköinti",
  "52219": "Muu maaliikennettä palveleva toiminta",
  "5222": "Vesiliikennettä palveleva toiminta",
  "52221": "Satamat",
  "52229": "Muu vesiliikennettä palveleva toiminta",
  "5223": "Ilmaliikennettä palveleva toiminta",
  "52230": "Ilmaliikennettä palveleva toiminta",
  "5224": "Lastinkäsittely",
  "52240": "Lastinkäsittely",
  "5229": "Muu liikennettä palveleva toiminta",
  "52291": "Huolinta ja rahtaus",
  "52299": "Muu kuljetusvälitys",
  "53": "Posti- ja kuriiritoiminta",
  "531": "Postin yleispalvelu",
  "5310": "Postin yleispalvelu",
  "53100": "Postin yleispalvelu",
  "532": "Muu posti-, jakelu- ja kuriiritoiminta",
  "5320": "Muu posti-, jakelu- ja kuriiritoiminta",
  "53200": "Muu posti-, jakelu- ja kuriiritoiminta",
  I: "Majoitus- ja ravitsemistoiminta",
  "55": "Majoitus",
  "551": "Hotellit ja vastaavat majoitusliikkeet",
  "5510": "Hotellit ja vastaavat majoitusliikkeet",
  "55101": "Hotellit",
  "55109": "Motellit, matkustajakodit ja vastaavat majoitusliikkeet",
  "552": "Lomakylät, retkeilymajat yms. majoitus",
  "5520": "Lomakylät, retkeilymajat yms. majoitus",
  "55201": "Retkeilymajat",
  "55209": "Lomakylät yms. majoitus",
  "553": "Leirintäalueet, asuntovaunu- ja matkailuvaunualueet",
  "5530": "Leirintäalueet, asuntovaunu- ja matkailuvaunualueet",
  "55300": "Leirintäalueet, asuntovaunu- ja matkailuvaunualueet",
  "559": "Muu majoitus",
  "5590": "Muu majoitus",
  "55901": "Asuntolat ja täysihoitolat yms.",
  "55902": "Maatilamatkailu, bed &amp; breakfast",
  "55903": "Lomamökkien vuokraus",
  "55909": "Muualla luokittelematon majoitustoiminta",
  "56": "Ravitsemistoiminta",
  "561": "Ravintolat ja vastaava ravitsemistoiminta",
  "5610": "Ravintolat ja vastaava ravitsemistoiminta",
  "56101": "Ravintolat",
  "56102": "Kahvila-ravintolat",
  "56103": "Ruokakioskit",
  "562": "Ateriapalvelut ja muut ravitsemispalvelut",
  "5621": "Pitopalvelu",
  "56210": "Pitopalvelu",
  "5629": "Henkilöstö- ja laitosruokalat",
  "56290": "Henkilöstö- ja laitosruokalat",
  "563": "Baarit ja kahvilat",
  "5630": "Baarit ja kahvilat",
  "56301": "Olut- ja drinkkibaarit",
  "56302": "Kahvilat ja kahvibaarit",
  J: "Informaatio ja viestintä",
  "58": "Kustannustoiminta",
  "581": "Kirjojen ja lehtien kustantaminen ja muu kustannustoiminta",
  "5811": "Kirjojen kustantaminen",
  "58110": "Kirjojen kustantaminen",
  "5812": "Hakemistojen ja postituslistojen julkaiseminen",
  "58120": "Hakemistojen ja postituslistojen julkaiseminen",
  "5813": "Sanomalehtien kustantaminen",
  "58130": "Sanomalehtien kustantaminen",
  "5814": "Aikakauslehtien ja harvemmin ilmestyvien sanomalehtien kustantaminen",
  "58141": "Paikallislehtien ja harvemmin ilmestyvien sanomalehtien kustantaminen",
  "58142": "Aikakauslehtien kustantaminen",
  "5819": "Muu kustannustoiminta",
  "58190": "Muu kustannustoiminta",
  "582": "Ohjelmistojen kustantaminen",
  "5821": "Tietokonepelien kustantaminen",
  "58210": "Tietokonepelien kustantaminen",
  "5829": "Muu ohjelmistojen kustantaminen",
  "58290": "Muu ohjelmistojen kustantaminen",
  "59": "Elokuva-, video- ja televisio-ohjelmatuotanto, äänitteiden ja musiikin kustantaminen",
  "591": "Elokuva-, video- ja televisio-ohjelmatoiminta",
  "5911": "Elokuvien, videoiden ja televisio-ohjelmien tuotanto",
  "59110": "Elokuvien, videoiden ja televisio-ohjelmien tuotanto",
  "5912": "Elokuvien, video- ja televisio-ohjelmien jälkituotanto",
  "59120": "Elokuvien, video- ja televisio-ohjelmien jälkituotanto",
  "5913": "Elokuvien, videoiden ja televisio-ohjelmien levitys",
  "59130": "Elokuvien, videoiden ja televisio-ohjelmien levitys",
  "5914": "Elokuvien esittäminen",
  "59140": "Elokuvien esittäminen",
  "592": "Äänitysstudiot; äänitteiden ja musiikin kustantaminen",
  "5920": "Äänitysstudiot; äänitteiden ja musiikin kustantaminen",
  "59200": "Äänitysstudiot; äänitteiden ja musiikin kustantaminen",
  "60": "Radio- ja televisiotoiminta",
  "601": "Radio-ohjelmien tuottaminen ja lähettäminen",
  "6010": "Radio-ohjelmien tuottaminen ja lähettäminen",
  "60100": "Radio-ohjelmien tuottaminen ja lähettäminen",
  "602": "Televisio-ohjelmien tuottaminen ja lähettäminen",
  "6020": "Televisio-ohjelmien tuottaminen ja lähettäminen",
  "60201": "Televisio-ohjelmien tuottaminen ja lähettäminen (pl. maksulliset tv-kanavat)",
  "60202": "Maksulliset tv-kanavat",
  "61": "Televiestintä",
  "611": "Langallisen verkon hallinta ja palvelut",
  "6110": "Langallisen verkon hallinta ja palvelut",
  "61100": "Langallisen verkon hallinta ja palvelut",
  "612": "Langattoman verkon hallinta ja palvelut",
  "6120": "Langattoman verkon hallinta ja palvelut",
  "61200": "Langattoman verkon hallinta ja palvelut",
  "613": "Satelliittiviestintä",
  "6130": "Satelliittiviestintä",
  "61300": "Satelliittiviestintä",
  "619": "Muut televiestintäpalvelut",
  "6190": "Muut televiestintäpalvelut",
  "61900": "Muut televiestintäpalvelut",
  "62": "Ohjelmistot, konsultointi ja siihen liittyvä toiminta",
  "620": "Ohjelmistot, konsultointi ja siihen liittyvä toiminta",
  "6201": "Ohjelmistojen suunnittelu ja valmistus",
  "62010": "Ohjelmistojen suunnittelu ja valmistus",
  "6202": "Atk-laitteisto- ja ohjelmistokonsultointi",
  "62020": "Atk-laitteisto- ja ohjelmistokonsultointi",
  "6203": "Tietojenkäsittelyn ja laitteistojen käyttö- ja hallintapalvelut",
  "62030": "Tietojenkäsittelyn ja laitteistojen käyttö- ja hallintapalvelut",
  "6209": "Muu laitteisto- ja tietotekninen palvelutoiminta",
  "62090": "Muu laitteisto- ja tietotekninen palvelutoiminta",
  "63": "Tietopalvelutoiminta",
  "631": "Tietojenkäsittely, palvelintilan vuokraus ja niihin liittyvät palvelut; verkkoportaalit",
  "6311": "Tietojenkäsittely, palvelintilan vuokraus ja niihin liittyvät palvelut",
  "63110": "Tietojenkäsittely, palvelintilan vuokraus ja niihin liittyvät palvelut",
  "6312": "Verkkoportaalit",
  "63120": "Verkkoportaalit",
  "639": "Muu tietopalvelutoiminta",
  "6391": "Uutistoimistot",
  "63910": "Uutistoimistot",
  "6399": "Muualla luokittelematon tietopalvelutoiminta",
  "63990": "Muualla luokittelematon tietopalvelutoiminta",
  K: "Rahoitus- ja vakuutustoiminta",
  "64": "Rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)",
  "641": "Pankkitoiminta",
  "6411": "Keskuspankkitoiminta",
  "64110": "Keskuspankkitoiminta",
  "6419": "Muu pankkitoiminta",
  "64190": "Muu pankkitoiminta",
  "642": "Rahoitusalan holdingyhtiöiden toiminta",
  "6420": "Rahoitusalan holdingyhtiöiden toiminta",
  "64200": "Rahoitusalan holdingyhtiöiden toiminta",
  "643": "Rahastotoiminta",
  "6430": "Rahastotoiminta",
  "64300": "Rahastotoiminta",
  "649": "Muut rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)",
  "6491": "Rahoitusleasing",
  "64910": "Rahoitusleasing",
  "6492": "Muu luotonanto",
  "64920": "Muu luotonanto",
  "6499": "Muualla luokittelemattomat rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)",
  "64990": "Muualla luokittelemattomat rahoituspalvelut (pl. vakuutus- ja eläkevakuutustoiminta)",
  "65": "Vakuutus-, jälleenvakuutus- ja eläkevakuutustoiminta (pl. pakollinen sosiaalivakuutus)",
  "651": "Vakuutustoiminta",
  "6511": "Henkivakuutustoiminta",
  "65110": "Henkivakuutustoiminta",
  "6512": "Muu vakuutustoiminta",
  "65121": "Vahinkovakuutusyhtiöt",
  "65122": "Vakuutusyhdistykset",
  "65129": "Muu vahinkovakuutus",
  "652": "Jälleenvakuutustoiminta",
  "6520": "Jälleenvakuutustoiminta",
  "65200": "Jälleenvakuutustoiminta",
  "653": "Eläkevakuutustoiminta",
  "6530": "Eläkevakuutustoiminta",
  "65300": "Eläkevakuutustoiminta",
  "66": "Rahoitusta ja vakuuttamista palveleva toiminta",
  "661": "Rahoitusta ja vakuuttamista palveleva toiminta (pl. vakuutus- ja eläkevakuutustoiminta)",
  "6611": "Pörssitoiminta ja rahoitusmarkkinoiden hallinnolliset tukipalvelut",
  "66110": "Pörssitoiminta ja rahoitusmarkkinoiden hallinnolliset tukipalvelut",
  "6612": "Arvopaperien ja raaka-ainesopimusten välittäminen",
  "66120": "Arvopaperien ja raaka-ainesopimusten välittäminen",
  "6619": "Muu rahoitusta palveleva toiminta (pl. vakuutus- ja eläkevakuutustoiminta)",
  "66190": "Muu rahoitusta palveleva toiminta (pl. vakuutus- ja eläkevakuutustoiminta)",
  "662": "Vakuutus- ja eläkevakuutustoimintaa avustava toiminta",
  "6621": "Riskin- ja vahingonarviointi",
  "66210": "Riskin- ja vahingonarviointi",
  "6622": "Vakuutusasiamiesten ja -välittäjien toiminta",
  "66220": "Vakuutusasiamiesten ja -välittäjien toiminta",
  "6629": "Muu vakuutus- ja eläkevakuutustoimintaa avustava toiminta",
  "66290": "Muu vakuutus- ja eläkevakuutustoimintaa avustava toiminta",
  "663": "Omaisuudenhoitotoiminta",
  "6630": "Omaisuudenhoitotoiminta",
  "66300": "Omaisuudenhoitotoiminta",
  L: "Kiinteistöalan toiminta",
  "68": "Kiinteistöalan toiminta",
  "681": "Omien kiinteistöjen kauppa",
  "6810": "Omien kiinteistöjen kauppa",
  "68100": "Omien kiinteistöjen kauppa",
  "682": "Omien tai leasing-kiinteistöjen vuokraus ja hallinta",
  "6820": "Omien tai leasing-kiinteistöjen vuokraus ja hallinta",
  "68201": "Asuntojen vuokraus",
  "68202": "Asuntojen ja asuinkiinteistöjen hallinta",
  "68209": "Muiden kiinteistöjen vuokraus ja hallinta",
  "683": "Kiinteistöalan toiminta palkkio- tai sopimusperusteisesti",
  "6831": "Kiinteistönvälitys",
  "68310": "Kiinteistönvälitys",
  "6832": "Kiinteistöjen isännöinti",
  "68320": "Kiinteistöjen isännöinti",
  M: "Ammatillinen, tieteellinen ja tekninen toiminta",
  "69": "Lakiasiain- ja laskentatoimen palvelut",
  "691": "Lakiasiainpalvelut",
  "6910": "Lakiasiainpalvelut",
  "69101": "Asianajotoimistot",
  "69102": "Lakiasiaintoimistot",
  "69103": "Patenttitoimistot",
  "69109": "Muu lakiasiain palvelu",
  "692": "Laskentatoimi, kirjanpito ja tilintarkastus; veroneuvonta",
  "6920": "Laskentatoimi, kirjanpito ja tilintarkastus; veroneuvonta",
  "69201": "Kirjanpito- ja tilinpäätöspalvelu",
  "69202": "Tilintarkastuspalvelu",
  "69209": "Muu laskentatoimen palvelu",
  "70": "Pääkonttorien toiminta; liikkeenjohdon konsultointi",
  "701": "Pääkonttorien toiminta",
  "7010": "Pääkonttorien toiminta",
  "70100": "Pääkonttorien toiminta",
  "702": "Liikkeenjohdon konsultointi",
  "7021": "Suhdetoiminta ja viestintä",
  "70210": "Suhdetoiminta ja viestintä",
  "7022": "Muu liikkeenjohdon konsultointi",
  "70220": "Muu liikkeenjohdon konsultointi",
  "71": "Arkkitehti- ja insinööripalvelut; tekninen testaus ja analysointi",
  "711": "Arkkitehti- ja insinööripalvelut ja niihin liittyvä tekninen konsultointi",
  "7111": "Arkkitehtipalvelut",
  "71110": "Arkkitehtipalvelut",
  "7112": "Insinööripalvelut ja niihin liittyvä tekninen konsultointi",
  "71121": "Yhdyskuntasuunnittelu",
  "71122": "Maa- ja vesirakentamisen tekninen palvelu",
  "71123": "Rakennetekninen palvelu",
  "71124": "LVI-tekninen suunnittelu",
  "71125": "Sähkötekninen suunnittelu",
  "71126": "Muu rakennustekninen palvelu",
  "71127": "Kone- ja prosessisuunnittelu",
  "71129": "Muu tekninen palvelu",
  "712": "Tekninen testaus ja analysointi",
  "7120": "Tekninen testaus ja analysointi",
  "71201": "Autokatsastus",
  "71202": "Muu tekninen testaus ja analysointi",
  "72": "Tieteellinen tutkimus ja kehittäminen",
  "721": "Luonnontieteen ja tekniikan tutkimus ja kehittäminen",
  "7211": "Biotekninen tutkimus ja kehittäminen",
  "72110": "Biotekninen tutkimus ja kehittäminen",
  "7219": "Muu luonnontieteellinen ja tekninen tutkimus ja kehittäminen",
  "72191": "Lääketieteellinen tutkimus ja kehittäminen",
  "72192": "Muu luonnontieteellinen tutkimus ja kehittäminen",
  "72193": "Tekniikan tutkimus ja kehittäminen",
  "722": "Yhteiskuntatieteellinen ja humanistinen tutkimus ja kehittäminen",
  "7220": "Yhteiskuntatieteellinen ja humanistinen tutkimus ja kehittäminen",
  "72200": "Yhteiskuntatieteellinen ja humanistinen tutkimus ja kehittäminen",
  "73": "Mainostoiminta ja markkinatutkimus",
  "731": "Mainostoiminta",
  "7311": "Mainostoimistot ja mainospalvelu",
  "73111": "Mainostoimistot",
  "73112": "Suora- ja ulkomainonta",
  "73119": "Muu mainospalvelu",
  "7312": "Mainostilan vuokraus ja myynti",
  "73120": "Mainostilan vuokraus ja myynti",
  "732": "Markkina- ja mielipidetutkimukset",
  "7320": "Markkina- ja mielipidetutkimukset",
  "73200": "Markkina- ja mielipidetutkimukset",
  "74": "Muut erikoistuneet palvelut liike-elämälle",
  "741": "Taideteollinen muotoilu ja suunnittelu",
  "7410": "Taideteollinen muotoilu ja suunnittelu",
  "74101": "Graafinen muotoilu",
  "74102": "Sisustussuunnittelu",
  "74109": "Teollinen muotoilu ym.",
  "742": "Valokuvaustoiminta",
  "7420": "Valokuvaustoiminta",
  "74201": "Valokuvaamot ja muu kuvaustoiminta",
  "74202": "Valokuvien kehittäminen",
  "743": "Kääntäminen ja tulkkaus",
  "7430": "Kääntäminen ja tulkkaus",
  "74300": "Kääntäminen ja tulkkaus",
  "749": "Muualla luokittelemattomat erikoistuneet palvelut liike-elämälle",
  "7490": "Muualla luokittelemattomat erikoistuneet palvelut liike-elämälle",
  "74901": "Ohjelmatoimistot ja manageripalvelut",
  "74909": "Muu ammatillinen, tieteellinen ja tekninen toiminta",
  "75": "Eläinlääkintäpalvelut",
  "750": "Eläinlääkintäpalvelut",
  "7500": "Eläinlääkintäpalvelut",
  "75000": "Eläinlääkintäpalvelut",
  N: "Hallinto- ja tukipalvelutoiminta",
  "77": "Vuokraus- ja leasingtoiminta",
  "771": "Moottoriajoneuvojen vuokraus ja leasing",
  "7711": "Autojen ja kevyiden moottoriajoneuvojen vuokraus ja leasing",
  "77110": "Autojen ja kevyiden moottoriajoneuvojen vuokraus ja leasing",
  "7712": "Kuorma-autojen ja muiden raskaiden ajoneuvojen vuokraus ja leasing",
  "77120": "Kuorma-autojen ja muiden raskaiden ajoneuvojen vuokraus ja leasing",
  "772": "Henkilökohtaisten ja kotitaloustavaroiden vuokraus ja leasing",
  "7721": "Vapaa-ajan ja urheiluvälineiden vuokraus ja leasing",
  "77210": "Vapaa-ajan ja urheiluvälineiden vuokraus ja leasing",
  "7722": "Videofilmien vuokraus",
  "77220": "Videofilmien vuokraus",
  "7729": "Muiden henkilökohtaisten ja kotitaloustavaroiden vuokraus ja leasing",
  "77290": "Muiden henkilökohtaisten ja kotitaloustavaroiden vuokraus ja leasing",
  "773": "Koneiden ja laitteiden vuokraus ja leasing",
  "7731": "Maatalouskoneiden ja -laitteiden vuokraus ja leasing",
  "77310": "Maatalouskoneiden ja -laitteiden vuokraus ja leasing",
  "7732": "Rakennuskoneiden ja -laitteiden vuokraus ja leasing",
  "77320": "Rakennuskoneiden ja -laitteiden vuokraus ja leasing",
  "7733": "Toimistokoneiden ja -laitteiden sekä tietokoneiden vuokraus ja leasing",
  "77330": "Toimistokoneiden ja -laitteiden sekä tietokoneiden vuokraus ja leasing",
  "7734": "Vesiliikennevälineiden vuokraus ja leasing",
  "77340": "Vesiliikennevälineiden vuokraus ja leasing",
  "7735": "Ilmaliikennevälineiden vuokraus ja leasing",
  "77350": "Ilmaliikennevälineiden vuokraus ja leasing",
  "7739": "Muiden koneiden ja laitteiden vuokraus ja leasing",
  "77390": "Muiden koneiden ja laitteiden vuokraus ja leasing",
  "774": "Henkisen omaisuuden ja vastaavien tuotteiden leasing (pl. tekijänoikeuden suojaamat teokset)",
  "7740": "Henkisen omaisuuden ja vastaavien tuotteiden leasing (pl. tekijänoikeuden suojaamat teokset)",
  "77400": "Henkisen omaisuuden ja vastaavien tuotteiden leasing (pl. tekijänoikeuden suojaamat teokset)",
  "78": "Työllistämistoiminta",
  "781": "Työnvälitystoiminta",
  "7810": "Työnvälitystoiminta",
  "78100": "Työnvälitystoiminta",
  "782": "Työvoiman vuokraus",
  "7820": "Työvoiman vuokraus",
  "78200": "Työvoiman vuokraus",
  "783": "Muut henkilöstön hankintapalvelut",
  "7830": "Muut henkilöstön hankintapalvelut",
  "78300": "Muut henkilöstön hankintapalvelut",
  "79": "Matkatoimistojen ja matkanjärjestäjien toiminta; varauspalvelut",
  "791": "Matkatoimistojen ja matkanjärjestäjien toiminta",
  "7911": "Matkatoimistojen toiminta",
  "79110": "Matkatoimistojen toiminta",
  "7912": "Matkanjärjestäjien toiminta",
  "79120": "Matkanjärjestäjien toiminta",
  "799": "Varauspalvelut, matkaoppaiden palvelut ym.",
  "7990": "Varauspalvelut, matkaoppaiden palvelut ym.",
  "79900": "Varauspalvelut, matkaoppaiden palvelut ym.",
  "80": "Turvallisuus-, vartiointi- ja etsiväpalvelut",
  "801": "Yksityiset turvallisuuspalvelut",
  "8010": "Yksityiset turvallisuuspalvelut",
  "80100": "Yksityiset turvallisuuspalvelut",
  "802": "Turvallisuusjärjestelmät",
  "8020": "Turvallisuusjärjestelmät",
  "80200": "Turvallisuusjärjestelmät",
  "803": "Etsivätoiminta",
  "8030": "Etsivätoiminta",
  "80300": "Etsivätoiminta",
  "81": "Kiinteistön- ja maisemanhoito",
  "811": "Kiinteistönhoito",
  "8110": "Kiinteistönhoito",
  "81100": "Kiinteistönhoito",
  "812": "Siivouspalvelut",
  "8121": "Kiinteistöjen siivous",
  "81210": "Kiinteistöjen siivous",
  "8122": "Muu rakennus- ja teollisuussiivous",
  "81220": "Muu rakennus- ja teollisuussiivous",
  "8129": "Muu siivoustoiminta",
  "81291": "Katujen ja teiden puhtaanapito",
  "81299": "Muualla luokittelemattomat siivouspalvelut",
  "813": "Maisemanhoitopalvelut",
  "8130": "Maisemanhoitopalvelut",
  "81300": "Maisemanhoitopalvelut",
  "82": "Hallinto- ja tukipalvelut liike-elämälle",
  "821": "Hallinto- ja toimistopalvelut",
  "8211": "Yhdistetyt toimistopalvelut",
  "82110": "Yhdistetyt toimistopalvelut",
  "8219": "Sihteeri-, toimisto- ja postituspalvelut",
  "82191": "Sihteeri- ja muu toimistopalvelu",
  "82192": "Postituspalvelut",
  "822": "Puhelinpalvelukeskusten toiminta",
  "8220": "Puhelinpalvelukeskusten toiminta",
  "82200": "Puhelinpalvelukeskusten toiminta",
  "823": "Messujen ja kongressien järjestäminen",
  "8230": "Messujen ja kongressien järjestäminen",
  "82300": "Messujen ja kongressien järjestäminen",
  "829": "Muu liike-elämää palveleva toiminta",
  "8291": "Perintä- ja luottotietopalvelut",
  "82910": "Perintä- ja luottotietopalvelut",
  "8292": "Pakkauspalvelut",
  "82920": "Pakkauspalvelut",
  "8299": "Muut palvelut liike-elämälle",
  "82990": "Muut palvelut liike-elämälle",
  O: "Julkinen hallinto ja maanpuolustus; pakollinen sosiaalivakuutus",
  "84": "Julkinen hallinto ja maanpuolustus; pakollinen sosiaalivakuutus",
  "841": "Julkinen hallinto",
  "8411": "Julkinen yleishallinto",
  "84110": "Julkinen yleishallinto",
  "8412": "Terveydenhuollon, opetuksen, kulttuurin ja muiden yhteiskuntapalvelujen hallinto",
  "84121": "Opetuksen ja kulttuuriasiain hallinto",
  "84122": "Terveydenhuollon ja sosiaaliturvan hallinto",
  "8413": "Työvoima- ja elinkeinoasiain hallinto",
  "84130": "Työvoima- ja elinkeinoasiain hallinto",
  "842": "Ulkoasiain hallinto, maanpuolustus ja järjestystoimi",
  "8421": "Ulkoasiainhallinto",
  "84210": "Ulkoasiainhallinto",
  "8422": "Maanpuolustus",
  "84220": "Maanpuolustus",
  "8423": "Oikeustoimi",
  "84231": "Oikeudenhoito",
  "84232": "Vankeinhoito",
  "8424": "Poliisitoimi ja rajojen vartiointi",
  "84241": "Poliisitoimi",
  "84242": "Rajojen vartiointi ja väestönsuojelu",
  "8425": "Palo- ja pelastustoimi",
  "84250": "Palo- ja pelastustoimi",
  "843": "Pakollinen sosiaalivakuutustoiminta",
  "8430": "Pakollinen sosiaalivakuutustoiminta",
  "84301": "Kansaneläkelaitoksen vakuutustoiminta",
  "84302": "Lakisääteinen työeläkevakuutus",
  "84309": "Muu pakollinen sosiaalivakuutustoiminta",
  P: "Koulutus",
  "85": "Koulutus",
  "851": "Esiasteen koulutus",
  "8510": "Esiasteen koulutus",
  "85100": "Esiasteen koulutus",
  "852": "Alemman perusasteen koulutus",
  "8520": "Alemman perusasteen koulutus",
  "85200": "Alemman perusasteen koulutus",
  "853": "Ylemmän perusasteen ja keskiasteen koulutus",
  "8531": "Ylemmän perusasteen koulutus ja lukiokoulutus",
  "85311": "Ylemmän perusasteen koulutus",
  "85312": "Lukiokoulutus",
  "8532": "Keskiasteen ammatillinen koulutus",
  "85320": "Keskiasteen ammatillinen koulutus",
  "854": "Korkea-asteen koulutus",
  "8541": "Korkea-asteen koulutus (pl. yliopistot ja ammattikorkeakoulut)",
  "85410": "Korkea-asteen koulutus (pl. yliopistot ja ammattikorkeakoulut)",
  "8542": "Korkea-asteen koulutus yliopistoissa ja ammattikorkeakouluissa",
  "85420": "Korkea-asteen koulutus yliopistoissa ja ammattikorkeakouluissa",
  "855": "Muu koulutus",
  "8551": "Urheilu- ja liikuntakoulutus",
  "85510": "Urheilu- ja liikuntakoulutus",
  "8552": "Taiteen ja musiikin koulutus",
  "85520": "Taiteen ja musiikin koulutus",
  "8553": "Kuljettajakoulutus",
  "85530": "Kuljettajakoulutus",
  "8559": "Muualla luokittelematon koulutus",
  "85591": "Kansanopistot, kansalaisopistot, työväenopistot yms.",
  "85592": "Koulutuskeskukset",
  "85593": "Kielikoulut ja -opistot",
  "85599": "Muut koulutusta antavat yksiköt",
  "856": "Koulutusta palveleva toiminta",
  "8560": "Koulutusta palveleva toiminta",
  "85600": "Koulutusta palveleva toiminta",
  Q: "Terveys- ja sosiaalipalvelut",
  "86": "Terveyspalvelut",
  "861": "Terveydenhuollon laitospalvelut",
  "8610": "Terveydenhuollon laitospalvelut",
  "86101": "Varsinaiset sairaalapalvelut",
  "86102": "Kuntoutuslaitokset ja sairaskodit",
  "862": "Lääkäri- ja hammaslääkäripalvelut",
  "8621": "Terveyskeskus- ja vastaavat yleislääkäripalvelut",
  "86210": "Terveyskeskus- ja vastaavat yleislääkäripalvelut",
  "8622": "Lääkäriasemat, yksityislääkärit ja vastaavat erikoislääkäripalvelut",
  "86220": "Lääkäriasemat, yksityislääkärit ja vastaavat erikoislääkäripalvelut",
  "8623": "Hammaslääkäripalvelut",
  "86230": "Hammaslääkäripalvelut",
  "869": "Muut terveydenhuoltopalvelut",
  "8690": "Muut terveydenhuoltopalvelut",
  "86901": "Fysioterapia",
  "86902": "Laboratoriotutkimukset",
  "86903": "Kuvantamistutkimukset",
  "86904": "Sairaankuljetuspalvelut",
  "86909": "Muu terveyspalvelu",
  "87": "Sosiaalihuollon laitospalvelut",
  "871": "Sosiaalihuollon hoitolaitokset",
  "8710": "Sosiaalihuollon hoitolaitokset",
  "87101": "Ikääntyneiden hoitolaitokset",
  "87102": "Vammaisten hoitolaitokset (pl. kehitysvammaisten laitospalvelut)",
  "872": "Kehitysvammaisten sekä mielenterveys- ja päihdeongelmaisten asumispalvelut",
  "8720": "Kehitysvammaisten sekä mielenterveys- ja päihdeongelmaisten asumispalvelut",
  "87201": "Kehitysvammaisten laitokset ja asumispalvelut",
  "87202": "Mielenterveysongelmaisten asumispalvelut",
  "87203": "Päihdeongelmaisten laitokset",
  "87204": "Päihdeongelmaisten asumispalvelut",
  "873": "Vanhusten ja vammaisten asumispalvelut",
  "8730": "Vanhusten ja vammaisten asumispalvelut",
  "87301": "Ikääntyneiden palveluasuminen",
  "87302": "Vammaisten palveluasuminen (pl. kehitysvammaisten palveluasuminen)",
  "879": "Muut sosiaalihuollon laitospalvelut",
  "8790": "Muut sosiaalihuollon laitospalvelut",
  "87901": "Lasten ja nuorten laitokset ja ammatillinen perhehoito",
  "87902": "Ensi- ja turvakodit",
  "87909": "Muut laitokset ja asumispalvelut",
  "88": "Sosiaalihuollon avopalvelut",
  "881": "Vanhusten ja vammaisten sosiaalihuollon avopalvelut",
  "8810": "Vanhusten ja vammaisten sosiaalihuollon avopalvelut",
  "88101": "Kotipalvelut ikääntyneille ja vammaisille",
  "88102": "Ikääntyneiden päivätoiminta",
  "88103": "Vammaisten päivä- ja työtoiminta",
  "88109": "Muut vanhusten ja vammaisten avopalvelut",
  "889": "Muut sosiaalihuollon avopalvelut",
  "8891": "Lasten päivähoitopalvelut",
  "88911": "Lasten päiväkodit",
  "88919": "Muu lasten päivähoito",
  "8899": "Muualla luokittelemattomat sosiaalihuollon avopalvelut",
  "88991": "Kotipalvelut muille kuin ikääntyneille ja vammaisille",
  "88992": "Päivä- ja työtoiminta muille kuin ikääntyneille ja vammaisille",
  "88993": "Avomuotoinen päihdekuntoutus",
  "88999": "Muut muualla luokittelemattomat sosiaalihuollon avopalvelut",
  R: "Taiteet, viihde ja virkistys",
  "90": "Kulttuuri- ja viihdetoiminta",
  "900": "Kulttuuri- ja viihdetoiminta",
  "9001": "Esittävät taiteet",
  "90010": "Esittävät taiteet",
  "9002": "Esittäviä taiteita palveleva toiminta",
  "90020": "Esittäviä taiteita palveleva toiminta",
  "9003": "Taiteellinen luominen",
  "90030": "Taiteellinen luominen",
  "9004": "Taidelaitosten toiminta",
  "90040": "Taidelaitosten toiminta",
  "91": "Kirjastojen, arkistojen, museoiden ja muiden kulttuurilaitosten toiminta",
  "910": "Kirjastojen, arkistojen, museoiden ja muiden kulttuurilaitosten toiminta",
  "9101": "Kirjastojen ja arkistojen toiminta",
  "91010": "Kirjastojen ja arkistojen toiminta",
  "9102": "Museoiden toiminta",
  "91020": "Museoiden toiminta",
  "9103": "Historiallisten nähtävyyksien, rakennusten ja vastaavien kohteiden toiminta",
  "91030": "Historiallisten nähtävyyksien, rakennusten ja vastaavien kohteiden toiminta",
  "9104": "Kasvitieteellisten puutarhojen, eläintarhojen ja luonnonpuistojen toiminta",
  "91040": "Kasvitieteellisten puutarhojen, eläintarhojen ja luonnonpuistojen toiminta",
  "92": "Rahapeli- ja vedonlyöntipalvelut",
  "920": "Rahapeli- ja vedonlyöntipalvelut",
  "9200": "Rahapeli- ja vedonlyöntipalvelut",
  "92000": "Rahapeli- ja vedonlyöntipalvelut",
  "93": "Urheilutoiminta sekä huvi- ja virkistyspalvelut",
  "931": "Urheilutoiminta",
  "9311": "Urheilulaitosten toiminta",
  "93110": "Urheilulaitosten toiminta",
  "9312": "Urheiluseurojen toiminta",
  "93120": "Urheiluseurojen toiminta",
  "9313": "Kuntokeskukset",
  "93130": "Kuntokeskukset",
  "9319": "Muu urheilutoiminta",
  "93190": "Muu urheilutoiminta",
  "932": "Huvi- ja virkistystoiminta",
  "9321": "Huvi- ja teemapuistojen toiminta",
  "93210": "Huvi- ja teemapuistojen toiminta",
  "9329": "Muu huvi- ja virkistystoiminta",
  "93291": "Hiihto- ja laskettelukeskukset",
  "93299": "Muualla luokittelematon huvi- ja virkistystoiminta",
  S: "Muu palvelutoiminta",
  "94": "Järjestöjen toiminta",
  "941": "Elinkeinoelämän, työnantaja- ja ammattialajärjestöjen toiminta",
  "9411": "Elinkeinoelämän ja työnantajajärjestöjen toiminta",
  "94110": "Elinkeinoelämän ja työnantajajärjestöjen toiminta",
  "9412": "Ammattialajärjestöjen toiminta",
  "94120": "Ammattialajärjestöjen toiminta",
  "942": "Ammattiyhdistysten toiminta",
  "9420": "Ammattiyhdistysten toiminta",
  "94200": "Ammattiyhdistysten toiminta",
  "949": "Muiden järjestöjen toiminta",
  "9491": "Seurakunnat ja uskonnolliset järjestöt",
  "94910": "Seurakunnat ja uskonnolliset järjestöt",
  "9492": "Poliittiset järjestöt",
  "94920": "Poliittiset järjestöt",
  "9499": "Muut järjestöt",
  "94991": "Tutkimusta ja kulttuuria palvelevat järjestöt",
  "94999": "Muualla luokittelemattomat muut järjestöt",
  "95": "Tietokoneiden, henkilökohtaisten ja kotitaloustavaroiden korjaus",
  "951": "Tietokoneiden ja viestintälaitteiden korjaus",
  "9511": "Tietokoneiden ja niiden oheislaitteiden korjaus",
  "95110": "Tietokoneiden ja niiden oheislaitteiden korjaus",
  "9512": "Viestintälaitteiden korjaus",
  "95120": "Viestintälaitteiden korjaus",
  "952": "Henkilökohtaisten ja kotitaloustavaroiden korjaus",
  "9521": "Viihde-elektroniikan korjaus",
  "95210": "Viihde-elektroniikan korjaus",
  "9522": "Kotitalouskoneiden sekä kodin ja puutarhan laitteiden korjaus",
  "95220": "Kotitalouskoneiden sekä kodin ja puutarhan laitteiden korjaus",
  "9523": "Jalkineiden ja nahkatavaroiden korjaus",
  "95230": "Jalkineiden ja nahkatavaroiden korjaus",
  "9524": "Huonekalujen ja kodin kalusteiden korjaus",
  "95240": "Huonekalujen ja kodin kalusteiden korjaus",
  "9525": "Kellojen ja korujen korjaus",
  "95250": "Kellojen ja korujen korjaus",
  "9529": "Muiden henkilökohtaisten ja kotitaloustavaroiden korjaus",
  "95290": "Muiden henkilökohtaisten ja kotitaloustavaroiden korjaus",
  "96": "Muut henkilökohtaiset palvelut",
  "960": "Muut henkilökohtaiset palvelut",
  "9601": "Pesulapalvelut",
  "96011": "Pesulapalvelut yrityksille",
  "96012": "Pesulapalvelut kotitalouksille",
  "9602": "Kampaamo- ja kauneudenhoitopalvelut",
  "96021": "Parturit ja kampaamot",
  "96022": "Kauneudenhoitopalvelut",
  "9603": "Hautaustoimistojen palvelut",
  "96030": "Hautaustoimistojen palvelut",
  "9604": "Kylpylaitokset, saunat, solariumit yms. palvelut",
  "96040": "Kylpylaitokset, saunat, solariumit yms. palvelut",
  "9609": "Muualla luokittelemattomat henkilökohtaiset palvelut",
  "96090": "Muualla luokittelemattomat henkilökohtaiset palvelut",
  T:
    "Kotitalouksien toiminta työnantajina; kotitalouksien eriyttämätön toiminta tavaroiden ja palvelujen tuottamiseksi omaan käyttöön",
  "97": "Kotitalouksien toiminta kotitaloustyöntekijöiden työnantajina",
  "970": "Kotitalouksien toiminta kotitaloustyöntekijöiden työnantajina",
  "9700": "Kotitalouksien toiminta kotitaloustyöntekijöiden työnantajina",
  "97000": "Kotitalouksien toiminta kotitaloustyöntekijöiden työnantajina",
  "98": "Kotitalouksien eriyttämätön toiminta tavaroiden ja palvelujen tuottamiseksi omaan käyttöön",
  "981": "Kotitalouksien eriyttämätön toiminta tavaroiden tuottamiseksi omaan käyttöön",
  "9810": "Kotitalouksien eriyttämätön toiminta tavaroiden tuottamiseksi omaan käyttöön",
  "98100": "Kotitalouksien eriyttämätön toiminta tavaroiden tuottamiseksi omaan käyttöön",
  "982": "Kotitalouksien eriyttämätön toiminta palvelujen tuottamiseksi omaan käyttöön",
  "9820": "Kotitalouksien eriyttämätön toiminta palvelujen tuottamiseksi omaan käyttöön",
  "98200": "Kotitalouksien eriyttämätön toiminta palvelujen tuottamiseksi omaan käyttöön",
  U: "Kansainvälisten organisaatioiden ja toimielinten toiminta",
  "99": "Kansainvälisten organisaatioiden ja toimielinten toiminta",
  "990": "Kansainvälisten organisaatioiden ja toimielinten toiminta",
  "9900": "Kansainvälisten organisaatioiden ja toimielinten toiminta",
  "99000": "Kansainvälisten organisaatioiden ja toimielinten toiminta",
  X: "Toimiala tuntematon",
  "00": "Toimiala tuntematon",
  "000": "Toimiala tuntematon",
  "0000": "Toimiala tuntematon",
  "00000": "Toimiala tuntematon",
};
