import React from 'react';
import {withTranslation} from 'react-i18next';
import {useField, useFormikContext} from 'formik';
import {Form} from 'react-bootstrap';
import useOmsenCustomerCategories from '../../hooks/useOmsenCustomerCategories';
import Select from 'react-select';
import {selectCustomStyle} from '../../styles/selectCustomStyle';



const FormikCustomerCategoryPicker = ({...props}: any) => {
  const { data:  customerCategories, isLoading } = useOmsenCustomerCategories()
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const isInvalid = Boolean(!!formikProps.getFieldMeta(field.name).error && formikProps.getFieldMeta(field.name).touched);

  return (
    <Form.Group className={"FormikCustomerCategoryPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Select
        options={customerCategories}
        getOptionValue={(option) => option.code?.toString() ?? ""}
        getOptionLabel={(option) => option.text ?? ""}
        isLoading={isLoading}
        isMulti={false}
        onChange={(newValue) => formikProps.setFieldValue(field.name, newValue?.code)}
        value={customerCategories ? customerCategories.find((option) => option.code === field.value) : { code: "", text: "" }}
        styles={selectCustomStyle(isInvalid)}
        className={isInvalid ? "is-invalid" : "is-valid"}
        isDisabled={props.disabled}
      />
      <Form.Control.Feedback type="invalid">
        {String(formikProps.getFieldMeta(field.name).error) ?? undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikCustomerCategoryPicker);

