import React, { useContext } from "react";
import accordionUpIcon from "../../assets/accordion_up_icon.svg";
import accordionDownIcon from "../../assets/accordion_down_icon.svg";
import accordionValidIcon from "../../assets/check_circle_green_icon.svg";
import accordionInvalidIcon from "../../assets/feedback_red_icon.svg";
import { AccordionContext, useAccordionButton} from "react-bootstrap";

const AppSidebarAccordionHeader = ({ children, eventKey, callback, validity }: any) => {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  const isCurrentEventKey = currentEventKey === eventKey;
  let extraIcon = null;
  if (validity !== undefined) {
    if (validity === true) {
      extraIcon = <span> {<img className={"validityIcon"} src={accordionValidIcon} alt={"Valid"} />}</span>;
    } else {
      extraIcon = <span> {<img className={"validityIcon"} src={accordionInvalidIcon} alt={"Invalid"} />}</span>;
    }
  }

  return (
    <div className={"AppSidebarAccordionHeader clickable"} onClick={decoratedOnClick}>
      <span className={"AppSidebarAccordionHeader-icon"}>
        {isCurrentEventKey === false ? (
          <img className={"up"} src={accordionUpIcon} alt={"hide"} />
        ) : (
          <img className={"down"} src={accordionDownIcon} alt={"show"} />
        )}
      </span>
      <span className={"AppSidebarAccordionHeader-title"}>
        {children}
        {extraIcon}
      </span>
    </div>
  );
};

export default AppSidebarAccordionHeader;
