import React, { useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";
import { DomainEntitiesProspectOmsenData } from "../../../api";
import { IProspect, OmsenState } from "../../../contracts/data/IProspect";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import FormikProductPicker from "../../shared/FormikProductPicker";
import FormikOccupation from "../../shared/FormikOccupation";
import FormikEmployersPicker from "../../shared/FormikEmployersPicker";
import * as Yup from "yup";
import AlertAutoDismissible from "../../shared/AlertAutoDismissible";
import { TUpdateProspectAction } from "../../../redux/actions/prospect";
import FormikIndustrialClassificationPicker from "../../shared/FormikIndustrialClassificationPicker";
import FormikInput from "../../shared/FormikInput";
import FormikCountryPicker from "../../shared/FormikCountryPicker";
import FormikLanguagePicker from "../../shared/FormikLanguagePicker";
import FormikCustomerCategoryPicker from "../../shared/FormikCustomerCatergoryPicker";
import NaturalPerson from "../../shared/NaturalPerson";
import LegalPerson from "../../shared/LegalPerson";
import ModalConfirm from "../../shared/ModalConfirm";
import { InsuranceType } from "../../../contracts/data/IInsurance";
import { validateEmail } from "../../../utils/validatorUtils";
import { parsePhoneNumber } from "awesome-phonenumber";
import OmsenMaintenanceWindow from "../../shared/OmsenMaintenanceWindow";
import useOmsenMaintenanceWindow from "../../../hooks/useOmsenMaintenanceWindow";
import FormikDatePicker from "../../shared/FormikDatePicker";
import moment from "moment/moment";
import { hasFormikError, isValueNullOrEmptyString, mapToOmsenCompanyType } from "../../../utils/appUtils";
import PromptMessage, { SetPromptMessageFunc } from "../../shared/PromptMessage";

const createEmptyOmsenDataObject = (prospect?: IProspect): DomainEntitiesProspectOmsenData => ({
  startDate: null,
  industrialClassificationCode: prospect?.businessIndustryCode,
  productCodes: [],
  employeePayrollDetails: [],
  employerAnnualEarningsDetails: [],
  proposal: {
    invoiceReceiverName: null,
    claimReceiverName: null,
    internalNote: null,
    policyHolder: {
      customerCategoryCode: null,
      fullName: prospect?.companyName ?? null,
      ssn: null,
      postalAddress: prospect?.companyAddress ?? null,
      postalCode: prospect?.companyZipCode ?? null,
      city: prospect?.companyCity ?? null,
      countryCode: null,
      languageCode: null,
      phone: prospect?.companyPhone ?? null,
      mobile: null,
      email: prospect?.companyEmail ?? null,
      kycNaturalPerson: {
        taxDomicileCode: null,
        countryOfBirthNumber: 0,
        citizenshipNumbers: [],
        politicalExposedPerson: false,
      },
      kycLegalPerson: {
        industrialClassificationCode: null,
        countryOfRegistrationNumber: 0,
        countriesOfTaxationNumbers: [],
        contactPersonName: null,
        contactPersonPhone: null,
        contactPersonEmail: null,
        realBeneficiaries: [],
      },
    },
  },
  errors: null,
});

export type TFormValues = {
  omsenData: DomainEntitiesProspectOmsenData | null;
  omsenState: number | null;
};

type TProps = {
  isSubmitting: boolean;
  prospect: IProspect;
  updateProspect: TUpdateProspectAction;
  setPromptMessage: SetPromptMessageFunc;
} & WithTranslation;

type TSubmitState = {
  submitResultMessage: string;
  submitResultSuccess: boolean;
};
const OmsenForm: React.FC<TProps> = ({ prospect, t, updateProspect, isSubmitting, setPromptMessage }) => {
  const [submitState, setSubmitState] = useState<TSubmitState>({ submitResultMessage: "", submitResultSuccess: true });
  const [show, setShow] = useState(false);
  const { data: maintenanceWindowData } = useOmsenMaintenanceWindow();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const initialValues: TFormValues = {
    omsenData: prospect.omsenData ?? createEmptyOmsenDataObject(prospect),
    omsenState: prospect.omsenState,
  };

  const getOmsenInsurance = () => {
    const insuranceDetails = prospect.insurances.find((insurance) => insurance.type === InsuranceType.Omsen)?.details;
    if (insuranceDetails && insuranceDetails?.length > 0) return true;
  };

  const validationSchema = Yup.object().shape({
    omsenState: Yup.number().notRequired(),
    omsenData: Yup.object().when("omsenState", ([omsenState], schema) => {
      const premiumCostCalculationObject = schema.shape({
        productCodes: Yup.array().notRequired(),
        startDate: Yup.date().when("productCodes", ([productCodes], schema) => {
          if (productCodes.length > 0) {
            return schema
              .required(t("omsen_start_date_required"))
              .min(moment().startOf("day").add(1, "day"), t("omsen_start_date_tomorrow_or_future"));
          }
          return schema.notRequired();
        }),
        industrialClassificationCode: Yup.number().when("productCodes", ([productCodes], schema) => {
          if (productCodes.length > 0) return schema.required(t("Industrial classification") + t("is required"));
          return schema.notRequired();
        }),
        employeePayrollDetails: Yup.array().when("productCodes", ([productCodes], schema) => {
          if (productCodes?.includes(211)) {
            return schema.min(1, t("At least one occupation is required")).of(
              Yup.object().shape({
                totalPayrollAmount: Yup.number()
                  .required(t("Required"))
                  .min(1, t("Total payroll must be greater than 0")),
              }),
            );
          }
          return schema;
        }),
        employerAnnualEarningsDetails: Yup.array().when("productCodes", ([productCodes], schema) => {
          if (productCodes?.includes(212)) {
            return schema
              .min(1, t("At least one employer is required"))
              .max(3, t("A maximum of three employers is allowed"))
              .of(
                Yup.object().shape({
                  fullName: Yup.mixed().required(t("Full name required!")),
                  ssn: Yup.string().required(t("Ssn required!")),
                  annualEarningsAmount: Yup.number().min(1, t("Must be greater than 0")),
                  occupationAndEarningPercentages: Yup.array()
                    .of(
                      Yup.object().shape({
                        percentageOfAnnualEarnings: Yup.number()
                          .required(t("Required"))
                          .min(1, t("Must be greater than 0"))
                          .max(100, t("Must be less than 100")),
                      }),
                    )
                    .min(1, t("At least one occupation is required"))
                    .test("sum-of-percentage", t("The sum of the percentages must be 100"), (values) => {
                      const sum =
                        values?.reduce((acc, value) => {
                          return acc + value.percentageOfAnnualEarnings;
                        }, 0) || 0;
                      return sum === 100;
                    }),
                }),
              );
          }
          return schema;
        }),
      });
      if (omsenState === OmsenState.PendingOffer) {
        return premiumCostCalculationObject.shape({
          proposal: Yup.object().shape({
            invoiceReceiverName: Yup.string().required(t("Invoice receiver name") + t("is required")),
            claimReceiverName: Yup.string().required(t("Claim receiver name") + t("is required")),
            policyHolder: Yup.object().shape({
              customerCategoryCode: Yup.string().required(t("Customer category") + t("is required")),
              fullName: Yup.string().required(t("Name") + t("is required")),
              ssn: Yup.string().required(t("Business ID/Ssn") + t("is required")),
              postalAddress: Yup.string().required(t("Postal address") + t("is required")),
              postalCode: Yup.string().required(t("Postal code") + t("is required")),
              city: Yup.string().required(t("City") + t("is required")),
              countryCode: Yup.string().required(t("County") + t("is required")),
              languageCode: Yup.string().required(t("Language") + t("is required")),
              kycNaturalPerson: Yup.object().when("customerCategoryCode", ([customerCategoryCode], schema) => {
                if (["EF", "Private"].includes(customerCategoryCode)) {
                  return schema.shape({
                    taxDomicileCode: Yup.string().required(t("Tax domicile") + t("is required")),
                    countryOfBirthNumber: Yup.number().min(1, t("Country of birth") + t("is required")),
                    citizenshipNumbers: Yup.array().of(Yup.number()).min(1, t("At least 1 citizenship is required")),
                  });
                }
                return schema;
              }),
              kycLegalPerson: Yup.object().when("customerCategoryCode", ([customerCategoryCode], schema) => {
                if (["ÖB", "AB", "KB"].includes(customerCategoryCode)) {
                  return schema.shape({
                    industrialClassificationCode: Yup.string().required(
                      t("Industrial classification") + t("is required"),
                    ),
                    countriesOfTaxationNumbers: Yup.array()
                      .of(Yup.number())
                      .min(1, t("At least 1 taxation country") + t("is required")),
                    contactPersonName: Yup.string().required(t("Name") + t("is required")),
                    contactPersonPhone: Yup.string()
                      .nullable()
                      .test("phone-number", t("Invalid phone number"), function (value) {
                        if (!value) return true;
                        const regions = ["FI", "SE"];
                        const validPhone = regions.some((region) => {
                          const phone = parsePhoneNumber(value, { regionCode: region });
                          return phone.valid;
                        });
                        return validPhone;
                      }),
                    contactPersonEmail: Yup.string()
                      .nullable()
                      .test("test-email", t("Invalid email address"), function (value) {
                        if (!value) return true;
                        return validateEmail(value);
                      }),
                  });
                }
                return schema;
              }),
            }),
          }),
        });
      }
      return premiumCostCalculationObject;
    }),
  });

  const handleSubmit = async (values: TFormValues) => {
    setSubmitState({ submitResultSuccess: true, submitResultMessage: "" });
    if (values.omsenData) {
      values.omsenData.errors = "";
    }

    const result = await updateProspect(prospect.id, { ...values });

    if (result !== null && !result?.omsenData?.errors) {
      setSubmitState({ submitResultSuccess: true, submitResultMessage: t("Successfully updated") });
    } else {
      if (result?.omsenData?.errors) {
        setSubmitState({
          submitResultSuccess: false,
          submitResultMessage: `${t("Unable to update")}: ${result?.omsenData?.errors}`,
        });
      } else {
        setSubmitState({ submitResultSuccess: false, submitResultMessage: t("Unable to update") });
      }
    }

    handleClose();
  };

  const openProposalFormWithDefaultValues = async (formikProps: FormikProps<TFormValues>) => {

    await formikProps.setFieldValue("omsenState", OmsenState.PendingOffer);
    if (prospect.firstName && prospect.lastName) {
      await formikProps.setFieldValue(
        "omsenData.proposal.invoiceReceiverName",
        prospect.firstName + " " + prospect.lastName,
      );
      await formikProps.setFieldValue(
        "omsenData.proposal.claimReceiverName",
        prospect.firstName + " " + prospect.lastName,
      );
    }

    if (initialValues.omsenData) {
      const { proposal } = initialValues.omsenData;
      if (proposal) {
        if (proposal.policyHolder) {
          if (isValueNullOrEmptyString(proposal.policyHolder.fullName)) {
            await formikProps.setFieldValue("omsenData.proposal.policyHolder.fullName", prospect.companyName);
          }

          if (isValueNullOrEmptyString(proposal.policyHolder.ssn)) {
            await formikProps.setFieldValue("omsenData.proposal.policyHolder.ssn", prospect.companyId);
          }

          if (isValueNullOrEmptyString(proposal.policyHolder.postalAddress)) {
            await formikProps.setFieldValue("omsenData.proposal.policyHolder.postalAddress", prospect.companyAddress);
          }

          if (isValueNullOrEmptyString(proposal.policyHolder.postalCode)) {
            await formikProps.setFieldValue("omsenData.proposal.policyHolder.postalCode", prospect.companyZipCode);
          }

          if (isValueNullOrEmptyString(proposal.policyHolder.city)) {
            await formikProps.setFieldValue("omsenData.proposal.policyHolder.city", prospect.companyCity);
          }

          if (isValueNullOrEmptyString(proposal.policyHolder.phone)) {
            await formikProps.setFieldValue("omsenData.proposal.policyHolder.phone", prospect.companyPhone);
          }

          if (isValueNullOrEmptyString(proposal.policyHolder.email)) {
            await formikProps.setFieldValue("omsenData.proposal.policyHolder.email", prospect.companyEmail);
          }

          if (isValueNullOrEmptyString(proposal.policyHolder.customerCategoryCode)) {
            await formikProps.setFieldValue(
              "omsenData.proposal.policyHolder.customerCategoryCode",
              mapToOmsenCompanyType(prospect.companyType),
            );
          }
        }
      }
    }
  };

  const initialValueWithDefaultIndustrialClassificationCode: TFormValues = {
    ...initialValues,
    omsenData: {
      ...initialValues.omsenData,
      industrialClassificationCode: prospect.businessIndustryCode,
    },
  };

  return (
    <>
      <OmsenMaintenanceWindow />
      {!maintenanceWindowData?.isActive && (
        <Formik
          enableReinitialize={true}
          initialValues={
            isValueNullOrEmptyString(initialValues?.omsenData?.industrialClassificationCode)
              ? initialValueWithDefaultIndustrialClassificationCode
              : initialValues
          }
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await handleSubmit(values);
          }}
        >
          {(formikProps) => (
            <Form autoComplete={"off"} onSubmit={(e: any) => formikProps.handleSubmit(e)}>
              <Form.Group className="form-group mb-0">
                <Form.Label>{t("omsen_start_date")}</Form.Label>
                <FormikDatePicker
                  name={"omsenData.startDate"}
                  lastValidYear={Number(moment().format("YYYY")) + 1}
                  disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                />
              </Form.Group>
              <FormikIndustrialClassificationPicker
                name={"omsenData.industrialClassificationCode"}
                title={t("Industrial classification")}
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              <FormikProductPicker
                name={"omsenData.productCodes"}
                title={t("Products")}
                disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
              />
              {formikProps.values.omsenData?.productCodes?.includes(211) && (
                <FormikOccupation
                  name={"omsenData.employeePayrollDetails"}
                  title={t("Occupation of the employee")}
                  disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                />
              )}
              {formikProps.values.omsenData?.productCodes?.includes(212) && (
                <FormikEmployersPicker
                  name="omsenData.employerAnnualEarningsDetails"
                  title={t("Employers")}
                  disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                  prospect={prospect}
                />
              )}
              {formikProps.values.omsenState === OmsenState.PremiumCalculation && getOmsenInsurance() && (
                <Col className={"pb-4"}>
                  <Button className={"w-100"} onClick={() => openProposalFormWithDefaultValues(formikProps)}>
                    {t("Create insurance proposal")}
                  </Button>
                </Col>
              )}
              {formikProps.values.omsenState !== OmsenState.PremiumCalculation && (
                <>
                  <Form.Group className="form-group">
                    <Form.Label>{t("Internal note")}</Form.Label>
                    <Form.Control
                      name={"omsenData.proposal.internalNote"}
                      as={"textarea"}
                      placeholder={""}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.omsenData?.proposal?.internalNote || ""}
                      disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                    />
                  </Form.Group>
                  <FormikCustomerCategoryPicker
                    name={"omsenData.proposal.policyHolder.customerCategoryCode"}
                    title={t("Customer category")}
                    disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                  />
                  <FormikInput
                    name={"omsenData.proposal.policyHolder.fullName"}
                    title={t("Company name")}
                    disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                  />
                  <FormikInput
                    name={"omsenData.proposal.policyHolder.ssn"}
                    title={t("Business ID")}
                    disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                  />
                  <FormikInput
                    name={"omsenData.proposal.policyHolder.postalAddress"}
                    title={t("Postal address")}
                    disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                  />
                  <Row>
                    <FormikInput
                      as={Col}
                      xs={5}
                      name={"omsenData.proposal.policyHolder.postalCode"}
                      title={t("Postal code")}
                      disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                    />
                    <FormikInput
                      as={Col}
                      xs={7}
                      name={"omsenData.proposal.policyHolder.city"}
                      title={t("City")}
                      value={prospect.companyCity}
                      disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                    />
                  </Row>
                  <FormikInput
                    name={"omsenData.proposal.policyHolder.phone"}
                    title={t("Phonenumber")}
                    disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                  />
                  <FormikInput
                    name={"omsenData.proposal.policyHolder.email"}
                    title={t("Email")}
                    disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                  />
                  <FormikCountryPicker
                    name={"omsenData.proposal.policyHolder.countryCode"}
                    title={t("Country")}
                    disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                  />
                  <FormikLanguagePicker
                    name={"omsenData.proposal.policyHolder.languageCode"}
                    title={t("Language")}
                    disabled={formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                  />
                  {["EF", "Private"].includes(
                    formikProps.getFieldProps("omsenData.proposal.policyHolder.customerCategoryCode").value,
                  ) && <NaturalPerson name={"omsenData.proposal.policyHolder.kycNaturalPerson"} />}
                  {["ÖB", "AB", "KB"].includes(
                    formikProps.getFieldProps("omsenData.proposal.policyHolder.customerCategoryCode").value,
                  ) && <LegalPerson name={"omsenData.proposal.policyHolder.kycLegalPerson"} prospect={prospect} />}
                </>
              )}
              <Form.Group className="form-group">
                <Button
                  variant="primary"
                  type="submit"
                  className={"w-100"}
                  disabled={isSubmitting || formikProps.values.omsenState === OmsenState.ConfirmedOffer}
                >
                  {isSubmitting && (
                    <>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                    </>
                  )}
                  {t("Save")}
                </Button>
                {formikProps.values.omsenState === OmsenState.PendingOffer &&
                  !formikProps.errors.omsenData &&
                  !formikProps.values.omsenData?.errors && (
                    <Button className={"w-100 mt-2"} onClick={handleShow} disabled={isSubmitting}>
                      {t("Confirm insurance proposal")}
                    </Button>
                  )}
                {submitState.submitResultMessage && (
                  <AlertAutoDismissible
                    variant={submitState.submitResultSuccess ? "success" : "danger"}
                    className={"mt-2"}
                  >
                    {submitState.submitResultMessage}
                  </AlertAutoDismissible>
                )}
              </Form.Group>
              <PromptMessage
                setPromptMessage={setPromptMessage}
                message={{
                  place: t("ÅLANDS FÖRSÄKRINGAR AB"),
                  changes: hasFormikError(formikProps.errors) ? null : formikProps.values,
                }}
                enabled={formikProps.dirty}
              />
              <ModalConfirm
                show={show}
                title={t("Confirm insurance proposal")}
                bodyText={t("confirm_omsen_insurance_proposal_text")}
                onAccept={async () => {
                  const newValues = {
                    ...formikProps.values,
                    omsenState: OmsenState.ConfirmedOffer,
                  };
                  await handleSubmit(newValues);
                }}
                onReject={handleClose}
                yesButtonText={t("Confirm")}
                noButtonText={t("Cancel")}
              />
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default withTranslation("translations")(OmsenForm);
