import useOmsenApiClient from "./clients/useOmsenApiClient";
import { useQuery } from "@tanstack/react-query";

export default function useOmsenCountries() {
  const client = useOmsenApiClient();

  const getOmsenCountries = async () => {
    const response = await client.apiOmsenCountriesGet();
    return response.data;
  };

  return useQuery(["omsenCountries"], getOmsenCountries, {
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
