import React from "react";
import { Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { withTranslation } from "react-i18next";

const FormikYelDiscountPicker = ({ ...props }: any) => {
  const { t } = props;
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const getValidTypes = () => {
    const result = [];
    result.push([0, t("0 %")]);
    result.push([22, t("22 %")]);

    return result;
  };

  const handleChange = (e: any) => {
    const value = e.currentTarget.value;

    formikProps.setFieldTouched(field.name, true, false);
    formikProps.setFieldError(field.name, "");
    formikProps.setFieldValue(field.name, value ? Number(value) : value);
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);

  return (
    <Form.Group className={"FormikYelDiscountPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Form.Control
        name={field.name}
        as={"select"}
        value={field.value != null ? field.value : undefined}
        onChange={(e) => handleChange(e)}
        isInvalid={isInvalid}
      >
        {field.value === "" || field.value === null ? <option value={""} key={"-"}></option> : null}
        {getValidTypes().map((x) => (
          <option value={x[0]} key={x[0]}>
            {x[1]}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikYelDiscountPicker);
