import { IInsuranceDetail } from "../contracts/data/IInsuranceDetail";
import { IErrorResponse } from "../contracts/IErrorResponse";
import moment, { Moment } from "moment";
import { SalesState } from "../contracts/data/IProspectBase";
import { BusinessProfit, BusinessRevenue } from "../contracts/data/IProspect";
import { Configuration } from "../api";
import { LocalStorageService } from "../services/LocalStorageService";
import { FormikErrors, FormikValues } from "formik";

export const isDevEnv = () => {
  return process.env.NODE_ENV === "development";
};

export const isTestEnv = () => {
  return process.env.NODE_ENV === "test";
};

export const getApiBaseUrl = () => {
  const BASE_URL = "http://localhost:5000";
  if (isDevEnv()) {
    return BASE_URL;
  }
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "")
  );
};

export const getBaseUrl = () => {
  const BASE_URL = "http://localhost:3000";
  if (isDevEnv()) {
    return BASE_URL;
  }
  return (
    window.location.protocol +
    "//" +
    window.location.hostname +
    (window.location.port ? ":" + window.location.port : "")
  );
};

export const delayMs = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const generateRandomId = () =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export type TFindInsuranceDetailResult = {
  data: IInsuranceDetail;
  parents: IInsuranceDetail[];
};

export const findInsuranceDetail = (
  id: string,
  rows: IInsuranceDetail[],
  parents: IInsuranceDetail[] = [],
): TFindInsuranceDetailResult | undefined => {
  let data = rows.find((x) => x.id === id);
  if (data !== undefined) {
    return {
      data,
      parents,
    };
  }

  for (let i = 0; i < rows.length; i++) {
    const result = findInsuranceDetail(id, rows[i].children, [...parents, rows[i]]);
    if (result !== undefined) {
      return result;
    }
  }

  return undefined;
};

export const translateErrorResponses = (errors: IErrorResponse[], t: (key: string) => string): IErrorResponse[] => {
  return errors.map((error) => {
    const translatedError = { ...error };
    const translateKey = error.code + "_Message";
    const translatedMessage = t(translateKey);
    if (translatedMessage !== translateKey) {
      translatedError.message = translatedMessage;
    }
    if (translatedError.property !== null) {
      translatedError.property = toCamelCase(translatedError.property);
    }
    return translatedError;
  });
};

export const applyTranslatedErrorsToCreateUser = (errors: IErrorResponse[] | IErrorResponse, t: any, values: any, formikHelpers: any) => {
  if(Array.isArray(errors)){
    const translatedErrors = translateErrorResponses(errors, t);
    if (translatedErrors.length > 0) {
      const concatenatedErrorMessage = translatedErrors
        .map((e) => {
          if (e.property !== null && Object.getOwnPropertyNames(values).includes(e.property)) {
            formikHelpers.setFieldError(e.property, e.message);
            return "";
          } else {
            return e.message;
          }
        })
        .filter((s) => s.length > 0)
        .join(", ");

      if (concatenatedErrorMessage.length > 0) {
        return concatenatedErrorMessage;
      } else {
        return ""
      }
    }
  } else {
    return t(`${errors.code}_Message`);
  }
};

export const getRandomNumber = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getFullName = (firstname: string, lastname: string): string => {
  return firstname + " " + lastname;
};

export const toCamelCase = (value: string | null | undefined): string => {
  if (value === null || value === undefined) {
    return "";
  }
  return value.charAt(0).toLowerCase() + value.substr(1);
};

export const getValidSalesStates = (): string[] => {
  const result = [];
  for (let state in SalesState) {
    if (isNaN(Number(state))) {
      result.push(state);
    }
  }
  return result;
};

export const getValidRevenueValues = () => {
  const result = [];

  result.push([BusinessRevenue.Tier1, "0 - 250000"]);
  result.push([BusinessRevenue.Tier2, "250000 - 500000"]);
  result.push([BusinessRevenue.Tier3, "500000 - 750000"]);
  result.push([BusinessRevenue.Tier4, "750000 - 1000000"]);
  result.push([BusinessRevenue.Tier5, "1000000+"]);

  return result;
};

export const getValidProfitValues = () => {
  const result = [];

  result.push([BusinessProfit.Tier1, "0 - 50000"]);
  result.push([BusinessProfit.Tier2, "50000 - 100000"]);
  result.push([BusinessProfit.Tier3, "100000 - 150000"]);
  result.push([BusinessProfit.Tier4, "150000 - 200000"]);
  result.push([BusinessProfit.Tier5, "200000+"]);

  return result;
};

export const getPercentString = (percent: number): string => {
  if (percent === null || percent === undefined) {
    return "";
  }
  return percent.toString() + "%";
};

export const hasExpired = (expiration: Moment, now: Moment, minutesBefore: number): boolean => {
  const diff = moment.duration(expiration.diff(now)).asMinutes();
  return diff < minutesBefore;
};

const _localStorageService = new LocalStorageService();
const _apiConfig = new Configuration({
  basePath: getApiBaseUrl(),
  apiKey: () => `Bearer ${_localStorageService.getCurrentUser()?.tokens.accessToken}`,
});

export const getApiConfig = () => {
  return _apiConfig;
};

export const isValueNullOrEmptyString = (value: string | null | undefined): boolean => {
  return value === null || value === "";
};

export const mapToOmsenCompanyType = (companyType: number | null) => {
  switch (companyType) {
    case 2:
      return "KB";
    case 3:
      return "ÖB";
    case 4:
      return "AB";
    default:
      return null;
  }
};

export const hasFormikError = (errors: FormikErrors<FormikValues>) => {
  return Object.keys(errors).length > 0;
};
