import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import AppSidebar from "../AppSidebar/AppSidebar";
import AppHeader from "../AppHeader/AppHeader";
import { Navigate, NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { Roles } from "../../contracts/data/Roles";
import * as paths from "../Routes/routePaths";
import HeaderButton from "../shared/HeaderButton";

type TDefaultLayoutProps = {
  navigate: NavigateFunction;
  showSidebarAdminButton?: boolean;
  showSidebarUserButton?: boolean;
  headerButtons?: React.ReactNode;
  prospectId?: string;
  prospectReference?: string;
  prospectViewName?: string;
  showHeaderButtons?: boolean;
  children: JSX.Element;
  userRoles?: string[];
};

type TDefaultLayoutState = {};

class DefaultLayoutWithParams extends Component<TDefaultLayoutProps, TDefaultLayoutState> {
  render() {
    const userRoles = this.props.userRoles?.toString();
    const prospectViewName = this.props.prospectViewName || "basic";
    const showSidebarAdminButton =
      this.props.showSidebarAdminButton !== undefined ? this.props.showSidebarAdminButton : false;
    const showSidebarUserButton =
      this.props.showSidebarUserButton !== undefined ? this.props.showSidebarUserButton : false;
    const isAdmin = userRoles?.includes(Roles.Admin);
    const path = new URL(window.location.href).pathname;
    const showAdminToggleButton = isAdmin && (path === paths.admin || path === paths.prospectsList);

    const headerButtons: React.ReactNode = showAdminToggleButton ? (
      <Col className="align-self-center d-flex justify-content-between">
        <div className="btn-group btn-group-justified AdminSellerToggle" data-toggle="buttons">
          <HeaderButton
            text="Seller"
            id="seller"
            className="btn px-4"
            href={paths.prospectsList}
            variant={path === paths.prospectsList ? "primary" : "outline-primary"}
          />
          <HeaderButton
            text="Admin"
            id="admin"
            className="btn px-4"
            href={paths.admin}
            variant={path === paths.admin ? "primary" : "outline-primary"}
          />
        </div>
        {this.props.headerButtons}
      </Col>
    ) : (
      this.props.headerButtons
    );

    if (!isAdmin && userRoles?.includes(Roles.Booker)) {
      return <Navigate replace to={paths.booker} />;
    }

    if (path === paths.admin && !isAdmin) {
      return <Navigate replace to={paths.prospectsList} />;
    }

    return (
      <Container className={"DefaultLayout"}>
        <Row>
          <Col>
            <AppHeader
              showHeaderButtons={this.props.showHeaderButtons ?? false}
              prospectId={this.props.prospectId}
              prospectReference={this.props.prospectReference}
              prospectViewName={prospectViewName}
              headerButtons={headerButtons}
            />
          </Col>
        </Row>
        <Row>
          <Col>{this.props.children}</Col>
        </Row>
        <AppSidebar
          navigate={this.props.navigate}
          showSidebarAdminButton={showSidebarAdminButton}
          showSidebarUserButton={showSidebarUserButton}
          prospectId={this.props.prospectId}
          prospectReference={this.props.prospectReference}
          prospectViewName={prospectViewName}
        />
      </Container>
    );
  }
}

export const DefaultLayout = ({
  headerButtons,
  showSidebarAdminButton,
  showSidebarUserButton,
  calculateShowAdminButton,
  calculateShowUserButton,
  showHeaderButtons,
  children,
  userRoles,
}: {
  headerButtons?: React.ReactNode;
  showSidebarAdminButton?: boolean;
  showSidebarUserButton?: boolean;
  calculateShowAdminButton?: boolean;
  calculateShowUserButton?: boolean;
  showHeaderButtons?: boolean;
  children: JSX.Element;
  userRoles?: string[];
}) => {
  const navigate = useNavigate();
  const { prospectId, prospectViewName, prospectReference } = useParams();
  const showAdminButton = calculateShowAdminButton ? prospectId !== undefined : showSidebarAdminButton;
  const showUserButton = calculateShowUserButton ? prospectId !== undefined : showSidebarUserButton;
  return (
    <DefaultLayoutWithParams
      navigate={navigate}
      showSidebarAdminButton={showAdminButton}
      showSidebarUserButton={showUserButton}
      headerButtons={headerButtons}
      prospectId={prospectId}
      prospectReference={prospectReference}
      prospectViewName={prospectViewName}
      showHeaderButtons={showHeaderButtons}
      children={children}
      userRoles={userRoles}
    />
  );
};
