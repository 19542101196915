import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { IProspect } from "../../contracts/data/IProspect";
// @ts-ignore
import copiedIconSrc from "../../assets/check_circle_green_icon.svg";
import { useTranslation } from "react-i18next";
import AlertAutoDismissible from "./AlertAutoDismissible";
import { InsuranceType } from "../../contracts/data/IInsurance";

const COPY_TIMEOUT = 5 * 60 * 1000;

type TProps = {
  customer: IProspect;
};

interface IExportCustomer extends IExportInsurances {
  firstName: string;
  lastName: string;
  email: string;
  socialSecurityNumber: string | null;
  phoneNumber: string | null;
  streetAddress: string | null;
  zipCode: string | null;
  city: string | null;
  countryOfBirth: string | null;
  citizenship: string | null;
  language: string | null;
  meetingDate: string | null;
  fiscalMonth: number | null;
  companyId: string | null;
  companyName: string | null;
  companyZipCode: string | null;
  companyStreetAddress: string | null;
  companyCity: string | null;
  companyEmail: string | null;
  companyLanguage: string | null;
  companyContactPerson: string | null;
  companyPhoneNumber: string | null;
  timestamp: string;
}

interface IExportInsurances {
  lifeInsurance: IExportInsurance;
  permanentDisabilityInsurance: IExportInsurance;
  temporaryDisabilityInsurance: IExportDisabilityInsurance;
  seriousSicknessInsurance: IExportInsurance;
}

interface IExportInsurance {
  amount: number | null;
}

interface IExportDisabilityInsurance {
  dailyAmount: number | null;
  deductibleDays: number | null;
  duration: number | null;
}

const mapCustomer = (prospect: IProspect): IExportCustomer => {
  return {
    firstName: prospect.firstName,
    lastName: prospect.lastName,
    socialSecurityNumber: prospect.socialSecurityNumber,
    email: prospect.email,
    phoneNumber: prospect.phoneNumber,
    streetAddress: prospect.streetAddress,
    zipCode: prospect.zipCode,
    city: prospect.city,
    countryOfBirth: prospect.countryOfBirth,
    citizenship: prospect.citizenship,
    language: prospect.language,
    meetingDate: prospect.meetingDate,
    timestamp: new Date().toISOString(),
    companyId: prospect.companyId,
    companyName: prospect.companyName,
    fiscalMonth: prospect.fiscalMonth,
    companyZipCode: prospect.companyZipCode,
    companyStreetAddress: prospect.companyAddress,
    companyCity: prospect.companyCity,
    companyEmail: prospect.companyEmail,
    companyLanguage: prospect.companyLanguage,
    companyContactPerson: prospect.companyContactPerson,
    companyPhoneNumber: prospect.companyPhone,
    ...mapInsurances(prospect),
  };
};

const mapInsurances = (prospect: IProspect): IExportInsurances => {
  const offerInsurance = prospect.insurances.find((i) => i.type === InsuranceType.Offer);

  const exportOffer: IExportInsurances = {
    lifeInsurance: { amount: null },
    permanentDisabilityInsurance: { amount: null },
    temporaryDisabilityInsurance: { dailyAmount: null, deductibleDays: null, duration: null },
    seriousSicknessInsurance: { amount: null },
  };

  if (!offerInsurance) {
    return exportOffer;
  }

  exportOffer.lifeInsurance.amount = offerInsurance.optionalLifeInsuranceAmount;
  exportOffer.permanentDisabilityInsurance.amount = offerInsurance.optionalDisabilityPensionAmount;
  exportOffer.seriousSicknessInsurance.amount = offerInsurance.optionalSeriousIllnessInsuranceAmount;
  exportOffer.temporaryDisabilityInsurance = {
    dailyAmount: offerInsurance.optionalSicknessPayAmount,
    deductibleDays: offerInsurance.optionalSicknessPayDeductibleDays,
    duration: offerInsurance.optionalSicknessDuration,
  };

  return exportOffer;
};

const writeTextToClipBoard = (text: string, onSuccess: () => void, onFailure: (reason: any) => void) => {
  if (navigator?.clipboard?.writeText !== undefined) {
    navigator.clipboard.writeText(text).then(onSuccess, onFailure);
  } else {
    onFailure("Clipboard API not available");
  }
};

type TCopyStatus = "init" | "success" | "failure";

const CustomerClipBoardButton: React.FC<TProps> = (props: TProps) => {
  const [copyStatus, setCopyStatus] = useState<TCopyStatus>("init");
  const { t } = useTranslation("translations");
  const clipBoardTimeoutRef = useRef<NodeJS.Timeout>();

  const clearClipboard = useCallback(() => {
    writeTextToClipBoard(
      "",
      () => setCopyStatus("init"),
      (reason) => console.error(reason),
    );
  }, [setCopyStatus]);

  useEffect(() => {
    return () => {
      clearClipboard();
    };
  }, [props.customer, clearClipboard]);

  const writeToClipBoard = () => {
    const customer = mapCustomer(props.customer);
    const customerAsJson = JSON.stringify(customer);
    writeTextToClipBoard(customerAsJson, handleWriteClipBoardSuccess, handleWriteClipBoardFailure);
  };

  const handleWriteClipBoardSuccess = () => {
    if (clipBoardTimeoutRef?.current) {
      clearTimeout(clipBoardTimeoutRef.current);
    }
    clipBoardTimeoutRef.current = setTimeout(() => {
      clearClipboard();
    }, COPY_TIMEOUT);
    setCopyStatus("success");
  };

  const handleWriteClipBoardFailure = (reason: any) => {
    setCopyStatus("failure");
    console.error("Failed to write to clipboard: ", reason);
  };

  return (
    <>
      <Button variant="primary" type="button" className="w-100" onClick={writeToClipBoard}>
        <span className="pe-1">{t("copy to clipboard")}</span>
        {copyStatus === "success" && <img src={copiedIconSrc} alt="copied" />}
      </Button>
      {copyStatus === "failure" && (
        <AlertAutoDismissible variant={"danger"} className="mt-2">
          {t("copy failed")}
        </AlertAutoDismissible>
      )}
    </>
  );
};

export default CustomerClipBoardButton;
