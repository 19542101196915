import React, { Component } from "react";
import { connect } from "react-redux";
import { TAppRootState } from "../../../redux/types/stateTypes";
import * as paths from "../../Routes/routePaths";
import { Navigate, useSearchParams } from "react-router-dom";
import LoginForm from "../../forms/LoginForm";
import { loginUser, TLoginUserAction } from "../../../redux/actions/authentication";

type TLoginProps = {
  loginUser: TLoginUserAction;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  queryParams: URLSearchParams;
};

type TLoginState = {};

class Login extends Component<TLoginProps, TLoginState> {
  render() {
    const path = this.props.queryParams.get('returnUrl')?? paths.prospectsList;

    if (this.props.isLoggedIn) {
      return <Navigate replace to={path} />;
    }

    return (
      <div className="Login px-5">
        <LoginForm
          loginUser={this.props.loginUser}
          isLoggedIn={this.props.isLoggedIn}
          isLoggingIn={this.props.isLoggingIn}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
  isLoggingIn: state.authentication.isLoggingIn,
});

type TMapDispatchToProps = {
  loginUser: TLoginUserAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  loginUser: (email, password) => dispatch(loginUser(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)((props: TLoginProps) => {
    const [queryParams] = useSearchParams();
  return (
    <Login {...props} queryParams={queryParams} />
  );
});
