import React from "react";
import { Modal, Button } from "react-bootstrap";

type TModalProps = {
  show: boolean;
  title: string;
  bodyHeader?: string;
  bodyText: string;
  onAccept: () => void;
  onReject: () => void;
  yesButtonText: string;
  noButtonText: string;
};

export default function ModalConfirm(props: TModalProps) {
  return (
    <Modal show={props.show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{props.bodyHeader}</h4>
        <p>{props.bodyText}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onAccept}>{props.yesButtonText}</Button>
        <Button className={"ms-3"} onClick={props.onReject}>
          {props.noButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
