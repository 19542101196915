import React from "react";
import { Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import moment from "moment";
import { dateTimeFormat_YYYYMMDD_HHmmss } from "../../constants/dateFormats";

const FormikYearPicker = ({ ...props }: any) => {
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const getValidYears = () => {
    const currentYear = Number(moment().format("YYYY"));
    const firstValidYear = 1920;
    const result = [];

    for (let i = firstValidYear; i <= currentYear; i++) {
      const str = String(i);
      const data = moment(i, "YYYY").format(dateTimeFormat_YYYYMMDD_HHmmss);
      result.push([data, str]);
    }
    return result.reverse();
  };

  const handleChange = (e: any) => {
    const value = e.currentTarget.value;
    const date = moment(value, "YYYY").format(dateTimeFormat_YYYYMMDD_HHmmss);
    formikProps.setFieldTouched(field.name, true, false);
    formikProps.setFieldError(field.name, "");
    formikProps.setFieldValue(field.name, value ? date : value);
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);

  return (
    <Form.Group className={"FormikYearPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Form.Control
        name={field.name}
        as={"select"}
        value={field.value || undefined}
        onChange={(e) => handleChange(e)}
        isInvalid={isInvalid}
      >
        {field.value === "" || field.value === null ? <option value={""} key={"-"}></option> : null}
        {field.value === 0 ? <option value={"0"} key={"0"}></option> : null}
        {getValidYears().map((x) => (
          <option value={x[0]} key={x[0]}>
            {x[1]}
          </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormikYearPicker;
