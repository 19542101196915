import useUserApiClient from "./clients/useUserApiClient";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ApplicationUserActionsRegisterUserRegisterCommand } from "../api";

export default function useCreateUser() {
  const client = useUserApiClient();
  const queryClient = useQueryClient();

  const createUser = async (newUser: ApplicationUserActionsRegisterUserRegisterCommand) => {
    const res = await client.apiUsersPost(newUser);
    return res.data;
  };

  return useMutation((newUser: ApplicationUserActionsRegisterUserRegisterCommand) => createUser(newUser), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["users"]);
    },
  });
}
