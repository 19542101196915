import useProspectApiClient from "./clients/useProspectApiClient";
import { useMutation } from "@tanstack/react-query";

export default function useIsProspectExistingWithCompanyId() {
  const client = useProspectApiClient();

  const isProspectExistingWithCompanyId = async (companyId: string) => {
    return await client.isProspectExistingWithCompanyId(companyId);
  };

  return useMutation(["isProspectExistingWithCompanyId"], isProspectExistingWithCompanyId);
}
