import React, { useState } from "react";
import { ApplicationUserActionsGetUsersGetPaginatedUsersResponse } from "../../../api";
import { withTranslation, WithTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  validateEmail,
  validatePasswordLength,
  validatePasswordLowercaseCount,
  validatePasswordNonAlphaNumericCount,
  validatePasswordUppercaseCount,
} from "../../../utils/validatorUtils";
import { Formik } from "formik";
import { Roles } from "../../../contracts/data/Roles";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import useCreateUser from "../../../hooks/useCreateUser";
import FormikRolePicker from "../../shared/FormikRolePicker";
import axios from "axios";
import { applyTranslatedErrorsToCreateUser } from "../../../utils/appUtils";
import useUpdateUser from "../../../hooks/useUpdateUser";

type TUserFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  isInActive: boolean;
  password: string;
  confirmPassword: string;
};

type TFormStatus = {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
};

type Props = {
  user: ApplicationUserActionsGetUsersGetPaginatedUsersResponse;
} & WithTranslation;

const UserForm: React.FC<Props> = ({ user, t }) => {
  const {
    mutateAsync: createUser,
    isLoading: loadingCreateUser,
    isError: isErrorCreateUser,
    isSuccess: successCreateUser,
  } = useCreateUser();
  const {
    mutateAsync: updateUser,
    isLoading: loadingUpdateUser,
    isError: isErrorUpdateUser,
    isSuccess: successUpdateUser,
  } = useUpdateUser();
  const [errorState, setErrorState] = useState<string>();
  const update = !!user.id;
  const formStatus: TFormStatus = update
    ? { isLoading: loadingUpdateUser, isError: isErrorUpdateUser, isSuccess: successUpdateUser }
    : { isLoading: loadingCreateUser, isError: isErrorCreateUser, isSuccess: successCreateUser };

  const initialValues: TUserFormValues = {
    firstName: user.firstName ?? "",
    lastName: user.lastName ?? "",
    email: user.email ?? "",
    roles: user.roles ?? [Roles.Seller],
    isInActive: user.isInActive ?? false,
    password: "",
    confirmPassword: "",
  };

  const validationUpdateSchema = Yup.object().shape({
    firstName: Yup.string().required(t("Required")),
    lastName: Yup.string().required(t("Required")),
    email: Yup.string().test("test-email", t("Invalid email address"), validateEmail),
    roles: Yup.array().of(Yup.string()).min(1, t("At least one role is needed")),
  });

  const validationCreateSchema = validationUpdateSchema.shape({
    password: Yup.string()
      .required(t("Required"))
      .test("test-password-length", t("Password is too short"), validatePasswordLength)
      .test(
        "test-password-uppercase",
        t("Password must contain at least one uppercase letter"),
        validatePasswordUppercaseCount,
      )
      .test(
        "test-password-lowercase",
        t("Password must contain at least one lowercase letter"),
        validatePasswordLowercaseCount,
      )
      .test(
        "test-password-lowercase",
        t("Password must contain at least one non alphanumeric"),
        validatePasswordNonAlphaNumericCount,
      ),
    confirmPassword: Yup.string()
      .required(t("Required"))
      .oneOf([Yup.ref("password")], t("Passwords must match")),
  });

  const handleShowPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordInput = document.getElementById("password-input");
    const passwordConfirmInput = document.getElementById("password-confirm-input");
    if (passwordInput instanceof HTMLInputElement && passwordConfirmInput instanceof HTMLInputElement) {
      if (event.currentTarget.checked) {
        passwordInput.type = "text";
        passwordConfirmInput.type = "text";
      } else {
        passwordInput.type = "password";
        passwordConfirmInput.type = "password";
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={update ? validationUpdateSchema : validationCreateSchema}
      onSubmit={async (values, formikHelpers) => {
        const newUser = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          roles: values.roles,
        };

        try {
          if (update && user.id) {
            await updateUser({ userId: user.id, command: { ...newUser, isInActive: user.isInActive } });
          } else {
            await createUser({ ...newUser, password: values.password });
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            const errorMessage =
              applyTranslatedErrorsToCreateUser(error.response?.data, t, values, formikHelpers) ||
              t(update ? "Updating user failed" : "User registration failed");
            setErrorState(errorMessage);
          }
        }
      }}
    >
      {(formikProps) => (
        <Form noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
          <Form.Control type="text" style={{ display: "none" }} />
          <Form.Control type="password" style={{ display: "none" }} />

          <Row>
            <Col>
              <Form.Group className={"pb-2 form-group"}>
                <Form.Label>{t("Firstname")}</Form.Label>
                <Form.Control
                  name={"firstName"}
                  type={"input"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.firstName}
                  isInvalid={!!formikProps.errors.firstName && formikProps.touched.firstName}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.firstName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className={"pb-2 form-group"}>
                <Form.Label>{t("Lastname")}</Form.Label>
                <Form.Control
                  name={"lastName"}
                  type={"input"}
                  placeholder={""}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  value={formikProps.values.lastName}
                  isInvalid={!!formikProps.errors.lastName && formikProps.touched.lastName}
                />
                <Form.Control.Feedback type="invalid">{formikProps.errors.lastName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className={"pb-2 form-group"}>
            <Form.Label>{t("Email")}</Form.Label>
            <Form.Control
              name={"email"}
              type={"email"}
              placeholder={""}
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.email}
              isInvalid={!!formikProps.errors.email && formikProps.touched.email}
            />
            <Form.Control.Feedback type="invalid">{formikProps.errors.email}</Form.Control.Feedback>
          </Form.Group>
          <FormikRolePicker fieldName="roles" />
          {!update && (
            <>
              <Row>
                <Col>
                  <Form.Group className={"form-group"}>
                    <Form.Label>{t("Password")}</Form.Label>
                    <Form.Control
                      name={"password"}
                      type={"password"}
                      id="password-input"
                      placeholder={""}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.password}
                      isInvalid={!!formikProps.errors.password && formikProps.touched.password}
                    />
                    <Form.Control.Feedback type="invalid">{formikProps.errors.password}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className={"form-group"}>
                    <Form.Label>{t("Confirm Password")}</Form.Label>
                    <Form.Control
                      name={"confirmPassword"}
                      type={"password"}
                      id="password-confirm-input"
                      placeholder={""}
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.confirmPassword}
                      isInvalid={!!formikProps.errors.confirmPassword && formikProps.touched.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">{formikProps.errors.confirmPassword}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check type={"checkbox"} label={t("Show Password")} onChange={handleShowPassword} />
                </Col>
              </Row>
            </>
          )}
          <Form.Group className={"pt-3 form-group"}>
            <Button variant="primary" type="submit" className={"w-100"} disabled={formStatus.isLoading}>
              {formStatus.isLoading ? (
                <>
                  <Spinner as="span" animation="border" size="sm" aria-hidden="true" />{" "}
                </>
              ) : null}
              {t(update ? "Save" : "Register")}
            </Button>
            {formStatus.isError ? (
              <Alert variant={"danger"} className={"mt-4"}>
                {errorState}
              </Alert>
            ) : null}
            {formStatus.isSuccess ? (
              <Alert variant={"success"} className={"mt-4"}>
                {t(update ? "User updated successful" : "User registration successful")}
              </Alert>
            ) : null}
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default withTranslation("translations")(UserForm);
