import useOmsenApiClient from "./clients/useOmsenApiClient";
import { useQuery } from "@tanstack/react-query";
import { LocalStorageService } from "../services/LocalStorageService";
import { ClientsOmsenProduct } from "../api";
import { useTranslation } from "react-i18next";

export default function useOmsenProducts() {
  const localStorageService = new LocalStorageService();
  const client = useOmsenApiClient();
  const { t } = useTranslation("translations");

  const getOmsenProducts = async (): Promise<ClientsOmsenProduct[]> => {
    if (!localStorageService.getCurrentUser()) {
      return [211, 219, 217, 212].map(code => ({ code, text: t(`omsen_product_name_${code}`)}));
    }

    const response = await client.apiOmsenProductsGet();
    return response.data;
  };

  return useQuery(["omsenProducts"], getOmsenProducts, {
    refetchOnWindowFocus: false,
    refetchOnMount: false
  });
}
