import React from "react";
import { Modal } from "react-bootstrap";
import { ApplicationUserActionsGetUsersGetPaginatedUsersResponse } from "../../../api";
import { withTranslation, WithTranslation } from "react-i18next";
import CreateUserForm from "./UserForm";

type Props = {
  isOpen: boolean;
  setOpenModal: (value: React.SetStateAction<boolean>) => void;
  user: ApplicationUserActionsGetUsersGetPaginatedUsersResponse;
} & WithTranslation;
const UserModal: React.FC<Props> = ({ isOpen, setOpenModal, user, t }) => {
  const saveOrEdit = user?.id ? "Edit" : "Create";
  return (
    <Modal
      show={isOpen}
      onHide={() => setOpenModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{t(saveOrEdit) + " " + t("user")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CreateUserForm user={user} />
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation("translations")(UserModal);
