import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { withTranslation } from "react-i18next";
import RangeSlider from "react-bootstrap-range-slider";

const DEFAULT_MAX_VALUE = 100000;
const DEFAULT_MIN_VALUE = 5000;

const FormikInput = ({ ...props }: any) => {
  const formikProps = useFormikContext<any>();
  const [field, , helpers] = useField(props);
  const [value, setValue] = React.useState(formikProps.values[field.name]);

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);
  let maxValue = DEFAULT_MAX_VALUE;
  if (props.max) {
    maxValue = props.max;
  }

  let minValue = DEFAULT_MIN_VALUE;
  if (props.min) {
    minValue = props.min;
  }

  const handleSliderChange = (e: any) => {
    // We need to set the name attribute to the event, since the slider doesn't have a name in the defined props
    e.target.setAttribute("name", field.name);
    formikProps.handleChange(e);
    setTimeout(formikProps.handleSubmit, 0);
  };

  const handleInputChange = (e: any) => {
    formikProps.handleChange(e);
    handleSliderTempChange(e);
  };

  const handleSliderTempChange = (e: any) => {
    helpers.setValue(Number.parseInt(e.target.value));
  };

  const handleBlur = (e: any) => {
    formikProps.handleSubmit(e);
  };
  useEffect(() => {
    setValue(formikProps.values[field.name]);
  }, [formikProps.values[field.name]]);

  return (
    <Form.Group className={"FormikInput form-group"} as={Row}>
      <Col xs="12">{props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}</Col>
      <Col xs="12" className={"pb-2"}>
        <RangeSlider
          tooltipPlacement="top"
          max={maxValue}
          min={minValue}
          value={value}
          onChange={handleSliderTempChange}
          onAfterChange={handleSliderChange}
        />
      </Col>
      <Col xs="12">
        <div className={(props.unit !== undefined ? "input-group" : "") + (isInvalid ? " is-invalid" : "")}>
          <Form.Control
            name={field.name}
            type="number"
            value={value}
            onChange={handleInputChange}
            className={"is-readonly"}
            onBlur={handleBlur}
            isInvalid = {isInvalid}
          />
          {props.unit !== undefined ? (
            <span className="input-group-text">{props.unit}</span>
          ) : null}
        </div>
      <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikInput);
