export const dateTimeFormat_YYYYMMDD_HHmmss = "YYYY-MM-DDTHH:mm:ss";
export const dateTimeFormat_YYYYMMDD_HHmm = "YYYY-MM-DDTHH:mm";
export const dateTimeFormat_YYYYMMDD_HH = "YYYY-MM-DDTHH";

export const dateFormat_DDMMYYYY = "DD.MM.YYYY";
export const dateFormat_HHmm = "HH:mm";
export const dateFormat_YYYYMMMDD = "YYYY-MM-DD";

export const dateTimePickerFormat_date = "dd.MM.yyyy HH:mm";
export const dateTimePickerFormat_time = "HH:mm";
