import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import { ServiceContainer } from "./services/ServiceContainer";
import { MockAuthenticationService } from "./services/mock/MockAuthenticationService";
import { LocalStorageService } from "./services/LocalStorageService";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import "./styles/index.scss";
import { ApiService } from "./services/ApiService";
import { AuthenticationService } from "./services/AuthenticationService";
import { ProspectService } from "./services/ProspectService";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const configureServices = () => {
  // To test with mocked authentication, set this to true
  const useMockAuthentication = false;

  const storageService = new LocalStorageService();
  const apiService = new ApiService(storageService);
  const authenticationService = useMockAuthentication
    ? new MockAuthenticationService()
    : new AuthenticationService(apiService);
  const prospectService = new ProspectService(apiService);

  return new ServiceContainer(authenticationService, storageService, prospectService, apiService);
};

const services = configureServices();
const store = configureStore(services);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <App services={services} />
        </I18nextProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
