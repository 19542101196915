import React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { Roles } from "../../contracts/data/Roles";
import { useField, useFormikContext } from "formik";
import {withTranslation, WithTranslation} from 'react-i18next';

type Props = {
  fieldName: string;
} & WithTranslation;

const FormikRolePicker: React.FC<Props> = ({ fieldName, t }) => {
  const formikProps = useFormikContext<any>();
  const [field] = useField(fieldName);
  const values = field.value.map((role: string) => {
    return { value: role, label: t(role) };
  });

  const handleChange = async (e: any) => {
    await formikProps.setFieldTouched(field.name, true);
    await formikProps.setFieldValue(field.name, Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const isInvalid = Boolean(
    !!formikProps.getFieldMeta(field.name).error && formikProps.getFieldMeta(field.name).touched,
  );

  return (
    <Form.Group className={"FormikCountryPicker form-group"}>
      <Form.Label>{t("Roles")}</Form.Label>
      <Select
        options={Object.keys(Roles).map((key) => ({ value: key, label: t(key) }))}
        value={values}
        isMulti={true}
        onChange={handleChange}
        className={isInvalid ? "is-invalid" : "is-valid"}
        styles={{ control: (styles) => ({ ...styles, height: '2rem', borderRadius: 0 }) }}
      />
      <Form.Control.Feedback type="invalid">
        {String(formikProps.getFieldMeta(field.name).error)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikRolePicker);
