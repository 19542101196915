export const validateEmail = (value: string | undefined | null): boolean => {
  if (value === undefined || value === null) {
    return false;
  }
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
};

export const validatePasswordLength = (value: string | undefined | null): boolean => {
  if (value === undefined || value === null) {
    return false;
  }
  return value.length >= 8;
};

export const validatePasswordDigitsCount = (value: string | undefined | null): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  let count = 0;
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    if (char >= "0" && char <= "9") {
      count++;
    }
  }

  return count >= 1;
};

export const validatePasswordUppercaseCount = (value: string | undefined | null): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  let count = 0;
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    if (char >= "A" && char <= "Z") {
      count++;
    }
  }

  return count >= 1;
};

export const validatePasswordLowercaseCount = (value: string | undefined | null): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  let count = 0;
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    if (char >= "a" && char <= "z") {
      count++;
    }
  }

  return count >= 1;
};

export const validatePasswordNonAlphaNumericCount = (value: string | undefined | null): boolean => {
  if (value === undefined || value === null) {
    return false;
  }

  let count = 0;
  for (let i = 0; i < value.length; i++) {
    const char = value[i];
    if (!((char >= "a" && char <= "z") || (char >= "A" && char <= "Z") || (char >= "0" && char <= "9"))) {
      count++;
    }
  }

  return count >= 1;
};
