import React from "react";
import { Form } from "react-bootstrap";
import useOmsenOccupations from "../../hooks/useOmsenOccupations";
import { useField, useFormikContext } from "formik";
import Select, { SingleValue } from "react-select";
import { selectCustomStyle } from "../../styles/selectCustomStyle";
import { ApplicationOmsenDtosOccupationDto } from "../../api";

const FormikOccupationPicker = ({ ...props }: any) => {
  const { data: occupations, isLoading, isError } = useOmsenOccupations();
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);
  const handleSelectChange = (value: SingleValue<ApplicationOmsenDtosOccupationDto>) => {
    if (props.onSelectChange) {
      props.onSelectChange(value);
    }
  };

  const filteredOccupations = occupations?.filter(
    (o) => !props.exitingOccupations?.some((exiting: any) => exiting.occupationCode === o.code),
  );

  const occupationText = () => {
    const occupation = (occupations ?? []).find((occupation) => occupation.code === props.occupationOption);

    if (occupation) {
      return <Form.Label column>{occupation.text}</Form.Label>;
    }
    return <Form.Label>No occupation found</Form.Label>;
  };

  // error can be an object when items in the array has validation errors, if so, we don't want to show it here
  const errorMsg = typeof (formikProps.getFieldMeta(field.name).error as unknown) === "string"
    ? formikProps.getFieldMeta(field.name).error
    : undefined;
  const isInvalid = Boolean(!!errorMsg && formikProps.getFieldMeta(field.name).touched);

  return (
    <Form.Group>
      {props.occupationText ? (
        occupationText()
      ) : (
        <Select
          options={filteredOccupations}
          getOptionValue={(option: ApplicationOmsenDtosOccupationDto) => option.code?.toString() ?? ""}
          getOptionLabel={(option: ApplicationOmsenDtosOccupationDto) => `${option.code} - ${option.text}`}
          isLoading={isLoading}
          isMulti={false}
          isClearable={true}
          value={props.value}
          onChange={(newValue) => newValue && handleSelectChange(newValue)}
          className={isInvalid ? "is-invalid" : "is-valid"}
          styles={selectCustomStyle(isInvalid)}
        />
      )}
      <Form.Control.Feedback type="invalid">{String(errorMsg)}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default FormikOccupationPicker;
