import React from "react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { WithTranslation, withTranslation } from "react-i18next";

type TProps = {
  items: TRadioGroupItem[];
  name: string;
  title?: string;
  onChange?: any;
} & WithTranslation;

export type TRadioGroupItem = {
  value: string | number;
  label: string;
};

const FormikRadioGroup = ({ ...props }: TProps) => {
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const handleChange = (value: string | number) => {
    formikProps.setFieldTouched(field.name, true, false);
    formikProps.setFieldError(field.name, "");
    formikProps.setFieldValue(field.name, value);

    if (props.onChange !== undefined) {
      props.onChange(value);
    }
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);

  return (
    <Form.Group className={"FormikRadioGroup form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Form.Control name={field.name} as={ButtonGroup} isInvalid={isInvalid}>
        {props.items.map((item) => (
          <Button
            key={item.value}
            variant={field.value === item.value ? "info" : "light"}
            onClick={() => handleChange(item.value)}
          >
            {item.label}
          </Button>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikRadioGroup);
