export enum SalesProduct {
  IndividualLifeInsurance = 1,
  GroupLifeInsurance = 2,
  IndividualPension = 3,
  GroupPension = 4,
  Tyel = 5,
  Yel = 6,
}

export const SalesProducts = {
  [SalesProduct.IndividualLifeInsurance]: "Riskihenki",
  [SalesProduct.GroupLifeInsurance]: "Ryhmähenkivak.",
  [SalesProduct.IndividualPension]: "Yksilöllinen eläkevak.",
  [SalesProduct.GroupPension]: "Ryhmäeläkevak.",
  [SalesProduct.Tyel]: "TyEL",
  [SalesProduct.Yel]: "YEL",
};

export interface ISale {
  id: string | null;
  salesProduct: SalesProduct;
  price: number;
}
