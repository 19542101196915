export function selectCustomStyle(isInvalid: boolean) {
  return {
    control: (style: any) => ({
      ...style,
      borderRadius: 0,
      fontSize: "1.25rem",
      fontWeight: 300,
      borderColor: isInvalid ? "red" : "black",
    }),
    menu: (styles: any) => ({
      ...styles, zIndex: 999,
    }),
    option: (styles: any) => ({
      ...styles, minHeight: 35,
    }),
  };
}
