import React, {ReactNode} from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import { getPercentString } from "../../utils/appUtils";

const DiscountDropdown = ({ ...props }: any) => {
    const { t } = useTranslation();

  type AnchorProps = React.HTMLProps<HTMLAnchorElement>;
  const DropDownToggle = React.forwardRef<HTMLAnchorElement, AnchorProps>(({ children, onClick }, ref) => (
    <a
      className="input-group-link"
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick(e);
      }}
    >
      {children}
    </a>
  ));

  type DivProps = React.HTMLProps<HTMLDivElement>;
  const DropDownMenu = React.forwardRef<HTMLDivElement, DivProps>(
    ({ children, style, className, }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children as ReactNode)}
          </ul>
        </div>
      );
    },
  );

  return (
        <Dropdown>
            <Dropdown.Toggle id="dropdown-custom-components" as={DropDownToggle} >
            {props.unit}
            </Dropdown.Toggle>
            <Dropdown.Menu as={DropDownMenu}>
            <Dropdown.Item
                eventKey="1"
                onClick={e => props.handleDiscount(0)}
                active={!props.initialDiscount || props.initialDiscount === 0}
                >
                {t("No discount")}
            </Dropdown.Item>
            <Dropdown.Item
                eventKey="2"
                onClick={e => props.handleDiscount(props.possibleDiscount)}
                active={props.initialDiscount === props.possibleDiscount}
                >
                {getPercentString(props.possibleDiscount) +" " + t("discount")
                }</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}
export default DiscountDropdown;
