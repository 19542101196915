import {
  SIGNALR_HUB_PROSPECT_UPDATED,
  SIGNALR_HUB_CONNECT_SUCCESS,
  SIGNALR_HUB_CONNECT_FAILURE,
} from "../actions/actionTypes";
import { TSignalRHubState } from "../types/stateTypes";
import { Reducer } from "redux";

export const initialState: TSignalRHubState = {
  data: null,
  connection: null,
};

const signalRHub: Reducer<TSignalRHubState> = (state = initialState, action) => {
  switch (action.type) {
    case SIGNALR_HUB_PROSPECT_UPDATED:
      return { ...state, data: action.payload };
    case SIGNALR_HUB_CONNECT_SUCCESS:
      return { ...state, connection: action.payload };
    case SIGNALR_HUB_CONNECT_FAILURE:
      return { ...state, connection: null };
    default:
      return state;
  }
};

export default signalRHub;
