import React, { Component } from "react";
import { Button, Form, Alert, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import { withTranslation, WithTranslation } from "react-i18next";
import { validateEmail } from "../../utils/validatorUtils";
import { TLoginUserAction } from "../../redux/actions/authentication";
import * as Yup from "yup";

type TLoginFormValues = {
  email: string;
  password: string;
};

type TLoginFormProps = {
  loginUser: TLoginUserAction;
  isLoggedIn: boolean;
  isLoggingIn: boolean;
} & WithTranslation;

type TLoginFormState = {
  loginError: string;
};

class LoginForm extends Component<TLoginFormProps, TLoginFormState> {
  constructor(props: TLoginFormProps) {
    super(props);

    this.state = {
      loginError: "",
    };
  }

  render() {
    const { t } = this.props;

    const initialValues: TLoginFormValues = {
      email: "",
      password: "",
    };

    const validationSchema = Yup.object().shape({
      email: Yup.string().test("test-email", t("Invalid email address"), validateEmail),
      password: Yup.string().required(t("Required")),
    });

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const result = await this.props.loginUser(values.email, values.password);
          if (result === null) {
            this.setState((state) => ({ ...state, loginError: t("Login failed") }));
          }
        }}
      >
        {(formikProps) => (
          <Form noValidate onSubmit={(e: any) => formikProps.handleSubmit(e)}>
            <Form.Group className={"form-group"}>
              <Form.Label className={"text-uppercase"}>{t("Email")}</Form.Label>
              <Form.Control
                name={"email"}
                type={"email"}
                placeholder={""}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.email}
                isInvalid={!!formikProps.errors.email && formikProps.touched.email}
              />
              <Form.Control.Feedback type="invalid">{formikProps.errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={"pb-2 form-group"}>
              <Form.Label className={"text-uppercase"}>{t("Password")}</Form.Label>
              <Form.Control
                name={"password"}
                type={"password"}
                placeholder={""}
                onChange={formikProps.handleChange}
                onBlur={formikProps.handleBlur}
                value={formikProps.values.password}
                isInvalid={!!formikProps.errors.password && formikProps.touched.password}
              />
              <Form.Control.Feedback type="invalid">{formikProps.errors.password}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={"pt-1 form-group"}>
              <Button variant="primary" type="submit" className={"w-100"} disabled={this.props.isLoggingIn}>
                {this.props.isLoggingIn ? (
                  <>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{" "}
                  </>
                ) : null}
                {t("Login")}
              </Button>
              {this.state.loginError ? (
                <Alert variant={"danger"} className={"mt-4"}>
                  {this.state.loginError}
                </Alert>
              ) : null}
            </Form.Group>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation("translations")(LoginForm);
