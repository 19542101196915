import React from "react";
import { Dropdown, DropdownButton, ListGroup } from "react-bootstrap";
import {IProspectFetch} from '../../contracts/data/IProspectFetch';

export type TTablePaginationProps  = {
  updateTable: (type: any, newState: any) => void;
  payload: IProspectFetch;
  sizePerPageOptions: number[];
  totalSize: number;
};

const TablePagination = ({updateTable, payload, sizePerPageOptions, totalSize}:TTablePaginationProps) => {
  const pageCount = Math.ceil(totalSize / payload.sizePerPage);

  const paginationPages=(start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  };

  const paginationRanges = () => {
    const totalPageNumbers = 5;
    const totalPageCount = pageCount;

    if (totalPageNumbers >= totalPageCount) {
      return paginationPages(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(payload.page, 1);
    const rightSiblingIndex = Math.min(payload.page, totalPageCount);

    const shouldShowLeft = leftSiblingIndex > 3;
    const shouldShowRight = rightSiblingIndex <= totalPageCount - 3;

    if (!shouldShowLeft && shouldShowRight) {
      let leftRange = paginationPages(1, totalPageNumbers);
      return [...leftRange];
    }

    if (shouldShowLeft && !shouldShowRight) {
      let rightRange = paginationPages(totalPageCount - totalPageNumbers + 1, totalPageCount);
      return [...rightRange];
    }

    if (shouldShowLeft && shouldShowRight) {
      let middleRange = paginationPages(leftSiblingIndex-2, rightSiblingIndex+2);
      return [...middleRange];
    }

    return paginationPages(1, totalPageCount);
  }


  return (
    <div className="pagination">
      <DropdownButton
        title={payload.sizePerPage}
        onSelect={(e) => {
          updateTable("pagination", { ...payload, sizePerPage: Number(e), page: 1 });
        }}
      >
        {sizePerPageOptions.map((pageSize: number) => (
          <Dropdown.Item key={pageSize} eventKey={pageSize}>
            {pageSize}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <ListGroup horizontal>
        {payload.page > 1 && (
          <ListGroup.Item onClick={() => updateTable("pagination", { ...payload, page: 1 })}>{"<<"}</ListGroup.Item>
        )}
        {payload.page > 1 && (
          <ListGroup.Item onClick={() => updateTable("pagination", { ...payload, page: payload.page - 1 })}>
            {"<"}
          </ListGroup.Item>
        )}
        {paginationRanges().map((pg: number) => (
          <ListGroup.Item
            key={pg}
            active={payload.page === pg}
            onClick={() => updateTable("pagination", { ...payload, page: pg })}
          >
            {pg}
          </ListGroup.Item>
        ))}
        {payload.page < pageCount && (
          <ListGroup.Item onClick={() => updateTable("pagination", { ...payload, page: payload.page + 1 })}>
            {">"}
          </ListGroup.Item>
        )}
        {payload.page < pageCount && (
          <ListGroup.Item onClick={() => updateTable("pagination", { ...payload, page: pageCount })}>
            {">>"}
          </ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );
};

export default TablePagination;
