import React, { Component } from "react";
import { TAppRootState } from "../../../redux/types/stateTypes";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import * as paths from "../../Routes/routePaths";
import aktia_white_logo from "../../../assets/aktia_white.png";
import veritas_white_logo from "../../../assets/veritas_white.png";
import { WithTranslation, withTranslation } from "react-i18next";

type THomeProps = {
  isLoggedIn: boolean;
} & WithTranslation;

type THomeState = {};

class Home extends Component<THomeProps, THomeState> {
  render() {
    const { t } = this.props;

    return (
      <div className="Home">
        <div className={"PartnershipLogo"}>
          <h6 className={"text-uppercase strikethrough text-center"}>
            <span>{t("Partnership")}</span>
          </h6>
          <div className={"pt-4 partnership-logo-container d-flex justify-content-center align-items-center"}>
            <img src={aktia_white_logo} alt={"Aktia logo"} className={"white"} />
            <img src={veritas_white_logo} alt={"Veritas logo"} className={"white"} />
          </div>
        </div>
        {!this.props.isLoggedIn ? <Navigate replace to={paths.login} /> : <Navigate replace to={paths.prospectsList} />}
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(Home));
