// Inspired from https://stackoverflow.com/questions/46190574/how-to-import-signalr-in-react-component/62162742#62162742

import { action } from "typesafe-actions";
import { getApiBaseUrl, isDevEnv } from "../../utils/appUtils";
import {
  SIGNALR_HUB_PROSPECT_UPDATED,
  SIGNALR_HUB_CONNECT,
  SIGNALR_HUB_CONNECT_SUCCESS,
  SIGNALR_HUB_CONNECT_FAILURE,
  SIGNALR_HUB_LISTEN_PROSPECT,
} from "./actionTypes";
import { HubConnection, HubConnectionBuilder, HubConnectionState, JsonHubProtocol, LogLevel } from "@microsoft/signalr";
import { TAppThunkAction, TAppThunkActionResult } from "../types/stateTypes";
import { IProspect } from "../../contracts/data/IProspect";
import { updateProspectSuccess } from "./prospect";

const startSignalRConnection = async (connection: HubConnection, dispatch: any) => {
  try {
    await connection.start();
    console.assert(connection.state === HubConnectionState.Connected);
    console.log("SignalR connection established");
    dispatch(signalRHubConnectSuccess(connection));
  } catch (err) {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.error("SignalR Connection Error: ", err);
    dispatch(signalRHubConnectFailure());
    setTimeout(() => startSignalRConnection(connection, dispatch), 5000);
  }
};

export type TSignalRHubConnectThunkAction = () => TAppThunkAction<HubConnection>;
export type TSignalRHubConnectAction = () => TAppThunkActionResult<HubConnection>;
export const signalRHubConnect: TSignalRHubConnectThunkAction = () => {
  return async (dispatch: any) => {
    dispatch(action(SIGNALR_HUB_CONNECT));

    const isDev = isDevEnv();
    const hubUrl = getApiBaseUrl() + "/api/meetingHub";

    const options = {
      logMessageContent: isDev,
      logger: isDev ? LogLevel.Warning : LogLevel.Error,
    };

    // create the connection instance
    // withAutomaticReconnect will automatically try to reconnect
    // and generate a new socket connection if needed
    const connection = new HubConnectionBuilder()
      .withUrl(hubUrl, options)
      .withAutomaticReconnect()
      .withHubProtocol(new JsonHubProtocol())
      .configureLogging(LogLevel.Information)
      .build();

    // Note: to keep the connection open the serverTimeout should be
    // larger than the KeepAlive value that is set on the server
    // keepAliveIntervalInMilliseconds default is 15000 and we are using default
    // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
    connection.serverTimeoutInMilliseconds = 60000;

    // re-establish the connection if connection dropped
    connection.onclose((error) => {
      console.assert(connection.state === HubConnectionState.Disconnected);
      console.log("Connection closed due to error. Try refreshing this page to restart the connection", error);
    });

    connection.onreconnecting((error) => {
      console.assert(connection.state === HubConnectionState.Reconnecting);
      console.log("Connection lost due to error. Reconnecting.", error);
    });

    connection.onreconnected((connectionId) => {
      console.assert(connection.state === HubConnectionState.Connected);
      console.log("Connection reestablished. Connected with connectionId", connectionId);
    });

    await startSignalRConnection(connection, dispatch);

    connection.on("ProspectUpdated", (data: any) => {
      dispatch(signalRHubProspectUpdated(data));
    });

    return connection;
  };
};

export type TSignalRHubProspectUpdatedThunkAction = (data: any) => TAppThunkAction<IProspect | null>;
export type TSignalRHubProspectUpdatedAction = (data: any) => TAppThunkActionResult<IProspect | null>;
export const signalRHubProspectUpdated: TSignalRHubProspectUpdatedThunkAction = (data: any) => {
  return async (dispatch, getState, services) => {
    dispatch(action(SIGNALR_HUB_PROSPECT_UPDATED, data));

    const prospect = services.prospectService.mapSignalRProspectUpdatedResponseToProspect(data);
    if (prospect !== null) {
      dispatch(updateProspectSuccess(prospect));
    }
    return prospect;
  };
};
export const signalRHubConnectSuccess = (connection: HubConnection) => action(SIGNALR_HUB_CONNECT_SUCCESS, connection);
export const signalRHubConnectFailure = () => action(SIGNALR_HUB_CONNECT_FAILURE);

export type TSignalRHubListenProspectThunkAction = (prospectReference: string) => TAppThunkAction<boolean>;
export type TSignalRHubListenProspectAction = (prospectReference: string) => TAppThunkActionResult<boolean>;
export const signalRHubListenProspect: TSignalRHubListenProspectThunkAction = (prospectReference: string) => {
  return async (dispatch, getState, services) => {
    dispatch(action(SIGNALR_HUB_LISTEN_PROSPECT));
    const state = getState();
    const connection = state.signalRHub.connection || (await dispatch(signalRHubConnect()));
    if (connection === null) {
      console.log("SignalR unable to listen to prospect, no connection available.");
      return false;
    }

    console.log("SignalR listening to prospect " + prospectReference);
    await connection.invoke("AddToMeeting", prospectReference);
    return true;
  };
};
