import React, { Component } from "react";
import { TAppRootState } from "../../../redux/types/stateTypes";
import { connect } from "react-redux";
import { Navigate, NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom";
import * as paths from "../../Routes/routePaths";
import { withTranslation, WithTranslation } from "react-i18next";
import ProspectCreateForm from "../../forms/ProspectCreateForm";
import {
  createProspect,
  fetchProspectById,
  TFetchProspectByIdAction,
  TCreateProspectAction,
  TFetchProspectEmailAction,
  fetchProspectEmail,
  TSendProspectEmailAction,
  sendProspectEmail,
} from "../../../redux/actions/prospect";
import { IProspectCreate } from "../../../contracts/data/IProspectCreate";
import { Button, Spinner } from "react-bootstrap";
import AlertAutoDismissible from "../../shared/AlertAutoDismissible";
import { IProspect } from "../../../contracts/data/IProspect";

type TProspectCreateProps = {
  isLoggedIn: boolean;
  isCreatingProspect: boolean;
  navigate: NavigateFunction;
  pathname: string;
  prospectId?: string;
  prospect: IProspect | null;
  isFetchingProspect: boolean;
  isFetchingProspectEmail: boolean;
  isSendingProspectEmail: boolean;
  createProspect: TCreateProspectAction;
  fetchProspectById: TFetchProspectByIdAction;
  fetchProspectEmail: TFetchProspectEmailAction;
  sendProspectEmail: TSendProspectEmailAction;
} & WithTranslation;

type TProspectCreateState = {
  prospectEmailContent: string | null;
  sendProspectEmailError: boolean;
};

class ProspectCreate extends Component<TProspectCreateProps, TProspectCreateState> {
  constructor(props: TProspectCreateProps) {
    super(props);

    this.state = {
      prospectEmailContent: null,
      sendProspectEmailError: false,
    };
  }

  async componentDidMount() {
    if (this.props.prospectId !== undefined) {
      await this.props.fetchProspectById(this.props.prospectId);
      if (this.props.pathname.includes("/created")) {
        await this.fetchProspectEmailContent(this.props.prospectId);
      }
    }
  }

  async componentDidUpdate(
    prevProps: Readonly<TProspectCreateProps>,
    prevState: Readonly<TProspectCreateState>,
    snapshot?: any,
  ) {
    if (prevProps.prospectId !== this.props.prospectId && this.props.prospectId !== undefined) {
      if (this.props.pathname.includes("/created")) {
        await this.props.fetchProspectById(this.props.prospectId);
        await this.fetchProspectEmailContent(this.props.prospectId);
      }
    }
  }

  fetchProspectEmailContent = async (prospectId: string) => {
    this.setState((state) => ({
      ...state,
      prospectEmailContent: null,
    }));
    const emailContent = await this.props.fetchProspectEmail(prospectId);
    this.setState((state) => ({
      ...state,
      prospectEmailContent: emailContent,
    }));
  };

  sendProspectEmail = async (prospectId: string) => {
    const result = await this.props.sendProspectEmail(prospectId);
    if (result) {
      this.setState(
        (state) => ({
          ...state,
          sendProspectEmailError: false,
        }),
        () => this.props.navigate(paths.prospectEmailSent.replace(":prospectId", prospectId)),
      );
    } else {
      this.setState((state) => ({
        ...state,
        sendProspectEmailError: true,
      }));
    }
  };

  getCurrentProspect = (): IProspect | undefined => {
    return this.props.prospect ?? undefined;
  };

  renderProspectCreate = () => {
    return (
      <>
        <ProspectCreateForm
          isSubmitting={this.props.isCreatingProspect}
          navigate={this.props.navigate}
          createProspect={this.props.createProspect}
        />
      </>
    );
  };

  renderProspectCopy = () => {
    const prospect = this.getCurrentProspect();

    return (
      <>
        <ProspectCreateForm
          isSubmitting={this.props.isCreatingProspect}
          navigate={this.props.navigate}
          createProspect={this.props.createProspect}
          copyProspect={prospect}
        />
      </>
    );
  };

  renderProspectCreated = () => {
    const { t } = this.props;
    const prospect = this.getCurrentProspect();

    if (prospect === undefined) {
      return null;
    }

    const emailContent = this.state.prospectEmailContent !== null ? this.state.prospectEmailContent : "";

    return (
      <>
        <h5 className={"mt-5"}>{t("The following message will be sent to the customer")}</h5>
        <div className={"message-container my-3"} dangerouslySetInnerHTML={{ __html: emailContent }} />
        <Button
          className={"btn-confirm"}
          variant={"primary"}
          onClick={() => this.sendProspectEmail(prospect.id)}
          disabled={this.props.isSendingProspectEmail}
        >
          {t("Send")}
        </Button>
        <Button
          className={"btn-confirm ms-2"}
          variant={"primary"}
          onClick={() =>
            this.props.navigate(
              paths.prospect.replace(":prospectId", prospect.id).replace(":prospectViewName", "basic"),
            )
          }
        >
          {t("Go to prospect")}
        </Button>
        {this.state.sendProspectEmailError ? (
          <AlertAutoDismissible variant={"danger"} className={"mt-2"}>
            {t("Unable to send email")}
          </AlertAutoDismissible>
        ) : null}
      </>
    );
  };

  renderProspectEmailSent = () => {
    const { t } = this.props;
    const prospect = this.getCurrentProspect();

    if (prospect === undefined) {
      return null;
    }

    return (
      <>
        <h5 className={"mt-5"}>{t("Email has been sent to the customer")}</h5>
        <Button
          className={"btn-confirm"}
          variant={"primary"}
          onClick={() =>
            this.props.navigate(
              paths.prospect.replace(":prospectId", prospect.id).replace(":prospectViewName", "basic"),
            )
          }
        >
          {t("Go to prospect")}
        </Button>
      </>
    );
  };

  render() {
    const { t } = this.props;
    const prospect = this.getCurrentProspect();

    if (!this.props.isLoggedIn) {
      return <Navigate replace to={paths.login} />;
    }

    if (this.props.isFetchingProspect || this.props.isFetchingProspectEmail) {
      return (
        <div className="ProspectCreate">
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;
        </div>
      );
    }

    let content = null;
    if (prospect === undefined) {
      content = this.renderProspectCreate();
    } else if (this.props.pathname.includes("/created")) {
      content = this.renderProspectCreated();
    } else if (this.props.pathname.includes("/emailSent")) {
      content = this.renderProspectEmailSent();
    } else if (this.props.pathname.includes("/copy")) {
      content = this.renderProspectCopy();
    } else {
      content = <Navigate replace to={paths.prospectsList} />;
    }

    return (
      <div className="ProspectCreate">
        <h1>{t("New customer")}</h1>
        {content}
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
  isCreatingProspect: state.prospect.isCreatingProspect,
  prospect: state.prospect.prospect,
  isFetchingProspect: state.prospect.isFetchingProspect,
  isFetchingProspectEmail: state.prospect.isFetchingProspectEmail,
  isSendingProspectEmail: state.prospect.isSendingProspectEmail,
});

type TMapDispatchToProps = {
  createProspect: TCreateProspectAction;
  fetchProspectById: TFetchProspectByIdAction;
  fetchProspectEmail: TFetchProspectEmailAction;
  sendProspectEmail: TSendProspectEmailAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  createProspect: (prospect: IProspectCreate) => dispatch(createProspect(prospect)),
  fetchProspectById: (prospectId: string) => dispatch(fetchProspectById(prospectId)),
  fetchProspectEmail: (prospectId: string) => dispatch(fetchProspectEmail(prospectId)),
  sendProspectEmail: (prospectId: string) => dispatch(sendProspectEmail(prospectId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")((props: TProspectCreateProps) => {
  const { prospectId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
return(
  <ProspectCreate {...props} prospectId={prospectId} pathname={pathname} navigate={navigate} />
)
}));
