import React from "react";
import { withTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import Select, { GroupBase, StylesConfig } from "react-select";
import useOmsenTaxDomiciles from "../../hooks/useOmsenTaxDomiciles";
import { ApplicationOmsenDtosTaxDomicileDto } from "../../api";
import { Form } from "react-bootstrap";

const FormikTaxDomicilePicker = ({ ...props }: any) => {
  const { data: taxDomiciles, isLoading } = useOmsenTaxDomiciles();
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const isInvalid = Boolean(
    !!formikProps.getFieldMeta(field.name).error && formikProps.getFieldMeta(field.name).touched,
  );

  const customStyles: StylesConfig<
    ApplicationOmsenDtosTaxDomicileDto,
    any,
    GroupBase<ApplicationOmsenDtosTaxDomicileDto>
  > = {
    control: (style) => ({
      ...style,
      borderRadius: 0,
      fontSize: "1.25rem",
      fontWeight: 300,
      borderColor: isInvalid ? "red" : "black",
    }),
  };

  return (
    <Form.Group className={"FormikLanguagePicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Select
        options={taxDomiciles}
        getOptionValue={(option) => option.code ?? ""}
        getOptionLabel={(option) => option.name ?? ""}
        isLoading={isLoading}
        isMulti={false}
        onChange={(newValue) => formikProps.setFieldValue(field.name, newValue?.code)}
        value={taxDomiciles ? taxDomiciles.find((option) => option.code === field.value) : { code: "", name: "" }}
        styles={customStyles}
        className={isInvalid ? "is-invalid" : "is-valid"}
        isDisabled={props.disabled}
      />
      <Form.Control.Feedback type="invalid">
        {String(formikProps.getFieldMeta(field.name).error) ?? undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikTaxDomicilePicker);
