import useOmsenMaintenanceWindow from "../../hooks/useOmsenMaintenanceWindow";
import React from "react";

export default function OmsenMaintenanceWindow() {
  const { data } = useOmsenMaintenanceWindow();

  if (!data || data.isInPast) {
    return null;
  }

  return (
    <div className={`mb-2 alert ${data.isActive ? 'alert-danger' : 'alert-warning'}`} role="alert">
      <div className="fs-5">{data.title}</div>
      <div className="fs-6">{data.message}</div>
    </div>
  );
}
