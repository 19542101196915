import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import FormikInput from "./FormikInput";
import FormikOccupationPicker from "./FormikOccupationPicker";
import { ReactComponent as DeleteIcon } from "../../assets/delete_icon.svg";
import { useFormikContext } from "formik";
import { withTranslation } from "react-i18next";
import {ApplicationOmsenDtosOccupationDto} from '../../api';

const EmployerWithOccupation = ({ ...props }: any) => {
  const [occupationOption, setOccupationOption] = useState<ApplicationOmsenDtosOccupationDto | null>(null);
  const formikProps = useFormikContext<any>();
  const { t } = props;

  const addOccupation = async () => {
    await formikProps.setFieldTouched(`${props.name}.occupationAndEarningPercentages`, true, false);
    if (!occupationOption) {
      formikProps.setFieldError(`${props.name}.occupationAndEarningPercentages`, "Can't be empty");
    } else {
      formikProps.setFieldError(`${props.name}.occupationAndEarningPercentages`, "");
      await formikProps.setFieldValue(`${props.name}.occupationAndEarningPercentages`, [
        ...props.occupationAndEarningPercentages,
        { occupationCode: occupationOption.code, percentageOfAnnualEarnings: 0 },
      ]);
      setOccupationOption(null);
    }
  };

  const deleteOccupation = async (value: string) => {
    formikProps.setFieldError(`${props.name}.occupationAndEarningPercentages`, "");
    await formikProps.setFieldValue(
      `${props.name}.occupationAndEarningPercentages`,
      props.occupationAndEarningPercentages.filter((occupation: any) => occupation.occupationCode !== value),
    );
  };

  return (
    <Container className={"FieldsInBorder margin-auto-col"}>
      <Row className={"pt-2"}>
        <Col className="position-relative">
          <Form.Label>{t("Full Name")}</Form.Label>
          {!props.disabled && <DeleteIcon className="position-absolute end-0" role={"button"} onClick={props.deleteEmployer}/>}
        </Col>
        <FormikInput name={`${props.name}.fullName`} type="text" disabled={props.disabled} />
      </Row>
      <Row>
        <Form.Label>{t("Ssn")}</Form.Label>
        <FormikInput name={`${props.name}.ssn`} disabled={props.disabled} />
      </Row>
      <Row>
        <Form.Label>{t("Annual earning (amount)")}</Form.Label>
        <FormikInput
          name={`${props.name}.annualEarningsAmount`}
          type="number"
          unit={"€"}
          disabled={props.disabled}
          zeroAsEmpty={true}
        />
      </Row>
      {!props.disabled && (
        <>
          <Row>
            <Form.Label>{t("Entrepreneurial profession")}</Form.Label>
          </Row>
          <Row className={"mb-1"}>
            <Col sm="10">
              <FormikOccupationPicker
                props={props}
                name={`${props.name}.occupationAndEarningPercentages`}
                onSelectChange={setOccupationOption}
                value={occupationOption}
                exitingOccupations={props.occupationAndEarningPercentages}
              />
            </Col>
            <Col sm="2">
              <Button className="AddButton" onClick={addOccupation}>
                +
              </Button>
            </Col>
          </Row>
        </>
      )}
      {props.occupationAndEarningPercentages && props.occupationAndEarningPercentages?.length > 0 && (
        <Container className={"my-2"}>
          <Row>
            <Col>
              <Form.Label>{t("Entrepreneurial profession")}</Form.Label>
            </Col>
            <Col>
              <Form.Label>{t("Percent")} (%)</Form.Label>
            </Col>
          </Row>
          {props.occupationAndEarningPercentages.map((occupation: any, index: number) => {
            return (
              <Row key={index}>
                <Col>
                  <FormikOccupationPicker
                    name={`${props.name}.occupationAndEarningPercentages[${index}].occupationCode`}
                    occupationText
                    occupationOption={occupation.occupationCode}
                  />
                </Col>
                <Col sm={!props.disabled ? "5" : "6"}>
                  <FormikInput
                    type="number"
                    name={`${props.name}.occupationAndEarningPercentages[${index}].percentageOfAnnualEarnings`}
                    unit={"%"}
                    disabled={props.disabled}
                    zeroAsEmpty={true}
                  />
                </Col>
                {!props.disabled && <Col sm="1">
                  <DeleteIcon role={"button"} onClick={() => deleteOccupation(occupation.occupationCode)} />
                </Col>}
              </Row>
            );
          })}
        </Container>
      )}
    </Container>
  );
};

export default withTranslation("translations")(EmployerWithOccupation);
