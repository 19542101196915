import React, { Component } from "react";
import {
  Link,
  Navigate,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import { IProspect } from "../../../contracts/data/IProspect";
import * as paths from "../../Routes/routePaths";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import TableList from "../../TableList/TableList";
import { TAppRootState } from "../../../redux/types/stateTypes";
import { connect } from "react-redux";
import { dateFormat_DDMMYYYY, dateFormat_HHmm, dateTimeFormat_YYYYMMDD_HHmmss } from "../../../constants/dateFormats";
import moment from "moment";
import ProspectView from "./ProspectView";
import {
  createProspect,
  createProspectComment,
  deleteProspect,
  deleteProspectComment,
  fetchAllSellers,
  fetchProspectById,
  fetchProspects,
  TCreateProspectAction,
  TCreateProspectCommentAction,
  TDeleteProspectCommentAction,
  TFetchAllSellersAction,
  TFetchProspectByIdAction,
  TFetchProspectsAction,
  TProspectDeleteAction,
  TUpdateProspectAction,
  TUpdateProspectCommentAction,
  updateProspect,
  updateProspectComment,
} from "../../../redux/actions/prospect";
import { IProspectBase } from "../../../contracts/data/IProspectBase";
import { getFullName } from "../../../utils/appUtils";
import { ISeller } from "../../../contracts/data/ISeller";
import { ReactComponent as EditIcon } from "../../../assets/edit_icon.svg";
import { ReactComponent as CopyIcon } from "../../../assets/copy_icon.svg";
import { ReactComponent as PresentationIcon } from "../../../assets/presentation_icon.svg";
import { ReactComponent as CommentIcon } from "../../../assets/comment_icon.svg";
import { ReactComponent as CommentIconGreen } from "../../../assets/comment_icon_green.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/delete_icon.svg";
import ModalConfirm from "../../shared/ModalConfirm";
import { IProspectCreate } from "../../../contracts/data/IProspectCreate";
import SalesStatePicker from "../../shared/SalesStatePicker";
import HistoryPopover from "../../shared/HistoryPopover";
import AlertAutoDismissible from "../../shared/AlertAutoDismissible";
import { IProspectFetch } from "../../../contracts/data/IProspectFetch";
import { IPaginatedProspects } from "../../../contracts/data/IPaginatedProspects";
import { IUser } from "../../../contracts/data/IUser";
import ProspectTableFilters from "../../shared/ProspectTableFilters";
import { debounce } from "lodash";
import { IProspectHistory } from "../../../contracts/data/IProspectHistory";

type TProspectProps = {
  isLoggedIn: boolean;
  prospectId?: string;
  prospectViewName?: string;
  summaryViewName?: string;
  insuranceId?: string;
  insuranceDetailId?: string;
  loggedInUser: IUser | null;
  sellers: ISeller[];
  paginatedProspects: IPaginatedProspects;
  prospect: IProspect | null;
  isFetchingProspects: boolean;
  isFetchingProspect: boolean;
  isCreatingProspectComment: boolean;
  isUpdatingProspectComment: boolean;
  isUpdatingProspect: boolean;
  isFetchingAllSellers: boolean;
  createProspect: TCreateProspectAction;
  updateProspect: TUpdateProspectAction;
  fetchProspects: TFetchProspectsAction;
  fetchProspectById: TFetchProspectByIdAction;
  deleteProspect: TProspectDeleteAction;
  createProspectComment: TCreateProspectCommentAction;
  updateProspectComment: TUpdateProspectCommentAction;
  deleteProspectComment: TDeleteProspectCommentAction;
  fetchAllSellers: TFetchAllSellersAction;
  navigate: NavigateFunction;
  pathname: string;
  queryParams: URLSearchParams;
} & WithTranslation;

type TProspectState = {
  hasFetchedProspectData: boolean;
  showModal: boolean;
  deleteProspectId: string;
  deleteProspectCommentId: string;
  deleteFunction: () => void;
  openPopoverProspectId: string;
  searchProspect: string;
  submitResultMessage: string;
  submitResultSuccess: boolean;
  updating: boolean;
  showError: boolean;
  ownButtonClass: string;
  allButtonClass: string;
  errorFetchingData: boolean;
  fetchProspectsPayload: IProspectFetch;
  totalSize: number;
  filterInputInFocus: boolean;
};

type ProspectLinksProps = {
  prospectId: string;
  prospectReference: string;
  view: string;
  deleteProspectHandler: (id: string) => void;
};

const activeButtonClass = "btn btn-primary px-4";
const outlineButtonClass = "btn btn-outline-primary px-4";

const ProspectLinks = ({ prospectId, prospectReference, view, deleteProspectHandler }: ProspectLinksProps) => {
  const editLink = paths.prospect.replace(":prospectId", prospectId).replace(":prospectViewName", view);
  const presentationLink = paths.meeting.replace(":prospectReference", prospectReference);
  const copyLink = paths.prospectCopy.replace(":prospectId", prospectId);

  const deleteProspectCallback = async () => {
    deleteProspectHandler(prospectId);
  };

  return (
    <div className={"ProspectLinks text-nowrap"}>
      <Link className={"me-1"} to={editLink}>
        <EditIcon />
      </Link>
      <Link className={"me-1"} to={copyLink}>
        <CopyIcon />
      </Link>
      <Link className={"me-1"} to={presentationLink}>
        <PresentationIcon />
      </Link>
      <DeleteIcon role={"button"} onClick={deleteProspectCallback} />
    </div>
  );
};

class Prospects extends Component<TProspectProps, TProspectState> {
  constructor(props: TProspectProps) {
    super(props);
    const sellerId = this.props.loggedInUser?.id ?? "";
    const defaultOptions: IProspectFetch = {
      sellerId,
      broker: false,
      sizePerPage: 80,
      page: 1,
      totalSize: 0,
      sortOrder: "desc",
      sortField: "meetingDate",
      filter: "*",
    };

    this.state = {
      hasFetchedProspectData: false,
      showModal: false,
      deleteProspectId: "",
      deleteProspectCommentId: "",
      deleteFunction: () => {},
      openPopoverProspectId: "",
      searchProspect: "",
      submitResultMessage: "",
      submitResultSuccess: true,
      updating: false,
      showError: false,
      ownButtonClass: activeButtonClass,
      allButtonClass: outlineButtonClass,
      errorFetchingData: false,
      fetchProspectsPayload: defaultOptions,
      totalSize: 0,
      filterInputInFocus: false,
    };
  }

  async componentDidMount() {
    await this.fetchProspectData(this.props.prospectId, this.state.fetchProspectsPayload);
    await this.fetchAllSellers();
  }

  async componentDidUpdate(prevProps: Readonly<TProspectProps>, prevState: Readonly<TProspectState>, snapshot?: any) {
    if (prevProps.prospectId !== this.props.prospectId) {
      await this.fetchProspectData(this.props.prospectId, this.state.fetchProspectsPayload);
    }
  }

  fetchProspectData = async (prospectId?: string, payload?: IProspectFetch) => {
    this.setState((state) => ({ ...state, hasFetchedProspectData: false }));
    if (prospectId !== undefined) {
      await this.props.fetchProspectById(prospectId);
    } else if (payload !== undefined) {
      const result = await this.props.fetchProspects(payload);
      if (result == null) this.setState((state) => ({ ...state, errorFetchingData: true }));
      else {
        this.setState((state) => ({
          ...state,
          errorFetchingData: false,
          fetchProspectsPayload: {
            ...state.fetchProspectsPayload,
            totalSize: this.props.paginatedProspects.totalAmount ?? 0,
            page: this.props.paginatedProspects.page ?? 1,
          },
        }));
      }
    }
    this.setState((state) => ({ ...state, hasFetchedProspectData: true }));
  };

  fetchAllSellers = async () => {
    const result = await this.props.fetchAllSellers();
    if (result == null) this.setState((state) => ({ ...state, errorFetchingData: true }));
    else this.setState((state) => ({ ...state, errorFetchingData: false }));
  };

  deleteProspectHandler = (prospectId: string) => {
    this.setState((state) => ({
      ...state,
      showModal: true,
      deleteProspectId: prospectId,
      deleteFunction: async () => await this.props.deleteProspect(prospectId),
    }));
  };

  deleteProspectCommentHandler = (commentId: string) => {
    this.setState((state) => ({
      ...state,
      showModal: true,
      openPopoverProspectId: "",
      deleteProspectCommentId: commentId,
      deleteFunction: async () => await this.props.deleteProspectComment(commentId),
    }));
  };

  modalConfirm = () => {
    const { t } = this.props;
    const clearState = {
      ...this.state,
      showModal: false,
      deleteProspectId: "",
      deleteProspectComment: "",
      deleteFunction: () => {},
    };

    const person = this.props.paginatedProspects.items.find((p) => p.id === this.state.deleteProspectId);

    const name = person ? getFullName(person.firstName, person.lastName) : "Henkilöä ei löytynyt";

    const modalTexts = (): { title: string; bodyHeader: string; bodyText: string } => {
      let texts = { title: "Delete comment", bodyHeader: "", bodyText: "remove-prospect-comment-confirm-message" };
      if (this.state.deleteProspectId !== "") {
        texts = { title: "delete-prospect", bodyHeader: name, bodyText: "remove-prospect-confirm-message" };
      }

      return texts;
    };

    return (
      <ModalConfirm
        show={this.state.showModal}
        title={t(modalTexts().title)}
        bodyHeader={modalTexts().bodyHeader}
        bodyText={t(modalTexts().bodyText)}
        onAccept={() => {
          this.state.deleteFunction();
          this.setState(clearState);
        }}
        onReject={() => this.setState(clearState)}
        yesButtonText={t("Yes")}
        noButtonText={t("No")}
      />
    );
  };

  debouncedApiCall = async (input: string) => {
    const searchWord = input.length === 0 ? "*" : input;
    await this.fetchProspectData(undefined, { ...this.state.fetchProspectsPayload, filter: searchWord, page: 1 });
    this.setState((state) => ({
      ...state,
      searchProspect: searchWord,
      fetchProspectsPayload: { ...state.fetchProspectsPayload, filter: searchWord, page: 1 },
    }));
  };

  search = (input: string) => {
    const searchWord = input.length === 0 ? "*" : input;
    this.setState({ ...this.state, searchProspect: searchWord });
  };

  debounceApiCall = debounce(this.debouncedApiCall, 300);

  toggleViewAll = async (viewAll: boolean) => {
    let sellerId = "";
    if (!viewAll && this.props.loggedInUser) sellerId = this.props.loggedInUser.id;
    else sellerId = "*";

    const payload = { ...this.state.fetchProspectsPayload, page: 1, sellerId };
    this.setState((state) => ({
      ...state,
      hasFetchedProspectData: false,
      fetchProspectsPayload: { ...this.state.fetchProspectsPayload, sellerId },
    }));
    await this.fetchProspectData(undefined, payload);

    //Toggle UI button
    let allButtonClassName: string;
    let ownButtonClassName: string;
    switch (viewAll) {
      case true:
        allButtonClassName = activeButtonClass;
        ownButtonClassName = outlineButtonClass;
        break;
      case false:
        allButtonClassName = outlineButtonClass;
        ownButtonClassName = activeButtonClass;
        break;
    }

    this.setState((state) => ({
      ...state,
      hasFetchedProspectData: true,
      allButtonClass: allButtonClassName,
      ownButtonClass: ownButtonClassName,
      fetchProspectsPayload: { ...this.state.fetchProspectsPayload, page: 1 },
    }));
  };

  filterSeller = async (sellerId: string) => {
    this.setState((state) => ({
      ...state,
      hasFetchedProspectData: false,
      fetchProspectsPayload: { ...this.state.fetchProspectsPayload, sellerId },
    }));
    const payload = { ...this.state.fetchProspectsPayload, page: 1, sellerId };
    await this.fetchProspectData(undefined, payload);
    this.setState((state) => ({
      ...state,
      hasFetchedProspectData: true,
      fetchProspectsPayload: {
        ...this.state.fetchProspectsPayload,
        page: 1,
        sellerId,
      },
    }));
  };

  filterWithBroker = async (broker: boolean) => {
    await this.fetchProspectData(undefined, { ...this.state.fetchProspectsPayload, broker: broker, page: 1 });
    this.setState((state) => ({
      ...state,
      fetchProspectsPayload: { ...state.fetchProspectsPayload, broker: broker, page: 1 },
    }));
  };

  closePopover = () => {
    if (this.state.openPopoverProspectId !== "") {
      this.setState((state) => ({
        ...state,
        openPopoverProspectId: "",
      }));
    }
  };

  updateTable = async (type: any, newState: any) => {
    this.closePopover();
    let payload: IProspectFetch;

    switch (type) {
      case "filter":
        payload = { ...this.state.fetchProspectsPayload, filter: newState };
        await this.fetchProspectData(undefined, payload);
        break;
      case "pagination":
        payload = { ...this.state.fetchProspectsPayload, page: newState.page, sizePerPage: newState.sizePerPage };
        await this.fetchProspectData(undefined, payload);
        this.setState((state) => ({
          ...state,
          fetchProspectsPayload: {
            ...this.state.fetchProspectsPayload,
            page: newState.page,
            sizePerPage: newState.sizePerPage,
          },
        }));
        break;
      case "sort":
        if (
          newState.sortOrder !== this.state.fetchProspectsPayload.sortOrder ||
          newState.sortField !== this.state.fetchProspectsPayload.sortField
        ) {
          payload = {
            ...this.state.fetchProspectsPayload,
            page: 1,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
          };
          await this.fetchProspectData(undefined, payload);
          this.setState((state) => ({
            ...state,
            fetchProspectsPayload: {
              ...this.state.fetchProspectsPayload,
              page: 1,
              sortField: newState.sortField,
              sortOrder: newState.sortOrder,
            },
          }));
        }
        break;
    }
  };

  showFilterSeller = () => {
    if (this.state.allButtonClass === activeButtonClass) return true;
    else return false;
  };

  renderSpinner = () => {
    return <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;
  };

  renderProspectsList = () => {
    const { t } = this.props;

    const columns = [
      {
        header: t("Customer"),
        accessorKey: "fullName",
        cell: (props: any) => props.getValue(),
      },
      {
        header: t("Company"),
        accessorKey: "companyName",
        cell: (props: any) => props.getValue(),
      },
      {
        header: t("Fiscal"),
        accessorKey: "fiscalMonth",
        cell: (props: any) => props.getValue(),
      },
      {
        header: t("Industry"),
        accessorKey: "businessIndustryCode",
        cell: (props: any) => props.getValue(),
      },
      {
        header: t("Phone"),
        accessorKey: "phoneNumber",
        cell: (props: any) => {return <p className='text-nowrap m-0'>{props.getValue()}</p>},
      },
      {
        header: t("_Meeting"),
        accessorKey: "meetingDate",
        cell: (props: any) => {
          const momentDate = moment(props.getValue(), dateTimeFormat_YYYYMMDD_HHmmss);
          return momentDate.format(dateFormat_DDMMYYYY);
        },
      },
      {
        header: t("Created"),
        accessorKey: "created",
        cell: (props: any) => {
          const momentDate = moment(props.getValue(), dateTimeFormat_YYYYMMDD_HHmmss);
          return momentDate.format(dateFormat_DDMMYYYY);
        },
      },
      {
        header: t("Sales State"),
        accessorKey: "salesState",
        cell: (props: any) => props.getValue(),
        enableSorting: false,
      },
      {
        header: "",
        accessorKey: "historyLink",
        cell: (props: any) => props.getValue(),
        enableSorting: false,
      },
      {
        header: "",
        accessorKey: "prospectLinks",
        cell: (props: any) => props.getValue(),
        enableSorting: false,
      },
    ];

    const hasComment = (prospectHistory: IProspectHistory[] | null) => {
      return prospectHistory?.find((ph) => ph.oldValue.includes("Comment"));
    };

    const popover = (p: IProspectBase) => {
      return (
        <Popover id={p.id} className="HistoryPopover">
          <HistoryPopover
            key={p.id}
            prospect={p}
            sellers={this.props.sellers}
            updateProspect={this.props.updateProspect}
            createComment={this.props.createProspectComment}
            updateComment={this.props.updateProspectComment}
            deleteComment={this.deleteProspectCommentHandler}
            currentUser={this.props.loggedInUser}
            commentCreated={() => {
              this.setState((state) => ({ ...state, openPopoverProspectId: p.id, newCommentCreated: true }));
            }}
          />
        </Popover>
      );
    };

    const data = this.props.paginatedProspects.items.map((p) => {
      const showOverlay = this.state.openPopoverProspectId === p.id ? true : false;
      return {
        ...p,
        fullName: getFullName(p.firstName, p.lastName),
        companyId: p.companyId ?? "",
        companyName: p.companyName ?? "",
        fiscalYear: p.fiscalMonth ?? 0,
        companyCity: p.companyCity ?? "",
        salesState: (
          <SalesStatePicker
            key={p.id}
            prospect={p}
            updateProspect={this.props.updateProspect}
            prospectUpdated={() => {}}
          />
        ),
        historyLink: (
          <OverlayTrigger
            transition={false}
            trigger="click"
            rootClose
            placement="right"
            defaultShow={showOverlay}
            onToggle={(show) => {
              const overlayToggel = show ? p.id : "";
              this.setState((state) => ({
                ...state,
                openPopoverProspectId: overlayToggel,
              }));
              show = !showOverlay;
            }}
            overlay={popover(p)}
          >
            {({ ref, ...triggerHandler }) => (
              <button
                key={p.id}
                {...triggerHandler}
                className={"popover-button"}
                disabled={!showOverlay && this.state.openPopoverProspectId !== ""}
              >
                {hasComment(p.prospectHistories) ? (
                  <CommentIconGreen ref={ref} key={p.id} />
                ) : (
                  <CommentIcon ref={ref} key={p.id} />
                )}
              </button>
            )}
          </OverlayTrigger>
        ),
        prospectLinks: (
          <ProspectLinks
            prospectId={p.id}
            prospectReference={p.reference}
            view={p.view}
            deleteProspectHandler={this.deleteProspectHandler}
          />
        ),
      };
    });

    const alertNoData = <AlertAutoDismissible variant={"danger"}>{t("Error fetching data")}</AlertAutoDismissible>;

    const handleClose = (e: any) => {
      this.setState((state) => ({
        ...state,
        submitResultSuccess: false,
        submitResultMessage: "",
        updating: false,
        showError: false,
      }));
    };

    const errorMessage = this.state.showError ? (
      <div className={"alert alert-danger alert-dismissible fade show"} role="alert">
        {this.state.submitResultMessage}
        <button onClick={(e) => handleClose} type="button" className={"close"} data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    ) : null;

    const renderTable =
      data.length > 0 ? (
        <TableList
          data={data}
          columns={columns}
          payload={this.state.fetchProspectsPayload}
          updateTable={this.updateTable}
          totalSize={this.props.paginatedProspects.totalAmount ?? 0}
        />
      ) : (
        <p>
          {t("No customers found")} <a href={paths.prospectCreate}>{t("Create new customer")}</a>
        </p>
      );

    return (
      <>
        <h1>{t("Customers")}</h1>
        {errorMessage}
        <ProspectTableFilters
          inputSearch={(value: string) => {
            this.search(value);
            this.debounceApiCall(value);
          }}
          inputValue={this.state.searchProspect}
          inputAutoFocus={this.state.filterInputInFocus}
          inputFocusChange={(focus: boolean) => this.setState({ ...this.state, filterInputInFocus: focus })}
          toggleViewAll={(viewAll: boolean) => this.toggleViewAll(viewAll)}
          toggleAllButtonClass={this.state.allButtonClass}
          toggleOwnButtonClass={this.state.ownButtonClass}
          showFilterSeller={this.showFilterSeller()}
          selectOnChange={(value: string) => this.filterSeller(value)}
          selectDefaultValue={this.state.fetchProspectsPayload.sellerId}
          sellers={this.props.sellers}
          selectValue={this.state.fetchProspectsPayload.sellerId}
          closePopover={this.closePopover}
          fetchProspectsPayload={this.state.fetchProspectsPayload}
          filterWithBroker={this.filterWithBroker}
        />
        {this.state.errorFetchingData ? alertNoData : null}

        <br />
        {this.props.isFetchingProspects ? this.renderSpinner() : renderTable}
      </>
    );
  };

  renderProspectView = () => {
    const isBusy =
      this.props.isFetchingProspect || this.props.isFetchingProspects || !this.state.hasFetchedProspectData;

    return isBusy ? (
      this.renderSpinner()
    ) : (
      <ProspectView
        pathname={this.props.pathname}
        navigate={this.props.navigate}
        queryParams={this.props.queryParams}
        prospectId={this.props.prospectId}
        prospectViewName={this.props.prospectViewName}
        summaryViewName={this.props.summaryViewName}
        insuranceId={this.props.insuranceId}
        insuranceDetailId={this.props.insuranceDetailId}
      />
    );
  };

  render() {
    const { pathname } = this.props;

    if (!this.props.isLoggedIn) {
      return <Navigate replace to={paths.loginWithReturnUrl(pathname)} />;
    }
    const content = this.props.prospectId === undefined ? this.renderProspectsList() : this.renderProspectView();
    const divId = this.props.prospectId === undefined ? "ProspectTableList" : "Prospects";
    return (
      <div className="Prospects" id={divId}>
        {content}
        <div>{this.modalConfirm()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
  paginatedProspects: state.prospect.paginatedProspects,
  prospect: state.prospect.prospect,
  user: state.authentication.user,
  sellers: state.prospect.sellerList,
  isFetchingProspects: state.prospect.isFetchingProspects,
  isFetchingProspect: state.prospect.isFetchingProspect,
  isCreatingProspectComment: state.prospect.isCreatingProspectComment,
  isUpdatingProspect: state.prospect.isUpdatingProspect,
  isFetchingAllSellers: state.prospect.isFetchingAllSellers,
  isUpdatingProspectComment: state.prospect.isUpdatingProspectComment,
  isDeletingProspect: state.prospect.isDeletingProspectComment,
});

type TMapDispatchToProps = {
  createProspect: TCreateProspectAction;
  updateProspect: TUpdateProspectAction;
  fetchProspects: TFetchProspectsAction;
  fetchProspectById: TFetchProspectByIdAction;
  deleteProspect: TProspectDeleteAction;
  createProspectComment: TCreateProspectCommentAction;
  updateProspectComment: TUpdateProspectCommentAction;
  deleteProspectComment: TDeleteProspectCommentAction;
  fetchAllSellers: TFetchAllSellersAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  createProspect: (prospect: IProspectCreate) => dispatch(createProspect(prospect)),
  updateProspect: (prospectId: string, prospect: Partial<IProspect>) => dispatch(updateProspect(prospectId, prospect)),
  fetchProspects: (payload: IProspectFetch) => dispatch(fetchProspects(payload)),
  fetchProspectById: (prospectId: string) => dispatch(fetchProspectById(prospectId)),
  deleteProspect: (prospectId: string) => dispatch(deleteProspect(prospectId)),
  createProspectComment: (prospectId: string, comment: string) => dispatch(createProspectComment(prospectId, comment)),
  updateProspectComment: (id: string, comment: string) => dispatch(updateProspectComment(id, comment)),
  deleteProspectComment: (id: string) => dispatch(deleteProspectComment(id)),
  fetchAllSellers: () => dispatch(fetchAllSellers()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withTranslation("translations")((props: TProspectProps) => {
    const { prospectViewName, prospectId, insuranceId, insuranceDetailId } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [queryParams] = useSearchParams();
    const summaryViewName = queryParams.get("summary");
    return (
      <Prospects
        {...props}
        navigate={navigate}
        pathname={pathname}
        queryParams={queryParams}
        summaryViewName={summaryViewName ?? undefined}
        prospectViewName={prospectViewName}
        prospectId={prospectId}
        insuranceId={insuranceId}
        insuranceDetailId={insuranceDetailId}
      />
    );
  }),
);
