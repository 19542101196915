import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { withTranslation } from "react-i18next";
import { ReactComponent as DeleteIcon } from "../../assets/delete_icon.svg";
import FormikOccupationPicker from "./FormikOccupationPicker";
import FormikInput from "./FormikInput";
import { ApplicationOmsenDtosOccupationDto } from "../../api";

const FormikOccupation = ({ ...props }: any) => {
  const [occupationOption, setOccupationOption] = useState<ApplicationOmsenDtosOccupationDto | null>(null);
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);
  const { t } = props;

  const addOccupation = async () => {
    await formikProps.setFieldTouched(field.name, true, false);
    if (!occupationOption) {
      formikProps.setFieldError(field.name, "Can't be empty");
    } else {
      formikProps.setFieldError(field.name, "");
      await formikProps.setFieldValue(field.name, [
        ...formikProps.values.omsenData.employeePayrollDetails,
        { occupationCode: occupationOption.code, totalPayrollAmount: 0 },
      ]);
      setOccupationOption(null);
    }
  };

  const deleteOccupation = async (value: string) => {
    await formikProps.setFieldTouched(field.name, true, false);
    formikProps.setFieldError(field.name, "");
    await formikProps.setFieldValue(
      field.name,
      formikProps.values.omsenData.employeePayrollDetails.filter(
        (occupation: any) => occupation.occupationCode !== value,
      ),
    );
  };

  const isInvalid = Boolean(
    !!formikProps.getFieldMeta(field.name).error && formikProps.getFieldMeta(field.name).touched,
  );

  return (
    <Form.Group as={Row} className={"FormikOccupation form-group margin-auto-col"}>
      {props.title !== undefined && !props.disabled ? <Form.Label>{props.title}</Form.Label> : null}
      {!props.disabled && (
        <>
          <Col sm="10">
            <FormikOccupationPicker
              name={props.name}
              onSelectChange={setOccupationOption}
              value={occupationOption}
              exitingOccupations={formikProps.values.omsenData.employeePayrollDetails}
            />
          </Col>
          <Col sm="2">
            <Button className="AddButton" onClick={addOccupation}>
              +
            </Button>
          </Col>
        </>
      )}
      {formikProps.values.omsenData.employeePayrollDetails.length > 0 && (
        <Container>
          <Row>
            <Col>
              <Form.Label>{t("Occupation of the employee")}</Form.Label>
            </Col>
            <Col>
              <Form.Label>{t("Total payroll")} (€)</Form.Label>
            </Col>
          </Row>
          {formikProps.values.omsenData.employeePayrollDetails.map((employeePayrollDetails: any, index: number) => {
            return (
              <Row key={index}>
                <Col>
                  <FormikOccupationPicker
                    name={`omsenData.employeePayrollDetails[${index}].occupationCode`}
                    occupationText
                    occupationOption={employeePayrollDetails.occupationCode}
                  />
                </Col>
                <Col sm={!props.disabled ? "5" : "6"}>
                  <FormikInput
                    name={`omsenData.employeePayrollDetails[${index}].totalPayrollAmount`}
                    isInvalid={isInvalid}
                    type="number"
                    unit={"€"}
                    disabled={props.disabled}
                    zeroAsEmpty={true}
                  />
                </Col>
                {!props.disabled && <Col sm="1">
                  <DeleteIcon role={"buttons"} onClick={() => deleteOccupation(employeePayrollDetails.occupationCode)} />
                </Col>}
              </Row>
            );
          })}
        </Container>
      )}
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikOccupation);
