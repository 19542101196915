import React from "react";
import { withTranslation } from "react-i18next";
import { useField, useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import useOmsenIndustrialClassifications from "../../hooks/useOmsenIndustrialClassifications";
import Select from "react-select";
import {selectCustomStyle} from '../../styles/selectCustomStyle';

const FormikIndustrialClassificationPicker = ({ ...props }: any) => {
  const { data: industrialClassifications, isLoading } = useOmsenIndustrialClassifications();
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const isInvalid = Boolean(
    !!formikProps.getFieldMeta(field.name).error && formikProps.getFieldMeta(field.name).touched,
  );

  return (
    <Form.Group className={"FormikIndustrialClassificationPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Select
        options={industrialClassifications}
        getOptionValue={(option) => option.code?.toString() ?? ""}
        getOptionLabel={(option) => option.text ?? ""}
        isLoading={isLoading}
        isMulti={false}
        onChange={(newValue) => formikProps.setFieldValue(field.name, newValue?.code)}
        value={
          industrialClassifications
            ? industrialClassifications.find((option) => option.code === field.value)
            : { code: "", text: "" }
        }
        styles={selectCustomStyle(isInvalid)}
        className={isInvalid ? "is-invalid" : "is-valid"}
        isDisabled={props.disabled}
      />
      <Form.Control.Feedback type="invalid">
        {String(formikProps.getFieldMeta(field.name).error) ?? undefined}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikIndustrialClassificationPicker);
