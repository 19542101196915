import React from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import FormikCountryPicker from "./FormikCountryPicker";
import FormikTaxDomicilePicker from "./FormikTaxDomicilePicker";

const NaturalPerson = ({ ...props }) => {
  const formikProps = useFormikContext<any>();
  const { t, name } = props;

  return (
    <Form.Group>
      <FormikTaxDomicilePicker name={name ? `${name}.taxDomicileCode` : "taxDomicileCode"} title={t("Tax domicile")} />
      <FormikCountryPicker
        name={name ? `${name}.countryOfBirthNumber` : "countryOfBirthNumber"}
        title={t("Country of birth")}
        valueType="number"
      />
      <FormikCountryPicker
        name={name ? `${name}.citizenshipNumbers` : "citizenshipNumbers"}
        title={t("Citizenships")}
        isMulti
      />
      <Form.Check
        type={"checkbox"}
        checked={formikProps.getFieldProps(name ? `${name}.politicalExposedPerson` : "politicalExposedPerson").value}
        label={t("Political exposed person")}
        onChange={(e) =>
          formikProps.setFieldValue(
            name ? `${name}.politicalExposedPerson` : "politicalExposedPerson",
            e.currentTarget.checked,
          )
        }
      />
    </Form.Group>
  );
};

export default withTranslation("translations")(NaturalPerson);
