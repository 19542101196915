import { IProspect } from "../../../contracts/data/IProspect";
import { withTranslation, WithTranslation } from "react-i18next";
import React, { Component } from "react";
import { IInsurance, InsuranceType } from "../../../contracts/data/IInsurance";
import { Col, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import ProspectInsuranceSummaryRows from "./ProspectInsuranceSummaryRows";

type TProspectInsuranceSummaryProps = {
  prospect: IProspect;
  insurance?: IInsurance;
  readonly: boolean;
  currentInsuranceId?: string;
  currentInsuranceDetailId?: string;
  navigateToInsuranceView(insuranceId: string): void;
  navigateToInsuranceCostSummaryView(insuranceId: string, push?: boolean): any;
  navigateToInsuranceDetailView(insuranceId: string, insuranceDetailId: string, push?: boolean): any;
  navigateToDetailedView(push?: boolean): any;
} & WithTranslation;

type TProspectInsuranceSummaryState = {};

class ProspectInsuranceSummary extends Component<TProspectInsuranceSummaryProps, TProspectInsuranceSummaryState> {
  render() {
    const { t, insurance } = this.props;

    if (insurance === undefined) {
      return (
        <div className={"ProspectInsuranceSummary p-3 collapsed"}>
          <div className={"ProspectInsuranceSummary-inner"}>
            <p>{t("Insurance not found")}</p>
          </div>
        </div>
      );
    }

    const isCollapsed = this.props.currentInsuranceId !== undefined;
    const isCurrentlyOpen = this.props.currentInsuranceId === insurance.id;

    let title = "";
    let letter = "";
    let yelIncome = "";

    switch (insurance.type) {
      case InsuranceType.Current: {
        title = t("Current insurance");
        letter = "A";
        yelIncome = t("YEL income");
        break;
      }
      case InsuranceType.Alternative: {
        title = t("Alternative insurance");
        letter = "B";
        yelIncome = t("YEL income");
        break;
      }
      case InsuranceType.Offer: {
        title = t("Company security");
        letter = "C";
        yelIncome = t("YEL income");
        break;
      }
      case InsuranceType.Omsen: {
        title = t("Ålands Försäkringar Ab");
        letter = "D";
        yelIncome = t("YEL income");
        break;
      }
    }

    const footerWithDetailedSummery = (insurance: IInsurance) => {
      type TSummeryRow = {
        id: string;
        title: string;
        annualCost: number;
        monthlyCost: number;
      };

      const optionalInsuranceCost = insurance.annualCost - insurance.annualCostYel;
      const summeryRows: TSummeryRow[] = [
        {
          id: "yel",
          title: t("YEL"),
          annualCost: insurance.annualCostYel,
          monthlyCost: insurance.annualCostYel / 12,
        },
        {
          id: "optionalInsurance",
          title: t("Additional insurance"),
          annualCost: optionalInsuranceCost,
          monthlyCost: optionalInsuranceCost / 12,
        },
      ];

      return (
        <Col className='d-flex flex-column justify-content-end mb-2'>
          {summeryRows.map((row) => (
            <Row key={row.id} className="details">
              <Col xs={6} className="details-label py-0 fw-bolder">
                {row.title}
              </Col>
              <Col xs={6} className={"cost-value text-end"}>
                <span>
                  <NumericFormat
                    value={row.monthlyCost}
                    displayType={"text"}
                    thousandSeparator={" "}
                    decimalScale={0}
                    suffix={` €/${t("month_unit")}`}
                  />
                </span>
              </Col>
            </Row>
          ))}
        </Col>
      );
    };

    const isHidden = insurance.isVisible !== true;
    const isClickable = !isHidden && this.props.readonly === false;
    const isFooterClickable = !isHidden && this.props.readonly === false && insurance.type === InsuranceType.Offer;
    const isWithOutyelIncome = insurance.type === InsuranceType.Omsen;

    if (isCurrentlyOpen) {
      return (
        <div className={"ProspectInsuranceSummary p-3 collapsed" + (isHidden ? " hidden" : "")}>
          <div className={"ProspectInsuranceSummary-inner"}>
            <Row>
              <Col
                className={"d-flex align-items-center mt-3" + (isClickable ? " clickable" : "")}
                onClick={() => (isClickable ? this.props.navigateToDetailedView() : null)}
              >
                <span className={"insurance-letter"}>
                  <span>{letter}</span>
                </span>
                <span className={"insurance-letter-title-group ps-3"}>
                  <h5 className={"mb-0"}>{t("Annual cost")}</h5>
                  <h4 className={"annual-cost"}>
                    <NumericFormat
                      value={insurance.annualCost}
                      displayType={"text"}
                      suffix={" €"}
                      thousandSeparator={" "}
                      decimalScale={0}
                    />
                  </h4>
                </span>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else if (isCollapsed) {
      return (
        <div
          className={"ProspectInsuranceSummary p-3" + ((isClickable ? " clickable" : "") + (isHidden ? " hidden" : ""))}
        >
          <div className={"ProspectInsuranceSummary-inner"}></div>
        </div>
      );
    }

    return (
      <div className={"ProspectInsuranceSummary p-3" + (isHidden ? " hidden" : "")}>
        <div className={"ProspectInsuranceSummary-inner"}>
          <div className={"ProspectInsuranceSummary-header"}>
            <Row>
              <Col
                className={"d-flex align-items-center mb-3" + (isClickable ? " clickable" : "")}
                onClick={() => (isClickable ? this.props.navigateToInsuranceView(insurance.id) : null)}
              >
                <span className={"insurance-letter"}>
                  <span>{letter}</span>
                </span>
                <h3 className={"insurance-letter-title ps-3 mb-0"}>{title}</h3>
              </Col>
            </Row>
            <div className={`${isWithOutyelIncome ? "invisible" : ""}`}>
              <Row>
                <Col>
                  <h4 className={"mb-0"}>{yelIncome}</h4>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h3 className={"mb-0"}>
                    <NumericFormat
                      value={insurance.annualIncomeYel ?? 0}
                      displayType={"text"}
                      thousandSeparator={" "}
                    />
                    <span className={"value-suffix"}> €/{t("year_unit")}</span>
                  </h3>
                </Col>
              </Row>
            </div>
            <div className={"separator"} />
            <ProspectInsuranceSummaryRows
              insurance={insurance}
              readonly={this.props.readonly}
              navigateToInsuranceDetailView={this.props.navigateToInsuranceDetailView}
            />
            <div className={"separator"} />
          </div>
          <div
            onClick={() => isFooterClickable && this.props.navigateToInsuranceCostSummaryView(insurance.id)}
            className={
              isFooterClickable ? "ProspectInsuranceSummary-withDetail clickable" : "ProspectInsuranceSummary-footer"
            }
          >
            <Row>
              {insurance.type === InsuranceType.Omsen && (
                <Col>
                  <h4 className={"mb-0"}>{t("Annual cost")}</h4>
                  <h4 className={"annual-cost text-nowrap pe-2"}>
                    <NumericFormat
                      value={insurance.annualCost}
                      displayType={"text"}
                      suffix={" €"}
                      thousandSeparator={" "}
                      decimalScale={0}
                    />
                  </h4>
                </Col>
              )}
              <Col>
                <h4 className={"mb-0"}>{t("Monthly cost")}</h4>
                <h4 className={"annual-cost text-nowrap"}>
                  <NumericFormat
                    value={insurance.monthlyCost}
                    displayType={"text"}
                    suffix={` €/${t("month_unit")}`}
                    thousandSeparator={" "}
                    decimalScale={0}
                  />
                </h4>
              </Col>
              {isFooterClickable && this.props.insurance && footerWithDetailedSummery(this.props.insurance)}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(ProspectInsuranceSummary);
