import { useTranslation } from "react-i18next";
import { IInsuranceDetail, InsuranceDetailType } from "../../../../contracts/data/IInsuranceDetail";
import { ClientsOmsenProduct } from "../../../../api";
import { IInsurance } from "../../../../contracts/data/IInsurance";

const getInsuranceDetailSummaryTitle = (args: {
  t: ReturnType<typeof useTranslation>["t"];
  insuranceDetail: IInsuranceDetail;
  page?: boolean;
  omsenProducts?: ClientsOmsenProduct[];
  insurance?: IInsurance;
}): string => {
  const { t, insuranceDetail, page, omsenProducts, insurance } = args;

  switch (insuranceDetail.type) {
    case InsuranceDetailType.DisabilityPension:
    case InsuranceDetailType.DisabilityPensionCommon:
      return page ? t("Disability pension") : t("Disability pension statutory");
    case InsuranceDetailType.DisabilityPensionOneOff:
      return t("Disability pension one-off payment");
    case InsuranceDetailType.FamilyPension:
      return t("Family pension");
    case InsuranceDetailType.FamilyWidowPension:
      return t("Family widow pension");
    case InsuranceDetailType.FamilyChildPension:
      return t("Family child pension");
    case InsuranceDetailType.FamilyPensionOneOff:
      return t("Family pension one-off payment");
    case InsuranceDetailType.OldAgePension:
      return t("Old age pension");
    case InsuranceDetailType.OldAgeExtraPension: {
      let title = t("Old age pension extra");
      if (insurance?.optionalOldAgeExtraPensionStartAge && insurance.optionalOldAgeExtraPensionEndAge) {
        title += ` ${insurance.optionalOldAgeExtraPensionStartAge}-${insurance.optionalOldAgeExtraPensionEndAge}v`;
      }
      return title + " (" + t("optional") + ")";
    }
    case InsuranceDetailType.SicknessPay:
    case InsuranceDetailType.SicknessPayCommon:
      return page ? t("Sickness benefit") : t("Sickness benefit statutory");
    case InsuranceDetailType.SicknessPayOptional:
      return t("Sickness optional benefit");
    case InsuranceDetailType.DisabilityOldAgeCommonPension:
    case InsuranceDetailType.OldAgeCommonPension:
      return t("Common pension");
    case InsuranceDetailType.OldAgeWorkPension:
      return t("Work pension");
    case InsuranceDetailType.OldAgeWarrantyPension:
      return t("Warranty pension");
    case InsuranceDetailType.SeriousIllnessOneOff:
      return t("Serious illness one-off payment");
    case InsuranceDetailType.LifeInsurance:
      if (insuranceDetail.monthlyAmount === 0 && insuranceDetail.oneOffAmount > 0) {
        return t("Life insurance");
      }
      return t("Life insurance until end");
    case InsuranceDetailType.PermanentDisabilityInsurance:
      return t("Permanent disability insurance");
    case InsuranceDetailType.OmsenPremium:
      return omsenProducts?.find((x) => x.code === insuranceDetail.omsenProductCode)?.text ?? "";
    default:
      return String(insuranceDetail.type);
  }
};

export default getInsuranceDetailSummaryTitle;
