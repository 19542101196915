import React from "react";
import { Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";
import { withTranslation } from "react-i18next";
import { getFullName } from "../../utils/appUtils";
import { ISeller } from "../../contracts/data/ISeller";

const FormikSellerPicker = ({ ...props }: any) => {
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const handleChange = (e: any) => {
    const value = e.currentTarget.value;

    formikProps.setFieldTouched(field.name, true, false);
    formikProps.setFieldError(field.name, "");
    formikProps.setFieldValue(field.name, value);
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);

  return (
    <Form.Group className={"FormikSellerPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Form.Control
        name={field.name}
        as={"select"}
        value={field.value != null ? field.value : undefined}
        disabled={props.disabled}
        onChange={(e) => handleChange(e)}
        isInvalid={isInvalid}
      >
        {field.value === "" || field.value === null ? <option value={""} key={"-"}></option> : null}
        {props.sellers.map((seller: ISeller) => (
            <option value={seller.id} key={seller.id}>
            {getFullName(seller.firstName, seller.lastName)}
            </option>
        ))}
      </Form.Control>
      <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikSellerPicker);
