import React from "react";
import { useField, useFormikContext } from "formik";
import { InsurancePaymentInterval } from "../../contracts/data/IInsurance";
import { withTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import {selectCustomStyle} from '../../styles/selectCustomStyle';
import Select from 'react-select';

const FormikInsurancePaymentIntervalPicker = ({ ...props }: any) => {
  const { t } = props;
  const formikProps = useFormikContext<any>();
  const [field] = useField(props);

  const getValidIntervals = () => {
    const result = [];

    for (let interval in InsurancePaymentInterval) {
      const value = Number(interval);

      if (!isNaN(value)) {
        if (value === 1) {
          result.push([value, `${value} ${t("time/y")}`]);
        } else {
          result.push([value, `${value} ${t("times/y")}`]);
        }
      }
    }

    return result;
  };

  const handleChange = async (value: any) => {
    await formikProps.setFieldTouched(field.name, true, false);
    formikProps.setFieldError(field.name, "");
    await formikProps.setFieldValue(field.name, Number(value));
  };

  const isInvalid = Boolean(!!formikProps.errors[field.name] && formikProps.touched[field.name]);
  const exitingValue = getValidIntervals().find((option) => option[0] === field.value);


  return (
    <Form.Group className={"FormikInsurancePaymentIntervalPicker form-group"}>
      {props.title !== undefined ? <Form.Label>{props.title}</Form.Label> : null}
      <Select
        options={getValidIntervals().map(option => ({value: option[0], label: option[1] }))}
        onChange={(newValue) => newValue && handleChange(newValue.value)}
        value={exitingValue ? { value: exitingValue[0], label: exitingValue[1] } : { value: 0, label: ""}}
        className={isInvalid ? "is-invalid" : "is-valid"}
        onBlur={formikProps.handleBlur}
        styles={selectCustomStyle(isInvalid)}
      />
      <Form.Control.Feedback type="invalid">{String(formikProps.errors[field.name])?? undefined}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default withTranslation("translations")(FormikInsurancePaymentIntervalPicker);
