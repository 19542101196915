import React, { Component } from "react";
import { NavigateFunction, Navigate, createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import { DefaultLayout } from "../layouts/DefaultLayout";
import { LoginLayout } from "../layouts/LoginLayout";
import * as paths from "./routePaths";
import Prospects from "../pages/Prospects/Prospects";
import { TAppRootState } from "../../redux/types/stateTypes";
import { connect } from "react-redux";
import { IUser } from "../../contracts/data/IUser";
import ProspectCreate from "../pages/Prospects/ProspectCreate";
import { StickyImageLayout } from "../layouts/StickyImageLayout";
import ProspectEditBasicInformation from "../pages/Prospects/ProspectEditBasicInformation";
import MeetingRedirect from "../shared/MeetingRedirect";
import Meeting from "../pages/Prospects/ProspectPresentation";
import Booker from "../pages/Booker/Booker";
import HeaderButton from "../shared/HeaderButton";
import Admin from "../pages/Admin/Admin";

type TRoutesProps = {
  isLoggedIn: boolean;
  user: IUser | null;
};

type TRoutesState = {};

class Routes extends Component<TRoutesProps, TRoutesState> {
  render() {
    const routes = [
      {
        path: paths.admin,
        element: (
          <DefaultLayout showHeaderButtons={true} userRoles={this.props.user?.roles}>
            <Admin isLoggedIn={this.props.isLoggedIn} />
          </DefaultLayout>
        ),
      },
      {
        path: paths.home,
        element: (
          <LoginLayout>
            <Home />
          </LoginLayout>
        ),
      },
      {
        path: paths.login,
        element: (
          <LoginLayout>
            <Login queryParams={{} as URLSearchParams} />
          </LoginLayout>
        ),
      },
      {
        path: paths.booker,
        element: (
          <DefaultLayout showHeaderButtons={true}>
            <Booker isLoggedIn={this.props.isLoggedIn} />
          </DefaultLayout>
        ),
      },
      {
        path: paths.meeting,
        element: (
          <DefaultLayout>
            <MeetingRedirect />
          </DefaultLayout>
        ),
      },

      {
        path: paths.meetingBasicInformation,
        element: (
          <StickyImageLayout>
            <ProspectEditBasicInformation />
          </StickyImageLayout>
        ),
      },
      {
        path: paths.meetingPresentation,
        element: (
          <DefaultLayout
            headerButtons={<HeaderButton text="Takaisin" href={paths.prospectsList} />}
            showSidebarUserButton={true}
            showSidebarAdminButton={false}
          >
            <Meeting queryParams={{} as URLSearchParams} navigate={{} as NavigateFunction} pathname="" />
          </DefaultLayout>
        ),
      },
    ];

    [paths.prospectCreate, paths.prospectCreated, paths.prospectEmailSent, paths.prospectCopy].forEach((path) =>
      routes.push({
        path,
        element: (
          <DefaultLayout
            showHeaderButtons={true}
            headerButtons={<HeaderButton text="Takaisin" href={paths.prospectsList} />}
            userRoles={this.props.user?.roles}
          >
            <ProspectCreate navigate={{} as NavigateFunction} pathname="" />
          </DefaultLayout>
        ),
      }),
    );
    [paths.prospectsList, paths.prospect, paths.prospectInsurance, paths.prospectInsuranceDetail].forEach((path) =>
      routes.push({
        path,
        element: (
          <DefaultLayout
            calculateShowAdminButton={this.props.isLoggedIn && this.props.user !== null}
            calculateShowUserButton
            userRoles={this.props.user?.roles}
            headerButtons={<HeaderButton text="Create customer" href={paths.prospectCreate} />}
          >
            <Prospects
              navigate={{} as NavigateFunction}
              pathname=""
              queryParams={{} as URLSearchParams}
              loggedInUser={this.props.user}
            />
          </DefaultLayout>
        ),
      }),
    );
    routes.push({ path: "*", element: <Navigate replace to={paths.home} /> });
    const router = createBrowserRouter(routes);
    return (
      <div className="Routes">
        <RouterProvider router={router} />
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
  user: state.authentication.user,
});

const mapDispatchToProps = (dispatch: any) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
