import React, { Component } from "react";
import { TAppRootState } from "../../redux/types/stateTypes";
import { connect } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import syt_logo_white from "./../../assets/app_header_logo_white.png";
import syt_logo_black from "./../../assets/app_header_logo_black.png";
import { withTranslation, WithTranslation } from "react-i18next";
import { logoutUser, TLogoutUserAction } from "../../redux/actions/authentication";
import { IProspect } from "../../contracts/data/IProspect";
import * as paths from "./../Routes/routePaths";
import { getFullName } from "../../utils/appUtils";
import HeaderButton from "../shared/HeaderButton";

type TAppHeaderProps = {
  logoutUser: TLogoutUserAction;
  isLoggedIn: boolean;
  isLoggingOut: boolean;
  prospectId?: string;
  prospectReference?: string;
  prospectViewName?: string;
  prospect: IProspect | null;
  showHeaderButtons?: boolean;
  headerButtons: React.ReactNode;
} & WithTranslation;

type TAppHeaderState = {};

class AppHeader extends Component<TAppHeaderProps, TAppHeaderState> {
  handleLogout = async () => {
    await this.props.logoutUser();
  };

  getCurrentProspect = (): IProspect | undefined => {
    const { prospectReference, prospectId, prospect } = this.props;
    if (prospect === null) {
      return undefined;
    }

    if (prospectReference !== undefined) {
      return prospect.reference === prospectReference ? prospect : undefined;
    }
    return prospect.id === prospectId ? prospect : undefined;
  };

  renderProspectAppHeader = () => {
    const { prospectViewName, prospectReference } = this.props;
    const prospect = this.getCurrentProspect();
    let currentProspectViewName = prospectViewName;

    if (prospectReference !== undefined && prospect !== undefined) {
      const urlParts = prospect.view ? prospect.view.split("/") : [];
      if (urlParts.length >= 1) {
        currentProspectViewName = urlParts[0];
      }
    }

    const sytLogo: any = (prospectViewName: string) => {
      switch (prospectViewName) {
        case "basic":
          return (
            <>
              <img src={syt_logo_white} alt={"Logo"} />
            </>
          );
        default:
          return (
            <>
              <img src={syt_logo_black} alt={"Logo"} />
            </>
          );
      }
    };

    if (currentProspectViewName !== "basic") {
      return (
        <Row className={"py-3 mb-2"}>
          <Col xs={12} md={6} className={"logo-container d-flex justify-content-start align-items-center"}>
            {sytLogo(currentProspectViewName)}
          </Col>
        </Row>
      );
    }

    return (
      <>
        <div className={"AppHeader-background"}></div>
        <Row className={"py-3 mb-2 has-background"}>
          <Col xs={12} md={6} className={"logo-container d-flex justify-content-start align-items-center"}>
            {sytLogo(currentProspectViewName)}
          </Col>
        </Row>
        <Row className={"py-3 mb-1 has-background"}>
          <Col>
            <h1 className={"customer-name"}>
              {prospect !== undefined ? getFullName(prospect.firstName, prospect.lastName) : ""}
            </h1>
          </Col>
        </Row>
      </>
    );
  };

  renderDefaultAppHeader = () => {
    const { t } = this.props;

    const showButtons = this.props.isLoggedIn && this.props.prospectReference === undefined;

    return (
      <Row className={"py-4 mb-3"}>
        <Col xs={12} md={6} className={"logo-container d-flex justify-content-start align-items-center"}>
          <img src={syt_logo_black} alt={"Logo"} />
        </Col>
        <Col xs={12} md={6} className={"d-flex justify-content-end"}>
          {showButtons ? (
            <>
              {this.props.headerButtons}
              <HeaderButton
                text="Logout"
                variant="link"
                onClick={this.handleLogout}
                disabled={this.props.isLoggingOut}
                className="btn-header ms-1 align-self-center btn-logout"
              />
            </>
          ) : null}
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div className="AppHeader" id={"AppHeader"}>
        {(this.props.prospectId || this.props.prospectReference) && !this.props.showHeaderButtons
          ? this.renderProspectAppHeader()
          : this.renderDefaultAppHeader()}
      </div>
    );
  }
}

const mapStateToProps = (state: TAppRootState) => ({
  isLoggedIn: state.authentication.isLoggedIn,
  isLoggingOut: state.authentication.isLoggingOut,
  prospect: state.prospect.prospect,
});

type TMapDispatchToProps = {
  logoutUser: TLogoutUserAction;
};

const mapDispatchToProps = (dispatch: any): TMapDispatchToProps => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translations")(AppHeader));
