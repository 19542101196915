import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { IProspect } from "../../../contracts/data/IProspect";
import { Button, Col, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import ProspectInsuranceSummary from "./ProspectInsuranceSummary";
import { IInsurance, InsuranceType } from "../../../contracts/data/IInsurance";
import ProspectInsuranceDetailSummary from "./ProspectInsuranceDetailSummary";
import { motion } from "framer-motion";
import FadingContainer from "../../shared/FadingContainer";
import { getFullName } from "../../../utils/appUtils";

type TProspectDetailedViewProps = {
  prospect: IProspect;
  readonly: boolean;
  currentInsuranceId?: string;
  currentInsuranceDetailId?: string;
  summaryViewName?: string;
  navigateToInsuranceView(insuranceId: string, push?: boolean): any;
  navigateToInsuranceCostSummaryView(insuranceId: string, push?: boolean): any;
  navigateToInsuranceDetailView(insuranceId: string, insuranceDetailId: string, push?: boolean): any;
  navigateToDetailedView(push?: boolean): any;
  navigateToBasicView(push?: boolean): any;
} & WithTranslation;

type TProspectDetailedViewState = {};

class ProspectDetailedView extends Component<TProspectDetailedViewProps, TProspectDetailedViewState> {
  renderProspectDetails = (prospect: IProspect) => {
    const { t } = this.props;

    type TInformationItem = {
      itemProperty: any;
      itemElement(): any;
    };

    const informationItems: TInformationItem[] = [];

    informationItems.push({
      itemProperty: prospect.annualIncomeYel,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"annualIncomeYel"}>
          <FadingContainer trigger={"annualIncomeYel_" + String(prospect.annualIncomeYel)}>
            <div className={"h4 mb-0 item-title"}>{t("YEL income")}</div>
            <div className={"h2 mb-0 item-value"}>
              <NumericFormat value={Number(prospect.annualIncomeYel)} displayType={"text"} thousandSeparator={" "} />
              <span className={"value-suffix"}> €/{t("year_unit")}</span>
            </div>
          </FadingContainer>
        </Col>
      ),
    });

    informationItems.push({
      itemProperty: prospect.monthlyPension,
      itemElement: () => (
        <Col xs={12} lg={4} className={"mb-2"} key={"monthlyPension"}>
          <FadingContainer trigger={"monthlyPension_" + String(prospect.monthlyPension)}>
            <div className={"h4 mb-0 item-title"}>{t("Accumulated pension to date")}</div>
            <div className={"h2 mb-0 item-value"}>
              <NumericFormat value={Number(prospect.monthlyPension)} displayType={"text"} thousandSeparator={" "} />
              <span className={"value-suffix"}> €/{t("month_unit")}</span>
            </div>
          </FadingContainer>
        </Col>
      ),
    });

    return (
      <div className={"ProspectDetailedView"}>
        <Row>
          <Col className={"pt-3 text-uppercase"}>
            <h2>{getFullName(prospect.firstName, prospect.lastName)}</h2>
          </Col>
        </Row>
        <Row>{informationItems.map((x) => (x.itemProperty !== null ? x.itemElement() : null))}</Row>
      </div>
    );
  };

  renderProspectInsuranceDetailSummary = () => {
    const currentInsuranceId = this.props.currentInsuranceId;
    const insurance = this.props.prospect.insurances.find((i) => i.id === currentInsuranceId);

    if (insurance === undefined || currentInsuranceId === undefined) {
      return <p>No insurance data found</p>;
    }

    return (
      <ProspectInsuranceDetailSummary
        prospect={this.props.prospect}
        readonly={this.props.readonly}
        insurance={insurance}
        currentInsuranceId={currentInsuranceId}
        currentInsuranceDetailId={this.props.currentInsuranceDetailId}
        summaryViewName={this.props.summaryViewName}
        navigateToInsuranceView={this.props.navigateToInsuranceView}
        navigateToInsuranceDetailView={this.props.navigateToInsuranceDetailView}
        navigateToDetailedView={this.props.navigateToDetailedView}
      />
    );
  };

  handleClickScroll = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  getInsuranceOmsen = () => {
    return this.props.prospect.insurances.find((i) => i.type === InsuranceType.Omsen);
  };

  insuranceCurrent = () => {
    return this.props.prospect.insurances.find((i) => i.type === InsuranceType.Current);
  };

  componentDidMount() {
    if (
      this.props.currentInsuranceId === this.getInsuranceOmsen()?.id ||
      (this.getInsuranceOmsen()?.isVisible && !this.insuranceCurrent()?.isVisible)
    ) {
      this.handleClickScroll("Omsen-option");
    }
  }

  componentDidUpdate(prevProps: Readonly<TProspectDetailedViewProps>) {
    if (this.props.currentInsuranceId !== prevProps.currentInsuranceId) {
      if (this.props.currentInsuranceId === this.getInsuranceOmsen()?.id) {
        this.handleClickScroll("Omsen-option");
      }
    }

    if (this.getInsuranceOmsen()?.isVisible && !this.insuranceCurrent()?.isVisible) {
      this.handleClickScroll("Omsen-option");
    }
  }

  render() {
    const { prospect } = this.props;

    const insuranceAlternative = prospect.insurances.find((i) => i.type === InsuranceType.Alternative);
    const insuranceOffer = prospect.insurances.find((i) => i.type === InsuranceType.Offer);
    const isOmsenInsuranceVisible =
      this.getInsuranceOmsen() &&
      (this.getInsuranceOmsen()?.annualCost ?? 0) > 0 &&
      this.getInsuranceOmsen()?.isVisible;

    const hasVisibleInsurances =
      prospect.insurances.find((i) => i.isVisible && i.type !== InsuranceType.Comparison) !== undefined;

    if (!hasVisibleInsurances) {
      return !this.props.readonly ? this.props.navigateToBasicView(false) : <div></div>;
    }

    const createProspectInsuranceSummary = (insurance?: IInsurance) => {
      return (
        <ProspectInsuranceSummary
          prospect={this.props.prospect}
          readonly={this.props.readonly}
          insurance={insurance}
          currentInsuranceId={this.props.currentInsuranceId}
          currentInsuranceDetailId={this.props.currentInsuranceDetailId}
          navigateToInsuranceView={this.props.navigateToInsuranceView}
          navigateToInsuranceCostSummaryView={this.props.navigateToInsuranceCostSummaryView}
          navigateToInsuranceDetailView={this.props.navigateToInsuranceDetailView}
          navigateToDetailedView={this.props.navigateToDetailedView}
        />
      );
    };

    const key: string = String(this.props.currentInsuranceId);

    return (
      <div className={"py-4 px-4"} key={key}>
        {this.props.currentInsuranceId === undefined
          ? this.renderProspectDetails(prospect)
          : this.renderProspectInsuranceDetailSummary()}

        <Row id={"ProspectInsurances"}>
          {isOmsenInsuranceVisible && !this.props.currentInsuranceId && (
            <Button className={"ScrollingButton start-0"} onClick={() => this.handleClickScroll("Current-option")}>
              {"<"}
            </Button>
          )}
          <Col xs={12}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: "easeIn", duration: 0.25 }}
            >
              <Row className={"mt-4 max-w-full overflow-x-hidden flex-lg-nowrap"}>
                <Col id="Current-option" className={"d-flex align-items-stretch mb-3"} xs={12} lg={4}>
                  {createProspectInsuranceSummary(this.insuranceCurrent())}
                </Col>
                <Col className={"d-flex align-items-stretch mb-3"} xs={12} lg={4}>
                  {createProspectInsuranceSummary(insuranceAlternative)}
                </Col>
                <Col className={"d-flex align-items-stretch mb-3"} xs={12} lg={4}>
                  {createProspectInsuranceSummary(insuranceOffer)}
                </Col>
                {isOmsenInsuranceVisible && (
                  <Col id="Omsen-option" className={"d-flex align-items-stretch mb-3"} xs={12} lg={4}>
                    {createProspectInsuranceSummary({ ...this.getInsuranceOmsen(), isVisible: true } as IInsurance)}
                  </Col>
                )}
              </Row>
            </motion.div>
          </Col>
          {isOmsenInsuranceVisible && !this.props.currentInsuranceId && (
            <Button className={"ScrollingButton end-0"} onClick={() => this.handleClickScroll("Omsen-option")}>
              {">"}
            </Button>
          )}
        </Row>
        {isOmsenInsuranceVisible && (
          <Row id={"ProspectInsurancesForPDF"} className={"d-none"}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ease: "easeIn", duration: 0.25 }}
            >
              <Row className={"mt-4 max-w-full"}>
                <Col id="Current-option" className={"d-flex align-items-stretch mb-3"} xs={12} lg={6}>
                  {createProspectInsuranceSummary(this.insuranceCurrent())}
                </Col>
                <Col className={"d-flex align-items-stretch mb-3"} xs={12} lg={6}>
                  {createProspectInsuranceSummary(insuranceAlternative)}
                </Col>
                <Col className={"d-flex align-items-stretch mb-3"} xs={12} lg={6}>
                  {createProspectInsuranceSummary(insuranceOffer)}
                </Col>
                {isOmsenInsuranceVisible && (
                  <Col id="Omsen-option" className={"d-flex align-items-stretch mb-3"} xs={12} lg={6}>
                    {createProspectInsuranceSummary({ ...this.getInsuranceOmsen(), isVisible: true } as IInsurance)}
                  </Col>
                )}
              </Row>
            </motion.div>
          </Row>
        )}
      </div>
    );
  }
}

export default withTranslation("translations")(ProspectDetailedView);
